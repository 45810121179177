import React from 'react';
import { Form } from 'react-bootstrap';

const SwitchFilter = ({
  controlId,
  label,
  value,
  onChange,
  disabled,
  className,
}) => {
  return (
    <Form.Group className={`${className} filter-item filter-item-switch`}>
      <Form.Check
        id={controlId}
        type="switch"
        label={label}
        checked={value || false}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default SwitchFilter;
