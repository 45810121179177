import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputFilter from '../widgets/filters/InputFilter';
import FiltersPannel from '../widgets/filters/FiltersPannel';

const MainFilters = ({
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <InputFilter
        controlId="filter-group-name"
        label={t('storeGroups.name', 'Name')}
        value={filters.name}
        onChange={(name) => setFilters({ ...filters, name })}
      />
      <InputFilter
        controlId="filter-group-email"
        label={t('storeGroups.ownerEmail', "Owner's email")}
        value={filters.owner_email}
        onChange={(owner_email) => setFilters({ ...filters, owner_email })}
      />
    </>
  );
};

const Filters = ({
  filters,
  setFilters,
  onClear,
}) => (
  <FiltersPannel
    onClear={onClear}
    mainFilters={
      <MainFilters
        filters={filters}
        setFilters={setFilters}
      />
    }
    exportButton={
      <Button className="icon-button add-group-button" as={Link} to="/admin/store_groups/new">
        <i className="vtmn-icon_edit_plus"></i>
        <Trans i18nKey="storeGroups.addGroup">Add group</Trans>
      </Button>
    }
  />
);

export default Filters;