import handleApiResponse from './handleApiResponse';
import fetchWithJWT from './fetchWithJWT';

function getPilotSites(user, updateTokens) {
  const { token, refreshToken, tokenExpireDate } = user;
  return fetchWithJWT(`${process.env.REACT_APP_base_URL}/api/stores/pilot`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

export default getPilotSites;
