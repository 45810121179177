import { handleApiResponse } from '../functions/handleApiResponse';

// seconds before expire time - when we need to refresh
const SECONDS_BEFORE_EXPIRE = 60;

const serverUrl = process.env.REACT_APP_base_URL;
// const apiKey = process.env.REACT_APP_APIKEY;

const refreshJWT = async (refreshToken, updateTokens) => {
  let tokens;
  try {
    const resp = await fetch(`${serverUrl}/api/auth/refresh`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        // "x-api-key" : apiKey,
        Authorization: `Bearer ${refreshToken}`,
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      })
    });
    tokens = await handleApiResponse(resp);
  } catch (e) {
    console.warn(`refresh token error ${e.status} ${e}`);
    window.location.reload();
    return;
  }

  const token = tokens.access_token;
  const newRefreshToken = tokens.refresh_token;
  const tokenExpiresIn = tokens.expires_in;
  if (updateTokens) {
    await updateTokens({ token, refreshToken: newRefreshToken, tokenExpiresIn });
  }
  return { token, refreshToken: newRefreshToken };
};

class JWTManager {
  constructor() {
    this.refreshPromise = null;
  }

  getAuthHeaders = async ({ token, refreshToken, tokenExpireDate, updateTokens }) => {
    if (!token) {
      return {
        //"x-api-key" : apiKey,
      };
    }

    if (this.refreshPromise) {
      // is refreshing now
      try {
        const headers = await this.refreshPromise;
        return headers;
      } catch (e) {
        // pass
      }
    }

    const now = Date.now() / 1000;
    const needRefresh = tokenExpireDate - now < SECONDS_BEFORE_EXPIRE;

    if (needRefresh && refreshToken) {
      // need to refresh
      this.refreshPromise = refreshJWT(refreshToken, updateTokens)
        .then((resp) => {
          const { token: newToken } = resp;
          this.refreshPromise = null;
          return {
            // "x-api-key" : apiKey,
            Authorization: `Bearer ${newToken}`,
          };
        });
      try {
        const headers = await this.refreshPromise;
        return headers;
      } catch (e) {
        this.refreshPromise = null;
      }
    }

    return {
      //"x-api-key" : apiKey,
      Authorization: `Bearer ${token}`,
    };
  };
}

export default JWTManager;
