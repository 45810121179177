import React from 'react';
import {
  Row,
  Spinner,
  Form,
  Button,
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { formatDateTime } from '../../../../functions/formatDate';
import formatMoney from '../../../../functions/formatMoney';

let counter = 0;

export const getFields = ({
  t,
  discrepancyType,
}) => {
  return [
    {
      title: t('declareToControl.columns.store', 'Store'),
      field: 'store',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.delivery', 'Delivery'),
      field: 'delivery',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.date', 'Date'),
      field: 'receptionDate',
      sortable: true,
      default: true,
      valueRenderer: (val) => val ? formatDateTime(new Date(val)) : null,
    },
    ...(discrepancyType === 'expedition' ? [{
      title: t('declareToControl.columns.parcels', 'Parcels'),
      field: 'content',
      sortable: false,
      default: true,
      valueRenderer: (val) => (val || []).map(x => <div key={x.parcel}>{x.parcel}</div>),
    }] : []),
    {
      title: (
        discrepancyType === 'wrong_size'
          ? t('declareToControl.columns.receivedItem', 'Received item')
          : t('declareToControl.columns.item', 'Item')
      ),
      field: 'item',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.modelId', 'Model Id'),
      field: 'modelId',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.rfid', 'RFID'),
      field: 'articleFlag',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.price', 'Selling price'),
      field: 'price',
      align: 'right',
      sortable: true,
      default: true,
      valueRenderer: (val, field, row) => formatMoney(val, row.currency, '-'),
    },
    {
      title: t('declareToControl.columns.cessionPrice', 'Cession price'),
      field: 'cessionPrice',
      align: 'right',
      sortable: true,
      default: true,
      valueRenderer: (val, field, row) => formatMoney(val, row.cessionCurrency, '-'),
    },
    {
      title: t('declareToControl.columns.itemLib', 'Item description'),
      field: 'itemLib',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.family', 'Family'),
      field: 'familyLabel',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.subDepartment', 'Sub department'),
      field: 'subDepartmentLabel',
      sortable: true,
      default: true,
    },
    {
      title: t('declareToControl.columns.universe', 'Universe'),
      field: 'universeLabel',
      sortable: true,
      default: true,
    },
    ...(discrepancyType === 'expedition' ? [
      {
        title: t('declareToControl.columns.shippingList', 'Shipping list'),
        field: 'qtyConfirmed',
        sortable: true,
        default: true,
      },
      {
        title: t('declareToControl.columns.pickedParty', 'Picked party'),
        field: 'picked_party',
        sortable: false,
        default: true,
        valueRenderer: (val) => (val || []).join(', '),
      },
      {
        title: t('declareToControl.columns.readInWarehouse', 'Read in warehouse'),
        field: 'qtyReadBeforeWarehouseGate',
        sortable: true,
        default: true,
      },
      {
        title: t('declareToControl.columns.readInStore', 'Read in store'),
        field: 'qtyReadByStoreGate',
        sortable: true,
        default: true,
      },
      {
        title: t('declareToControl.columns.missing', 'Missing'),
        field: 'missing',
        sortable: true,
        default: true,
        valueRenderer: (val) => val,
      },
      {
        title: t('declareToControl.columns.realizedMovement', 'Realized movement'),
        field: 'realizedMovement',
        sortable: true,
        default: true,
        valueRenderer: (val) => val,
      },
    ] : [
      {
        title: t('declareToControl.columns.unexpected', 'Unexpected'),
        field: 'unexpectedQty',
        sortable: true,
        default: true,
        valueRenderer: (val) => val,
      },
      {
        title: t('declareToControl.columns.realizedMovement', 'Realized movement'),
        field: 'realizedMovement',
        sortable: true,
        default: true,
        valueRenderer: (val) => val,
      },
    ]),
    {
      title: t('declareToControl.columns.sellingValue', 'Selling value'),
      field: 'value',
      align: 'right',
      sortable: true,
      default: true,
      width: 100,
      valueRenderer: (val, field, row) => formatMoney(val, row.currency, '-'),
    },
    {
      title: t('declareToControl.columns.cessionValue', 'Cession value'),
      field: 'cessionValue',
      align: 'right',
      sortable: true,
      default: true,
      width: 100,
      valueRenderer: (val, field, row) => formatMoney(val, row.cessionCurrency, '-'),
    },
    ...(discrepancyType === 'wrong_size' ? [
      {
        title: t('declareToControl.columns.wrongSizes', 'Missing items'),
        field: 'wrongSizes',
        sortable: false,
        default: true,
        valueRenderer: (val) =>
          val && val.length
            ? val.map((x, index) => <div key={index}>{`${x.item} - ${x.itemLib}`}</div>)
            : null,
      },
    ] : []),
  ];
};

const getInputColumns = ({
  t,
  updateDataRow,
  insertDataRow,
  deleteDataRow,
  onInputBlur,
  onCancelControl,
}) => [
  {
    title: t('declareToControl.columns.actions', 'Actions'),
    field: 'actions',
    sortable: false,
    default: true,
    width: 80,
    valueRenderer: (val, field, row, rowIndex) => {
      const id = row.id;
      const controlId = row.controlId;
      const disabled = row.saving || false;
      const canCopy = !row.clientSideId;
      const canRemove = !!row.clientSideId;
      const canCancel = row.controlStatus === 'new';
      const onCopyRow = () => {
        counter += 1;
        insertDataRow(rowIndex + 1, {
          ...row,
          saved: false,
          error: null,
          controlId: undefined,
          controlStatus: null,
          address: undefined,
          contenant: undefined,
          picker: undefined,
          clientSideId: counter,
        });
      };
      const onCancel = () => onCancelControl({
        id,
        controlId,
        rowIndex,
      });
      const onRemove = () => deleteDataRow(rowIndex);
      return (
        <div className="declareToControl-action-cell">
          {canCopy && (
            <Button
              className="tiny-button"
              size="sm"
              onClick={onCopyRow}
              disabled={disabled}
              title={t('declareToControl.actions.copy', 'Copy')}
            >
              <i className="vtmn-icon_plus_v2"></i>
            </Button>
          )}
          {canCancel && (
            <Button
              className="tiny-button"
              size="sm"
              onClick={onCancel}
              disabled={disabled}
              title={t('declareToControl.actions.cancel', 'Cancel control')}
            >
              <i className="vtmn-icon_cross"></i>
            </Button>
          )}
          {canRemove && (
            <Button
              className="tiny-button"
              size="sm"
              onClick={onRemove}
              disabled={disabled}
              title={t('declareToControl.actions.remove', 'Remove copy')}
            >
              <i className="vtmn-icon_delete"></i>
            </Button>
          )}
        </div>
      );
    }
  },
  {
    title: t('declareToControl.columns.address', 'Address to check'),
    field: 'address',
    sortable: false,
    default: true,
    width: 200,
    valueRenderer: (val, field, row, rowIndex) => {
      const id = row.id;
      const controlId = row.controlId;
      const address = row.address;
      const contenant = row.contenant;
      const picker = row.picker;
      const warehouseSector = row.warehouse_sector;
      const saving = row.saving || false;
      const saved = (address && row.saved) || false;
      const error = (row.error && row.error.address) || null;
      const disabled = row.controlStatus === 'completed';
      return (
        <Form.Group as={Row} className="mb-0">
          <Form.Control
            isValid={saved}
            isInvalid={!!error}
            size="sm"
            type="text"
            disabled={disabled}
            value={address || ''}
            onChange={(event) => {
              const { value } = event.target;
              updateDataRow(rowIndex, { address: value || undefined })
            }}
            onBlur={() => onInputBlur({
              id,
              controlId,
              address,
              contenant,
              picker,
              warehouseSector,
              rowIndex,
            })}
          />
          {saving && <Spinner size="sm" animation="border" variant="primary" className="inputSpinner" />}
          {!saving && error && (
            <Form.Control.Feedback type="invalid">
              {error || <Trans i18nKey="defaultError">Error</Trans>}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      );
    },
  },
  {
    title: t('declareToControl.columns.contenant', 'Contenant to check'),
    field: 'contenant',
    sortable: false,
    default: true,
    width: 200,
    valueRenderer: (val, field, row, rowIndex) => {
      const id = row.id;
      const controlId = row.controlId;
      const address = row.address;
      const contenant = row.contenant;
      const picker = row.picker;
      const warehouseSector = row.warehouse_sector;
      const saving = row.saving || false;
      const saved = (contenant && row.saved) || false;
      const error = (row.error && row.error.contenant) || null;
      const disabled = row.controlStatus === 'completed';
      return (
        <Form.Group as={Row} className="mb-0">
          <Form.Control
            isValid={saved}
            isInvalid={!!error}
            size="sm"
            type="text"
            disabled={disabled}
            value={contenant || ''}
            onChange={(event) => {
              const { value } = event.target;
              updateDataRow(rowIndex, { contenant: value || undefined })
            }}
            onBlur={() => onInputBlur({
              id,
              controlId,
              address,
              contenant,
              picker,
              warehouseSector,
              rowIndex,
            })}
          />
          {saving && <Spinner size="sm" animation="border" variant="primary" className="inputSpinner" />}
          {!saving && error && (
            <Form.Control.Feedback type="invalid">
              {error || <Trans i18nKey="defaultError">Error</Trans>}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      );
    },
  },
  {
    title: t('declareToControl.columns.picker', 'Picker'),
    field: 'picker',
    sortable: false,
    default: true,
    width: 200,
    valueRenderer: (val, field, row, rowIndex) => {
      const id = row.id;
      const controlId = row.controlId;
      const address = row.address;
      const contenant = row.contenant;
      const picker = row.picker;
      const warehouseSector = row.warehouse_sector;
      const saving = row.saving || false;
      const saved = (address && row.saved) || false;
      const error = (row.error && row.error.picker) || null;
      const disabled = row.controlStatus === 'completed';
      return (
        <Form.Group as={Row} className="mb-0">
          <Form.Control
            isValid={saved}
            isInvalid={!!error}
            size="sm"
            type="text"
            disabled={disabled}
            value={picker || ''}
            onChange={(event) => {
              const { value } = event.target;
              updateDataRow(rowIndex, { picker: value || undefined })
            }}
            onBlur={() => onInputBlur({
              id,
              controlId,
              address,
              contenant,
              picker,
              warehouseSector,
              rowIndex,
            })}
          />
          {saving && <Spinner size="sm" animation="border" variant="primary" className="inputSpinner" />}
          {!saving && error && (
            <Form.Control.Feedback type="invalid">
              {error || <Trans i18nKey="defaultError">Error</Trans>}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      );
    },
  },
  {
    title: t('declareToControl.columns.warehouseSector', 'Warehouse sector'),
    field: 'warehouse_sector',
    sortable: false,
    default: true,
    width: 200,
    valueRenderer: (val, field, row, rowIndex) => {
      const id = row.id;
      const controlId = row.controlId;
      const address = row.address;
      const contenant = row.contenant;
      const picker = row.picker;
      const warehouseSector = row.warehouse_sector;
      const saving = row.saving || false;
      const saved = (address && row.saved) || false;
      const error = (row.error && row.error.warehouseSector) || null;
      const disabled = row.controlStatus === 'completed';
      return (
        <Form.Group as={Row} className="mb-0">
          <Form.Control
            isValid={saved}
            isInvalid={!!error}
            size="sm"
            type="text"
            disabled={disabled}
            value={warehouseSector || ''}
            onChange={(event) => {
              const { value } = event.target;
              updateDataRow(rowIndex, { warehouse_sector: value || undefined })
            }}
            onBlur={() => onInputBlur({
              id,
              controlId,
              address,
              contenant,
              picker,
              warehouseSector,
              rowIndex,
            })}
          />
          {saving && <Spinner size="sm" animation="border" variant="primary" className="inputSpinner" />}
          {!saving && error && (
            <Form.Control.Feedback type="invalid">
              {error || <Trans i18nKey="defaultError">Error</Trans>}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      );
    },
  },
];

export const getDefaultSelection = (fields) => {
  const defaultSelection = {};
  for (const { field } of fields.filter(x => x.default)) {
    defaultSelection[field] = true;
  }
  return defaultSelection;
};

export const getSelectedFields = ({
  t,
  fields,
  selection,
  updateDataRow,
  insertDataRow,
  deleteDataRow,
  onInputBlur,
  onCancelControl,
}) => {
  const selected = [
    ...getInputColumns({
      t,
      updateDataRow,
      insertDataRow,
      deleteDataRow,
      onInputBlur,
      onCancelControl,
    }),
    ...fields.filter(x => selection[x.field])
  ];
  return selected;
};
