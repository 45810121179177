import React, { useState } from 'react';
import { Button, Spinner, Modal, Image } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next'
import { UploadFile, postFile  } from './FileEditor';
import useRequest from '../../functions/useRequest';

const EditImage = ({
  id,
  url,
  loading,
  onDelete,
  onSelect,
  onPreview,
  accept,
}) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div className="edit-file-wrapper">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="edit-file-wrapper">
      <input
        type="image"
        className="edit-file-result-photo"
        src={url}
        alt={url}
        onClick={(e) => {
          onPreview();
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      />
      <Button
        title={t('fileEditor.remove', 'Remove')}
        variant="outline-primary"
        className="edit-file-delete-button"
        onClick={onDelete}
      >
        <i className="vtmn-icon_delete"></i>
      </Button>
      <label
        role="button"
        title={t('fileEditor.edit', 'Edit')}
        className="btn btn-outline-primary edit-file-edit-button"
        htmlFor={`file-input-${id}`}
      >
        <i className="vtmn-icon_edit"></i>
        <input
          id={`file-input-${id}`}
          type="file"
          accept={accept}
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              const file = e.target.files[0];
              onSelect(file);
            }
          }}
        />
      </label>
      <Button
        title={t('fileEditor.preview', 'Preview')}
        variant="outline-primary"
        className="edit-file-preview-button"
        onClick={onPreview}
      >
        <i className="vtmn-icon_eye_on"></i>
      </Button>
    </div>
  );
}

const ImagePicker = ({
  id,
  file,
  removeFile,
  uploadFile,
  showPreview,
}) => (
  file.url ? (
    <EditImage
      id={id}
      url={file.url}
      loading={file.loading}
      onDelete={() => removeFile(file.url)}
      onSelect={uploadFile}
      onPreview={showPreview}
      accept="image/*"
    />
  ) : (
    <UploadFile
      disabled={file.loading}
      onUpload={uploadFile}
      accept="image/*"
    />
  )
);

const ImagePreview = ({ url, header, open, setOpen }) => {
  const onClose = () => setOpen(false);
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      show={open}
      onHide={onClose}
      className="edit-file-preview-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{header || t('fileEditor.previewModal.preview', 'Preview')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={url} fluid />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="icon-button" onClick={onClose}>
          <i className="vtmn-icon_cross"></i>
          <Trans i18nKey="fileEditor.previewModal.close">Close</Trans>
        </Button>
        <div className="spacer" />
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Button variant="primary" className="icon-button">
            <i className="vtmn-icon_download"></i>
            <Trans i18nKey="fileEditor.previewModal.download">Download</Trans>
          </Button>
        </a>
      </Modal.Footer>
    </Modal>
  )
};

const ImageEditor = ({
  user,
  updateTokens,
  id,
  url,
  setUrl,
  setUploading,
  previewHeader,
}) => {
  const { token } = user;
  const [{
    loading: uploading,
    error,
  }, uploadFile] = useRequest(postFile, {
    onSuccess: ({ path }) => setUrl(path),
  });
  const [previewOpen, setPreviewOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <ImagePreview
        header={previewHeader}
        url={`${process.env.REACT_APP_base_URL}/api/files/${url}?token=${token}`}
        open={previewOpen}
        setOpen={setPreviewOpen}
      />
      <ImagePicker
        id={id}
        file={{
          url: url && `${process.env.REACT_APP_base_URL}/api/files/${url}?token=${token}`,
          loading: uploading,
        }}
        removeFile={() => setUrl(null)}
        uploadFile={(file) => {
          setUploading(true);
          uploadFile(user, updateTokens, file).then(() => setUploading(false));
        }}
        showPreview={() => setPreviewOpen(true)}
      />
      {error && (
        <div className="invalid-feedback edit-file-error">
          {error.message || t('defaultError', 'Error')}
        </div>
      )}
    </>
  )
}

export default ImageEditor;
