import React from 'react';
import {
  Pagination,
  Form,
} from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

export const TablePagination = ({ pagination, totals, setPagination }) => {
  const isFirst = pagination.page === 0;
  const isLast = pagination.page + 1 === totals.pages;
  const hasFirst = !isFirst;
  const hasLast = totals.pages > 1 && !isLast;
  const hasLeftEllipsis = pagination.page + 1 >= 4;
  const hasRightEllipsis = totals.pages - pagination.page >= 4;
  const hasMiddleLeft = pagination.page + 1 >= 3;
  const hasMiddleRight = totals.pages - pagination.page >= 3;

  return (
    <Pagination size="sm" className="footer-pagination">
      <Pagination.Prev
        key="prev"
        disabled={isFirst}
        onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
      />
      {hasFirst && (
        <Pagination.Item
          key={0}
          onClick={() => setPagination({ ...pagination, page: 0 })}
        >
          1
        </Pagination.Item>
      )}
      {hasLeftEllipsis && <Pagination.Ellipsis disabled />}
      {hasMiddleLeft && (
        <Pagination.Item
          key={pagination.page - 1}
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
        >
          {pagination.page}
        </Pagination.Item>
      )}
      <Pagination.Item
        active
        key={pagination.page}
        onClick={() => setPagination({ ...pagination })}
      >
        {pagination.page + 1}
      </Pagination.Item>
      {hasMiddleRight && (
        <Pagination.Item
          key={pagination.page + 1}
          onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
        >
          {pagination.page + 2}
        </Pagination.Item>
      )}
      {hasRightEllipsis && <Pagination.Ellipsis disabled />}
      {hasLast && (
        <Pagination.Item
          key={totals.pages - 1}
          onClick={() => setPagination({ ...pagination, page: totals.pages - 1 })}
        >
          {totals.pages}
        </Pagination.Item>
      )}
      <Pagination.Next
        key="next"
        disabled={isLast}
        onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
      />
    </Pagination>
  );
};

export const PageSizeSelect = ({ pagination, setPagination }) => (
  <div className="pagesize-wrapper">
    <div>
      <Trans i18nKey="dataTable.pageSize">Page size:</Trans>
    </div>
    <Form.Control
      size="sm"
      className="footer-size-select"
      as="select"
      custom
      value={pagination.size}
      onChange={(event) => setPagination({
        page: 0,
        size: Number(event.target.value),
      })}
    >
      <option>10</option>
      <option>20</option>
      <option>50</option>
      <option>200</option>
    </Form.Control>
  </div>
);

export const PaginationInfo = ({ pagination, totals }) => {
  const from = totals.items && (pagination.page * pagination.size + 1);
  const to = Math.min((pagination.page + 1) * pagination.size, totals.items);
  const { t } = useTranslation();

  return (
    <div className="footer-info">
      {t(
        'dataTable.pageTotals',
        {
          defaultValue: 'Showing {{from}} to {{to}} of {{total}}',
          from,
          to,
          total: totals.items,
        },
      )}
    </div>
  );
};
