import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SelectFilter from './SelectFilter';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';


const getContenants = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/contenants`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DiscrepanciesControlsContenantFilter = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  invertable,
  inverted,
  onInvert,
  controlId,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchContenants] = useRequest(getContenants);
  const { warehouse } = useParams();
  useEffect(() => {
    fetchContenants(user, updateTokens, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse]);
  const options = (data && data.map(contenant => ({
    label: contenant.contenant,
    name: contenant.contenant,
    id: contenant.contenant,
  }))) || [];
  return (
    <SelectFilter
      multiple
      filterOpts
      controlId={controlId}
      label={label}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      disabled={disabled}
      loading={loading}
      error={error}
    />
  );
}

export default DiscrepanciesControlsContenantFilter;
