import formatMoney from '../../functions/formatMoney';
import { formatDurationSec, formatDurationHours } from '../../functions/formatDate';

const moneyRenderer = (val, field, row) => formatMoney(val, row.currency);

export const getFields = ({ t, showInvestment }) => [
  {
    title: t('efficiencyDashboard.breakdown.table.storeNumber', 'Store number'),
    field: 'site_id',
    key: 'site_id',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.storeName', 'Store name'),
    field: 'site_name',
    key: 'site_name',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.deliveriesCount', 'Deliveries count'),
    field: 'deliveries_count',
    key: 'deliveries_count',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.deliveriesMeasured', 'Deliveries measured'),
    field: 'deliveries_measured',
    key: 'deliveries_measured',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.measurementCoverage', 'Measurement coverage)'),
    field: 'coverage',
    key: 'coverage',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgOverallProductivityPlanned', 'Average overall productivity planned'),
    field: 'planned_overall_productivity',
    key: 'planned_overall_productivity',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgOverallProductivityRealised', 'Average overall productivity achieved'),
    field: 'overall_productivity',
    key: 'overall_productivity',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgProductivityPlanned', 'Average shelving productivity planned'),
    field: 'planned_shelving_productivity',
    key: 'planned_shelving_productivity',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgProductivityRealised', 'Average shelving productivity achieved'),
    field: 'shelving_productivity',
    key: 'shelving_productivity',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.totalTeammateHoursPlanned', 'Total teammate hours planned'),
    field: 'planned_teammate_hours_sec',
    key: 'planned_teammate_hours_sec',
    sortable: true,
    default: true,
    valueRenderer: formatDurationHours
  }, {
    title: t('efficiencyDashboard.breakdown.table.totalTeammateHoursRealised', 'Total teammate hours achieved'),
    field: 'teammate_hours_sec',
    key: 'teammate_hours_sec',
    sortable: true,
    default: true,
    valueRenderer: formatDurationHours
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgTeammateUnloadSort', 'Average teammate unload + sorting'),
    field: 'unloading_teammates',
    key: 'unloading_teammates',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgTeammateShelving', 'Average teammate shelving'),
    field: 'shelving_teammates',
    key: 'shelving_teammates',
    sortable: true,
    default: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgDurationTotal', 'Average duration total'),
    field: 'total_duration',
    key: 'total_duration',
    sortable: true,
    default: true,
    valueRenderer: formatDurationSec
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgDurationUnloadSort', 'Average duration unload + sorting'),
    field: 'unloading_duration',
    key: 'unloading_duration',
    sortable: true,
    valueRenderer: formatDurationSec
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgDurationShelving', 'Average duration shelving'),
    field: 'shelving_duration',
    key: 'shelving_duration',
    sortable: true,
    valueRenderer: formatDurationSec
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgReceptionSize', 'Average reception size'),
    field: 'quantities',
    key: 'quantities',
    sortable: true,
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgUnloadingHours', 'Average unloading teammate hours'),
    field: 'avg_unloading_hours_sec',
    key: 'avg_unloading_hours_sec',
    sortable: true,
    default: true,
    valueRenderer: formatDurationHours
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgShelvingHours', 'Average shelving teammate hours'),
    field: 'avg_shelving_hours_sec',
    key: 'avg_shelving_hours_sec',
    sortable: true,
    default: true,
    valueRenderer: formatDurationHours
  }, {
    title: t('efficiencyDashboard.breakdown.table.avgTotalHours', 'Average total teammate hours'),
    field: 'avg_total_hours_sec',
    key: 'avg_total_hours_sec',
    sortable: true,
    default: true,
    valueRenderer: formatDurationHours
  },
  ...(showInvestment
    ? [{
      title: t('efficiencyDashboard.breakdown.table.deltaInvestment', 'Delta investment'),
      field: 'delta_investment',
      key: 'delta_investment',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.investmentPlanned', 'Investment planned'),
      field: 'planned_total_investment_sum',
      key: 'planned_total_investment_sum',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.investmentAchieved', 'Investment achieved'),
      field: 'total_investment_sum',
      key: 'total_investment_achieved',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.avgInvestmentTotal', 'Average investment total'),
      field: 'total_investment_avg',
      key: 'total_investment_avg',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.avgInvestmentUnloadSort', 'Average investment unload + sorting'),
      field: 'unloading_investment_avg',
      key: 'unloading_investment_avg',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.avgInvestmentShelving', 'Average investment shelving'),
      field: 'shelving_investment_avg',
      key: 'shelving_investment_avg',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.totalInvestment', 'Total investment'),
      field: 'total_investment_sum',
      key: 'total_investment_sum',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.totalInvestmentUnloadSort', 'Total investment unload + sorting'),
      field: 'unloading_investment_sum',
      key: 'unloading_investment_sum',
      sortable: true,
      valueRenderer: moneyRenderer,
    }, {
      title: t('efficiencyDashboard.breakdown.table.totalInvestmentShelving', 'Total investment shelving'),
      field: 'shelving_investment_sum',
      key: 'shelving_investment_sum',
      sortable: true,
      valueRenderer: moneyRenderer,
    }]
    : []
  )
];

export const getDefaultSelection = (fields) => {
  const defaultSelection = {};
  for (const { key } of fields.filter(x => x.default)) {
    defaultSelection[key] = true;
  }
  return defaultSelection;
};

export const getSelectedFields = ({ fields, selection }) => {
  const selected = fields.filter(x => selection[x.key]);
  return selected;
};
