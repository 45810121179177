import React from 'react';
import { Form } from 'react-bootstrap';

const RangeEdit = ({ label, disabled, min, max, step, value, onChange, controlId }) => {
  return (
    <Form.Group className="form-item range-edit" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        disabled={disabled}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value || 0}
        onChange={(event) => onChange && onChange(Number(event.target.value))}
      />
    </Form.Group>
  );
};

export default RangeEdit;
