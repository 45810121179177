import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
} from 'react-bootstrap';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import capitalize from '../../functions/capitalize';
import blankFlag from '../../stylesheets/blankFlag.gif';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

const getCountries = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/countries/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DeploymentRow = ({ country }) => {
  const {
    country_id,
    cost_per_hour,
    country: name,
    country_code,
    shelving_productivity,
    shelving_teammates,
    unloading_duration,
    unloading_teammates,
  } = country;
  const flag = `flag flag-${country_code.toLowerCase()}`;
  const empty = !cost_per_hour && !shelving_productivity && !shelving_teammates && !unloading_duration && !unloading_teammates;
  const edit = cost_per_hour || shelving_productivity || shelving_teammates || unloading_duration || unloading_teammates;
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        {capitalize(name)}
      </td>
      <td className="text-center">
        <div className="country-flag">
          <img src={blankFlag}  className={flag} alt={country_code} />
        </div>
      </td>
      <td className="text-center">
        <Link to={`/admin/efficiency/country/deployment/${country_id}`}>
          {!empty
            ? (
              <Button className="tiny-button" size="sm" variant={edit ? 'warning' : ''}>
                {edit && (
                  <Trans i18nKey="efficiencyCountryDeployment.editedSettings">Edit</Trans>
                )}
              </Button>
            )
            : (
              <Button
                className="tiny-button"
                size="sm"
                variant="light"
                title={t('efficiencyCountryDeployment.edit', 'Edit country measurement efficiency settings')}
              >
                <i className="vtmn-icon_edit"></i>
              </Button>
            )
          }
        </Link>
      </td>
    </tr>
  );
};

const EfficiencyCountryDeployment = ({ user, updateTokens }) => {
  const [data, setData] = useState(null);
  const [{
    loading,
    error,
  }, fetchCountries] = useRequest(
    getCountries, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );

  useEffect(() => {
    fetchCountries(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="adminDeploymentManagement">
      {loading && <Loader />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <Table hover>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <Trans i18nKey="efficiencyCountryDeployment.headers.name">Name</Trans>
              </th>
              <th scope="col" className="text-center">&nbsp;</th>
              <th scope="col" className="text-center">
                <Trans i18nKey="efficiencyCountryDeployment.headers.settings">Settings</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(country => (
              <DeploymentRow
                key={country.id}
                country={country}
              />
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default EfficiencyCountryDeployment;
