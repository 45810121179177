import React from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from '../../../functions/formatMoney';
import CommonReturnTab from './commonReturnTab';

const UnexpectedReturns = ({ user, updateTokens, shipmentId, onItemClick }) => {
  const { t } = useTranslation();

  const tdInnerRender = (row, value) => {
    let cls = '';
    if (row.qtyOutRead > 0 && row.qtyInRead > 0) {
      cls = 'red-cell';
    }
    return <div className={cls}>{value}</div>;
  };

  const columns = [{
    title: t('returns.shipmentId', 'Shipment Id'),
    field: 'shipmentId',
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.item', 'Item'),
    field: 'itemCode',
    sortable: true,
    width: 100,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.sellingPrice', 'Selling price'),
    field: 'price',
    sortable: true,
    width: 100,
    valueRenderer: (val, column, row) => tdInnerRender(row, formatMoney(val, row.currency, '-')),
  }, {
    title: t('returns.itemDescription', 'Item description'),
    field: 'itemLib',
    sortable: true,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.inStore', 'In store'),
    field: 'qtyOutRead',
    sortable: true,
    width: 50,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.inWarehouse', 'In warehouse'),
    field: 'qtyInRead',
    sortable: true,
    width: 50,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }];

  return (
    <CommonReturnTab
      type="unexpected"
      user={user}
      updateTokens={updateTokens}
      shipmentId={shipmentId}
      onItemClick={onItemClick}
      columns={columns}
      sourceUrl={`${process.env.REACT_APP_base_URL}/api/returns/shipment/${shipmentId}/unexpected`}
    />
  );
};

export default UnexpectedReturns;
