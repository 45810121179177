import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../functions/formatDate';
import COLORS from '../../consts/colors';

const HistoryChart = ({ history, gateInfo, filters }) => {
  const { t } = useTranslation();
  const { store } = useParams();
  const [receivedHidden, setReceivedHidden] = useState(false);
  const [warehouseHidden, setWarehouseHidden] = useState(false);
  const [foundHidden, setFoundHidden] = useState(false);
  const [missingHidden, setMissingHidden] = useState(false);
  const gateInfoTime = gateInfo && new Date(gateInfo.deliveryStatusChangeDate).getTime();
  const needsAddToHistory = gateInfo && history.findIndex(h => h.delivery === gateInfo.deliveryID) === -1 &&
    new Date(filters.dateFrom).getTime() <= gateInfoTime && gateInfoTime <= new Date(filters.dateTo).getTime();
  const historyWithInfo = [
    ...history,
    ...(needsAddToHistory ? [{
      tagsReceived: gateInfo.receivedWithTagTotalQties,
      tagsAtWarehouse: gateInfo.warehouseWithTagTotalQties,
      tagsExpected: gateInfo.expectedWithTagTotalQties,
      store,
      delivery: gateInfo.deliveryID,
      tagsFoundAtMovements: gateInfo.foundAtMovementsQties,
      tagsMissingAtMovements: gateInfo.missingAtMovementsQties,
      status: gateInfo.deliveryStatus,
      statusChangeDate: gateInfo.deliveryStatusChangeDate,
    }] : []),
  ];
  const tagsReceivedList = historyWithInfo.map(x => x.tagsReceived);
  const tagsFoundAtMovementsList = historyWithInfo.map(x => x.tagsFoundAtMovements);
  const tagsMissingAtMovementsList = historyWithInfo.map(x => x.tagsMissingAtMovements);
  const tagsAtWarehouseList = historyWithInfo.map(x => {
    let delta = x.tagsAtWarehouse;
    if (!receivedHidden) {
      delta -= x.tagsReceived;
    }
    if (!foundHidden) {
      delta -= x.tagsFoundAtMovements;
    }
    if (!missingHidden) {
      delta -= x.tagsMissingAtMovements;
    }
    return delta > 0 ? delta : null;
  });
  const tagsExpectedList = historyWithInfo.map(x => {
    let delta = x.tagsExpected;
    let atWarehouseDetla = x.tagsAtWarehouse;
    if (!receivedHidden) {
      delta -= x.tagsReceived;
      atWarehouseDetla -= x.tagsReceived;
    }
    if (!foundHidden) {
      delta -= x.tagsFoundAtMovements;
      atWarehouseDetla -= x.tagsFoundAtMovements;
    }
    if (!missingHidden) {
      delta -= x.tagsMissingAtMovements;
      atWarehouseDetla -= x.tagsMissingAtMovements;
    }
    if (!warehouseHidden && atWarehouseDetla > 0) {
      delta -= atWarehouseDetla;
    }
    return delta > 0 ? delta : null;
  });
  const receivedColors = historyWithInfo.map(() => COLORS.VTMN_BRAND_DIGITAL);
  const labels = historyWithInfo.map(x => formatDate(new Date(x.statusChangeDate)));
  const data = {
    labels,
    datasets: [
      {
        label: t('monitoringGate.history.received', 'Tags received'),
        data: tagsReceivedList,
        backgroundColor: COLORS.VTMN_BRAND_DIGITAL,
      },
      {
        label: t('monitoringGate.history.foundAtMovements', 'Tags found at movements'),
        data: tagsFoundAtMovementsList,
        backgroundColor: COLORS.VTMN_SUCCESS,
      },
      {
        label: t('monitoringGate.history.confirmedMissing', 'Tags confirmed missing'),
        data: tagsMissingAtMovementsList,
        backgroundColor: COLORS.VTMN_DANGER,
      },
      {
        label: t('monitoringGate.history.warehouse', 'Tags at warehouse'),
        data: tagsAtWarehouseList,
        backgroundColor: COLORS.VTMN_BRAND_DIGITAL_LIGHT_1,
      },
      {
        label: t('monitoringGate.history.expected', 'Tags expected'),
        data: tagsExpectedList,
        backgroundColor: COLORS.PROGRESS_BG,
      },
    ]
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
    legend: {
      labels: {
        generateLabels: function(chart) {
          const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
          if (receivedColors.length) {
            labels[0].fillStyle = receivedColors[receivedColors.length - 1];
          }
          return labels;
        },
      },
      onClick: function(e, legendItem) {
        if (legendItem.datasetIndex === 0) {
          setReceivedHidden(!legendItem.hidden);
        } else if (legendItem.datasetIndex === 1) {
          setFoundHidden(!legendItem.hidden);
        } else if (legendItem.datasetIndex === 2) {
          setMissingHidden(!legendItem.hidden);
        } else if (legendItem.datasetIndex === 3) {
          setWarehouseHidden(!legendItem.hidden)
        }
        Chart.defaults.global.legend.onClick.call(this, e, legendItem);
      }
    },
    tooltips: {
      intersect: false,
      mode: 'label',
      callbacks: {
        title: function(tooltipItem, data) {
          const date = data.labels[tooltipItem[0].index];
          const delivery = historyWithInfo[tooltipItem[0].index].delivery;
          return `${date} - ${delivery}`;
        },
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          let currentValue = dataset.data[tooltipItem.index];
          if (tooltipItem.datasetIndex === 3) {
            currentValue = historyWithInfo[tooltipItem.index].tagsAtWarehouse;
          }
          if (tooltipItem.datasetIndex === 4) {
            currentValue = historyWithInfo[tooltipItem.index].tagsExpected;
          }
          const label = `${dataset.label}: ${currentValue}`;
          return label;
        }
    }
    }
  }
  return (
    <div className="monitoringGate-chart">
      <Bar data={data} options={options} />
    </div>
  );
};

export default HistoryChart;
