import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import '../../stylesheets/modals.css';
import Loader from '../loader';

const Confirm = ({
  show,
  onHide,
  icon,
  title,
  loading,
  body,
  buttonOpts,
  onConfirm,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        {icon || (
          <i className="modal-header-icon text-primary vtmn-icon_notif_help" />
        )}
        <Modal.Title>
          {title || <Trans i18nKey="shared.confirm">Confirm</Trans>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <Loader /> : body}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="my-0 mx-2s"
          variant="light"
          onClick={onHide}
        >
          {buttonOpts?.noText || <Trans i18nKey="shared.cancel">Cancel</Trans>}
        </Button>
        <Button
          className="my-0 mx-2"
          disabled={loading}
          variant={buttonOpts?.variant || "primary"}
          onClick={onConfirm}
        >
          {buttonOpts?.text || <Trans i18nKey="shared.confirm">Confirm</Trans>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
