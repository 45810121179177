import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import DurationEdit from '../widgets/form/DurationEdit';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import '../../stylesheets/efficiencyCountrySettings.css';

const SettingsForm = ({
  settings,
  setSettings,
}) => (
  <Form>
    <Form.Group as={Row} controlId="cost_per_hour">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.costPerHour">
          Cost per hour
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          step={0.01}
          min={0}
          max={1000000}
          type="number"
          value={settings.cost_per_hour || ''}
          onChange={(event) => setSettings({ ...settings, cost_per_hour: event.target.value || null })}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="currency">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.currency">
          Currency
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          type="text"
          value={settings.currency || ''}
          onChange={(event) => setSettings({ ...settings, currency: event.target.value || null })}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="unloading_teammates">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.unloadingTeammates">
          Unloading teammates
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          step={1}
          min={0}
          max={1000}
          type="number"
          value={settings.unloading_teammates || ''}
          onChange={(event) => setSettings({ ...settings, unloading_teammates: event.target.value || null })}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="unloading_duration">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.unloadingDuration">
          Unloading duration
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <div className="duration-field">
          <DurationEdit
            value={settings.unloading_duration || ''}
            onChange={(value) => setSettings({ ...settings, unloading_duration: value || null })}
            controlId="unloading_duration"
          />
          <small>HH:MM:SS</small>
        </div>
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="shelving_teammates">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.shelvingTeammates">
          Shelving teammates
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          step={1}
          min={0}
          max={1000}
          type="number"
          value={settings.shelving_teammates || ''}
          onChange={(event) => setSettings({ ...settings, shelving_teammates: event.target.value || null })}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="shelving_productivity">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.shelvingProductivity">
          Shelving productivity
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          step={0.01}
          min={0}
          max={1000000}
          type="number"
          value={settings.shelving_productivity || ''}
          onChange={(event) => setSettings({ ...settings, shelving_productivity: event.target.value || null })}
        />
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="do_unloading_notify">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.unloadingNotify">
          Notify that unloading time has expired
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8" className="efficiency-active-switch">
        <SwitchFilter
          controlId="do_unloading_notify"
          value={settings.do_unloading_notify || false}
          onChange={() => setSettings({ ...settings, do_unloading_notify: !settings.do_unloading_notify || null })}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="unloading_notify_delay">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.unloadingNotifyDelay">
          Unloading time notify delay
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <div className="duration-field">
          <DurationEdit
            value={settings.unloading_notify_delay || ''}
            onChange={(value) => setSettings({ ...settings, unloading_notify_delay: value || null })}
            controlId="unloading_notify_delay"
          />
          <small>HH:MM:SS</small>
        </div>
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="do_total_notify">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.overallNotify">
          Notify that overall time has expired
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8" className="efficiency-active-switch">
        <SwitchFilter
          controlId="do_total_notify"
          value={settings.do_total_notify || false}
          onChange={() => setSettings({ ...settings, do_total_notify: !settings.do_total_notify || null })}
        />
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="total_notify_delay">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.overallNotifyDelay">
          Overall time notify delay
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <div className="duration-field">
          <DurationEdit
            value={settings.total_notify_delay || ''}
            onChange={(value) => setSettings({ ...settings, total_notify_delay: value || null })}
            controlId="total_notify_delay"
          />
          <small>HH:MM:SS</small>
        </div>
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="show_top_performer">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="efficiencyCountryDeployment.settingsForm.showTopPerformer">
          Show top performer
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8" className="efficiency-active-switch">
        <SwitchFilter
          controlId="show_top_performer"
          value={settings.show_top_performer || false}
          onChange={() => setSettings({ ...settings, show_top_performer: !settings.show_top_performer || null })}
        />
      </Col>
    </Form.Group>
  </Form>
);

export default SettingsForm;
