import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import UserForm from './UserForm';

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const getSettings = ({
  user,
  updateTokens,
  userId,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users/${userId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const createSettings = ({
  user,
  updateTokens,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  userId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/users/${userId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const UserSettings = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const history = useHistory();
  const isNew = userId === 'new';
  const title = isNew
    ? t('usersManagement.newUser', 'New user')
    : t('usersManagement.editUser', 'Edit user');
  const [settings, setSettings] = useState(null);

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setSettings
  });

  useEffect(() => {
    if (!isNew) {
      fetchSettings({
        userId,
        user,
        updateTokens,
      });
      return;
    }
    setSettings({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, userId, fetchSettings]);

  const [{
    loading: countriesLoading,
    data: countries,
    error: countriesError,
  }, fetchCountries] = useRequest(getCountries);
  
  useEffect(() => {
    fetchCountries(user, updateTokens);
  }, [fetchCountries, user, updateTokens]);

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(isNew ? createSettings : updateSettings, {
    onSuccess: () => history.goBack(),
  });

  const onSave = () => saveSettings({
    userId,
    settings,
    user,
    updateTokens,
  });

  const hasError = !countriesLoading && !loading && !saving && (error || saveError || countriesError);
  const isReady = !countriesLoading && !countriesError && !loading && !saving && !error && !!settings;
  const isValid = settings && settings.email && settings.countries && settings.countries.length;

  return (
    <Container className="adminDeploymentGate">
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
          {(loading || saving) && <Loader />}
          {isReady && (
            <UserForm
              user={user}
              settings={settings}
              setSettings={setSettings}
              countries={countries}
            />
          )}
          {hasError && <ErrorMessage error={error || saveError || countriesError} />}
        </Card.Body>
        <Card.Footer className="p-0">
          <Button
            variant="secondary"
            className="icon-button"
            onClick={() => history.goBack()}
          >
            <i className="vtmn-icon_cross"></i>
            <Trans i18nKey="usersManagement.cancel">Cancel</Trans>
          </Button>
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={!isReady || !isValid}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="usersManagement.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default UserSettings;
