import React, { useState, useEffect } from 'react';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse } from '../../functions/handleApiResponse';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import '../../stylesheets/metabaseDashboard.css';

const getFrameUrl = (user, updateTokens, resource, params) => {
  const { token, refreshToken, tokenExpireDate } = user;

  const url = `${process.env.REACT_APP_base_URL}/api/dashboards`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      resource,
      params
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
    .then(handleApiResponse);
}

const MetabaseDashboard = ({
  user,
  updateTokens,
  title,
  metabaseResource,
  metabaseParams,
}) => {
  const [frameUrl, setFrameUrl] = useState(null);

  const [{
    loading,
    error,
  }, fetchFrameUrl] = useRequest(
    getFrameUrl, {
    onSuccess: setFrameUrl,
    onError: () => setFrameUrl(null),
  });

  useEffect(() => {
    fetchFrameUrl(user, updateTokens, metabaseResource, metabaseParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="metabase-dashboard">
      {loading && <Loader />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && (
        <iframe
          title={title}
          src={frameUrl}
          frameBorder={0}
          width="100%"
          allowTransparency
        />
      )}
    </div>
  );
};

export default MetabaseDashboard;
