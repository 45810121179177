import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import { formatDateForBigQuery } from '../../functions/formatDate';
import Info from './Info';
import HistoryChart from './HistoryChart';
import DaterangeFilter from '../widgets/filters/DaterangeFilter';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import '../../stylesheets/monitoringRobot.css';

const getRobotInventoryReport = (user, updateTokens, store) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${store}/robot/inventory/report`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const getRobotInventoryHintory = (user, updateTokens, store, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${store}/robot/inventory/history`;
  const formatedFilters = { ...filters };
  if (formatedFilters.dateFrom) {
    formatedFilters.dateFrom = formatDateForBigQuery(formatedFilters.dateFrom);
  }
  if (formatedFilters.dateTo) {
    formatedFilters.dateTo = formatDateForBigQuery(
      dayjs(formatedFilters.dateTo).add(1, 'day').toDate()
    );
  }
  const queryString = getQueryString({ filters: formatedFilters });
  return fetchWithJWT(`${url}?${queryString}`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const defaultDateFilters = () => {
  const monthAgo = dayjs().startOf('day').subtract(1, 'month').toDate();
  const endOfDay = dayjs().endOf('day').toDate();
  return [monthAgo, endOfDay];
};

const useHistoryWithReport = ({ historyData, reportData, filters }) => {
  const { store } = useParams();
  const memoizedHistory = useMemo(() => {
    const now = new Date();
    const addReport = (
      (!filters.dateFrom && !filters.dateTo)
      || (!filters.dateTo && now > filters.dateFrom)
      || (!filters.dateFrom && now < filters.dateTo)
      || (now > filters.dateFrom && now < filters.dateTo)
    );
    if (historyData && reportData && addReport) {
      const { totalUnsoldTags } = reportData.report;
      const historyWithReport = [
        ...historyData,
        {
          id: 'latest',
          totalUnsoldTags,
          store,
          date: now.toISOString(),
          day: now.toISOString().split('T')[0],
          ...reportData.autoImpactInfo,
        }
      ];
      return historyWithReport;

    } else {
      return historyData;
    }
  }, [store, historyData, reportData, filters]);
  return memoizedHistory;
};

const MonitoringRobot = ({ user, updateTokens }) => {
  const { store } = useParams();
  const { t } = useTranslation();
  const [monthAgo, endOfDay] = defaultDateFilters();
  const [filters, setFilters] = useState({
    dateFrom: monthAgo,
    dateTo: endOfDay,
  });
  const [{
    loading: loadingReport,
    error: errorReport,
    data: reportData,
  }, fetchInventoryReport] = useRequest(getRobotInventoryReport);
  const [{
    loading: loadingHistory,
    error: errorHistory,
    data: historyData,
  }, fetchInventoryHistory] = useRequest(getRobotInventoryHintory);
  useEffect(() => {
    fetchInventoryReport(user, updateTokens, store);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);
  useEffect(() => {
    fetchInventoryHistory(user, updateTokens, store, filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters]);
  const loading = loadingReport || loadingHistory;
  const error = !loading && (errorReport || errorHistory);
  const isReady = !loading && !error && reportData && historyData;
  const history = useHistoryWithReport({ historyData, reportData, filters });

  return (
    <div className = "container-fluid monitoringRobot">
      <h3>
        <Trans i18nKey="monitoringRobot.title">Monitoring Robot</Trans>
      </h3>

      {loading && <Loader />}
      {error && <ErrorMessage error={error} />}
      {isReady && (
        <>
          <div className="row">
            <div className="col-lg-4 monitoringRobot-info">
              <Info report={reportData.report} history={history} />
            </div>
          </div>
          <div className="row filter-card">
            <DaterangeFilter
              controlId="filter-date"
              label={t('monitoringRobot.dateFilter', 'Date filter')}
              valueFrom={filters.dateFrom}
              valueTo={filters.dateTo}
              onChange={(dateFrom, dateTo) => setFilters({
                ...filters,
                dateFrom,
                dateTo,
              })}
            />
          </div>
          <div className="row">
            <div className="bars-wrapper">
              <h4 className="monitoringRobot-chartTitle">
                <Trans i18nKey="monitoringRobot.history.title">Robot inventory history</Trans>
              </h4>
              <HistoryChart history={history} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MonitoringRobot;
