import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputGroup } from 'react-bootstrap';
import useDebounce from '../../../functions/useDebounce';

const isNil = (val) => val === null || val === undefined;

const NumrangeFilter = ({
  controlId,
  label,
  valueFrom,
  valueTo,
  onChange,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState([valueFrom, valueTo]);
  const debouncedValue = useDebounce(stateValue, 500);
  useEffect(
    () => {
      if (valueFrom !== debouncedValue[0] || valueTo !== debouncedValue[1]) {
        onChange(debouncedValue[0], debouncedValue[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue([valueFrom, valueTo]); },
    [valueFrom, valueTo],
  );
  return (
    <Form.Group controlId={controlId} className="filter-item">
      <Form.Label>
        <span className="filter-label-text">
          {label}
          {':'}
        </span>
      </Form.Label>
      <InputGroup className="filter-number-pare">
        <Form.Control
          size="sm"
          placeholder={t('filters.from', 'From')}
          type="number"
          value={isNil(stateValue[0]) ? '' : String(stateValue[0])}
          onChange={(event) => setStateValue([event.target.value, stateValue[1]])}
        />
        <Form.Control
          size="sm"
          placeholder={t('filters.to', 'To')}
          type="number"
          value={isNil(stateValue[1]) ? '' : String(stateValue[1])}
          onChange={(event) => setStateValue([stateValue[0], event.target.value])}
        />
      </InputGroup>
    </Form.Group>
  );
};

export default NumrangeFilter
