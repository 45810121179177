import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../../../../../components/loader';
import ErrorMessage from '../../../../../components/errorMessage';
import useRequest from '../../../../../functions/useRequest';
import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';
import DismissErrorModal from './dismissErrorModal';
import '../../../../../stylesheets/importLQT.css';

const MAX_FILE_SIZE = 32; // 32 Megabytes
const importRequest = (user, updateTokens, warehouse, file, signal) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file`;
  const body = new FormData();
  body.append('file', file);
  return fetchWithJWT(url, {
    method: 'POST',
    signal,
    headers: {},
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body,
  })
  .then(handleApiResponse);
}

const checkRequest = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/state?path=${path}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

const cancelProcessing = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/cancel`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({
      path
    }),
  })
  .then(handleApiResponse);
}

const StopProcessingModal = ({ show, onClose, onConfirm }) => (
  <Modal show={show}>
    <Modal.Header>
      <Modal.Title>
        <Trans i18nKey="declareToControl.uploadingCancel.warning">Warning</Trans>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Trans i18nKey="declareToControl.uploadingCancel.text">
        Are you sure you want to interrupt processing of the uploaded file?
      </Trans>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" className="m-0 mr-2" onClick={onClose}>
        <Trans i18nKey="declareToControl.uploadingCancel.cancel">Cancel</Trans>
      </Button>
      <Button variant="primary" className="m-0 mr-2" onClick={onConfirm}>
        <Trans i18nKey="declareToControl.uploadingCancel.confirm">Confirm</Trans>
      </Button>
    </Modal.Footer>
  </Modal>
)

const ImportProgress = ({
  show,
  onUploadCancel,
  onClose,
  isUploaded,
  count,
  total,
  createdEntities,
  existingEntities,
  error,
  isProcessed,
  onDismissError,
}) => (
  <Modal show={show}>
    <Modal.Body>
      {show && !error && (
        <>
          {!isProcessed && <Loader message={!isUploaded ? 'Uploading...' : 'Processing...'} />}
          <br/>
          {!isUploaded && <Spinner size="sm" animation="border" variant="primary" className="file-description-spinner" />}
          {isUploaded && <i className="vtmn-icon_tiny_bold_valid file-description-icon"></i>}
          <Trans i18nKey="declareToControl.uploadingProgress.uploading">
            File uploading
          </Trans>
          {isUploaded && (
            <>
              <br/>
              {!isProcessed && (
                <Spinner size="sm" animation="border" variant="primary" className="file-description-spinner" />
              )}
              {isProcessed && (
                <i className="vtmn-icon_tiny_bold_valid file-description-icon"></i>
              )}
              {!( count && total ) && (
                <Trans i18nKey="declareToControl.uploadingProgress.processing">Processing</Trans>
              )}
              {!!(count && total) && (
                <Trans
                  i18nKey="declareToControl.uploadingProgress.rowsCounter"
                  values={{
                    count: count > total ? total : count,
                    total,
                  }}
                />
              )}
              {typeof createdEntities === 'number' && (
                <>
                  <br/><br/>
                  <Trans
                    i18nKey="declareToControl.uploadingProgress.created"
                    values={{
                      count: createdEntities,
                    }}
                  /><br/>
                  <Trans
                    i18nKey="declareToControl.uploadingProgress.existing"
                    values={{
                      count: existingEntities,
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      {show && error && <ErrorMessage error={error} />}
    </Modal.Body>
    <Modal.Footer>
      {!error && (
        <Button variant="primary" onClick={onUploadCancel} className="m-0 mr-2" disabled={isProcessed}>
          <Trans i18nKey="declareToControl.actions.uploadCancel">Upload Cancel</Trans>
        </Button>
      )}
      <Button variant="secondary" onClick={onClose} className="m-0 mr-2" disabled={!isProcessed && !error}>
        <Trans i18nKey="declareToControl.uploadingCancel.close">Close</Trans>
      </Button>
      {error && (
        <Button variant="primary" onClick={onDismissError} className="m-0 mr-2">
          <Trans i18nKey="declareToControl.actions.dismissError">Dismiss error</Trans>
        </Button>
      )}
    </Modal.Footer>
  </Modal>
)

const ImportInstructionModal = ({
  user,
  updateTokens,
  warehouse,
  onClose,
  error,
  count,
  total,
  created,
  existing,
  path,
  onConfirm,
  onFinished,
}) => {
  const { t } = useTranslation();
  const [showInstruction, setShowInstruction] = useState(!path);
  const [showProgress, setShowProgress] = useState(!!path);
  const [isUploaded, setIsUploaded] = useState(!!path);
  const [showQuestion, setShowQuestion] = useState(false);
  const [finished, setFinished] = useState(false);
  const [showDismiss, setShowDismiss] = useState(false);
  const [serverPath, setServerPath] = useState(path);
  const [processedRows, setProcessedRows] = useState(count);
  const [totalRows, setTotalRows] = useState(total);
  const [createdEntities, setCreatedEntities] = useState(created);
  const [existingEntities, setExistingEntities] = useState(existing);
  const [backendError, setBackendError] = useState(error);
  const timerRef = useRef();
  const controllerRef = useRef();
  const inputFileRef = useRef();

  const [, doImportRequest] = useRequest(importRequest, {
    onError: setBackendError,
    onSuccess: (resp) => {
      setIsUploaded(true);
      setServerPath(resp.path);
    }
  });

  const [, doCheckState] = useRequest(checkRequest, {
    onError: setBackendError,
    onSuccess: (resp) => {
      const { count, total, created, existing } = resp;
      setProcessedRows(count);
      setTotalRows(total);
      setCreatedEntities(created);
      setExistingEntities(existing);
    }
  });

  const [, doCancelProcessing] = useRequest(cancelProcessing, {
    onError: setBackendError,
  });

  useEffect(() => {
    if (!isUploaded || finished || backendError) {
      return;
    }
    timerRef.current = setInterval(() => {
      doCheckState(user, updateTokens, warehouse, serverPath)
    }, 1000);
    
    return () => clearInterval(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploaded, serverPath, warehouse, finished, backendError]);

  useEffect(() => {
    if (!!(processedRows && totalRows && processedRows >= totalRows) && timerRef.current) {
      setFinished(true);
      onFinished();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedRows, totalRows]);
  
  useEffect(() => {
    if (!error) {
      return;
    }
    setShowInstruction(false);
    setShowProgress(true);
  }, [error]);

  const uploadFiles = (list) => {
    if (!list || !list.length) {
      return;
    }

    setShowInstruction(false);
    setShowProgress(true);
    const mbSize = _.round(list[0].size / Math.pow(10, 6), 2);
    if (mbSize >= MAX_FILE_SIZE) {
      setBackendError({
        message: t('declareToControl.uploading.maxSizeError', {
          defaultValue: '{{size}}Mb more then maximum file size {{max}}Mb',
          size: mbSize,
          max: MAX_FILE_SIZE,
        })
      });
      return;
    }

    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    
    doImportRequest(user, updateTokens, warehouse, list[0], signal);
  }

  const onUploadingCancel = () => {
    controllerRef.current && controllerRef.current.abort('Interrupted by user');
    doCancelProcessing(user, updateTokens, warehouse, serverPath);
    setFinished(true);
    onConfirm();
  }

  return (
    <>
      <Modal
        show={showInstruction}
        onHide={onClose}
      >
        <Modal.Body className="file-description">
          <strong>
            <Trans i18nKey="declareToControl.uploading.requirements">
              Requirements for uploaded files:
            </Trans>
          </strong><br/>
          <Trans i18nKey="declareToControl.uploading.columnNames">
            1. The uploaded file must contain columns with the following names:
          </Trans><br/>
            <p>- <span>ADRESSE</span></p>
            <p>- <span>ARTICLE</span></p>
            <p>- <span>CONTENANT</span></p>
            <p>- <span>COLIS</span></p>
            <p>- <span>QTY_PICKED</span></p>
            <p>- <span>QTY_LEFT_ON_CONTENAN</span></p>
            <p>- <span>PICKED_BY_WHOM</span></p>
            <p>- <span>DATE_OF_PICKING</span></p>
            <p>- <span>SECTEUR</span></p>
            <p>- <span>UNIVERS</span></p>
          <Trans i18nKey="declareToControl.uploading.fileSize" values={{ max: MAX_FILE_SIZE }}>
            2. Maximum file size {{MAX_FILE_SIZE}}Mb
          </Trans><br/>
          <Trans i18nKey="declareToControl.uploading.fileTypes">
            3. Supported file formats: .csv, .xls
          </Trans>
        </Modal.Body>
        <Modal.Footer className="file-description-footer">
          <Button
            className="icon-button filter-button"
            onClick={() => {
              inputFileRef.current.click()
            }}
          >
            <Trans i18nKey="declareToControl.actions.upload">Upload file</Trans>
          </Button>
          <Form.Group controlId="formFile" className="mb-3 invisible-file-input">
            <Form.Control type="file" ref={inputFileRef} onChange={(e) => uploadFiles(e.target.files)}/>
          </Form.Group>
          <Button className="icon-button filter-button" variant="secondary" onClick={onClose}>
            <Trans i18nKey="declareToControl.uploadingCancel.cancel">Cancel</Trans>
          </Button>
        </Modal.Footer>
      </Modal>

      {showProgress && (
        <ImportProgress
          show={showProgress}
          onUploadCancel={() => setShowQuestion(true)}
          onClose={onConfirm}
          isUploaded={isUploaded}
          count={processedRows}
          total={totalRows}
          createdEntities={createdEntities}
          existingEntities={existingEntities}
          error={backendError}
          isProcessed={finished}
          onDismissError={() => setShowDismiss(true)}
        />
      )}
      <StopProcessingModal
        show={showQuestion}
        onClose={() => setShowQuestion(false)}
        onConfirm={onUploadingCancel}
      />
      <DismissErrorModal
        show={showDismiss}
        user={user}
        updateTokens={updateTokens}
        warehouse={warehouse}
        path={serverPath}
        onClose={() => setShowDismiss(false)}
        onConfirm={onConfirm}
      />
    </>
  );
}

export default ImportInstructionModal;
