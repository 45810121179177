import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'react-bootstrap';
import ShipmentsList from './shipmentsList';

import ItemModal from '../itemModal';
import UnexpectedReturns from './tabs/unexpected';
import ShippingListReturns from './tabs/shippingList';
import '../../stylesheets/returns.css';

const Returns = ({ user, updateTokens, match }) => {
  const storeId = match.params.store;
  const warehouseId = match.params.warehouse;
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeTab, setActiveTab] = useState('shipping');

  useEffect(() => {
    setSelectedShipment(null);
    setSelectedItem(null);
  }, [storeId, warehouseId]);

  return (
    <div className="returns">
      <h2>
        <Trans i18nKey="returns.title">Store returns</Trans>
      </h2>
      <ShipmentsList
        user={user}
        updateTokens={updateTokens}
        siteId={storeId || warehouseId}
        isWarehouse={warehouseId && !storeId}
        onClick={setSelectedShipment}
      />
      {selectedShipment && (
        <>
          <Card className={`filter-card mb-3 mt-0`}>
            <Card.Header>
              <ul className="nav nav-tabs card-header-tabs flex-nowrap">
                <li
                  className={`nav-item nav-link clickableText tabHeader ${activeTab === 'shipping' ? 'active' : ''}`}
                  onClick={() => setActiveTab("shipping")}
                >
                  <Trans i18nKey="returns.tabs.byItemCode">By item code</Trans>
                </li>
                <li
                  className={`nav-item nav-link clickableText tabHeader ${activeTab === 'unexpected' ? 'active' : ''}`}
                  onClick={() => setActiveTab("unexpected")}
                >
                  <Trans i18nKey="returns.tabs.unexpected">Unexpected</Trans>
                </li>
              </ul>
            </Card.Header>
            <Card.Body>
              {activeTab === 'shipping' && (
                <ShippingListReturns
                  user={user}
                  updateTokens={updateTokens}
                  shipment={selectedShipment}
                  onItemClick={setSelectedItem}
                  isWarehouse={warehouseId && !storeId}
                />
              )}
              {activeTab === 'unexpected' && (
                <UnexpectedReturns
                  user={user}
                  updateTokens={updateTokens}
                  shipment={selectedShipment}
                  onItemClick={setSelectedItem}
                  isWarehouse={warehouseId && !storeId}
                />
              )}
            </Card.Body>
          </Card>

          {selectedItem && <ItemModal
            show={!!selectedItem}
            onClose={() => setSelectedItem(null)}
            itemId={selectedItem.itemCode}
            itemLib={selectedItem.itemLib}
            universeLabel={selectedItem.universeLabel}
            departmentLabel={selectedItem.departmentLabel}
            subDepartmentLabel={selectedItem.subDepartmentLabel}
            familyLabel={selectedItem.familyLabel}
            brandName={selectedItem.brandName}
            pixlId={selectedItem.pixlId}
            price={selectedItem.price}
            currency={selectedItem.currency}
            modelId={selectedItem.modelId}
            modelLib={selectedItem.modelLib}
            articleFlag={selectedItem.articleFlag}
          />}
        </>
      )}
    </div>
  );
};

export default Returns;
