import React, { useEffect } from 'react';
import SelectFilter from '../widgets/filters/SelectFilter';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

const getAvailableStoreGroups = (user, updateTokens, countryId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let url = `${process.env.REACT_APP_base_URL}/api/groups/stores`;
  if (countryId) {
    url += `?country_id=${countryId}`;
  } else {
    url += `?no_country=true`;
  }
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoreGroupSelect = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  countryId,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchAvailableStoreGroups] = useRequest(getAvailableStoreGroups);

  
  useEffect(() => {
    fetchAvailableStoreGroups(user, updateTokens, countryId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const options = (data && data.map(group => ({
    label: group.name,
    name: group.name,
    id: group.id,
  }))) || [];

  const onSelect = (id) => {
    const record = data.find((r) => r.id === id);
    onChange(record);
  }

  return (
    <SelectFilter
      filterOpts
      controlId="select-group-id"
      label={label}
      options={options}
      selected={selected}
      onChange={onSelect}
      disabled={disabled}
      loading={loading}
      error={error}
    />
  );
};

export default StoreGroupSelect;
