import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
  Row,
  Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import capitalize from '../../functions/capitalize';
import blankFlag from '../../stylesheets/blankFlag.gif';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import Confirm from '../widgets/Confirm';

const isNil = (val) => val === null || val === undefined;

const getStoresDeployment = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/stores/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const switchActivity = (user, updateTokens, storeId, isActive) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/stores/${storeId}/activity`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ active: !isActive }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DeploymentRow = ({ site, onSwitchActive }) => {
  const flag = `flag flag-${site.countryCode.toLowerCase()}`;
  const edit = site.costPerHour || site.currency || !isNil(site.efficiencyActive);
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        {site.id}
      </td>
      <td>
        {capitalize(site.name)}
      </td>
      <td className="text-center">
        <div className="country-flag">
          <img src={blankFlag}  className={flag} alt={site.countryCode} />
        </div>
      </td>
      <td className="text-center">
        {!!site.storeSettingsId
          ? (
            <Link to={`/admin/efficiency/deployment/${site.id}`}>
              <Button className="tiny-button" size="sm" variant={edit ? 'warning' : ''}>
                {edit && (
                  <Trans i18nKey="efficiencyDeployment.editedSettings">Edit</Trans>
                )}
              </Button>
            </Link>
          )
          : (
            <Button
              className="tiny-button"
              size="sm"
              variant="light"
              as={Link}
              to={`/admin/efficiency/deployment/new?store=${site.id}`}
              title={t('efficiencyDeployment.settings.new', 'New measurement efficiency settings')}
            >
              <i className="vtmn-icon_edit_plus"></i>
            </Button>
          )
        }
      </td>
      <td className="text-center activity-filter">
        <SwitchFilter
          controlId={`activity-filter-${site.id}`}
          value={site.efficiencyActive}
          onChange={onSwitchActive}
        />
      </td>
    </tr>
  );
};

const EfficiencyDeployment = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [switchedSite, setSwitchedSite] = useState(null);
  const [{
    loading,
    error,
  }, fetchStoresDeployment] = useRequest(
    getStoresDeployment, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );

  useEffect(() => {
    fetchStoresDeployment(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActivity = (row) => {
    const tData = [...data];
    const record = tData.find((r) => r.id === switchedSite.id);
    if (!record) {
      return;
    }
    record.efficiencyActive = !record.efficiencyActive;
    record.storeSettingsId = row.storeSettingsId;
    setData(tData);
    setSwitchedSite();
  };

  const [{
    loading: loadingActivity,
    error: errorActivity,
  }, fetchActivity] = useRequest(
    switchActivity, {
      onSuccess: updateActivity
    }
  );

  const changeActivity = async () => {
    if (!switchedSite || !switchedSite.id) {
      return;
    }
    await fetchActivity(user, updateTokens, switchedSite.id, switchedSite.efficiencyActive);
  };

  return (
    <Container className="adminDeploymentManagement">
      <Row>
        <Card body className="admin-top-card">
          <Button className="icon-button" as={Link} to="/admin/efficiency/deployment/new">
            <i className="vtmn-icon_edit_plus"></i>
            <Trans i18nKey="efficiencyDeployment.enable">Enable efficiency measurement</Trans>
          </Button>
        </Card>
      </Row>
      {loading && <Loader />}
      {!loadingActivity && errorActivity && <ErrorMessage error={errorActivity} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <Table hover>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <Trans i18nKey="efficiencyDeployment.headers.site">Site</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="efficiencyDeployment.headers.description">Description</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="efficiencyDeployment.headers.country">Country</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="efficiencyDeployment.headers.settings">Settings</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="efficiencyDeployment.headers.efficiencyActivity">Efficiency activity</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(site => (
              <DeploymentRow
                key={site.id}
                site={site}
                onSwitchActive={() => {
                  setShowConfirm(true);
                  setSwitchedSite(site);
                }}
              />
            ))}
          </tbody>
        </Table>
      )}
      <Confirm
        title={t('efficiencyDeployment.activeConfirm.warning', 'Warning')}
        body={t('efficiencyDeployment.activeConfirm.text', 'Are you sure you want to change the site\'s efficiency measurement status?')}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        loading={loadingActivity}
        onConfirm={async () => {
          await changeActivity();
          setShowConfirm(false);
        }}
      />
    </Container>
  );
};

export default EfficiencyDeployment;
