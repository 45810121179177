import React from 'react';
import { useTranslation } from 'react-i18next'
import '../stylesheets/errorMessage.css';

const ErrorMessage = ({ error }) => {
  const { t } = useTranslation();
  return (
    <div className="error-message">
      <i className="error-message-icon vtmn-icon_cross_rounded"></i>
      <div className="error-message-text">
        {error.message || t('defaultError', 'Error')}
      </div>
    </div>
  );
};

export default ErrorMessage;
