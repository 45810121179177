import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Spinner,
  Modal,
} from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import getFiltersWithInverted from '../../functions/getFiltersWithInverted';

const useEvent = (event, handler) => {
  useEffect(() => {
    window.addEventListener(event, handler);
    return () => window.removeEventListener(event, handler);
  }, [event, handler]);
};

const onExportGranted = (
  doExportReport,
  user,
  updateTokens,
  filters,
  invertedFilters,
  sort,
  setOauthToken,
  setTokenValidUntil,
) => (event) => {
  if (event.key === 'credentials' && event.newValue !== null){
    const credentials = JSON.parse(event.newValue)
    const oauthToken = credentials.token;
    const validUntil = credentials.validUntil;
    doExportReport(user, updateTokens, oauthToken, {
      filters: getFiltersWithInverted(filters, invertedFilters),
      sort
    });
    setOauthToken(oauthToken);
    setTokenValidUntil(validUntil);
  }
};

const onExportOauth = () => {
  const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const client_id = process.env.REACT_APP_spreadsheet_clientId;
  const redirect_uri = `${process.env.REACT_APP_fedID_redirect_URI}/googleOAuth`;
  const scope = 'https://www.googleapis.com/auth/spreadsheets';
  window.open(`${baseUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=${scope}`, "_blank")
};

const onExportComplete = (resp) => {
  window.open(`https://docs.google.com/spreadsheets/d/${resp.id}`, "_blank");
};

const isValid = (tokenValidUntil) => {
  if (!tokenValidUntil) {
    return false;
  }

  const VALID_LIMIT_MS = 20000;
  const validUntil = new Date(tokenValidUntil);
  const now = new Date();
  const msLeft = validUntil.getTime() - now.getTime();

  const tokenValid = msLeft > VALID_LIMIT_MS;
  return tokenValid;
};

const EXPORT_LIMIT = 50000;

const ExportConfirm = ({ show, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal className="filter-export-confirm" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="filters.exportConfirm.warning">Warning</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t('filters.exportConfirm.tooMuch', 'You are about to export too much data.')}</p>
        <p>{t(
          'filters.exportConfirm.limit',
          {
            defaultValue: 'The data would be limited with {{limit}} rows.',
            limit: EXPORT_LIMIT,
          },
        )}</p>
        <p>{t('filters.exportConfirm.continue', 'Continue export?')}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          <Trans i18nKey="filters.exportConfirm.close">Close</Trans>
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          <Trans i18nKey="filters.exportConfirm.export">Export</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ExportButton = ({
  user,
  updateTokens,
  filters,
  invertedFilters,
  sort,
  totalItems,
  exportReport,
  size,
}) => {
  const [oauthToken, setOauthToken] = useState(null);
  const [tokenValidUntil, setTokenValidUntil] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [{
    loading,
    error,
  }, doExportReport] = useRequest(exportReport, {
    onSuccess: onExportComplete,
    onError: (e) => console.error('Export error', e),
  })
  const memoizedOnExportGranted = useMemo(() => onExportGranted(
    doExportReport,
    user,
    updateTokens,
    filters,
    invertedFilters,
    sort,
    setOauthToken,
    setTokenValidUntil,
  ), [
    doExportReport,
    user,
    updateTokens,
    filters,
    invertedFilters,
    sort,
    setOauthToken,
    setTokenValidUntil,
  ]);
  useEvent('storage', memoizedOnExportGranted);

  const onExport = () => {
    if (oauthToken && isValid(tokenValidUntil)) {
      doExportReport(user, updateTokens, oauthToken, {
        filters: getFiltersWithInverted(filters, invertedFilters),
        sort
      });
    } else {
      onExportOauth();
    }
  };

  return (
    <div className={`filter-export ${error ? 'filter-export-witherror' : ''}`}>
      <Button
        size={size}
        disabled={loading}
        className="icon-button filter-button"
        onClick={() => {
          if (totalItems > EXPORT_LIMIT) {
            setShowModal(true);
          } else {
            onExport();
          }
        }}
      >
        {(
          loading
            ? <Spinner size="sm" animation="border" variant="light" className="buttonSpinner" />
            : <i className="vtmn-icon_external_link"></i>
        )}
        <Trans i18nKey="filters.export">Export</Trans>
      </Button>
      {error && <span className="filter-export-error" title={error.message}>{error.message}</span>}

      <ExportConfirm
        show={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => {
          setShowModal(false);
          onExport();
        }}
      />
    </div>
  )
};

export default ExportButton;
