import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import useRequest from '../../functions/useRequest'
import fetchWithJWT from '../../functions/fetchWithJWT'
import handleApiResponse from '../../functions/handleApiResponse'
import Loader from '../loader'
import ErrorMessage from '../errorMessage'
import Info from './Info'
import HistoryChart from './HistoryChart'
import KeyPerformanceIndicators from './KeyPerformanceIndicators'
import DaterangeFilter from '../widgets/filters/DaterangeFilter'
import { formatDateForBigQuery } from '../../functions/formatDate'
import getQueryString from '../../functions/getQueryString'
import '../../stylesheets/monitoringGate.css'

const getGateInfo = (user, updateTokens, store) => {
  const { token, refreshToken, tokenExpireDate } = user
  const url = `${process.env.REACT_APP_base_URL}/api/${store}/gate/monitoring`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const getGateHistory = (user, updateTokens, store, filters) => {
  const { token, refreshToken, tokenExpireDate } = user
  const formatedFilters = { ...filters }

  if (formatedFilters.dateFrom) {
    formatedFilters.dateFrom = formatDateForBigQuery(formatedFilters.dateFrom)
  }
  if (formatedFilters.dateTo) {
    formatedFilters.dateTo = formatDateForBigQuery(formatedFilters.dateTo)
  }

  const queryString = getQueryString({
    filters: formatedFilters,
  })

  const url = `${process.env.REACT_APP_base_URL}/api/${store}/gate/monitoring/history?${queryString}`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const getMonitoringKPIs = (user, updateTokens, store, filters) => {
  const { token, refreshToken, tokenExpireDate } = user
  const formatedFilters = { ...filters }

  if (formatedFilters.dateFrom) {
    formatedFilters.dateFrom = formatDateForBigQuery(formatedFilters.dateFrom)
  }
  if (formatedFilters.dateTo) {
    formatedFilters.dateTo = formatDateForBigQuery(formatedFilters.dateTo)
  }

  const queryString = getQueryString({
    filters: formatedFilters,
  })

  const url = `${process.env.REACT_APP_base_URL}/api/${store}/gate/monitoring/kpis?${queryString}`
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse)
}

const from = new Date()
from.setDate(from.getDate() - 30)

const MonitoringGate = ({ user, updateTokens }) => {
  const { t } = useTranslation()
  const { store } = useParams()
  const [filters, setFilters] = useState({
    dateFrom: from,
    dateTo: new Date(),
  })

  const [
    { loading: loadingInfo, error: errorInfo, data: gateInfo },
    fetchGateInfo,
  ] = useRequest(getGateInfo)
  const [
    { loading: loadingHistory, error: errorHistory, data: history },
    fetchGateHistory,
  ] = useRequest(getGateHistory)
  const [
    { loading: loadingKPI, error: errorKPI, data: kpiInfo },
    fetchKPIInfo,
  ] = useRequest(getMonitoringKPIs)

  useEffect(() => {
    fetchGateInfo(user, updateTokens, store)
    fetchGateHistory(user, updateTokens, store, filters)
    fetchKPIInfo(user, updateTokens, store, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, filters])
  const loading = loadingInfo || loadingHistory || loadingKPI
  const error = errorHistory || errorKPI
  const isReady = !loading && !error && history

  return (
    <div className="container-fluid monitoringGate">
      <h3>
        <Trans i18nKey="monitoringGate.title">Receptions RFID control</Trans>
      </h3>

      {loading && <Loader />}
      {!loading && error && <ErrorMessage error={error} />}
      {isReady && (
        <>
          <div className="row">
            <div className="col-lg-6">
              {errorInfo ? (
                <ErrorMessage error={errorInfo} />
              ) : (
                <Info gateInfo={gateInfo} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h4 className="monitoringGate-chartTitle">
                <Trans i18nKey="monitoringGate.history.title">
                  Reception control history
                </Trans>
              </h4>
              <DaterangeFilter
                controlId="filter-date"
                label={t('monitoringGate.filters.date', 'Date frame')}
                valueFrom={filters.dateFrom}
                valueTo={filters.dateTo}
                onChange={(dateFrom, dateTo) =>
                  setFilters({
                    ...filters,
                    dateFrom,
                    dateTo,
                  })
                }
              />
              <br />
              <div className="col-lg-6">
                <KeyPerformanceIndicators kpiInfo={kpiInfo} />
              </div>
              <br />
              <HistoryChart history={history} gateInfo={gateInfo} filters={filters} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MonitoringGate
