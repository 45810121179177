import getSymbolFromCurrency from 'currency-symbol-map';

const RX_FORMAT_MONEY = /\B(?=(\d{3})+(?!\d))/g;

const formatMoney = (value, currency, defaultVal) => {
  if (value === null || value === undefined) {
    return defaultVal;
  }

  const currencySymbol = getSymbolFromCurrency(currency) || currency;
  const formatedValue = value.toFixed(2).replace(RX_FORMAT_MONEY, '\xa0');
  return `${formatedValue}\xa0${currencySymbol}`;
}

export const formatCurrency = (currency) => getSymbolFromCurrency(currency) || currency;

export default formatMoney;
