import React, { useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const QuantityEditModal = ({ controlId, deliveries, onChange, show, onHide, minValue }) => {
  const [localValue, setLocalValue] = useState(deliveries);

  const close = () => {
    onChange([...localValue]);
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="efficiencyAdmin.columns.quantities">Quantities</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="delivery-quantities">
        {localValue.map((d, i) => (
          <Form.Group as={Row} key={i} controlId={`${controlId}-delivery-${d.id}`}>
            <Form.Label >
              {`${d.id}: `}
            </Form.Label>
            <Form.Control
              step={1}
              min={minValue}
              max={1000000}
              type="number"
              value={d.qty}
              onChange={(e) => {
                const val = parseInt(e.target.value, 10);

                setLocalValue([
                  ...localValue.slice(0, i),
                  {
                    ...localValue[i],
                    qty: val <= minValue ? minValue : val,
                  },
                  ...localValue.slice(i + 1)
                ])
              }}
            />
          </Form.Group>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button className="m-0" variant="primary" onClick={close}>
          <Trans i18nKey="shared.ok">OK</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const QuantityEdit = ({ label, onChange, controlId, minValue, deliveries }) => {
  const [show, setShow] = useState(false);
  const value = deliveries.reduce((acc, d) => acc + d.qty, 0);

  return (
    <Form.Group className="form-item involvement-edit" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        type="button"
        value={value}
        onClick={() => setShow(true)}
      />
      <QuantityEditModal
        controlId={controlId}
        show={show}
        onHide={() => setShow(false)}
        onChange={onChange}
        deliveries={deliveries}
        minValue={minValue || 0}
      />
    </Form.Group>
  );
}

export default QuantityEdit;
