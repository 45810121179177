import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import { formatDateForBigQuery } from '../../functions/formatDate';
import getQueryString from '../../functions/getQueryString';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import HistoryChart from '../monitoringRobot/HistoryChart';
import Info from '../monitoringRobot/Info';
import { inventoryIdToDate } from '../monitoringRobot/functions';
import Filters from './Filters';
import '../../stylesheets/monitoringRobots.css';
import { Alert } from 'react-bootstrap';

const getRobotsInventoryHistory = async (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/robots/inventory/history`;
  const formatedFilters = { ...filters };
  if (formatedFilters.dateFrom) {
    formatedFilters.dateFrom = formatDateForBigQuery(formatedFilters.dateFrom);
  }
  if (formatedFilters.dateTo) {
    formatedFilters.dateTo = formatDateForBigQuery(
      dayjs(formatedFilters.dateTo).add(1, 'day').toDate()
    );
  }
  if (formatedFilters.storeIn) {
    formatedFilters.storeIn = formatedFilters.storeIn.join(',');
  }
  const queryString = getQueryString({ filters: formatedFilters });
  const history =await fetchWithJWT(`${url}?${queryString}`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  const byStore = _.groupBy(history, 'store');
  return byStore;
};

const getStores = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/monitoring`;
  const stores = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  const withRobots = stores.filter(x => x.features.hasRobot);
  return withRobots;
};

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const getPersonalStore = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/oser/personalStores`;
  const stores = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return stores;
};

const defaultCountry = (lang) => {
  if (lang === 'EN') {
    return 'GB'
  }
  return lang;
};

const defaultDateFilters = () => {
  const monthAgo = dayjs().startOf('day').subtract(1, 'month').toDate();
  const endOfDay = dayjs().endOf('day').toDate();
  return [monthAgo, endOfDay];
};

const RobotHistory  = ({ history, store, key }) => {
  const lastHistory = history[history.length - 1];
  const report = lastHistory ? ({
    totalUnsoldTags: lastHistory.totalUnsoldTags,
    inventories: [{
      inventoryId: lastHistory.lastInventoryId,
    }]
  }) : null;
  return (
    <div>
      <h4 className="mt-3 mb-3">
        {store.id}
        {' - '}
        {store.name}
      </h4>

      <div className="row">
        <div className="col-lg-8">
          <HistoryChart history={history} />
        </div>
        <div className="col-lg-4 monitoringRobot-info">
          <Info history={history} report={report} />
        </div>
      </div>
    </div>
  )
}

const RobotsHistory = ({ history, stores }) => {
  const storeIds = Object.keys(history);

  return storeIds.map((storeId => (
    <RobotHistory
      key={storeId}
      history={history[storeId]}
      store={stores.find(x => x.id === storeId)}
    />
  )))
};

const ROBOT_RUN_TIMEOUT = 2 * 24 * 60 * 60 * 1000;
const isRobotRunTimeout = (inventoryDate, now) => {
  if (
    inventoryDate
    && now.getTime() - inventoryDate.getTime() > ROBOT_RUN_TIMEOUT
  ) {
    return true;
  }
  return false;
};

const NoRobotRunWarning = ({ history, stores }) => {
  if (!history || !stores) {
    return null;
  }

  const now = new Date();

  const storeIds = Object.keys(history);
  const noRobotRun = storeIds.filter(storeId => {
    const storeHistory = history[storeId];
    const lastHistory = storeHistory[storeHistory.length - 1];
    const inventoryDate = inventoryIdToDate(lastHistory?.maxLastInventoryId);
    const noRun = isRobotRunTimeout(inventoryDate, now);
    return noRun;
  });
  const noRobotRunStores = stores.filter(x => noRobotRun.indexOf(x.id) !== -1);

  if (noRobotRunStores.length === 0) {
    return null;
  }

  return (
    <Alert variant="primary" className="noRobotRun-message">
      <Alert.Heading>
        <Trans i18nKey="monitoringRobots.warning">Warning</Trans>
      </Alert.Heading>
      <p>
        <Trans i18nKey="monitoringRobots.noRobotRun">The robot from the following store(s) didn’t run for the past 2 days</Trans>
      </p>
      <ul className="ml-3">
        {noRobotRunStores.map(store => (
          <li key={store.id}>
            {store.id}
            {' - '}
            {store.name}
          </li>
        ))}
      </ul>
    </Alert>
  );
};


const AdminMonitoringRobots = ({
  user,
  updateTokens,
}) => {
  const [monthAgo, endOfDay] = defaultDateFilters();
  const [filters, setFilters] = useState({
    dateFrom: monthAgo,
    dateTo: endOfDay,
    countryCode: defaultCountry(user.countryCode),
    storeIn: [],
  });
  const [{
    loading: countriesLoading,
    data: countries,
    error: countriesError,
  }, fetchCountries] = useRequest(getCountries);
  useEffect(() => {
    fetchCountries(user, updateTokens);
  }, [fetchCountries, user, updateTokens]);
  const [{
    loading: storesLoading,
    data: stores,
    error: storesError,
  }, fetchStores] = useRequest(getStores);
  useEffect(() => {
    fetchStores(user, updateTokens);
  }, [fetchStores, user, updateTokens]);

  const [{
    loading: personalStoresLoading,
    data: personalStores,
    error: personalStoresError,
  }, fetchPersonalStore] = useRequest(getPersonalStore);
  useEffect(() => {
    fetchPersonalStore(user, updateTokens);
  }, [fetchPersonalStore, user, updateTokens]);

  const [{
    loading: historyLoading,
    data: history,
    error: historyError,
  }, fetchRobotsInventoryHistory] = useRequest(getRobotsInventoryHistory);
  useEffect(() => {
    fetchRobotsInventoryHistory(user, updateTokens, filters);
  }, [fetchRobotsInventoryHistory, filters, user, updateTokens]);

  const loading = personalStoresLoading || countriesLoading || storesLoading || historyLoading;
  const error = personalStoresError || countriesError || storesError || historyError;
  const isReady = !loading && !error && history && stores;

  return (
    <div className = "container-fluid monitoringRobots">
      <h3>
        <Trans i18nKey="monitoringRobots.title">Monitoring Robots</Trans>
      </h3>
      <Filters
        filters={filters}
        setFilters={setFilters}
        stores={stores || []}
        countries={countries || []}
        personalStores={personalStores || []}
        storesLoading={storesLoading}
        countriesLoading={countriesLoading}
        personalStoresLoading={personalStoresLoading}
      />
      {loading && <Loader />}
      {error && <ErrorMessage error={error} />}
      {isReady && (
        <>
          <NoRobotRunWarning
            history={history}
            stores={stores}
          />
          <RobotsHistory
            history={history}
            stores={stores}
          />
        </>
      )}
    </div>
  );
};

export default AdminMonitoringRobots;
