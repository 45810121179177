import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Row,
  Card,
  Button,
} from 'react-bootstrap';
import DaterangeFilter from '../widgets/filters/DaterangeFilter';
import SelectFilter from '../widgets/filters/SelectFilter';
import renderFlagItem from '../widgets/renderFlagItem';

const Filters = ({
  filters,
  setFilters,
  stores,
  countries,
  personalStores,
  storesLoading,
  countriesLoading,
  personalStoresLoading,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState('full');
  const isVisible = visibility !== 'hidden';

  const filtered = !!(filters.storeIn && filters.storeIn.length);
  let storesFiltered = stores;
  if (filters.countryCode) {
    storesFiltered = storesFiltered.filter(x => x.countryCode === filters.countryCode);
  }
  const storesOptions = storesFiltered.map(x => ({
    id: x.id,
    countryCode: x.countryCode,
    label: `${x.id} - ${x.name}`,
  }));
  const countryOptions = countries.map(x => ({
    id: x.code,
    countryCode: x.code,
    label: x.name,
  }));
  const oserOptions = personalStores.map(x => ({
    id: x.id,
    label: x.name,
  }));
  const showOserFilter = filters.countryCode === 'RU';

  return (
    <Card className={`filter-card filter-card-${visibility} mb-3 mt-0`}>
      <Card.Header>
        <Button
          size="sm"
          className={`icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
          onClick={() => setVisibility(isVisible ? 'hidden' : 'partial')}
        >
          <i className="vtmn-icon_filter2"></i>
          {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
          <Trans i18nKey="filters.filters">Filters</Trans>
          {isVisible ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </Button>
        <div className="spacer"/>
      </Card.Header>
      {isVisible && (
        <Card.Body>
          <Row>
            <DaterangeFilter
              controlId="filter-date"
              label={t('monitoringRobots.dateFilter', 'Date filter')}
              valueFrom={filters.dateFrom}
              valueTo={filters.dateTo}
              onChange={(dateFrom, dateTo) => setFilters({
                ...filters,
                dateFrom,
                dateTo,
              })}
            />
            <SelectFilter
              controlId="filter-countries"
              label={t('monitoringRobots.country', 'Country')}
              options={countryOptions}
              selected={filters.countryCode}
              onChange={(countryCode) => setFilters({
                ...filters,
                countryCode,
                storeIn: [],
                oser: undefined,
              })}
              loading={countriesLoading}
              renderItem={renderFlagItem}
            />
            <SelectFilter
              controlId="filter-stores"
              label={t('monitoringRobots.stores', 'Stores')}
              options={storesOptions}
              selected={filters.storeIn}
              onChange={(storeIn) => setFilters({
                ...filters,
                storeIn,
              })}
              disabled={filters.oser}
              multiple
              loading={storesLoading}
              renderItem={renderFlagItem}
            />

            {showOserFilter && (
              <SelectFilter
                controlId="filter-oser"
                label={t('monitoringRobots.oserFilter', 'OSER filter')}
                options={oserOptions}
                selected={filters.oser}
                onChange={(id) => setFilters({
                  ...filters,
                  storeIn:  personalStores.find(x => x.id === id)?.store_ids,
                  oser: id
                })}
                loading={personalStoresLoading}
              />
            )}

          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default Filters;
