import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import SelectFilter from './SelectFilter';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';
import '../../../stylesheets/storeSelect.css';


const getAvailableWarehouses = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/warehouses`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DiscrepanciesWarehouseFilter = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchAvailableWarehouses] = useRequest(getAvailableWarehouses);
  const { t } = useTranslation();
  useEffect(() => {
    fetchAvailableWarehouses(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = (data && data.map(warehouse => ({
    label: `${warehouse.id} - ${warehouse.name}`,
    name: warehouse.name,
    id: warehouse.id,
  }))) || [];
  return (
    <SelectFilter
      multiple
      filterOpts
      controlId="select-warehouse-id"
      label={t('reportingDiscrepancies.filters.warehouse','Warehouse')}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      disabled={disabled}
      loading={loading}
      error={error}
    />
  );
}

export default DiscrepanciesWarehouseFilter;
