import _ from 'lodash';
import { getQueryString, getFiltersParams, getSortParams } from '../../functions/getQueryString';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse, { handlePaginatedApiResponse } from '../../functions/handleApiResponse';

const formatFilters = (filters) => {
  const formatedFilters = { ...filters };
  if (formatedFilters.unloading_start_date_from) {
    formatedFilters.unloading_start_date_from = formatedFilters.unloading_start_date_from.toISOString();
  }

  if (formatedFilters.unloading_start_date_to) {
    formatedFilters.unloading_start_date_to = formatedFilters.unloading_start_date_to.toISOString();
  }
  return formatedFilters;
};

export const getEfficiencies = (user, updateTokens, { filters, pagination, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.records_type === 'all') {
    formatedFilters.records_type = 'correct,incorrect,valid';
  } else if (formatedFilters.records_type === 'correct') {
    formatedFilters.records_type = 'correct,valid';
  }
  
  const queryString = getQueryString({
    filters: formatFilters(formatedFilters),
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

export const delEfficiency = (user, updateTokens, store, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/${store}/efficiency/${id}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const delMultyEfficiency = (user, updateTokens, ids) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    body: JSON.stringify({ ids }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const editEfficiency = (user, updateTokens, store, id, efficiency) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/${store}/efficiency/${id}`;
  const body = {
    ...(_.omit(efficiency, 'quantities')),
    deliveries: efficiency.deliveries.map((d) => ({
      id: d.id,
      parcels: d.parcels,
      qty: d.qty,
    }))
  }
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

export const exportEfficiencies = (user, updateTokens, oauthToken, { filters, sort, fields }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/export`;
  const formatedFilters = { ...filters };
  if (formatedFilters.records_type === 'all') {
    formatedFilters.records_type = 'correct,incorrect,valid';
  } else if (formatedFilters.records_type === 'correct') {
    formatedFilters.records_type = 'correct,valid';
  }

  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
      fields,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

export const validateEfficiency = (user, updateTokens, id) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/${id}/validate`;
  return fetchWithJWT(url, {
    method: 'PUT',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}