import React from 'react';
import { useTranslation } from 'react-i18next';
import TextEdit from '../widgets/form/TextEdit';
import SelectFilter from '../widgets/filters/SelectFilter';
import renderFlagItem from '../widgets/renderFlagItem';
import '../../stylesheets/groups.css';

const GroupForm = ({
  settings,
  setSettings,
  stores,
  countries,
}) => {
  const { t } = useTranslation();
  const storeOptions = stores.map(x => ({
    id: x.id,
    countryCode: x.countryCode,
    label: `${x.id} - ${x.name}`,
  }));
  const countryOptions = countries.map(x => ({
    id: x.id,
    countryCode: x.code,
    label: x.name,
  }));

  return (
    <div className="group-settings-form">
      <div className="group-form-row">
        <TextEdit
          label={t('storeGroups.name', 'Name')}
          value={settings.name}
          controlId="name"
          onChange={(name) => setSettings({
            ...settings,
            name,
          })}
        />
      </div>
      <div className="group-form-row">
        <SelectFilter
          filterOpts
          multiple
          controlId="filter-countries"
          label={t('storeGroups.countries', 'Countries')}
          options={countryOptions}
          selected={settings.countries}
          onChange={(countries) => setSettings({
            ...settings,
            countries,
          })}
          renderItem={renderFlagItem}
        />
      </div>
      <div className="group-form-row">
        <SelectFilter
          filterOpts
          multiple
          controlId="filter-stores"
          label={t('storeGroups.stores', 'Stores')}
          options={storeOptions}
          selected={settings.stores}
          onChange={(stores) => setSettings({
            ...settings,
            stores,
          })}
          renderItem={renderFlagItem}
        />
      </div>
    </div>
  );
};

export default GroupForm;
