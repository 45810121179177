import React from 'react';

const SelectToggleButton = ({ isOpen, onClick }) => (
  <button
    type="button"
    className="select-toggle-button"
    onClick={onClick}
    onMouseDown={e => {
      // Prevent input from losing focus.
      e.preventDefault();
      e.stopPropagation();
      return false;
    }}>
  </button>
);

export default SelectToggleButton;
