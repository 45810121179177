import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../functions/formatDate';
import formatMoney from '../../../functions/formatMoney';
import CommonReturnTab from './commonReturnTab';

const ShippingListReturns = ({ user, updateTokens, shipment, onItemClick, isWarehouse }) => {
  const { t } = useTranslation();

  const tdInnerRender = (row, value) => {
    let cls = '';
    if (!isWarehouse) {
      if (!shipment.scannedInWarehouse) {
        if (row.qtyOutRead < row.qtyInFact && row.qtyOutRead > 0) {
          cls = 'orange-cell';
        } else if (row.qtyOutRead > 0) {
          cls = 'red-cell';
        }
      } else {
        if (row.qtyInRead < row.qtyInFact && row.qtyOutRead < row.qtyInFact
          && row.qtyOutRead > 0 && row.qtyInRead > 0) {
          cls = 'orange-cell';
        } else if (row.qtyOutRead === 0 && row.qtyInRead === 0) {
          cls = 'red-cell';
        }
      }
    } else {
      if (row.qtyInRead < row.qtyInFact) {
        if (row.qtyOutRead < row.qtyInFact) {
          cls = 'red-cell';
        } else {
          cls = 'orange-cell';
        }
      }
    }
    return <div className={cls}>{value}</div>;
  };

  const columns = [{
    title: t('returns.sendingDate', 'Sending date'),
    field: 'sendingDate',
    width: 120,
    valueRenderer: (val, column, row) => val ? tdInnerRender(row, formatDateTime(new Date(val))) : tdInnerRender(row, null),
  }, {
    title: t('returns.shipmentId', 'Shipment Id'),
    field: 'shipmentId',
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.packages', 'Packages'),
    field: 'packages',
    width: 300,
    valueRenderer: (val, column, row) => val ? tdInnerRender(row, val.join(', ')) : tdInnerRender(row, null),
  }, {
    title: t('returns.item', 'Item'),
    field: 'itemCode',
    sortable: true,
    width: 100,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.sellingPrice', 'Selling price'),
    field: 'price',
    sortable: true,
    width: 100,
    valueRenderer: (val, column, row) => tdInnerRender(row, formatMoney(val, row.currency, '-'))
  }, {
    title: t('returns.itemDescription', 'Item description'),
    field: 'itemLib',
    sortable: true,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.shippingList', 'Shipping list'),
    field: 'qtyInFact',
    sortable: true,
    width: 50,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.inStore', 'In store'),
    field: 'qtyOutRead',
    sortable: true,
    width: 50,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }, {
    title: t('returns.inWarehouse', 'In warehouse'),
    field: 'qtyInRead',
    sortable: true,
    width: 50,
    valueRenderer: (val, column, row) => tdInnerRender(row, val),
  }];

  return (
    <CommonReturnTab
      type="shippingItems"
      user={user}
      updateTokens={updateTokens}
      shipmentId={shipment.shipmentId}
      onItemClick={onItemClick}
      columns={columns}
      sourceUrl={`${process.env.REACT_APP_base_URL}/api/returns/shipment/${shipment.shipmentId}/shipping-items`}
    />
  );
};

export default ShippingListReturns;
