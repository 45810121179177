import React from 'react';
import '../../stylesheets/TableHeadCell.css';

const TableHeadCell = ({ field, sort, setSort }) => {
  let sortIcon = 'sort';
  if (sort && sort.field === field.field) {
    if (sort.direction === 'ASC') {
      sortIcon = 'arrow1_down';
    } else {
      sortIcon = 'arrow1_up';
    }
  }
  return (
    <th
      className={field.sortable ? 'column-sortable' : null}
      style={field.width ? { width: `${field.width}px` } : null}
      onClick={() => {
        if (field.sortable) {
          const nextDirection = (
            sort && sort.field === field.field && sort.direction !== 'DESC'
          ) ? 'DESC' : 'ASC';
          setSort({
            field: field.field,
            direction: nextDirection,
          });
        }
      }}
    >
      <div className="table-head-inner-wrapper">
        <div className="datatable-title-wraper">{field.title}</div>
        {field.sortable && (
          <i className={`sort-icon vtmn-icon_${sortIcon}`}></i>
        )}
      </div>
    </th>
  );
}

export default TableHeadCell;
