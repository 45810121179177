import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import SelectFilter from './SelectFilter';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';
import renderFlagItem from '../renderFlagItem';
import '../../../stylesheets/storeSelect.css';


const getAvailableStores = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = warehouse
    ? `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/stores`
    : `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/stores`
  ;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const DiscrepanciesStoreFilter = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  invertable,
  inverted,
  onInvert,
  controlId,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  const { warehouse } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    fetchAvailableStores(user, updateTokens, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse]);
  const options = (data && data.map(store => ({
    countryCode: store.countryCode,
    label: `${store.id} - ${store.name}`,
    name: store.name,
    id: store.id,
  }))) || [];
  return (
    <SelectFilter
      multiple
      filterOpts
      controlId={controlId || 'select-store-id'}
      label={label || t('declareToControl.filters.store','Store')}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      disabled={disabled}
      loading={loading}
      error={error}
      renderItem={renderFlagItem}
    />
  );
}

export default DiscrepanciesStoreFilter;
