import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import GroupForm from './GroupForm';

const getStores = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const getSettings = ({
  user,
  updateTokens,
  groupId,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores/${groupId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const createSettings = ({
  user,
  updateTokens,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  groupId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores/${groupId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoreGroupsSettings = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const history = useHistory();
  const isNew = groupId === 'new';
  const title = isNew
    ? t('storeGroups.newGroup', 'New group')
    : t('storeGroups.editGroup', 'Edit group');
    const [settings, setSettings] = useState(null);

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setSettings
  });

  useEffect(() => {
    if (!isNew) {
      fetchSettings({
        groupId,
        user,
        updateTokens,
      });
      return;
    }
    setSettings({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, groupId, fetchSettings]);

  const [{
    loading: countriesLoading,
    data: countries,
    error: countriesError,
  }, fetchCountries] = useRequest(getCountries);

  const [{
    loading: storesLoading,
    data: stores,
    error: storesError,
  }, fetchStores] = useRequest(getStores);
  
  useEffect(() => {
    fetchStores(user, updateTokens);
    fetchCountries(user, updateTokens);
  }, [fetchStores, fetchCountries, user, updateTokens]);

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(isNew ? createSettings : updateSettings, {
    onSuccess: () => history.goBack(),
  });

  const onSave = () => saveSettings({
    groupId,
    settings,
    user,
    updateTokens,
  });

  const hasError = !storesLoading && !loading && !saving && !countriesLoading && (error || saveError || storesError || countriesError);
  const isReady = !storesLoading && !storesError && !loading && !saving && !error && !!settings && !countriesLoading;
  const isValid = settings && settings.name && settings.stores && settings.stores.length;

  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
          {(loading || saving) && <Loader />}
          {isReady && (
            <GroupForm
              settings={settings}
              setSettings={setSettings}
              stores={stores}
              countries={countries}
            />
          )}
          {hasError && <ErrorMessage error={error || saveError || storesError || countriesError} />}
        </Card.Body>
        <Card.Footer className="p-0">
          <Button
            variant="secondary"
            className="icon-button"
            onClick={() => history.goBack()}
          >
            <i className="vtmn-icon_cross"></i>
            <Trans i18nKey="storeGroups.cancel">Cancel</Trans>
          </Button>
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={!isReady || !isValid}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="storeGroups.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default StoreGroupsSettings;
