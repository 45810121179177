import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next'
import StoreSelect from '../widgets/StoreSelect';
import ImageEditor from '../widgets/ImageEditor';
import FileEditor from '../widgets/FileEditor';

const RobotForm = ({ user, updateTokens, robot, setRobot, setUploading }) => {
  const { t } = useTranslation();
  const { search } = useLocation()
  const predefinedStore = (search && (new URLSearchParams(search)).get('store')) || null;
  useEffect(() => {
    if (!robot.id && predefinedStore) {
      setRobot(prevRobot => ({ ...prevRobot, storeId: predefinedStore }));
    }
  }, [robot.id, setRobot, predefinedStore])
  return (
    <Form>
      <StoreSelect
        user={user}
        updateTokens={updateTokens}
        selected={robot.storeId}
        onChange={(storeId) => setRobot({ ...robot, storeId })}
        disabled={!!robot.id || !!predefinedStore}
        filter={{ withoutRobot: !robot.id || !predefinedStore }}
      />
      <Form.Group className="form-checkbox-row">
        <Form.Check
          id="robot-start-has-electricity"
          className="offset-lg-3"
          type="switch"
          label={t('deploymentRobot.hasElectricity', 'Starting point has electricity')}
          checked={robot.startHasElectricity || false}
          onChange={() => setRobot({ ...robot, startHasElectricity: !robot.startHasElectricity })}
        />
      </Form.Group>
      <Form.Group className="form-checkbox-row">
        <Form.Check
          id="robot-wifi-ready"
          className="offset-lg-3"
          type="switch"
          label={t('deploymentRobot.wifiReady', 'Wifi DKTRobot is ready')}
          checked={robot.wifiReady || false}
          onChange={() => setRobot({ ...robot, wifiReady: !robot.wifiReady })}
        />
      </Form.Group>
      <Form.Group className="form-checkbox-row">
        <Form.Check
          id="robot-ssh-available"
          className="offset-lg-3"
          type="switch"
          label={t('deploymentRobot.sshAvailable', 'Ssh protocol is available for robot manufacturer to connect to the robot')}
          checked={robot.sshAvailable || false}
          onChange={() => setRobot({ ...robot, sshAvailable: !robot.sshAvailable })}
        />
      </Form.Group>
      <Form.Group as={Row} controlId="robot-arrival-date">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRobot.arrivalDate">
            Date of arrival of the robot in the store
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-datepicker-col">
          <Form.Control
            isClearable
            placeholderText={t('deploymentRobot.selectDate', 'Select date...')}
            as={DatePicker}
            selected={robot.arrivalDate}
            onChange={(arrivalDate) => setRobot({ ...robot, arrivalDate })}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="robot-training-session">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRobot.training">
            Training session date
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-datepicker-col">
          <Form.Control
            isClearable
            placeholderText={t('deploymentRobot.selectDate', 'Select date...')}
            as={DatePicker}
            selected={robot.trainingSession}
            onChange={(trainingSession) => setRobot({ ...robot, trainingSession })}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRobot.startPicture">
            Starting point picture
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-file-col">
          <ImageEditor
            id="robot-start-picture"
            user={user}
            updateTokens={updateTokens}
            previewHeader={t('deploymentRobot.startPicturePreview', 'Preview starting point picture')}
            url={robot.startPicture}
            setUrl={(startPicture) => {
              setRobot({ ...robot, startPicture });
            }}
            setUploading={startPicture => setUploading({ startPicture })}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRobot.massPlan">
            Mass plan (sport level)
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-file-col">
          <FileEditor
            id="robot-mass-plan-file"
            user={user}
            updateTokens={updateTokens}
            url={robot.massPlanFile}
            setUrl={(massPlanFile) => {
              setRobot({ ...robot, massPlanFile });
            }}
            setUploading={massPlanFile => setUploading({ massPlanFile })}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRobot.architectPlan">
            Architect plan
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-file-col">
          <FileEditor
            id="robot-architect-plan-file"
            user={user}
            updateTokens={updateTokens}
            url={robot.architectPlanFile}
            setUrl={(architectPlanFile) => {
              setRobot({ ...robot, architectPlanFile });
            }}
            setUploading={architectPlanFile => setUploading({ architectPlanFile })}
            accept=".dwg, image/vnd.dwg, image/jpeg, image/png, application/pdf"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="robot-comment">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRobot.comment">
            Comment
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8">
          <Form.Control
            as="textarea"
            rows={3}
            value={robot.comment || ''}
            onChange={(event) => setRobot({ ...robot, comment: event.target.value })}
          />
        </Col>
      </Form.Group>
      <Form.Group className="form-checkbox-row">
        <Form.Check
          id="robot-comment-is-blocking"
          className="offset-lg-3"
          type="switch"
          label={t('deploymentRobot.commentIsBlocking', 'Comment is a blocking point')}
          checked={(robot.comment && robot.commentIsBlocking) || false}
          disabled={!robot.comment}
          onChange={() => setRobot({ ...robot, commentIsBlocking: !robot.commentIsBlocking })}
        />
      </Form.Group>
    </Form>
  );
};

export default RobotForm;
