import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoaderSpinner from 'react-loader-spinner';
import '../../stylesheets/simpleSelect.css';

const SimpleSelect = ({
  disabled,
  controlId,
  label,
  options,
  selected,
  onChange,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group className="form-item simple-select" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        isInvalid={!!error}
        as="select"
        disabled={disabled}
        id={controlId}
        className="custom-select"
        value={selected}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map(({ id, label }) => <option key={id} value={id}>{label}</option>)}
      </Form.Control>
      {loading && (
        <div className="simple-select-loader">
          <LoaderSpinner
            type="TailSpin"
            color="#007dbc"
            height={15}
            width={15}
          />
        </div>
      )}
      {error && (
        <Form.Control.Feedback type="invalid">
          {error.message || t('shared.error', 'Error')}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default SimpleSelect;
