export function formatDateForBigQuery (date) {
  const year = date.getFullYear();
  let month = "";
  if (date.getMonth() + 1 < 10){
    month = '0' + (date.getMonth() + 1).toString();
  } else {
    month = date.getMonth() + 1;
  }
  let day = "";
  if (date.getDate() < 10){
    day = '0' + (date.getDate()).toString();
  } else {
    day = date.getDate();
  }
  return `${year}-${month}-${day}`;
}

export function formatHoursForBigQuery (date) {
  return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
}

export function formatDateTimeForBigQuery (date) {
  const dateStr = formatDateForBigQuery(date);
  const timeStr = formatHoursForBigQuery(date);
  return `${dateStr}T${timeStr}`;
}

export function formatDate(date) {
  const year = date.getFullYear();
  let month = "";
  if (date.getMonth() + 1 < 10){
    month = '0' + (date.getMonth() + 1).toString();
  } else {
    month = date.getMonth() + 1;
  }
  let day = "";
  if (date.getDate() < 10){
    day = '0' + (date.getDate()).toString();
  } else {
    day = date.getDate();
  }
  return `${day}/${month}/${year}`;
}

export function formatDateTime(date) {
  if (!date) {
    return null;
  }
  const year = date.getFullYear();
  let month = "";
  if (date.getMonth() + 1 < 10){
    month = '0' + (date.getMonth() + 1).toString();
  } else {
    month = date.getMonth() + 1;
  }
  let day = "";
  if (date.getDate() < 10){
    day = '0' + (date.getDate()).toString();
  } else {
    day = date.getDate();
  }
  let hours = "";
  if (date.getHours() < 10) {
    hours = '0' + (date.getHours()).toString();
  } else {
    hours = date.getHours();
  }
  let minutes = "";
  if (date.getMinutes() < 10) {
    minutes = '0' + (date.getMinutes()).toString();
  } else {
    minutes = date.getMinutes();
  }
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const zeroPad2 = (val) => {
  if (val > 9) {
    return `${val}`;
  }
  return `00${val || 0}`.slice(-2);
};

const transformSeconds = (sec) => {
  let seconds = Math.floor(sec);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  seconds = seconds % 60;
  seconds = zeroPad2(seconds);
  minutes = zeroPad2(minutes);
  hours = zeroPad2(hours);
  return { hours, minutes, seconds };
};

export const formatDurationSec = (sec) => {
  const { hours, minutes, seconds } = transformSeconds(sec);
  const duration = `${hours}:${minutes}:${seconds}`;
  return duration;
};

export const formatDurationHours = (sec) => {
  const { hours, minutes } = transformSeconds(sec);
  const duration = `${hours}:${minutes}`;
  return duration;
};

export const startOfDay = (date) => {
  if (date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  }
  return date;
}

export const endOfDay = (date) => {
  if (date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date.toISOString();
  }
  return date;
}
