import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { formatDurationSec } from '../../../functions/formatDate';
import ReactInputMask from 'react-input-mask';

const lpad = (x) => `00${x}`.slice(-2);

const DurationEdit = ({ label, disabled, value, onChange, controlId }) => {
  const [textVal, setTextVal] = useState(formatDurationSec(value));
  useEffect(() => {
    setTextVal(formatDurationSec(value));
  }, [value]);

  const onEdit = (e) => {
    const rawVal = e.target.value;
    let nextTextVal = rawVal.replace(/[^\d:]/g, '');
    let [h,m,s] = nextTextVal.split(':')
    h = Number(h) || 0;
    m = Number(m) || 0;
    s = Number(s) || 0;
    if (h > 99) h = 99;
    if (m > 60) m = 60;
    if (s > 60) s = 60;
    nextTextVal = `${lpad(h)}:${lpad(m)}:${lpad(s)}`;
    const nextVal = s + m * 60 + h * 3600;
    return onChange && onChange(nextVal);
  };

  return (
    <Form.Group className="form-item" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        disabled={disabled}
        mask="99:99:99"
        as={ReactInputMask}
        value={textVal}
        onChange={onEdit}
      />
    </Form.Group>
  );
};

export default DurationEdit;
