import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import handleApiResponse from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';
import blankFlag from '../../stylesheets/blankFlag.gif';
import Confirm from '../widgets/Confirm';
import Filters from './Filters';
import '../../stylesheets/groups.css';

const getGroupsDeployment = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ filters });
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const deleteGroup = (
  user,
  updateTokens,
  userId,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/groups/stores/${userId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const GroupRow = ({ group, deleteCallback }) => {
  const stores = group.stores.map((c) => (
    <div key={c.id} className="group-stores">
      <img src={blankFlag}  className={`flag flag-${c.code.toLowerCase()}`} alt={c.code} />
      {c.id} - {c.name}
    </div>
  ))
  const countries = group.countries.map((c) => (
    <div key={c.id} className="group-stores">
      <img src={blankFlag}  className={`flag flag-${c.code.toLowerCase()}`} alt={c.code} />
      {c.name}
    </div>
  ))
  const { t } = useTranslation();
  return (
    <tr>
      <td>{group.name}</td>
      <td>{countries}</td>
      <td className="group-stores-list">{stores}</td>
      <td>{group.owner_email}</td>
      <td>
        <div className="groups-toolbar">
          <Link to={`/admin/store_groups/${group.id}`}>
            <Button
              className="tiny-button"
              size="sm"
              variant="light"
              title={t('storeGroups.editGroup', 'Edit group')}
            >
              <i className="vtmn-icon_edit"></i>
            </Button>
          </Link>
          <Button
            className="tiny-button"
            size="sm"
            variant="light"
            title={t('storeGroups.deleteGroup', 'Delete group')}
            onClick={deleteCallback}
          >
            <i className="vtmn-icon_delete"></i>
          </Button>
        </div>
      </td>
    </tr>
  );
}

const StoreGroups = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletedGroup, setDeletedGroup] = useState(null);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({});

  const [{
    loading,
    error,
  }, fetchGroupsDeployment] = useRequest(
    getGroupsDeployment, {
      onSuccess: setData,
      onError: () => setData(null),
    }
  );
  useEffect(() => {
    fetchGroupsDeployment(user, updateTokens, filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const updateList = () => {
    const record = data.find((r) => r.id === deletedGroup);
    if (!record) {
      return;
    }
    const tData = data.filter((r) => r.id !== deletedGroup);
    setData(tData);
    setDeletedGroup();
  };

  const [{
    loading: loadingDelete,
    error: errorDelete,
  }, fetchDelete] = useRequest(
    deleteGroup, {
      onSuccess: updateList
    }
  );
  const removeGroup = async () => {
    if (!deletedGroup) {
      return;
    }
    await fetchDelete(user, updateTokens, deletedGroup);
  };

  return (
    <Container className="adminGroups">
      {!loading && !error && (
        <Filters
          filters={filters}
          setFilters={setFilters}
          onClear={() => setFilters({})}
        />
      )}
      {loading && <Loader />}
      {!loadingDelete && errorDelete && <ErrorMessage error={errorDelete} />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <Table hover>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <Trans i18nKey="storeGroups.name">Name</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storeGroups.countries">Countries</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storeGroups.stores">Stores</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storeGroups.ownerEmail">Owner's email</Trans>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data.map((group, i) => (
              <GroupRow
                key={group.id}
                group={group}
                deleteCallback={() => {
                  setShowConfirm(true);
                  setDeletedGroup(group.id);
                }}
              />
            ))}
          </tbody>
        </Table>
      )}
      <Confirm
        title={t('storeGroups.deleteConfirm.warning', 'Warning')}
        body={t('storeGroups.deleteConfirm.text', 'Are you sure you want to delete the group of stores?')}
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        loading={loadingDelete}
        onConfirm={async () => {
          await removeGroup();
          setShowConfirm(false);
        }}
      />
    </Container>
  );
};

export default StoreGroups;
