import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Container,
  Tabs,
  Tab,
} from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';

import Filters from './Filters';
import { getMovementCategories } from './MovementCategoriesFilter';
import { formatDateTime, formatDateForBigQuery, formatDateTimeForBigQuery } from '../../../../functions/formatDate';
import { DataTable, TableFooter } from '../../../widgets/DataTable';
import useRequest from '../../../../functions/useRequest';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import formatMoney from '../../../../functions/formatMoney';
import getFiltersWithInverted from '../../../../functions/getFiltersWithInverted';
import '../../../../stylesheets/warehouseReportingDiscrepancies.css';

const getReport = (user, updateTokens, { filters, pagination, sort }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateTimeForBigQuery(formatedFilters.receptionDateTo);
  }
  if (filters.discrepancyType === 'transport') {
    formatedFilters.withTags = 'true';
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const getReportTotals = (user, updateTokens, { filters }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateForBigQuery(formatedFilters.receptionDateTo);
  }
  const queryString = getQueryString({
    filters: formatedFilters,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/totals?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};


const getFields = ({ t, discrepancyType, withWarehouse }) => [
  ...(withWarehouse ? [
    {
      title: t('reportingDiscrepancies.columns.warehouse', 'Warehouse'),
      field: 'warehouse',
      sortable: true,
    },
  ] : []),
  {
    title: t('reportingDiscrepancies.columns.store', 'Store'),
    field: 'store',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.delivery', 'Delivery'),
    field: 'delivery',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.date', 'Date'),
    field: 'receptionDate',
    sortable: true,
    valueRenderer: (val) => val ? formatDateTime(new Date(val)) : null,
  },
  ...(discrepancyType !== 'unexpected' ? [
    {
      title: t('reportingDiscrepancies.columns.pallets', 'Pallets'),
      field: 'content.pallet',
      sortable: false,
      valueRenderer: (val, column, row) => (row.content || []).map(x => <div key={`${x.pallet}_${x.parcel}`}>{x.pallet}</div>),
    },
    {
      title: t('reportingDiscrepancies.columns.parcels', 'Parcels'),
      field: 'content',
      sortable: false,
      valueRenderer: (val) => (val || []).map(x => <div key={x.parcel}>{x.parcel}</div>),
    },
  ] : []),
  {
    title: (
      discrepancyType === 'wrong_size'
        ? t('reportingDiscrepancies.columns.receivedItem', 'Received item')
        : t('reportingDiscrepancies.columns.item', 'Item')
    ),
    field: 'item',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.modelId', 'Model Id'),
    field: 'modelId',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.rfid', 'RFID'),
    field: 'articleFlag',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.price', 'Selling price'),
    field: 'price',
    align: 'right',
    sortable: true,
    valueRenderer: (val, field, row) => formatMoney(val, row.currency, '-'),
  },
  {
    title: t('reportingDiscrepancies.columns.cessionPrice', 'Cession price'),
    field: 'cessionPrice',
    align: 'right',
    sortable: true,
    valueRenderer: (val, field, row) => formatMoney(val, row.cessionCurrency, '-'),
  },
  {
    title: t('reportingDiscrepancies.columns.itemLib', 'Item description'),
    field: 'itemLib',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.family', 'Family'),
    field: 'familyLabel',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.subDepartment', 'Sub department'),
    field: 'subDepartmentLabel',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.universe', 'Universe'),
    field: 'universeLabel',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.movementCategories', 'Movement categories'),
    field: 'movementCategories',
    sortable: true,
    valueRenderer: (val) => {
      const categories = getMovementCategories(t, discrepancyType);
      return categories[val] || val;
    }
  },
  ...((discrepancyType !== 'unexpected' && discrepancyType !== 'wrong_size') ? [
    {
      title: t('reportingDiscrepancies.columns.shippingList', 'Shipping list'),
      field: 'qtyConfirmed',
      sortable: true,
    },
    {
      title: t('reportingDiscrepancies.columns.readInWarehouse', 'Read in warehouse'),
      field: 'qtyReadBeforeWarehouseGate',
      sortable: true,
    },
    {
      title: t('reportingDiscrepancies.columns.storeGate', 'Store inbound'),
      field: 'qtyReadByStoreGate',
      sortable: true,
    },
    {
      title: t('reportingDiscrepancies.columns.missing', 'Missing'),
      field: 'missing',
      sortable: true,
    },
  ] : [
    {
      title: t('reportingDiscrepancies.columns.unexpected', 'Unexpected'),
      field: 'unexpectedQty',
      sortable: true,
    },
  ]),
  {
    title: t('reportingDiscrepancies.columns.realizedMovement', 'Realized movement'),
    field: 'realizedMovement',
    sortable: true,
  },
  {
    title: t('reportingDiscrepancies.columns.sellingValue', 'Selling value'),
    field: 'value',
    align: 'right',
    sortable: true,
    width: 100,
    valueRenderer: (val, field, row) => {
      if (val == null) {
        return '-';
      }
      if (val instanceof Array) {
        return val.map(totalValRow => {
          const currency = getSymbolFromCurrency(totalValRow.currency) || totalValRow.currency;
          const totalValRowVal = totalValRow.value;
          return <div key={totalValRow.currency}>{formatMoney(totalValRowVal, currency)}</div>;
        })
      }
      return formatMoney(val, row.currency);
    }
  },
  {
    title: t('reportingDiscrepancies.columns.cessionValue', 'Cession value'),
    field: 'cessionValue',
    align: 'right',
    sortable: true,
    width: 100,
    valueRenderer: (val, field, row) => {
      if (val == null) {
        return '-';
      }
      if (val instanceof Array) {
        return val.map(totalValRow => {
          const currency = getSymbolFromCurrency(totalValRow.cessionCurrency) || totalValRow.cessionCurrency;
          const totalValRowVal = totalValRow.cessionValue;
          return <div key={totalValRow.currency}>{formatMoney(totalValRowVal, currency)}</div>;
        })
      }
      return formatMoney(val, row.cessionCurrency);
    }
  },
  ...(discrepancyType === 'transport' ? [{
    title: t('reportingDiscrepancies.columns.epcTags', 'EPC tags'),
    field: 'epcTags',
    sortable: false,
    width: 100,
    valueRenderer: (val) => val && val.map(tag => <div key={tag}>{tag}</div>),
  }] : []),
  ...(discrepancyType === 'wrong_size' ? [{
    title: t('reportingDiscrepancies.columns.wrongSizes', 'Missing items'),
    field: 'wrongSizes',
    sortable: false,
    valueRenderer: (val) =>
      val && val.length
        ? val.map((x, index) => <div key={index}>{`${x.item} - ${x.itemLib}`}</div>)
        : null,
  }] : []),
];

const getFilters = (filters, invertedFilters, discrepancyType) => {
  const withInverted = getFiltersWithInverted(filters, invertedFilters);
  return { ...withInverted, discrepancyType };
};


const DiscrepanciesTab = ({ user, updateTokens, discrepancyType }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const initialFilters = {
    receptionDateFrom: dayjs().startOf('day').subtract(7, 'day').toDate(),
    receptionDateTo: dayjs().endOf('day').toDate(),
  };
  const [invertedFilters, setInvertedFilters] = useState({});
  const withWarehouse = !warehouse;
  const [{
    loading,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetchReport, setFilters, setPagination, setSort] = useTableRequest(
    getReport, {
      initialState: {
        filters: initialFilters,
      }
    }
  );
  useEffect(() => {
    fetchReport(user, updateTokens, {
      filters: getFilters(filters, invertedFilters, discrepancyType),
      pagination,
      sort,
    }, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters, invertedFilters, discrepancyType, pagination, sort]);
  const [{
    loading: totalsLoading ,
    data: totalsData,
    error: totalsError,
  }, fetchTotals] = useRequest(getReportTotals);
  useEffect(() => {
    fetchTotals(user, updateTokens, { filters: getFilters(filters, invertedFilters, discrepancyType) }, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters, invertedFilters, discrepancyType]);

  return (
    <>
      <Filters
        withWarehouse={withWarehouse}
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        totals={totals}
        discrepancyType={discrepancyType}
        sort={sort}
        setFilters={setFilters}
        onClear={() => {
          setFilters(initialFilters);
          setInvertedFilters({});
        }}
        invertedFilters={invertedFilters}
        setInvertedFilters={setInvertedFilters}
      />
      <DataTable
        rowKey={x => `${x.id}-${x.movementId}`}
        data={data}
        fields={getFields({ t, discrepancyType, withWarehouse })}
        loading={loading || totalsLoading}
        error={error || totalsError}
        sort={sort}
        setSort={setSort}
        summaryRow={{ ...totalsData, movementCategories: 'grand_total'}}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </>
  );
};

const ReportingDiscrepancies = ({ user, updateTokens }) => {
  const [tab, setTab] = useState('expedition');
  const { t } = useTranslation();
  return (
    <Container className="warehouseReportingDiscrepancies" fluid>
      <h2>
        <Trans i18nKey="reportingDiscrepancies.title">Store discrepancies</Trans>
      </h2>
      <Tabs
        id="reportingDiscrepancies-tabs"
        activeKey={tab}
        onSelect={(k) => setTab(k)}
      >
        <Tab eventKey="expedition" title={t('reportingDiscrepancies.expedition', 'Expedition')}>
          {tab === 'expedition' && <DiscrepanciesTab user={user} updateTokens={updateTokens} discrepancyType="expedition" />}
        </Tab>
        <Tab eventKey="unexpected" title={t('reportingDiscrepancies.unexpected', 'Unexpected')}>
          {tab === 'unexpected' && <DiscrepanciesTab user={user} updateTokens={updateTokens} discrepancyType="unexpected" />}
        </Tab>
        <Tab eventKey="wrong_size" title={t('reportingDiscrepancies.wrongSize', 'Wrong size')}>
          {tab === 'wrong_size' && <DiscrepanciesTab user={user} updateTokens={updateTokens} discrepancyType="wrong_size" />}
        </Tab>
      </Tabs>
    </Container>
  )
}

export default ReportingDiscrepancies;
