import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import ErrorMessage from '../errorMessage';
import SettingsForm from './SettingsForm';

const getSettings = ({
  user,
  updateTokens,
  id,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/store/settings/${id}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  id,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/store/settings/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const AdminDeploymentSettings = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { siteId } = useParams();
  const history = useHistory();
  const title = t('storesDeployment.editSettings', 'Edit settings');
  const [settings, setSettings] = useState({});

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setSettings
  });
  useEffect(() => {
    fetchSettings({
      id: siteId,
      user,
      updateTokens,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, fetchSettings]);

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(updateSettings, {
    onSuccess: () => history.goBack(),
  });

  const hasError = !loading && !saving && (error || saveError);
  const isReady = !loading && !saving && !error;


  const onSave = () => saveSettings({
    id: siteId,
    settings,
    user,
    updateTokens,
  });

  return (
    <Container className="adminDeploymentSettings">
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
          {(loading || saving) && <Loader />}
          {hasError && <ErrorMessage error={error || saveError} />}
          {isReady && (
            <SettingsForm
              settings={settings}
              setSettings={setSettings}
            />
          )}
        </Card.Body>
        <Card.Footer className="p-0">
          <Button
            variant="secondary"
            className="icon-button"
            onClick={() => history.goBack()}
          >
            <i className="vtmn-icon_cross"></i>
            <Trans i18nKey="deploymentSettings.cancel">Cancel</Trans>
          </Button>
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={!isReady}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="deploymentSettings.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default AdminDeploymentSettings;
