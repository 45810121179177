import React from 'react';
import { Modal } from 'react-bootstrap';
import logoRFID from './RFID-Icon.svg';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateTime } from '../functions/formatDate';
import warehouse_inactive from '../images/pictos_warehouse/warehouse_inactive.png';
import warehouse_checked from '../images/pictos_warehouse/warehouse_checked.png';
import warehouse_notChecked from '../images/pictos_warehouse/warehouse_not-checked.png';
import '../stylesheets/item.css';

const ItemModal = ({
  show,
  onClose,
  itemId,
  itemLib,
  universeLabel,
  departmentLabel,
  subDepartmentLabel,
  familyLabel,
  brandName,
  pixlId,
  price,
  currency,
  modelId,
  modelLib,
  articleFlag,

  qtiesReceivedTotal,
  qtiesReadBeforeGateTotal,
  qtiesPredictedTotal,
  isFoundAtMovements,
  isMissingAtMovements,
  isRevisedAtMovements,
  movement,
  unexpected,
  unexpectedMatchesSize,
  stock = null,
}) => {
  const { t } = useTranslation();
  const picture = "https://contents.mediadecathlon.com/p" + pixlId + "/a.jpg?f=170x170";

  const displayRFID = () => {
    if (articleFlag !== 'P' && articleFlag !== 'L') {
      return (
        <img className="not-rfid" src={logoRFID} alt="RFID-icon"></img>
      )
    }
  }

  const decideWarehouseColor = () => {
    // warehouse - read more then expected
    const isGreen = qtiesPredictedTotal <= qtiesReadBeforeGateTotal;
    if (isGreen) {
      return "green";
    }

    return "red";
  }

  const warehouseColor = decideWarehouseColor();

  const displayWarehouse = (warehouseColor) => {
    switch (warehouseColor) {
      case 'green':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_checked}
            alt="warehouseIcon green"
            title={t(
              'itemComponent.greenTitle',
              'All expected quantities were scanned properly at the warehouse'
            )}
          />
        );
      case 'red':
        return (
          <img
            className="warehouseIcon"
            src={warehouse_notChecked}
            alt="warehouseIcon red"
            title={t(
              'itemComponent.redTitle',
              'Not all quantities were scanned at the warehouse during picking'
            )}
          />
        );
      default:
        return (
          <img
            className="warehouseIcon"
            src={warehouse_inactive}
            alt="warehouseIcon grey"
          />
        );
    }
  }

  const warehouseSectionOfModal = (warehouseColor) => {
    return (
      <div className="warehouseSectionOfModal">
        {displayWarehouse(warehouseColor)}
        <div>
          <Trans i18nKey="itemComponent.modal.expected">Expected</Trans>
          {' '}
          {qtiesPredictedTotal}
        </div>
        {!unexpected && (
          <div>
            <Trans i18nKey="itemComponent.modal.beforeGateRead">In warehouse</Trans>
            {' '}
            {qtiesReadBeforeGateTotal}
          </div>
        )}
        <div>
          <Trans i18nKey="itemComponent.modal.storeRead">In store</Trans>
          {' '}
          {qtiesReceivedTotal}
        </div>
        {!!movement && !!movement.delta && (
          <div>
            <Trans i18nKey="itemComponent.modal.delta">Delta</Trans>
            {' '}
            {movement.delta}
          </div>
        )}
      </div>
    )
  }

  const displayRobotIcon = (isFoundAtMovements, isMissingAtMovements, isRevisedAtMovements) => {
    if (isFoundAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotFoundIcon vtmn-icon_decision"
            title={t('itemComponent.robotFoundTitle', 'Found during inventory')}
          ></i>
        </div>
      );
    }
    if (isMissingAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotMissingIcon vtmn-icon_decision"
            title={t('itemComponent.robotMissingTitle', 'Confirmed missing due to movements')}
          ></i>
        </div>
      );
    }
    if (isRevisedAtMovements) {
      return (
        <div className="robotIcon">
          <i className="robotRevisedIcon vtmn-icon_decision"
            title={t('itemComponent.robotRevisedTitle', 'Has movement correction')}
          ></i>
        </div>
      );
    }
  }

  const displayRobotIconSection = () => {
    const hasIcon = (
      isFoundAtMovements
      || isMissingAtMovements
      || isRevisedAtMovements
    );
    if (!hasIcon || !movement) {
      return;
    }
    const controlUser = movement.lastMovementUser;
    const controlDate = movement.lastMovementDate && formatDateTime(new Date(movement.lastMovementDate));
    const controlUserLabel = t('itemComponent.controlUser', 'Control user');
    const controlDateLabel = t('itemComponent.controlDate', 'Control date');

    return (
      <>
        <br />
        <div className="displayRobotIconSection">
          {displayRobotIcon(
            isFoundAtMovements,
            isMissingAtMovements,
            isRevisedAtMovements,
          )}
          <div>
            {controlUserLabel}
            {' : '}
            {controlUser}
          </div>
          <div>
            {controlDateLabel}
            {' : '}
            {controlDate}
          </div>
        </div>
      </>
    );
  }

  const displayUnexpectedMatchesSizeIconSections = () => {
    if (unexpectedMatchesSize && unexpectedMatchesSize.length) {
      return unexpectedMatchesSize.map(match => (
        <React.Fragment key={match.missingItemId}>
          <br />
          <div className="displayUnexpectedMatchesSizeIconSection">
            {displayUnexpectedMatchesSizeIcon(match)}
            <div>
              {t('itemComponent.unexpectedMatchesSize', 'Same missing quantity for another size of this model')}
              {': '}
              {match.missingItemId}
              {' - '}
              {match.missingItem.item_lib}
            </div>
          </div>
        </React.Fragment>
      ));
    }
  }

  const displayUnexpectedMatchesSizeIcon = (match) => {
    const titleStart = t('itemComponent.unexpectedMatchesSize', 'Same missing quantity for another size of this model');
    const title = `${titleStart} (${match.missingItemId} - ${match.missingItem.item_lib})`;

    return (
      <div className="sizeMatchIconWrapper" key={match.missingItemId}>
        <i className="sizeMatchIcon vtmn-icon_notif_alert"
          title={title}
        ></i>
      </div>
    );
  }

  const displayStock = () => {
    if (stock !== null) {
      return (
        <div className="stock">
          <Trans i18nKey="itemComponent.totalStock">Total stock in CubeInStore</Trans>
          {' : '}
          {stock}
        </div>
      )
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      className="productModal"
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {itemId} - {itemLib}
        </h5>
        <button type="button" className="close" onClick={onClose} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body row">
        <div className="tree">
          {universeLabel} / {departmentLabel} / {subDepartmentLabel} / {familyLabel}
        </div>
        <div className="col-6 itemImage">
          <div className="row brand">
            <div>
              {brandName}
            </div>
            {displayRFID()}
          </div>
          <p className="price">{price} <span className="currency">{currency}</span></p>
          <img src={picture} alt="item" />
        </div>
        <div className="col-6 rightSectionOfModal">
          <div>
            {modelId} - {modelLib}
          </div>
          <br />
          {!!qtiesPredictedTotal && warehouseSectionOfModal(warehouseColor)}
          {displayRobotIconSection()}
          {unexpectedMatchesSize && displayUnexpectedMatchesSizeIconSections()}
          <br />
          {displayStock()}
        </div>
      </div>
    </Modal>
  );
};

export default ItemModal;
