import React, { useEffect, useMemo } from 'react';
import { Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Trans, useTranslation } from 'react-i18next'
import SelectToggleButton from './SelectToggleButton';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';
import handleApiResponse from '../../functions/handleApiResponse';
import renderFlagItem from './renderFlagItem';
import '../../stylesheets/storeSelect.css';

const getAvailableStores = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters
  });

  const url = `${process.env.REACT_APP_base_URL}/api/stores?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getWarehouses = (user, updateTokens, filters) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({
    filters
  });

  const url = `${process.env.REACT_APP_base_URL}/api/warehouses?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SiteSelect = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  filter,
}) => {
  const [{
    loading: storesLoading,
    data: storesData,
    error: storesError,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  const [{
    loading: warehouseLoading,
    data: warehouseData,
    error: warehouseError,
  }, fetchWarehouses] = useRequest(getWarehouses);
  const loading = storesLoading || warehouseLoading;
  const error = storesError || warehouseError;

  const { t } = useTranslation();
  useEffect(() => {
    fetchAvailableStores(user, updateTokens, filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchWarehouses(user, updateTokens, filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = useMemo(() => {
    const stores = (storesData && storesData.map(store => ({
      countryCode: store.countryCode,
      label: `${store.id} - ${store.name}`,
      name: store.name,
      id: store.id,
    }))) || [];
    const warehouses = (warehouseData && warehouseData.map(warehouse => ({
      countryCode: warehouse.countryCode,
      label: `${warehouse.id} - ${warehouse.name}`,
      name: warehouse.name,
      id: warehouse.id,
    }))) || [];
    return [
      ...stores,
      ...warehouses,
    ];
  }, [storesData, warehouseData]);


  const selectedSites = (selected && options.filter(store => String(store.id) === String(selected))) || [];
  const hasValue = selectedSites && selectedSites.length;
  return (
    <Form.Group as={Row} className="storeSelect">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="siteSelect.label">Site</Trans>
        {':'}
      </Form.Label>
      <div className='col-lg-8'>
        <Typeahead
          id="select-store-id"
          renderMenuItemChildren={renderFlagItem}
          placeholder={t('siteSelect.placeholder', 'Select site...')}
          disabled={disabled}
          isLoading={loading}
          isInvalid={!!error}
          options={options}
          selected={selectedSites}
          paginationText={t('defaultMoreResults', 'Display more results...')}
          clearButton
          onChange={([site]) => onChange(site && site.id)}
        >
          {({ isMenuShown, toggleMenu }) => (
            !hasValue && (
              <SelectToggleButton
                isOpen={isMenuShown}
                onClick={e => {
                  toggleMenu();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              />
            )
          )}
        </Typeahead>
        {!loading && error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('defaultError', 'Error')}
          </Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  );
}

export default SiteSelect;
