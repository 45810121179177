import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';

const IntEdit = ({ className, label, disabled, value, max, onChange, controlId }) => {
  const [textVal, setTextVal] = useState(_.isNil(value) ? '' : String(value));
  useEffect(() => {
    setTextVal(_.isNil(value) ? '' : String(value));
  }, [value])

  const onEdit = (e) => {
    let nextTextVal = e.target.value.replace(/[^\d]/g, '');
    const numVal = Number(nextTextVal);
    let nextVal = (numVal || numVal === 0) ? numVal : null;
    if (max && nextVal > max) {
      nextTextVal = String(max);
      nextVal = max;
    }
    setTextVal(nextTextVal);
    return onChange && onChange(nextVal);
  };

  return (
    <Form.Group className={`form-item ${className || ''}`} controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        disabled={disabled}
        type="text"
        inputMode="numeric"
        value={textVal}
        onChange={onEdit}
      />
    </Form.Group>
  );
};

export default IntEdit;
