import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next'
import RobotForm from './RobotForm';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';


const getRobot = ({ user, updateTokens, id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/robots/${id}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse)
  .then(robot => ({
    ...robot,
    arrivalDate: robot.arrivalDate && new Date(robot.arrivalDate),
    trainingSession: robot.trainingSession && new Date(robot.trainingSession),
  }));
};

const createRobot = ({ user, updateTokens, robot }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/robots`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(robot),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateRobot = ({ user, updateTokens, id, robot }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/robots/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(robot),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const prepareSave = (robot) => ({
  ...robot,
  commentIsBlocking: (robot.comment && robot.commentIsBlocking) || false,
});

const AdminDeploymentRobot = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { robotId } = useParams();
  const history = useHistory();
  const isNew = robotId === 'new';
  const title = isNew ? t('deploymentRobot.new', 'New robot') : t('deploymentRobot.edit', 'Edit robot');
  const [robot, setRobot] = useState({});
  const [uploading, setUploading] = useState({});
  const isUploading = Object.values(uploading).filter(x => x).length > 0;

  const [{
    loading,
    error,
  }, fetchRobot] = useRequest(getRobot, {
    onSuccess: setRobot
  });
  useEffect(() => {
    if (!isNew) {
      fetchRobot({
        id: robotId,
        user,
        updateTokens,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, robotId, fetchRobot]);

  const [{
    loading: saving,
    error: saveError,
  }, saveRobot] = useRequest(isNew ? createRobot : updateRobot, {
    onSuccess: () => history.goBack(),
  });
  const hasError = !loading && !saving && (error || saveError);
  const isReady = !loading && !saving && !error;
  const onSave = () => saveRobot({
    id: robotId,
    robot: prepareSave(robot),
    user,
    updateTokens,
  });

  return (
    <Container>
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
          <Card.Subtitle className="text-muted">
            <Trans i18nKey="deploymentRobot.subtitle">all fields are mandatory to validate the deployment</Trans>
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
        {(loading || saving) && <Loader />}
        {isReady && (
          <RobotForm
            user={user}
            updateTokens={updateTokens}
            robot={robot}
            setRobot={setRobot}
            setUploading={addUploading => setUploading(prevUploading => ({ ...prevUploading, ...addUploading }))}
          />
        )}
        {hasError && <ErrorMessage error={error || saveError} />}
        </Card.Body>
        <Card.Footer className="p-0">
          <Button
            variant="secondary"
            className="icon-button"
            onClick={() => history.goBack()}
          >
            <i className="vtmn-icon_cross"></i>
            <Trans i18nKey="deploymentRobot.cancel">Cancel</Trans>
          </Button>
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={isUploading || !isReady || !robot.storeId}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="deploymentRobot.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default AdminDeploymentRobot;
