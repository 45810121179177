import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FiltersPannel from '../widgets/filters/FiltersPannel';
import SelectFilter from '../widgets/filters/SelectFilter';
import renderFlagItem from '../widgets/renderFlagItem';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

export const USER_ROLES = [{
  id: 'leader',
  label: 'Country Efficiency Leader'
}, {
  id: 'manager',
  label: 'Efficiency Manager'
}]
const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const MainFilters = ({
  filters,
  setFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  const [{
    loading,
    data,
    error,
  }, fetchCountries] = useRequest(getCountries);

  useEffect(() => {
    fetchCountries(user, updateTokens);
  }, [fetchCountries, user, updateTokens]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const countryOptions = data.map(x => ({
      id: x.id,
      countryCode: x.code,
      label: x.name,
    }));
    setOptions(countryOptions);
  }, [data]);

  const isReady = !loading && !error;

  return (
    <>
      {isReady && (
        <>
          <SelectFilter
            filterOpts
            multiple
            controlId="filter-countries"
            label={t('usersManagement.country', 'Country')}
            options={options}
            selected={filters.countries}
            onChange={(countries) => setFilters({
              ...filters,
              countries,
            })}
            renderItem={renderFlagItem}
          />
          <SelectFilter
            filterOpts
            multiple
            controlId="filter-leader"
            label={t('usersManagement.userRole', 'User role')}
            options={USER_ROLES}
            selected={filters.roles}
            onChange={(roles) => setFilters({
              ...filters,
              roles,
            })}
          />
        </>
      )}
      {error && <ErrorMessage error={error} />}
    </>
  );
};

const Filters = ({
  filters,
  setFilters,
  onClear,
  user,
  updateTokens,
}) => {

  return (
    <FiltersPannel
      onClear={onClear}
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
      exportButton={
        <Button size="sm" className="icon-button add-user-button" as={Link} to="/admin/users/new">
          <i className="vtmn-icon_edit_plus"></i>
          <Trans i18nKey="usersManagement.addUser">Add user</Trans>
        </Button>
      }
    />
  );
}

export default Filters;
