import React, { useEffect } from 'react';
import SelectFilter from '../../widgets/filters/SelectFilter';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';
import '../../../stylesheets/storeSelect.css';


const getAvailableStores = (user, updateTokens, siteId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/returns/stores?siteId=${siteId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoreSelect = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  siteId,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  
  useEffect(() => {
    fetchAvailableStores(user, updateTokens, siteId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const options = (data && data.map(store => ({
    label: `${store.id} - ${store.name}`,
    name: store.name,
    id: store.id,
  }))) || [];
  return (
    <SelectFilter
      multiple
      filterOpts
      controlId="select-store-id"
      label={label}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      error={error}
    />
  );
}

export default StoreSelect;
