import React from 'react';
import { useTranslation } from 'react-i18next';
import TextEdit from '../widgets/form/TextEdit';
import SelectFilter from '../widgets/filters/SelectFilter';
import renderFlagItem from '../widgets/renderFlagItem';
import '../../stylesheets/users.css';
import { USER_ROLES } from '../usersAdmin/Filters';

const UserForm = ({
  user,
  settings,
  setSettings,
  countries,
}) => {
  const { t } = useTranslation();

  const countryOptions = countries.map(x => ({
    id: x.id,
    countryCode: x.code,
    label: x.name,
  }));

  return (
    <div className="user-settings-form">
      <div className="user-form-row">
        <TextEdit
          label={t('usersManagement.email', 'Email')}
          value={settings.email}
          controlId="email"
          onChange={(email) => setSettings({
            ...settings,
            email,
          })}
        />
      </div>
      <div className="user-form-row">
        <SelectFilter
          filterOpts
          multiple
          controlId="filter-countries"
          label={t('usersManagement.country', 'Country')}
          options={countryOptions}
          selected={settings.countries}
          onChange={(countries) => setSettings({
            ...settings,
            countries,
          })}
          renderItem={renderFlagItem}
        />
      </div>
      {user.rights.indexOf('userManagement') > -1 && (
        <div className="user-form-row">
          <SelectFilter
            controlId="filter-leader"
            label={t('usersManagement.userRole', 'User role')}
            options={USER_ROLES}
            selected={settings.countryLeader ? 'leader' : 'manager'}
            onChange={(role) => setSettings({
              ...settings,
              countryLeader: role === 'leader' ? true : false,
            })}
          />
        </div>
      )}
    </div>
  );
};

export default UserForm;
