import React from 'react';
import { Trans } from 'react-i18next';
import { formatDateTime } from '../../functions/formatDate';

const Info = ({ gateInfo }) => {
  const {
    expectedWithTagTotalQties,
    receivedWithTagTotalQties,
    warehouseWithTagTotalQties,
    foundAtMovementsQties,
    missingAtMovementsQties,
    revisedAtMovementsQties,
    lastReceptionDate,
  } = gateInfo;
  const percentDone = receivedWithTagTotalQties * 100 / expectedWithTagTotalQties;
  // one digit after comma
  const percentDoneRounded = Math.round(percentDone * 10) / 10;
  const successfulyDone = (receivedWithTagTotalQties + foundAtMovementsQties) * 100 / expectedWithTagTotalQties;
  const successfulyDoneRounded = Math.round(successfulyDone * 10) / 10;
  const percentFound = foundAtMovementsQties * 100 / expectedWithTagTotalQties;
  const percentFoundRounded = Math.round(percentFound * 10) / 10;
  const percentMissing = missingAtMovementsQties * 100 / expectedWithTagTotalQties;
  const percentMissingRounded = Math.round(percentMissing * 10) / 10;
  const percentRevised = revisedAtMovementsQties * 100 / expectedWithTagTotalQties;
  const percentRevisedRounded = Math.round(percentRevised * 10) / 10;

  let percentLeftRounded = Math.round(
    (100 - percentDoneRounded - percentFoundRounded - percentMissingRounded - percentRevisedRounded) * 10
  ) / 10;
  if (percentLeftRounded < 0) {
    percentLeftRounded = 0;
  }


  let percentWarehouseRounded = 0;
  if (warehouseWithTagTotalQties > receivedWithTagTotalQties + foundAtMovementsQties) {
    const percentWarehouse = warehouseWithTagTotalQties * 100 / expectedWithTagTotalQties;
    percentWarehouseRounded = Math.round(percentWarehouse * 10) / 10;
    if (percentWarehouseRounded > percentLeftRounded) {
      percentWarehouseRounded = percentLeftRounded;
      percentLeftRounded = 0;
    } else {
      percentLeftRounded -= percentWarehouseRounded;
    }
  }

  let progressClassName = 'progress-bar';
  let progressPercentClass = '';
  if (successfulyDone < 50) {
    progressPercentClass = ' progress-percent-dark';
  }

  return (
    <>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.lastReadDate">Date of the last tag read:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {lastReceptionDate && formatDateTime(new Date(lastReceptionDate))}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.warehouseReads">Number of tags read in warehouse during last delivery:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {warehouseWithTagTotalQties}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.currentReads">Number of tags read in store during last delivery:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {receivedWithTagTotalQties}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.foundAtMovements">Number of tags found at movements:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {foundAtMovementsQties}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.confirmedMissing">Number of tags confirmed missing:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {missingAtMovementsQties}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.expectedReads">Number of tags expected:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {expectedWithTagTotalQties}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringGate.info.percentReads">Percent of products read in the last delivery:</Trans>
          {' '}
        </div>
        <div className="col progress-value">
          <div className="progress">
            <div
              className={progressClassName}
              role="progressbar"
              style={{ width: `${Math.max(percentDoneRounded, 1)}%` }}
              aria-valuenow={percentDoneRounded}
              aria-valuemin="0" aria-valuemax="100"
              title={`${percentDoneRounded} %`}
            >
              <span className={`progress-percent${progressPercentClass}`}>
                {successfulyDoneRounded} %
              </span>
            </div>
            {!!percentFound && (
              <div
                className="progress-bar progress-bar-striped bg-success"
                role="progressbar"
                style={{ width: `${Math.max(percentFoundRounded, 1)}%` }}
                aria-valuenow={percentFoundRounded}
                aria-valuemin="0" aria-valuemax="100"
                title={`${percentFoundRounded} %`}
              />
            )}
            {!!percentWarehouseRounded && (
              <div
                className="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: `${Math.max(percentWarehouseRounded, 1)}%`, opacity: '0.3' }}
                aria-valuenow={percentWarehouseRounded}
                aria-valuemin="0" aria-valuemax="100"
                title={`${percentWarehouseRounded} %`}
              />
            )}
            {!!percentLeftRounded && (
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${Math.max(percentLeftRounded, 1)}%`, backgroundColor: 'transparent' }}
                aria-valuenow={percentLeftRounded}
                aria-valuemin="0" aria-valuemax="100"
                title={`${percentLeftRounded} %`}
              />
            )}
            {!!percentRevised && (
              <div
                className="progress-bar progress-bar-striped bg-warning"
                role="progressbar"
                style={{ width: `${Math.max(percentRevisedRounded, 1)}%`}}
                aria-valuenow={percentRevisedRounded}
                aria-valuemin="0" aria-valuemax="100"
                title={`${percentRevisedRounded} %`}
              />
            )}
            {!!percentMissing && (
              <div
                className="progress-bar bg-danger"
                role="progressbar"
                style={{ width: `${Math.max(percentMissingRounded, 1)}%` }}
                aria-valuenow={percentMissingRounded}
                aria-valuemin="0" aria-valuemax="100"
                title={`${percentMissingRounded} %`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
};

export default Info;
