import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Row,
  Card,
  Button,
} from 'react-bootstrap';
import DiscrepanciesStoreFilter from '../../../widgets/filters/DiscrepanciesStoreFilter';
import RfidFilter from '../../../widgets/filters/RfidFilter';
import InputFilter from '../../../widgets/filters/InputFilter';
import NumrangeFilter from '../../../widgets/filters/NumrangeFilter';
import DaterangeFilter from '../../../widgets/filters/DaterangeFilter';
import SwitchFilter from '../../../widgets/filters/SwitchFilter';
import MovementFilter from '../../../widgets/filters/MovementFilter';
import UniverseFilter from '../../../widgets/filters/UniverseFilter';
import StayedAtWarehouseLabel from '../../StayedAtWarehouseLabel';
import ExportButton from '../../../widgets/ExportButton';
import WarehouseSectorFilter from '../../../widgets/filters/WarehouseSectorFilter';
import SelectColumns from '../../../widgets/filters/SelectColumns';
import ImportLQT from './importLQT';
import '../../../../stylesheets/filters.css';

import { formatDateForBigQuery } from '../../../../functions/formatDate';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse } from '../../../../functions/handleApiResponse';
import { getFiltersParams, getSortParams } from '../../../../functions/getQueryString';

const exportReport = (discrepancyType, warehouse, fields) => (user, updateTokens, oauthToken, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters, discrepancyType };
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateForBigQuery(formatedFilters.receptionDateTo);
  }
  if (formatedFilters.stayedAtWarehouse) {
    formatedFilters.stayedAtWarehouse = 'true';
  }
  if (formatedFilters.discrepancyType !== 'wrong_size') {
    formatedFilters.controllable = true;
  }
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/declare-to-control/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      filters: getFiltersParams({ filters: formatedFilters }),
      sort: getSortParams({ sort }),
      fields,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const hasFilters = (filters) => {
  // check all filters except: receptionDateFrom, receptionDateTo
  const keys = Object.keys(filters).filter(x => x !== 'receptionDateFrom' && x !== 'receptionDateTo');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
}

const Filters = ({
  user,
  updateTokens,
  discrepancyType,
  filters,
  setFilters,
  onClear,
  invertedFilters,
  setInvertedFilters,
  sort,
  totals,
  fields,
  fieldsSelection,
  setFieldsSelection,
  updateData,
}) => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState('partial');
  const isExpedition = discrepancyType === 'expedition';
  const isUnexpected = discrepancyType === 'unexpected';
  const isWrongSize = discrepancyType === 'wrong_size';
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  const filtered = hasFilters(filters);
  const { warehouse } = useParams();
  const memoizedExportReport = useMemo(
    () => exportReport(discrepancyType, warehouse, fieldsSelection),
    [discrepancyType, warehouse, fieldsSelection]
  );

  return (
    <Card className={`filter-card filter-card-${visibility} mb-3 mt-0`}>
      <Card.Header>
        <SelectColumns
          fields={fields}
          fieldsSelection={fieldsSelection}
          setFieldsSelection={setFieldsSelection}
        />
        <Button
          size="sm"
          className={`icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
          onClick={() => setVisibility(isVisible ? 'hidden' : 'partial')}
        >
          <i className="vtmn-icon_filter2"></i>
          {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
          <Trans i18nKey="filters.filters">Filters</Trans>
          {isVisible ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </Button>
        <div className="spacer"/>
        <ImportLQT
          size="sm"
          user={user}
          updateTokens={updateTokens}
          warehouse={warehouse}
          updateData={updateData}
        />
        <ExportButton
          size="sm"
          user={user}
          updateTokens={updateTokens}
          filters={filters}
          invertedFilters={invertedFilters}
          sort={sort}
          totalItems={totals && totals.items}
          exportReport={memoizedExportReport}
        />
      </Card.Header>
      {isVisible && (
        <Card.Body>
          <Row>
            <DaterangeFilter
              controlId="filter-date"
              label={t('declareToControl.filters.date', 'Date')}
              valueFrom={filters.receptionDateFrom}
              valueTo={filters.receptionDateTo}
              onChange={(receptionDateFrom, receptionDateTo) => setFilters({
                ...filters,
                receptionDateFrom,
                receptionDateTo,
              })}
            />

            <DiscrepanciesStoreFilter
              user={user}
              updateTokens={updateTokens}
              selected={filters.store}
              onChange={(store) => setFilters({ ...filters, store })}
              invertable
              inverted={invertedFilters.store}
              onInvert={() => setInvertedFilters({
                ...invertedFilters,
                store: !invertedFilters.store,
              })}
            />

            <InputFilter
              controlId="filter-delivery"
              label={t('declareToControl.filters.delivery', 'Delivery')}
              value={filters.delivery}
              onChange={(delivery) => setFilters({ ...filters, delivery })}
              invertable
              inverted={invertedFilters.delivery}
              onInvert={() => setInvertedFilters({
                ...invertedFilters,
                delivery: !invertedFilters.delivery,
              })}
            />

            {isExpedition && (
              <InputFilter
                controlId="filter-parcel"
                label={t('declareToControl.filters.parcel', 'Parcel')}
                value={filters.parcel}
                onChange={(parcel) => setFilters({ ...filters, parcel })}
                invertable
                inverted={invertedFilters.parcel}
                onInvert={() => setInvertedFilters({
                  ...invertedFilters,
                  parcel: !invertedFilters.parcel,
                })}
              />
            )}

            <InputFilter
              controlId="filter-item"
              label={
                isWrongSize
                  ? t('declareToControl.filters.receivedItem', 'Received item')
                  : t('declareToControl.filters.item', 'Item')
              }
              value={filters.item}
              onChange={(item) => setFilters({ ...filters, item })}
              invertable
              inverted={invertedFilters.item}
              onInvert={() => setInvertedFilters({
                ...invertedFilters,
                item: !invertedFilters.item,
              })}
            />

            {isWrongSize && (
              <InputFilter
                controlId="filter-wrongsize-item"
                label={t('declareToControl.filters.wrongSizeItem', 'Missing item')}
                value={filters.wrongSizeItem}
                onChange={(wrongSizeItem) => setFilters({ ...filters, wrongSizeItem })}
                invertable
                inverted={invertedFilters.wrongSizeItem}
                onInvert={() => setInvertedFilters({
                  ...invertedFilters,
                  wrongSizeItem: !invertedFilters.wrongSizeItem,
                })}
              />
            )}

            {isFull && (
              <>
                <WarehouseSectorFilter
                  user={user}
                  updateTokens={updateTokens}
                  warehouse={warehouse}
                  byDiscrepancies={true}
                  selected={filters.sector}
                  onChange={(sector) => setFilters({ ...filters, sector })}
                />

                <MovementFilter
                  selected={filters.movementCategory}
                  onChange={(movementCategory) => setFilters({ ...filters, movementCategory })}
                />

                <RfidFilter
                  selected={filters.articleFlag}
                  onChange={(articleFlag) => setFilters({ ...filters, articleFlag })}
                  invertable
                  inverted={invertedFilters.articleFlag}
                  onInvert={() => setInvertedFilters({
                    ...invertedFilters,
                    articleFlag: !invertedFilters.articleFlag,
                  })}
                />

                <NumrangeFilter
                  controlId="filter-price"
                  label={t('declareToControl.filters.price', 'Selling price')}
                  valueFrom={filters.priceFrom}
                  valueTo={filters.priceTo}
                  onChange={(priceFrom, priceTo) => setFilters({ ...filters, priceFrom, priceTo })}
                />
                <NumrangeFilter
                  controlId="filter-cession-price"
                  label={t('declareToControl.filters.cessionPrice', 'Cession price')}
                  valueFrom={filters.cessionPriceFrom}
                  valueTo={filters.cessionPriceTo}
                  onChange={(cessionPriceFrom, cessionPriceTo) => setFilters({
                    ...filters,
                    cessionPriceFrom,
                    cessionPriceTo,
                  })}
                />

                <InputFilter
                  controlId="filter-model-id"
                  label={t('declareToControl.filters.modelId', 'Model Id')}
                  value={filters.modelId}
                  onChange={(modelId) => setFilters({ ...filters, modelId })}
                  invertable
                  inverted={invertedFilters.modelId}
                  onInvert={() => setInvertedFilters({
                    ...invertedFilters,
                    modelId: !invertedFilters.modelId,
                  })}
                />
                <InputFilter
                  controlId="filter-item-lib"
                  label={t('declareToControl.filters.itemLib', 'Item description')}
                  value={filters.itemLib}
                  onChange={(itemLib) => setFilters({ ...filters, itemLib })}
                  invertable
                  inverted={invertedFilters.itemLib}
                  onInvert={() => setInvertedFilters({
                    ...invertedFilters,
                    itemLib: !invertedFilters.itemLib,
                  })}
                />

                <InputFilter
                  controlId="filter-family"
                  label={t('declareToControl.filters.family', 'Family')}
                  value={filters.familyLabel}
                  onChange={(familyLabel) => setFilters({ ...filters, familyLabel })}
                  invertable
                  inverted={invertedFilters.familyLabel}
                  onInvert={() => setInvertedFilters({
                    ...invertedFilters,
                    familyLabel: !invertedFilters.familyLabel,
                  })}
                />
                <InputFilter
                  controlId="filter-sub-department"
                  label={t('declareToControl.filters.subDepartment', 'Sub department')}
                  value={filters.subDepartmentLabel}
                  onChange={(subDepartmentLabel) => setFilters({ ...filters, subDepartmentLabel })}
                  invertable
                  inverted={invertedFilters.subDepartmentLabel}
                  onInvert={() => setInvertedFilters({
                    ...invertedFilters,
                    subDepartmentLabel: !invertedFilters.subDepartmentLabel,
                  })}
                />
                <UniverseFilter
                  controlId="filter-universe"
                  user={user}
                  updateTokens={updateTokens}
                  selected={filters.universeLabel}
                  onChange={(universeLabel) => setFilters({ ...filters, universeLabel })}
                />

                {isExpedition && (
                  <NumrangeFilter
                    controlId="filter-shipping-list"
                    label={t('declareToControl.filters.shippingList', 'Shipping list')}
                    valueFrom={filters.qtyConfirmedFrom}
                    valueTo={filters.qtyConfirmedTo}
                    onChange={(qtyConfirmedFrom, qtyConfirmedTo) => setFilters({ ...filters, qtyConfirmedFrom, qtyConfirmedTo })}
                  />
                )}
                {isExpedition && (
                  <NumrangeFilter
                    controlId="filter-before-gate"
                    label={t('declareToControl.filters.readInWarehouse', 'Read in warehouse')}
                    valueFrom={filters.qtyReadBeforeWarehouseGateFrom}
                    valueTo={filters.qtyReadBeforeWarehouseGateTo}
                    onChange={(qtyReadBeforeWarehouseGateFrom, qtyReadBeforeWarehouseGateTo) => setFilters({
                      ...filters,
                      qtyReadBeforeWarehouseGateFrom,
                      qtyReadBeforeWarehouseGateTo,
                    })}
                  />
                )}
                {isExpedition && (
                  <NumrangeFilter
                    controlId="filter-store-gate"
                    label={t('declareToControl.filters.readInStore', 'Read in store')}
                    valueFrom={filters.qtyReadByStoreGateFrom}
                    valueTo={filters.qtyReadByStoreGateTo}
                    onChange={(qtyReadByStoreGateFrom, qtyReadByStoreGateTo) => setFilters({
                      ...filters,
                      qtyReadByStoreGateFrom,
                      qtyReadByStoreGateTo,
                    })}
                  />
                )}
                {isExpedition && (
                  <NumrangeFilter
                    controlId="filter-missing"
                    label={t('declareToControl.filters.missing', 'Missing')}
                    valueFrom={filters.missingFrom}
                    valueTo={filters.missingTo}
                    onChange={(missingFrom, missingTo) => setFilters({
                      ...filters,
                      missingFrom,
                      missingTo,
                    })}
                  />
                )}
                {(isUnexpected || isWrongSize) && (
                  <NumrangeFilter
                    controlId="filter-unexpected"
                    label={t('declareToControl.filters.unexpected', 'Unexpected')}
                    valueFrom={filters.unexpectedQtyFrom}
                    valueTo={filters.unexpectedQtyTo}
                    onChange={(unexpectedQtyFrom, unexpectedQtyTo) => setFilters({
                      ...filters,
                      unexpectedQtyFrom,
                      unexpectedQtyTo,
                    })}
                  />
                )}
                <NumrangeFilter
                  controlId="filter-realized-movement"
                  label={t('declareToControl.filters.realizedMovement', 'Realized movement')}
                  valueFrom={filters.realizedMovementFrom}
                  valueTo={filters.realizedMovementTo}
                  onChange={(realizedMovementFrom, realizedMovementTo) => setFilters({
                    ...filters,
                    realizedMovementFrom,
                    realizedMovementTo,
                  })}
                />

                <NumrangeFilter
                  controlId="filter-value"
                  label={t('declareToControl.filters.sellingValue', 'Selling value')}
                  valueFrom={filters.valueFrom}
                  valueTo={filters.valueTo}
                  onChange={(valueFrom, valueTo) => setFilters({
                    ...filters,
                    valueFrom,
                    valueTo,
                  })}
                />
                <NumrangeFilter
                  controlId="filter-cession-value"
                  label={t('declareToControl.filters.cessionValue', 'Cession value')}
                  valueFrom={filters.cessionValueFrom}
                  valueTo={filters.cessionValueTo}
                  onChange={(cessionValueFrom, cessionValueTo) => setFilters({
                    ...filters,
                    cessionValueFrom,
                    cessionValueTo,
                  })}
                />

                <SwitchFilter
                  controlId="filter-show-declared"
                  label={t('declareToControl.filters.showDeclared', 'Show in control')}
                  value={filters.withDeclaredToControl}
                  onChange={() => setFilters({ ...filters, withDeclaredToControl: !filters.withDeclaredToControl })}
                />

                <SwitchFilter
                  controlId="filter-show-completed"
                  label={t('declareToControl.filters.showCompleted', 'Show control completed')}
                  value={filters.withCompletedControl}
                  onChange={() => setFilters({ ...filters, withCompletedControl: !filters.withCompletedControl })}
                />

                {isExpedition && (
                  <SwitchFilter
                    controlId="filter-stayed-at-warehouse"
                    label={<StayedAtWarehouseLabel/>}
                    value={filters.stayedAtWarehouse}
                    onChange={() => setFilters({
                      ...filters,
                      stayedAtWarehouse: !filters.stayedAtWarehouse,
                    })}
                  />
                )}
              </>
            )}

            <div className="filter-button-container">
              <div className="spacer" />

              <Button
                size="sm"
                className="icon-button filter-button filter-button-toggle"
                onClick={() => setVisibility(isFull ? 'partial' : 'full')}
              >
                {isFull ? (
                  <Trans i18nKey="filters.less">Less</Trans>
                ) : (
                  <Trans i18nKey="filters.more">More</Trans>
                )}
                {isFull ? (
                  <i className="vtmn-icon_chevron_up_v2 right"></i>
                ) : (
                  <i className="vtmn-icon_chevron_down_v2 right"></i>
                )}
              </Button>

              <Button
                size="sm"
                className="icon-button filter-button filter-button-clear"
                onClick={onClear}
              >
                <i className="vtmn-icon_cross"></i>
                <Trans i18nKey="filters.clear">Clear</Trans>
              </Button>
            </div>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default Filters;
