/**
 * Hook for requesting api
 * based on https://github.com/react-rekindle/use-request
 *
 * @example
 * // Base example
 * const getData = () => axios.get('/api/data');
 * const [{
 *   loading,
 *   error,
 *   data
 * }, fetchData] = useRequest(getData);
 * fetchData();
 *
 * @example
 * // With initial state and callback
 * const postData = (params) => axios.post('/api/data', params);
 * const [{ loading }, doPostData] = useRequest(postData, {
 *   onSuccess: (data) => console.log('onSuccess', data),
 *   onError: (error) => console.log('onError', error),
 *   initialState: { loading: true },
 * });
 * doPostData(params);
 */

import {
  useReducer,
  useCallback,
  useRef,
  useEffect,
} from 'react';

const REQUEST_START = 'REQUEST_START';
const REQUEST_DONE = 'REQUEST_DONE';
const REQUEST_ERROR = 'REQUEST_ERROR';

const defaultInitialState = {
  loading: false,
  data: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case REQUEST_START:
      return { ...defaultInitialState, loading: true };
    case REQUEST_DONE:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case REQUEST_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      throw new Error('error');
  }
};

const request = async (
  instance,
  dispatch,
  onSuccess,
  onError,
  isMountedRef,
) => {
  try {
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_START });
    const result = await instance();
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_DONE, payload: result });
    if (onSuccess) {
      onSuccess(result);
    }
    return result;
  } catch (error) {
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_ERROR, error });
    if (onError) {
      onError(error);
    }
    return;
  }
};

export const useRequest = (
  fetcher,
  {
    onSuccess,
    onError,
    initialState,
  } = {},
) => {
  const initialIState = {
    ...defaultInitialState,
    ...initialState,
  };
  const isMountedRef = useRef(true);
  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);
  const [state, dispatch] = useReducer(reducer, initialIState);
  const requestCallback = (...args) => request(
    () => fetcher(...args),
    dispatch,
    onSuccess,
    onError,
    isMountedRef,
  );
  const memoizedRequestCallback = useCallback(requestCallback, [onSuccess, onError, fetcher]);
  return [state, memoizedRequestCallback];
};

export default useRequest;
