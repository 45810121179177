import React from 'react';
import { Trans } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';

const isNil = (val) => val === null || val === undefined;

const SettingsForm = ({ settings, setSettings }) => (
  <Form>
    <Form.Group as={Row} controlId="gate-gps-lon">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="deploymentSettings.timeFrom">
          "Time from" for match tags
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          step={0.05}
          min={-180}
          max={180}
          type="number"
          value={isNil(settings.storeTagTimeframeFromHours) ? '' : String(settings.storeTagTimeframeFromHours)}
          onChange={(event) => setSettings({ ...settings, storeTagTimeframeFromHours: event.target.value })}
        />
        <small>
          <Trans i18nKey="deploymentSettings.hours">Hours</Trans>
        </small>
      </Col>
    </Form.Group>
    <Form.Group as={Row} controlId="gate-gps-lon">
      <Form.Label column lg="4" className="text-lg-right">
        <Trans i18nKey="deploymentSettings.timeTo">
          "Time to" for match tags
        </Trans>
        {': '}
      </Form.Label>
      <Col lg="8">
        <Form.Control
          step={0.05}
          min={-180}
          max={180}
          type="number"
          value={isNil(settings.storeTagTimeframeToHours) ? '' : String(settings.storeTagTimeframeToHours)}
          onChange={(event) => setSettings({ ...settings, storeTagTimeframeToHours: event.target.value })}
        />
        <small>
          <Trans i18nKey="deploymentSettings.hours">Hours</Trans>
        </small>
      </Col>
    </Form.Group>
  </Form>
);

export default SettingsForm;
