import React, { useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import settingsIcon from '../../../images/icons/settings.svg';
import '../../../stylesheets/selectColumns.css';

const SelectColumns = ({
  fields,
  fieldsSelection,
  setFieldsSelection,
}) => {
  const [show, setShow] = useState(false)
  return (
    <DropdownButton
      className="select-columns"
      show={show}
      size="sm"
      title={
        <img src={settingsIcon} alt="robot-status" />
      }
      onToggle={(isOpen, event, { source }) => {
        if (isOpen) {
          setShow(true);
        } else {
          if (source !== 'select') {
            setShow(false);
          }
        }
      }}
    >
      {fields.map(({ title, field }) => (
        <Dropdown.Item
          className="d-flex"
          as="div"
          role="button"
          onClick={(e) => {
            setFieldsSelection({
              ...fieldsSelection,
              [field]: !fieldsSelection[field],
            });
          }}
        >
          <Form.Check
            type="checkbox"
            id={`select-columns-${field}`}
            className="mr-2"
            checked={!!fieldsSelection[field]}
            readOnly
          />
          {title}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  )
};

export default SelectColumns;
