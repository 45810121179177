import React from 'react';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import { TablePagination, PaginationInfo, PageSizeSelect } from './Pagination';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import '../../stylesheets/dataTable.css';

const TableCell = ({ row, field, rowIndex }) => {
  const val = row[field.field];
  const renderredVal = field.valueRenderer ? field.valueRenderer(val, field, row, rowIndex) : val;
  return (
    <td style={field.align ? { textAlign: field.align } : null }>
      {renderredVal}
    </td>
  );
};

const TableRow = ({ row, fields, index, isSummary, onClick, checkable, checked, toggleCheck }) => {
  return (
    <tr
      className={`${isSummary ? 'summary-row' : ''} ${onClick ? 'clickable-row' : ''}`}
      onClick={() => onClick && onClick(row)}
    >
      {checkable && (
        <td align="center">
          {!isSummary && (
            <input
              type="checkbox"
              checked={checked}
              onClick={toggleCheck}
            />
          )}
        </td>
      )}
      {fields.map(field => <TableCell key={field.field} field={field} row={row} rowIndex={index} />)}
    </tr>
  );
};

const TableHeaderCell = ({ field, sort, setSort }) => {
  let sortIcon = 'sort';
  if (sort && sort.field === field.field) {
    if (sort.direction === 'ASC') {
      sortIcon = 'arrow1_down';
    } else {
      sortIcon = 'arrow1_up';
    }
  }
  return (
    <th
      className={field.sortable ? 'column-sortable' : null}
      style={field.width ? { width: `${field.width}px` } : null}
      onClick={() => {
        if (field.sortable) {
          const nextDirection = (
            sort && sort.field === field.field && sort.direction !== 'DESC'
          ) ? 'DESC' : 'ASC';
          setSort({
            field: field.field,
            direction: nextDirection,
          });
        }
      }}
    >
      <div className="datatable-title-wraper">{field.title}</div>
      {field.sortable && (
        <i className={`sort-icon vtmn-icon_${sortIcon}`}></i>
      )}
    </th>
  );
}


export const DataTable = ({
  data,
  fields,
  error,
  loading,
  sort,
  setSort,
  rowKey,
  summaryRow,
  onRowClick,
  checkable = false,
  checkedRows = {},
  setCheckedRows,
}) => {
  const isAllChecked = data.length && Object.keys(checkedRows).length === data.length;

  const toggleCheck = (id) => {
    const newCheckedRows = _.omit(checkedRows, id);
    if (!checkedRows[id]) {
      newCheckedRows[id] = true;
    }
    setCheckedRows({
      ...newCheckedRows,
    });
  };

  const toggleCheckAll = () => {
    const allKeys = {};
    if (!isAllChecked) {
      for (const row of data) {
        allKeys[row.id] = true;
      }
    }
    setCheckedRows(allKeys);
  };

  if (loading) {
    return <Loader/>;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    return;
  }
  return (
    <Table
      className="dataTable"
      striped
      bordered
      hover
      size="sm"
    >
      <thead>
        <tr>
          {checkable && (
            <td align="center">
              <input
                type="checkbox"
                onClick={toggleCheckAll}
                checked={isAllChecked}
              />
            </td>
          )}
          {fields.map(field => <TableHeaderCell key={field.field} field={field} sort={sort} setSort={setSort} />)}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          let key = row.id;
          if (rowKey) {
            if (typeof rowKey === 'function') {
              key = rowKey(row);
            } else {
              key = row[rowKey];
            }
          }
          return (
            <TableRow
              key={key}
              row={row}
              fields={fields}
              index={index}
              onClick={onRowClick}
              checkable={checkable}
              checked={!!checkedRows[row.id]}
              toggleCheck={() => toggleCheck(row.id)}
            />
          );
        })}
        {summaryRow && (
          <TableRow
            key="summary"
            row={summaryRow}
            fields={fields}
            index={-1}
            isSummary
            checkable={checkable}
          />
        )}
      </tbody>
    </Table>
  )
};

export const TableFooter = ({ pagination, totals, setPagination, hideInfo }) => (
  <div className="dataTableFooter">
    {hideInfo ? <div className="footer-info"/> : (
      <PaginationInfo
        pagination={pagination}
        totals={totals}
      />
    )}
    <PageSizeSelect
      pagination={pagination}
      setPagination={setPagination}
    />
    <TablePagination
      pagination={pagination}
      totals={totals}
      setPagination={setPagination}
    />
  </div>
);
