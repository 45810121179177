import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import useDebounce from '../../../functions/useDebounce';


const InputFilter = ({
  controlId,
  label,
  value,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);
  const { t } = useTranslation();

  useEffect(
    () => {
      if (value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );

  return (
    <Form.Group controlId={controlId} className="filter-item">
      <Form.Label>
        <span className="filter-label-text">
          {label}
          {inverted && t('filters.excludes', ' excludes')}
          {':'}
        </span>
        {invertable && (
          <Form.Check
            id={`${controlId}-invert-switch`}
            type="switch"
            label={t('filters.invert', 'exclude')}
            checked={inverted || false}
            onChange={onInvert}
          />
        )}
      </Form.Label>

      <Form.Control
        size="sm"
        type="text"
        value={stateValue || ''}
        onChange={(event) => { setStateValue(event.target.value); }}
      />
    </Form.Group>
  );
};

export default InputFilter;
