import React, { useEffect, useMemo } from 'react';
import useTableRequest from '../../../functions/useTableRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../functions/handleApiResponse';
import getQueryString from '../../../functions/getQueryString';
import { DataTable, TableFooter } from '../../widgets/DataTable';
import Loader from '../../loader';
import ExportButton from '../../widgets/ExportButton';
import { getSortParams } from '../../../functions/getQueryString';

const getData = (user, updateTokens, { filters, pagination, sort }, url) => {
  const { token, refreshToken, tokenExpireDate } = user;

  const queryString = getQueryString({
    filters,
    pagination,
    sort,
  });

  return fetchWithJWT(`${url}?${queryString}`, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
    .then(handlePaginatedApiResponse);
};

const exportReport = (shipmentId, type) => (user, updateTokens, oauthToken, { filters, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/returns/shipment/${shipmentId}/export`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      oauthToken,
      sort: getSortParams({ sort }),
      type
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const CommonReturnTab = ({
  user,
  updateTokens,
  shipmentId,
  type,
  onItemClick,
  columns,
  sourceUrl,
}) => {
  const [{
    loading,
    data,
    error,
    filters,
    sort,
    pagination,
    totals,
  // eslint-disable-next-line no-unused-vars
  }, fetchData, setFilters, setPagination, setSort] = useTableRequest(
    getData
  );

  useEffect(() => {
    setPagination({ ...pagination, page: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentId]);

  useEffect(() => {
    if (loading) {
      return;
    }
    fetchData(user, updateTokens, {
      filters,
      pagination,
      sort,
    }, sourceUrl);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, sort]);

  const memoizedExportReport = useMemo(() => exportReport(shipmentId, type), [shipmentId, type]);

  return (
    <>
      {loading && <Loader />}
      {!loading && !error && data && (
      <>
        <div className="card-toolbar">
          <ExportButton
            size="sm"
            user={user}
            updateTokens={updateTokens}
            sort={sort}
            exportReport={memoizedExportReport}
          />
        </div>
        <div className="card-table">
          <DataTable
            rowKey={x => `${x.itemCode}-${x.shipmentId}`}
            data={data}
            fields={columns}
            loading={loading}
            sort={sort}
            setSort={setSort}
            error={error}
            onRowClick={onItemClick}
          />
        </div>
        <TableFooter
          pagination={pagination}
          totals={totals}
          setPagination={setPagination}
        />
      </>
      )}
    </>
  );
};

export default CommonReturnTab;
