import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { Row, Button, Spinner } from 'react-bootstrap';
import PalletDetails from './palletDetails';
import { DisplayItem, calculateItemProps } from './displayItem';
import getPrice from '../../functions/getPrice';
import ReadingRateModal from './ReadingRateModal';
import ProgressBar from './ProgressBar';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import NumberFilter from '../widgets/filters/NumberFilter';
import { TableFooter } from '../widgets/DataTable';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import getQueryString from '../../functions/getQueryString';
import refreshIconSrc from '../../images/icons/refresh-line.svg';
import robotIconSuccessSrc from '../../images/icons/robot-icon-success.svg';
import robotIconWarningSrc from '../../images/icons/robot-icon-warning.svg';
import UnexpectedTab from './unexpectedTab';
import '../../stylesheets/liveReception.css';

const REFRESH_INTERVAL = 10000;
const VALID_NATURE = 2;
const NO_SPORT_LABEL = 'NO SPORT';
export const CHECK_STOCK_OUT_RISK_INTERVAL = 36; //hours

const splitEvenly = (plan, fact, byParcelPlan) => {
  let perParcelFact = byParcelPlan.map((parcelPlan) => Math.floor(fact * parcelPlan / plan));
  let factLeft = fact - _.sum(perParcelFact);
  if (factLeft) {
    perParcelFact.forEach((parcelFact, index) => {
      if (factLeft) {
        factLeft -= 1;
        perParcelFact[index] += 1;
      }
    });
  }
  return perParcelFact;
};

const defaultState = {
  currency: '$',
  receptions: [],
  myReception: { id: undefined },
  reveptionOpenTime: null,
  lastReceivedTagId: null,
  forceDisplayReceptions: null,
  shippingLists: [],
  receivedEPC: new Set(),
  itemDetails: [],
  unexpectedItems: [],
  unexpectedItemsStored: [],
  unexpectedItemsSizeMatches: [],
  movements: {},
  movementsUpdateError: null,
  stockExtractorQuotaLimitReached: false,
  movementAPICallsLimitReached: false,
  unexpectedSportItems: {},
  sportIndicators: [],
  palletIndicators: [],
  totalIndicator: {},
  selectedIndicator: null,
  palletsToDisplayContent: [],
  sportsToDisplayContent: [],
  sportsToDisplayEvenFullyReceivedItems: [],
  loadingReceptions: true,
  loadingPallets: false,
  loadingItemDetails: false,
  loadingNewTags: false,
  alert: false,
  alertMessage: null,
  warehouseReadingActivated: false,
  activeList: "by sport",
  shipmentsPage: 0,
  shipmentsPageSize: 10,
  shipmentsTotal: {
    pages: 0,
    items: 0,
  },
};

class LiveStoreReception extends Component {
  constructor(props){
    super(props);

    const savedFilters = localStorage.liveStoreReceptionFilters;
    const filters = savedFilters ? JSON.parse(savedFilters) : ({
      bothGatesMissing: false,
      storeGateZero: false,
      hideIncompleteBoxes: false,
      hideControlled: false,
      hideCheaper: false,
    });

    const filterValues = {
      hideCheaper: 0,
    };

    this.state = {
      ...defaultState,
      filters,
      filterValues,
      filtersVisible: false,
      authExpired: false,
      defaultMinimumPrice: 0,
    };
  }

  displayAlerts = () => {
    const { t } = this.props;
    return(
      <>
        {this.state.authExpired ? <div className="alert alert-danger" role="alert">
          {t('liveStoreReception.errors.authExpired', 'Your authorization has expired, please relogin')}
          <Button
            variant="danger"
            size="sm"
            className="m-0 ml-2"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('liveStoreReception.errors.login', 'Login')}
          </Button>
        </div> : null}
        {this.state.alert ? <div className="alert alert-danger" role="alert">
          {t('liveStoreReception.errors.error', 'Something went wrong')}
          {' : '}
          {this.state.alertMessage}
        </div> : null}
        {this.state.movementsUpdateError ? <div className="alert alert-warning" role="alert">
          {t('liveStoreReception.errors.movementsUpdateError', 'Error during movements calculation')}
          {' : '}
          {`${this.state.movementsUpdateError}`}
        </div> : null}
        {this.state.stockExtractorQuotaLimitReached ? <div className="alert alert-warning" role="alert">
          {t('liveStoreReception.errors.stockExtractorQuotaLimitReached', 'We have reached limit of API calls to Stock Extractor')}
        </div> : null}
        {this.state.movementAPICallsLimitReached ? <div className="alert alert-warning" role="alert">
          {t('liveStoreReception.errors.movementAPICallsLimitReached', 'Partialy calculated movements: some movements might be omitted')}
        </div> : null}
      </>
    );
  }

  updateDeliveryTags = async () => {
    const hasSelectedDelivery = !!this.state.myReception.id;
    if (!hasSelectedDelivery) {
      return;
    }

    const newTags = await this.getDeliveryNewTags();
    if (newTags && newTags.length) {
      const lastTag = newTags[newTags.length - 1];
      this.setState({ lastReceivedTagId: lastTag.id });
      this.onNewTags(newTags);
    }
  }

  getDeliveryNewTags = () => {
    const delivery = this.state.myReception.id;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const storeId = this.props.match.params.store;
    const openTime = this.state.reveptionOpenTime.toISOString();
    const fromId = this.state.lastReceivedTagId;
    let url = `${process.env.REACT_APP_base_URL}/api/${storeId}/deliveries/v2/${delivery}/newTags?openTime=${openTime}`;
    if (fromId) {
      url += `&fromId=${fromId}`
    }

    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .catch(error => {
      if (error.status === 401) {
        this.onAuthExpired();
      } else {
        console.warn('Error at getStoreEvents', error);
      }
    });
  };

  onAuthExpired = () => {
    this.setState({ authExpired: true });
  }

  onNewTags = async (tags) => {
    const hasSelectedDelivery = !!this.state.myReception.id;
    const isDeliveryLoading = this.state.loadingPallets || this.state.loadingItemDetails;
    if (!hasSelectedDelivery || isDeliveryLoading) {
      return;
    }

    this.setState({ loadingNewTags: true });

    const newTags = tags.filter(tag => !this.state.receivedEPC.has(tag.epc));
    const updatedShippingLists = [...this.state.shippingLists];
    const newReceivedEPC = new Set(this.state.receivedEPC);
    const newUnexpectedItems = [...this.state.unexpectedItems];

    const newItemsCodes = [];
    const newUnexpectedItemsCodes = [];

    newTags.forEach(tag => {
      newReceivedEPC.add(tag.epc);
      const chosenShippingListIndex = updatedShippingLists.findIndex(
        pickingLine => pickingLine.item === tag.item_code && pickingLine.qty_read_by_store_gate < pickingLine.qty_confirmed
      );
      const iFoundAShippingListToAllocateThisTag = chosenShippingListIndex !== -1;

      if (iFoundAShippingListToAllocateThisTag) {
        updatedShippingLists[chosenShippingListIndex].qty_read_by_store_gate++;
      } else {
        if (newUnexpectedItems.filter(item => item.item === tag.item_code).length === 0) {
          newUnexpectedItems.push({
            item: tag.item_code,
            qty_read_unexpected: 1,
            qty_read_by_warehouse_gate: 0, // loaded later
          });
          newUnexpectedItemsCodes.push(tag.item_code);
        } else {
          newUnexpectedItems.filter(item => item.item === tag.item_code)[0].qty_read_unexpected++;
        }
      }
      if (
        this.state.itemDetails.filter(details => details.item_id === tag.item_code).length === 0
        && newItemsCodes.indexOf(tag.item_code) === -1
      ) {
        newItemsCodes.push(tag.item_code);
      }
    });

    const delivery = this.state.myReception.id;

    if (newUnexpectedItemsCodes && newUnexpectedItemsCodes.length) {
      // loading qty_read_by_warehouse_gate for new unexpected items
      const warehouseUnexpected = await this.getWarehouseUnexpected(delivery, newUnexpectedItemsCodes);
      (warehouseUnexpected || []).forEach(undexpected => {
        const match = newUnexpectedItems.find(item => item.item === undexpected.item);
        if (match) {
          match.qty_read_by_warehouse_gate = undexpected.qty_read_by_warehouse_gate;
        }
      });
    }

    let updatedItemDetails = this.state.itemDetails;
    if (newItemsCodes && newItemsCodes.length) {
      // loading details for new items
      const newItemDetails = await this.getItemDetails(newItemsCodes)
      updatedItemDetails = [
        ...this.state.itemDetails,
        ...(newItemDetails.itemDetails || []),
      ];
    }

    const { movements } = this.state;
    const {
      sportIndicators,
      palletIndicators,
      totalIndicator,
    } = this.calculateDelivery(
      updatedItemDetails,
      updatedShippingLists,
      movements,
    );
    const unexpectedItemsSizeMatches = this.matchUnexpectedToWrongSizes(
      updatedShippingLists,
      newUnexpectedItems,
      updatedItemDetails,
    );
    const { totalUnexpected, unexpectedSportItems } = this.getUnexpectedSportItems(
      newUnexpectedItems,
      updatedItemDetails,
      movements,
      updatedShippingLists,
      unexpectedItemsSizeMatches,
    );
    this.updateReceptionTotalQties(delivery, totalIndicator);
    this.setState({
      receivedEPC: newReceivedEPC,
      unexpectedItems: newUnexpectedItems,
      unexpectedItemsStored: newUnexpectedItems,
      unexpectedItemsSizeMatches,
      shippingLists: updatedShippingLists,
      itemDetails: updatedItemDetails,
      sportIndicators,
      palletIndicators,
      totalIndicator: {
        ...totalIndicator,
        unexpected: totalUnexpected
      },
      loadingNewTags: false,
      unexpectedSportItems,
    });
  }

  getTagsColorCls = (qty_confirmed, qty_read) => {
    if (!qty_read || !qty_confirmed) {
      return '';
    }
    if (qty_read > qty_confirmed * 0.9) {
      return 'text-success';
    }
    if (qty_read > qty_confirmed * 0.5) {
      return 'text-warning';
    }
    return 'text-danger';
  };

  componentDidMount() {
    this.updateDeliveryTagsIntervalRef = setInterval(this.updateDeliveryTags, REFRESH_INTERVAL);
    this.getDeliveries();
    this.getDefaultValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevStoreId = prevProps.match.params.store;
    const storeId = this.props.match.params.store;
    const prevPage = prevState.shipmentsPage;
    const prevPageSize = prevState.shipmentsPageSize;
    const { shipmentsPage, shipmentsPageSize } = this.state;

    if (storeId !== prevStoreId) {
      this.setState({
        ...defaultState,
        receptions: [],
      }, this.getDeliveries);
      this.getDefaultValues();
    }
    if (prevPage !== shipmentsPage || prevPageSize !== shipmentsPageSize) {
      this.getDeliveries();
    }
  }

  componentWillUnmount(){
    if (this.updateDeliveryTagsIntervalRef) {
      clearInterval(this.updateDeliveryTagsIntervalRef);
    }
  }

  updateReceptionTotalQties = (deliveryId, totalIndicator) => {
    const { receptions } = this.state;
    const receptionIndex = receptions.findIndex(x => x.id === deliveryId);
    if (receptionIndex !== -1) {
      const reception = receptions[receptionIndex];
      const updatedReception = {
        ...reception,
        qty_read: totalIndicator.receivedWithTagTotalQties + totalIndicator.foundAtMovementsQties,
        qty_confirmed: totalIndicator.expectedWithTagTotalQties,
      };
      const updatedReceptions = [
        ...receptions.slice(0, receptionIndex),
        updatedReception,
        ...receptions.slice(receptionIndex+1),
      ];
      this.setState({ receptions: updatedReceptions });
    }
  }

  getDeliveries = () => {
    const storeId = this.props.match.params.store;
    const { t, user, updateTokens } = this.props;
    const { shipmentsPage, shipmentsPageSize } = this.state;
    const { token, refreshToken, tokenExpireDate } = user;
    const queryString = getQueryString({
      pagination: {
        page: shipmentsPage,
        size: shipmentsPageSize
      },
    });
    const url = `${process.env.REACT_APP_base_URL}/api/${storeId}/deliveries/v2?${queryString}`;
    this.setState({ loadingReceptions: true, alert: false });
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handlePaginatedApiResponse)
    .then(({result: receptions, contentRange}) => {
      this.setState({
        receptions,
        loadingReceptions: false,
        shipmentsTotal: {
          pages: Math.max(1, Math.ceil(contentRange.max / shipmentsPageSize)),
          items: contentRange.max
        }
      });
    })
    .catch(error => {
      if (error.status === 401) {
        this.onAuthExpired();
      } else {
        const errorTitle = t('liveStoreReception.errors.apiError', 'Error when loading deliveries from stores.com API')
        console.warn(`Error while getting receptions for store ${storeId} : ${error}`);
        this.setState({
          loadingReceptions: false,
          alert: true,
          alertMessage: `${errorTitle} : ${error}`
        });
      }
    });
  }

  getDefaultValues = () => {
    const storeId = this.props.match.params.store;
    const { t, user, updateTokens } = this.props;
    const { filterValues } = this.state;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${process.env.REACT_APP_base_URL}/api/${storeId}/defaultValues`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(handleApiResponse)
    .then(defaults => {
      this.setState({
        defaultMinimumPrice: defaults.filter_minimum_price,
        filterValues: {
          ...filterValues,
          hideCheaper: defaults.filter_minimum_price
        },
      })
    })
    .catch(error => {
      if (error.status === 401) {
        this.onAuthExpired();
      } else {
        const errorTitle = t('liveStoreReception.errors.defaultsError', 'Error when loading country default values')
        console.warn(`Error while getting default values for country of store ${storeId} : ${error}`);
        this.setState({
          loadingReceptions: false,
          alert: true,
          alertMessage: `${errorTitle} : ${error}`
        })
      }
    })
  }

  displayReception = (reception) => {
    const { t } = this.props;
    let myClass = "deliveries";
    if (this.state.myReception.id === reception.id){
      myClass += " table-primary"
    }
    const date = new Date(reception.reception_date)
    const formattedDate = date.toLocaleDateString('ru');
    const formattedChangeDate = reception.status_change_date && (new Date(reception.status_change_date)).toLocaleString('ru');
    const robotStatusTitles = {
      'robot_finished': t('liveStoreReception.robotFinished', 'Robot finished inventory after delivery ended'),
      'robot_started': t('liveStoreReception.robotStarted', 'Robot started new inventory after delivery ended'),
    };
    const tagsCls = this.getTagsColorCls(reception.qty_confirmed, reception.qty_read);
    if (this.state.myReception.id === undefined || this.state.forceDisplayReceptions || this.state.myReception.id === reception.id){
      const robotStatus = reception.robot_status;
      const robotIconSrc = robotStatus === 'robot_finished' ? robotIconSuccessSrc : robotIconWarningSrc;
      return(
        <tr key={reception.id} className={myClass} onClick={(event) => this.getDataFromDelivery(reception)}>
          <td className="clickableText">
            <div className="clickableText">
              {reception.id}
            </div>
            <div className={`mobile-tags d-md-none ${tagsCls}`}>
              {reception.qty_read} / {reception.qty_confirmed}
            </div>
          </td>
          <td className={`clickableText d-none d-md-table-cell ${tagsCls}`}>
            {reception.qty_read} / {reception.qty_confirmed}
          </td>
          <td className="clickableText">{formattedDate}</td>
          <td className="clickableText">
            <div className="clickableText d-none d-md-table-cell">
              {reception.status}
            </div>
            <div className="clickableText mobile-status d-md-none">
              <div className="mobile-status-wrapper">
                {reception.status}
                {' '}
                {robotStatus && (
                  <div className="iconCell">
                    <img className="robot-icon" src={robotIconSrc} alt="robot-status" />
                  </div>
                )}
              </div>
              <div className="mobile-change-date">
                {formattedChangeDate}
              </div>
            </div>
          </td>
          <td className="clickableText d-none d-md-table-cell">{formattedChangeDate}</td>
          <td
            className="clickableText d-none d-md-table-cell"
            title={robotStatusTitles[robotStatus]}
          >
            {robotStatus && (
              <div className="iconCell">
                <img className="robot-icon" src={robotIconSrc} alt="robot-status" />
              </div>
            )}
          </td>
        </tr>
      )
    }
  }

  forceDisplayReceptions = () => {
    this.setState({
      forceDisplayReceptions: !this.state.forceDisplayReceptions
    })
  }

  fetchDeliveryContent = (reception) => {
    const { t } = this.props;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    let deliveryContent = null;
    let error = null;
    const url = `${process.env.REACT_APP_base_URL}/api/${this.props.match.params.store}/deliveries/v2/${reception.id}/withMovements`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
    })
    .then(result => {
      if (result.status === 404) {
        error = t(
          'liveStoreReception.errors.noData',
          'There is no data for this delivery in the database. Please alert End2End team.',
        );
      } else if (result.status !== 200) {
        const errorTitle = t(
          'liveStoreReception.errors.contentError',
          'Error in fetchDeliveryContent for reception',
        );
        return result.text().then(e => {
          console.warn(`Error in fetchDeliveryContent for reception ${reception.id} : ${e}`);
          error = `${errorTitle} ${reception.id} : ${error}`;
        })
      } else {
        return result.json()
        .then((data) => { deliveryContent = data; })
      }
    })
    .catch(e => {
      console.warn(`Error in fetchDeliveryContent for reception ${reception.id} : ${e}`);
      const errorTitle = t(
        'liveStoreReception.errors.contentError',
        'Error in fetchDeliveryContent for reception',
      );
      error = `${errorTitle} ${reception.id} : ${e}`;
    })
    .then(() => ({ error, deliveryContent }));
  }

  getDataFromDelivery = async (reception) => {
    this.setState({
      alert: false,
      loadingItemDetails: false,
      loadingPallets: true,
      myReception: reception,
      reveptionOpenTime: new Date(),
      lastReceivedTagId: null,
      shippingLists: [],
      receivedEPC: new Set(),
      itemDetails: [],
      movements: {},
      movementsUpdateError: null,
      stockExtractorQuotaLimitReached: false,
      movementAPICallsLimitReached: false,
      unexpectedItems: [],
      unexpectedItemsStored: [],
      unexpectedItemsSizeMatches: [],
      forceDisplayReceptions: false,
      sportIndicators: [],
      palletIndicators: [],
      totalIndicator: {},
    });

    const { error, deliveryContent } = await this.fetchDeliveryContent(reception);
    if (error || !deliveryContent) {
      this.setState({
        loadingPallets: false,
        alert: true,
        alertMessage: error || 'Error: empty delivery content',
      });
      return;
    }

    this.setState({ loadingPallets: false, loadingItemDetails: true });
    const warehouseReadingActivated = _.sumBy(deliveryContent.shippingLists, 'qty_read_by_warehouse_gate') > 0;
    const receivedEPC = new Set([
      ...deliveryContent.shippingLists.map(l => l.read_by_store_gate).flat(),
      ...deliveryContent.unexpected.map(u => u.read_unexpected).flat(),
    ]);
    const movements = deliveryContent.movements.reduce((accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.item]: currentValue
    }), {});
    const shippingLists = deliveryContent.shippingLists;
    const uniqueItems = [...new Set([
      ...deliveryContent.shippingLists.map(l => l.item),
      ...deliveryContent.unexpected.map(u => u.item),
    ])];
    const { error: detailsError, itemDetails } = await this.getItemDetails(uniqueItems);

    if (detailsError || !itemDetails) {
      this.setState({
        loadingItemDetails: false,
        alert: true,
        alertMessage: detailsError || 'Empty ItemDetails',
      });
      return;
    }

    const unexpectedItems = deliveryContent.unexpected;

    const {
      sportIndicators,
      palletIndicators,
      totalIndicator,
    } = this.calculateDelivery(itemDetails, shippingLists, movements);
    const unexpectedItemsSizeMatches = this.matchUnexpectedToWrongSizes(
      shippingLists,
      unexpectedItems,
      itemDetails,
    );

    const currencyDetails = itemDetails.find(itemDetails => (
      itemDetails
      && itemDetails.currency
      && itemDetails.price
    ));
    const currency = currencyDetails ? currencyDetails.currency.toLowerCase() : '$';

    const stockExtractorQuotaLimitReached = !!(deliveryContent.movementsStatistics && deliveryContent.movementsStatistics.stockExtractorQuotaLimitReached);
    const movementAPICallsLimitReached = !!(deliveryContent.movementsStatistics && deliveryContent.movementsStatistics.movementAPICallsLimitReached);
    const movementsUpdateError = stockExtractorQuotaLimitReached ? null : deliveryContent.movementsError;

    const { totalUnexpected, unexpectedSportItems } = this.getUnexpectedSportItems(
      unexpectedItems,
      itemDetails,
      movements,
      shippingLists,
      unexpectedItemsSizeMatches,
    );
    this.updateReceptionTotalQties(reception.id, totalIndicator);
    this.setState({
      currency,
      shippingLists,
      receivedEPC,
      movements,
      movementsUpdateError,
      stockExtractorQuotaLimitReached,
      movementAPICallsLimitReached,
      itemDetails,
      sportIndicators,
      palletIndicators,
      totalIndicator: {
        ...totalIndicator,
        unexpected: totalUnexpected
      },
      unexpectedItems,
      unexpectedItemsStored: unexpectedItems,
      unexpectedItemsSizeMatches,
      warehouseReadingActivated: warehouseReadingActivated,
      forceDisplayReceptions: false,
      loadingPallets: false,
      loadingItemDetails: false,
      unexpectedSportItems,
    });
  }

  getItemDetails = (uniqueItems) => {
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const store = this.props.match.params.store;
    const country = this.props.match.params.country;

    let itemDetails = [];
    let error = null;
    const url = `${process.env.REACT_APP_base_URL}/api/${country}/${store}/itemDetails`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: 'POST',
      body:JSON.stringify({
        itemsSeparatedByCommas: uniqueItems.join(","),
        language: 'en'
      })
    })
    .then(handleApiResponse)
    .then(resp => {
      const itemDetailsWithoutNull = resp.filter(e => e !== null);
      this.sortItemDetails(itemDetailsWithoutNull);
      //I receive null if there is an error in retrieveing details of the item
      itemDetails = itemDetailsWithoutNull;
    })
    .catch(e => {
      error = `Error in getExpectedItemDetails : ${e}`;
      console.warn(error);
    })
    .then(() => ({ error, itemDetails }));
  }

  sortItemDetails = (itemDetails) => {
    itemDetails.sort((a,b) => (
      compareItems('universe_label', a, b)
      || compareItems('department_label', a, b)
      || compareItems('sub_department_label', a, b)
      || compareItems('family_label', a, b)
      || compareItems('model_lib', a, b)
      || compareItems('item_id', a, b)
    ));
    function compareItems(key, a, b){
      if (a[key] < b[key]){
        return - 1;
      }
      if (a[key] > b[key]){
        return 1;
      }
      return 0;
    }
  }

  associateTagToParcels = (shippingLists) => {
    const byParcelContent = [];
    shippingLists.forEach(l => {
      const byParcelConfirmed = l.content.map(c => c.qty_confirmed);
      const byParcelReadByStoreGate = splitEvenly(l.qty_confirmed, l.qty_read_by_store_gate, byParcelConfirmed);
      const byParcelReadByWarehouseGate = splitEvenly(l.qty_confirmed, l.qty_read_by_warehouse_gate, byParcelConfirmed);
      const byParcelReadBeforeWarehouseGate = splitEvenly(l.qty_confirmed, l.qty_read_before_warehouse_gate, byParcelConfirmed);

      l.content.forEach((c, index) => {
        byParcelContent.push({
          store: l.store,
          delivery: l.delivery,
          item: l.item,
          pallet: c.pallet,
          parcel: c.parcel,
          qty_confirmed: c.qty_confirmed,
          qty_predicted: c.qty_predicted,
          qty_read_by_store_gate: byParcelReadByStoreGate[index],
          qty_read_by_warehouse_gate: byParcelReadByWarehouseGate[index],
          qty_read_before_warehouse_gate: byParcelReadBeforeWarehouseGate[index],
        })
      });
    });

    return byParcelContent;
  }

  calculateDelivery = (itemDetails, shippingLists, movements) => {
    const expectedItems = shippingLists.map(l => l.item);
    const expectedItemDetails = itemDetails
      .filter(item => (
        expectedItems.indexOf(item.item_id) !== -1
        && (item.universe_label || item.nature === VALID_NATURE)
      ));
    const sports = expectedItemDetails.map(item => item.universe_label ? item.universe_label.toUpperCase() : NO_SPORT_LABEL);
    const uniqueSports = [...new Set(sports)];
    const sportIndicators = uniqueSports.map(sport => ({
      name: sport,
      indicator: this.calculateSportIndicators(sport, expectedItemDetails, shippingLists, movements),
    }));

    const byParcelContent = this.associateTagToParcels(shippingLists);

    const pallets = [...new Set(byParcelContent.map(p => p.pallet))];
    const palletIndicators = pallets.map(palletID => ({
      name: palletID,
      indicator: this.calculatePalletIndicators(palletID, itemDetails, byParcelContent, movements)
    }));
    const indicatorSorter = (a, b) => {
      const {
        expectedWithTagTotalQties: expectedWithTagTotalQtiesB,
        receivedWithTagTotalQties: receivedWithTagTotalQtiesB,
        foundAtMovementsQties: foundAtMovementsQtiesB,
        missingValue: missingValueB,
      } = b.indicator;
      const {
        expectedWithTagTotalQties: expectedWithTagTotalQtiesA,
        receivedWithTagTotalQties: receivedWithTagTotalQtiesA,
        foundAtMovementsQties: foundAtMovementsQtiesA,
        missingValue: missingValueA,
      } = a.indicator;
      const diffMissingValue = missingValueB - missingValueA;
      const diffMissingQties = (
        (expectedWithTagTotalQtiesB - receivedWithTagTotalQtiesB - foundAtMovementsQtiesB)
        - (expectedWithTagTotalQtiesA - receivedWithTagTotalQtiesA - foundAtMovementsQtiesA)
      );
      let diffName = 0;
      if (a.name > b.name) {
        diffName = 1;
      } else if (a.name < b.name) {
        diffName = -1;
      }
      return diffMissingValue || diffMissingQties || diffName;
    };
    sportIndicators.sort(indicatorSorter);
    palletIndicators.sort(indicatorSorter);
    const totalIndicator = this.calculateTolalIndicator(sportIndicators);
    return { sportIndicators, palletIndicators, totalIndicator };
  }

  matchUnexpectedToWrongSizes = (parcelsContent, unexpectedItems, itemDetails) => {
    const missingItems = parcelsContent.filter(item => item.qty_confirmed > item.qty_read_by_store_gate);
    const missingItemsDeltas = missingItems.reduce((accumulator, item) => {
      accumulator[item.item] = (accumulator[item.item] || 0) + item.qty_confirmed - item.qty_read_by_store_gate;
      return accumulator;
    }, {});
    const unexpectedItemsDeltas = unexpectedItems.reduce((accumulator, item) => {
      accumulator[item.item] = (accumulator[item.item] || 0) + item.qty_read_unexpected;
      return accumulator;
    }, {});

    let unexpectedItemsSizeMatches = [];

    const byModel = _.groupBy(itemDetails, 'model_id');
    Object.keys(byModel).forEach(model_id => {
      const modelItems = byModel[model_id];
      if (modelItems.length <= 1) {
        return;
      }
      const unexpectedModelItems = modelItems.filter(item => unexpectedItemsDeltas[item.item_id]);
      if (!unexpectedModelItems.length) {
        return;
      }
      const missingModelItems = modelItems.filter(item => missingItemsDeltas[item.item_id]);
      if (!missingModelItems.length) {
        return;
      }

      unexpectedModelItems.forEach(unexpectedItem => {
        missingModelItems.forEach(missingItem => {
          if (unexpectedItemsDeltas[unexpectedItem.item_id] === missingItemsDeltas[missingItem.item_id]) {
            unexpectedItemsSizeMatches.push({
              unexpectedItemId: unexpectedItem.item_id,
              missingItemId: missingItem.item_id,
              missingItem,
            });
          }
        })
      })
    });
    return unexpectedItemsSizeMatches;
  }

  calculateIndicator = (
    itemsDetails,
    uniqueItems,
    shippingLists,
    shippingListsWithTag,
    movements,
  ) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const expectedTotalQties = shippingLists.map(l => l.qty_confirmed).reduce(reducer, 0);
    const expectedWithTagTotalQties = shippingListsWithTag.map(l => l.qty_confirmed).reduce(reducer, 0);
    const expectedWithTagValue = shippingListsWithTag.map(l => l.qty_confirmed * getPrice(l.item, itemsDetails)).reduce(reducer, 0);
    const receivedTotalQties = shippingLists.map(l => l.qty_read_by_store_gate).reduce(reducer, 0);
    const receivedWithTagTotalQties = shippingListsWithTag.map(l => l.qty_read_by_store_gate).reduce(reducer, 0);
    const warehousTotalQties = shippingLists.map(l => l.qty_read_before_warehouse_gate).reduce(reducer, 0);
    const warehouseWithTagTotalQties = shippingLists.map(l => l.qty_read_before_warehouse_gate).reduce(reducer, 0);

    const shippingListsWithMovements = shippingLists.filter(l => !!movements[l.item]);
    const byItemWithMovements = shippingListsWithMovements.reduce((accumulator, l) => {
      if (accumulator[l.item]) {
        accumulator[l.item].qty_confirmed += l.qty_confirmed;
        accumulator[l.item].qty_read_by_store_gate += l.qty_read_by_store_gate;
      } else {
        accumulator[l.item] = {
          item: l.item,
          qty_confirmed: l.qty_confirmed,
          qty_read_by_store_gate: l.qty_read_by_store_gate,
        };
      }
      return accumulator;
    }, {});
    
    const missingItemsWithMovements = Object.values(byItemWithMovements).filter(item => item.qty_confirmed > item.qty_read_by_store_gate);
    const foundMovementsItems = [];

    let foundAtMovementsQties = 0;
    let missingAtMovementsQties = 0;
    let revisedAtMovementsQties = 0;
    let leftQties = 0;
    let foundAtMovementsValue = 0;
    let missingAtMovementsValue = 0;
    let revisedAtMovementsValue = 0;
    missingItemsWithMovements.forEach(item => {
      const movement = movements[item.item];
      const missingCount = item.qty_confirmed - item.qty_read_by_store_gate;
      if (movement.status === 'stock_found' || movement.delta === 0) {
        foundAtMovementsQties += missingCount;
        foundMovementsItems.push(item.item);
        const price = getPrice(item.item, itemsDetails);
        foundAtMovementsValue += missingCount * price;
      } else if (movement.delta === -missingCount) {
        missingAtMovementsQties += missingCount;
        const price = getPrice(item.item, itemsDetails);
        missingAtMovementsValue += missingCount * price;
      } else {
        revisedAtMovementsQties += missingCount;
        const price = getPrice(item.item, itemsDetails);
        revisedAtMovementsValue += missingCount * price;
      }
    });

    leftQties = (
      expectedWithTagTotalQties
      - receivedWithTagTotalQties
      - foundAtMovementsQties
      - missingAtMovementsQties
      - revisedAtMovementsQties
    );

    let warehouseVisibleQties = 0;
    if (warehouseWithTagTotalQties > receivedWithTagTotalQties + foundAtMovementsQties) {
      warehouseVisibleQties = warehouseWithTagTotalQties - receivedWithTagTotalQties - foundAtMovementsQties;
      if (warehouseVisibleQties > leftQties) {
        warehouseVisibleQties = leftQties;
      }
    }

    let percentDone = 100;
    let percentFoundAtMovements = 0;
    let percentMissingAtMovements = 0;
    let percentRevisedAtMovements = 0;
    let percentLeft = 0;
    let percentWarehouseVisible = 0;

    if (expectedWithTagTotalQties > 0){
      percentDone = receivedWithTagTotalQties / expectedWithTagTotalQties * 100;
      percentWarehouseVisible = warehouseVisibleQties / expectedWithTagTotalQties * 100;
      percentFoundAtMovements = foundAtMovementsQties / expectedWithTagTotalQties * 100;
      percentMissingAtMovements = missingAtMovementsQties / expectedWithTagTotalQties * 100;
      percentRevisedAtMovements = revisedAtMovementsQties / expectedWithTagTotalQties * 100;
      percentLeft = (
        100
        - percentDone
        - percentFoundAtMovements
        - percentWarehouseVisible
        - percentMissingAtMovements
        - percentRevisedAtMovements
      );
      if (percentLeft < 0.0000000001) {
        percentLeft = 0;
      }
    }

    const receivedValue = shippingListsWithTag.map(
      l => l.qty_read_by_store_gate * getPrice(l.item, itemsDetails)
    ).reduce(reducer, 0);
    const warehouseValue = shippingListsWithTag.map(
      l => l.qty_read_before_warehouse_gate * getPrice(l.item, itemsDetails)
    ).reduce(reducer, 0);
    let missingValue = 0;
    let leftValue = 0;
    if (expectedWithTagValue > 0){
      missingValue = (
        expectedWithTagValue
        - receivedValue
        - foundAtMovementsValue
      );
      leftValue = (
        missingValue
        - missingAtMovementsValue
        - revisedAtMovementsValue
      );
    }

    return {
      myItemsDetails: itemsDetails,
      uniqueItems,
      myParcels: shippingLists,
      expectedTotalQties,
      expectedWithTagTotalQties,
      receivedTotalQties,
      receivedWithTagTotalQties,
      warehousTotalQties,
      warehouseWithTagTotalQties,
      warehouseVisibleQties,
      foundAtMovementsQties,
      missingAtMovementsQties,
      revisedAtMovementsQties,
      leftQties,
      percentDone,
      percentWarehouseVisible,
      percentFoundAtMovements,
      percentMissingAtMovements,
      percentRevisedAtMovements,
      percentLeft,
      expectedWithTagValue,
      receivedValue,
      warehouseValue,
      missingValue,
      foundAtMovementsValue,
      missingAtMovementsValue,
      revisedAtMovementsValue,
      leftValue,
    }
  }

  calculateSportIndicators = (sport, itemDetails, shippingLists, movements) => {
    const sportItemsDetails = itemDetails.filter(item => (
      item.universe_label
      || item.nature === VALID_NATURE
    )).filter(item => (item.universe_label ? item.universe_label.toUpperCase() : NO_SPORT_LABEL) === sport);
    const sportItems = sportItemsDetails.map(itemDetails => itemDetails.item_id);
    const sportShippingLists = shippingLists.filter(parcel => sportItems.includes(parcel.item));
    const sportItemsDetailsWithTag = sportItemsDetails.filter(item => item.article_flag === 'P' || item.article_flag === 'L');
    const sportItemsWithTag = sportItemsDetailsWithTag.map(itemDetails => itemDetails.item_id);
    const sportShippingListsWithTag = shippingLists.filter(parcel => sportItemsWithTag.includes(parcel.item));
    const sortedUniqueItems = _.orderBy(
      sportShippingLists,
      [
        (item) => {
          const details = sportItemsDetails.filter((details) => details.item_id === item.item);
          const price = details.length ? details[0].price : 0;
          return (item.qty_confirmed - item.qty_read_by_store_gate) * price;
        },
        (item) => {
          const details = sportItemsDetails.filter((details) => details.item_id === item.item);
          const price = details.length ? details[0].price : 0;
          return item.qty_confirmed * price;
        },
      ],
      ['desc', 'desc'],
    ).map(({ item }) => item);

    return this.calculateIndicator(
      sportItemsDetails,
      sortedUniqueItems,
      sportShippingLists,
      sportShippingListsWithTag,
      movements,
    );
  }

  calculatePalletIndicators = (palletID, itemDetails, byParcelContent, movements) => {
    const myParcels = byParcelContent.filter(parcel => parcel.pallet === palletID);
    const myItems = myParcels.map(pickingLine => pickingLine.item);
    const uniqueItems = [...new Set(myItems)];
    const myItemsDetails = itemDetails.filter(item => (
      item.universe_label
      || item.nature === VALID_NATURE
    )).filter(item => uniqueItems.includes(item.item_id));
    const myItemsDetailsWithTag = myItemsDetails.filter(item => item.article_flag === 'P' || item.article_flag === 'L');
    const myItemsWithTag = myItemsDetailsWithTag.map(itemDetails => itemDetails.item_id);
    const myParcelsWithTag = myParcels.filter(parcel => myItemsWithTag.includes(parcel.item));

    return this.calculateIndicator(
      myItemsDetails,
      uniqueItems,
      myParcels,
      myParcelsWithTag,
      movements,
    );
  }

  getUnexpectedSportItems = (
    unexpectedItems,
    itemDetails,
    movements,
    shippingLists,
    unexpectedItemsSizeMatches,
  ) => {
    const {
      filters,
      filterValues,
    } = this.state;
    const sportIndicators = {};
    const total = {
      foundAtMovementsQties: 0,
      foundAtMovementsValue: 0,
      missingAtMovementsQties: 0,
      missingAtMovementsValue: 0,
      revisedAtMovementsQties: 0,
      revisedAtMovementsValue: 0,
      notCheckedValue: 0,
      total: 0,
      additionalValue: 0,
    };
    const filteredUnexpectedItems = unexpectedItems.filter(
      (item) => !filters.hideCheaper || getPrice(item.item, itemDetails) >= filterValues.hideCheaper
    );

    filteredUnexpectedItems.forEach((item) => {
      item.unexpectedMatchesSize = unexpectedItemsSizeMatches.filter(
        match => match.unexpectedItemId === item.item
      );
      const listItem = shippingLists.find(pickingLine => pickingLine.item === item);
      const qtyReadUnexpected = unexpectedItems.find(e => e.item === item.item).qty_read_unexpected;
      item.expectedPickingLinesForThisItem = listItem ? [{
        ...listItem,
        qty_read_by_store_gate: listItem.qty_read_by_store_gate + qtyReadUnexpected,
      }] : [{
        item: item.item,
        qty_confirmed: 0,
        qty_read_by_store_gate: qtyReadUnexpected,
        qty_read_by_warehouse_gate: item.qty_read_by_warehouse_gate,
      }];
      item.details = itemDetails.find((details) => details.item_id === item.item);

      if (item.details) {
        const itemPrice = (item.details.price || 0);
        item.amount = item.qty_read_unexpected * itemPrice;
        const universe = item.details.universe_label ? item.details.universe_label.toUpperCase() : NO_SPORT_LABEL;
        let foundAtMovementsQties = 0;
        let missingAtMovementsQties = 0;
        let revisedAtMovementsQties = 0;
        let notCheckedValue = 0;
        const movement = movements[item.item];
        if (movement) {
          item.movement = movement;
          if (movement.status === 'stock_found' || movement.delta === 0) {
            foundAtMovementsQties = item.qty_read_unexpected;
          } else if (movement.delta === item.qty_read_unexpected) {
            missingAtMovementsQties = item.qty_read_unexpected;
          } else {
            revisedAtMovementsQties = item.qty_read_unexpected;
          }
        } else {
          notCheckedValue = item.qty_read_unexpected * itemPrice;
        }
        if (!sportIndicators[universe]) {
          sportIndicators[universe] = {
            items: {
              [item.item]: item,
            },
            total: item.qty_read_unexpected,
            amount: item.qty_read_unexpected * itemPrice,
            foundAtMovementsQties, //green
            foundAtMovementsValue: foundAtMovementsQties * itemPrice,
            missingAtMovementsQties, //red
            missingAtMovementsValue: missingAtMovementsQties * itemPrice,
            revisedAtMovementsQties, //orange
            revisedAtMovementsValue: revisedAtMovementsQties * itemPrice,
            additionalValue: notCheckedValue,
          };
        } else {
          sportIndicators[universe].items[item.item] = item;
          sportIndicators[universe].total += item.qty_read_unexpected;
          sportIndicators[universe].foundAtMovementsQties += foundAtMovementsQties;
          sportIndicators[universe].foundAtMovementsValue += foundAtMovementsQties * itemPrice;
          sportIndicators[universe].missingAtMovementsQties += missingAtMovementsQties;
          sportIndicators[universe].missingAtMovementsValue += missingAtMovementsQties * itemPrice;
          sportIndicators[universe].revisedAtMovementsQties += revisedAtMovementsQties;
          sportIndicators[universe].revisedAtMovementsValue += revisedAtMovementsQties * itemPrice;
          sportIndicators[universe].additionalValue += notCheckedValue;
        }
        total.foundAtMovementsQties += foundAtMovementsQties;
        total.foundAtMovementsValue += foundAtMovementsQties * itemPrice;
        total.missingAtMovementsQties += missingAtMovementsQties;
        total.missingAtMovementsValue += missingAtMovementsQties * itemPrice;
        total.revisedAtMovementsQties += revisedAtMovementsQties;
        total.revisedAtMovementsValue += revisedAtMovementsQties * itemPrice;
        total.additionalValue += notCheckedValue;
        total.total += item.qty_read_unexpected;
      }
    });

    return { unexpectedSportItems: sportIndicators, totalUnexpected: total };
  }

  calculateTolalIndicator = (sportIndicators) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const receivedWithTagTotalQties = sportIndicators.map(sport => sport.indicator.receivedWithTagTotalQties).reduce(reducer, 0);
    const warehouseWithTagTotalQties = sportIndicators.map(sport => sport.indicator.warehouseWithTagTotalQties).reduce(reducer, 0);
    const expectedWithTagTotalQties = sportIndicators.map(sport => sport.indicator.expectedWithTagTotalQties).reduce(reducer, 0);
    const foundAtMovementsQties = sportIndicators.map(sport => sport.indicator.foundAtMovementsQties).reduce(reducer, 0);
    const missingAtMovementsQties = sportIndicators.map(sport => sport.indicator.missingAtMovementsQties).reduce(reducer, 0);
    const revisedAtMovementsQties = sportIndicators.map(sport => sport.indicator.revisedAtMovementsQties).reduce(reducer, 0);
    const leftQties = sportIndicators.map(sport => sport.indicator.leftQties).reduce(reducer, 0);
    const receivedValue = sportIndicators.map(sport => sport.indicator.receivedValue).reduce(reducer, 0);
    const warehouseValue = sportIndicators.map(sport => sport.indicator.warehouseValue).reduce(reducer, 0);
    const expectedWithTagValue = sportIndicators.map(sport => sport.indicator.expectedWithTagValue).reduce(reducer, 0);
    const foundAtMovementsValue = sportIndicators.map(sport => sport.indicator.foundAtMovementsValue).reduce(reducer, 0);
    const missingAtMovementsValue = sportIndicators.map(sport => sport.indicator.missingAtMovementsValue).reduce(reducer, 0);
    const revisedAtMovementsValue = sportIndicators.map(sport => sport.indicator.revisedAtMovementsValue).reduce(reducer, 0);
    const missingValue = sportIndicators.map(sport => sport.indicator.missingValue).reduce(reducer, 0);
    const leftValue = sportIndicators.map(sport => sport.indicator.leftValue).reduce(reducer, 0);

    let warehouseVisibleQties = 0;
    if (warehouseWithTagTotalQties > receivedWithTagTotalQties + foundAtMovementsQties) {
      warehouseVisibleQties = warehouseWithTagTotalQties - receivedWithTagTotalQties - foundAtMovementsQties;
      if (warehouseVisibleQties > leftQties) {
        warehouseVisibleQties = leftQties;
      }
    }

    let percentDone = 100;
    let percentWarehouseVisible = 0;
    let percentFoundAtMovements = 0;
    let percentMissingAtMovements = 0;
    let percentRevisedAtMovements = 0;
    let percentLeft = 0;
    if (expectedWithTagTotalQties > 0){
      percentDone = receivedWithTagTotalQties / expectedWithTagTotalQties * 100;
      percentWarehouseVisible = warehouseVisibleQties / expectedWithTagTotalQties * 100;
      percentFoundAtMovements = foundAtMovementsQties / expectedWithTagTotalQties * 100;
      percentMissingAtMovements = missingAtMovementsQties / expectedWithTagTotalQties * 100;
      percentRevisedAtMovements = revisedAtMovementsQties / expectedWithTagTotalQties * 100;
      percentLeft = (
        100
        - percentDone
        - percentFoundAtMovements
        - percentWarehouseVisible
        - percentMissingAtMovements
        - percentRevisedAtMovements
      );
      if (percentLeft < 0.0000000001) {
        percentLeft = 0;
      }
    }

    return {
      receivedWithTagTotalQties,
      warehouseWithTagTotalQties,
      expectedWithTagTotalQties,
      foundAtMovementsQties,
      missingAtMovementsQties,
      revisedAtMovementsQties,
      leftQties,
      receivedValue,
      warehouseValue,
      expectedWithTagValue,
      foundAtMovementsValue,
      missingAtMovementsValue,
      revisedAtMovementsValue,
      missingValue,
      leftValue,
      percentDone,
      percentWarehouseVisible,
      percentFoundAtMovements,
      percentMissingAtMovements,
      percentRevisedAtMovements,
      percentLeft,
    };
  }

  sportHasVisibleItems = (sport, sportIndicators) => {
    const { filters, filterValues } = this.state;
    const displayFullyReceivedItems = this.state.sportsToDisplayEvenFullyReceivedItems.includes(sport);

    const hasVisibleItems = sportIndicators.uniqueItems.map((item_id) => {
      const itemDetails = sportIndicators.myItemsDetails.filter(item => item.item_id === item_id)[0];
      const allParcels = sportIndicators.myParcels;
      const movement = this.state.movements[item_id];
      const { visible } = calculateItemProps({
        allParcels,
        itemDetails,
        movement,
        filters,
        displayFullyReceivedItems,
        filterValues,
      });
      return visible;
    }).filter(x => x).length > 0;
    return hasVisibleItems;
  }

  palletHasVisibleItems = (palletID, palletIndicators) => {
    const { filters, filterValues } = this.state;
    const hasVisibleItems = palletIndicators.uniqueItems.map((item_id) => {
      const itemDetails = palletIndicators.myItemsDetails.filter(item => item.item_id === item_id)[0];
      const allParcels = palletIndicators.myParcels;
      const movement = this.state.movements[item_id];
      const { visible } = calculateItemProps({
        allParcels,
        itemDetails,
        movement,
        filters,
        displayFullyReceivedItems: false,
        filterValues,
      });
      return visible;
    }).filter(x => x).length > 0;
    return hasVisibleItems;
  }

  displaySport = (sport, sportIndicators) => {
    const { filters } = this.state;
    const noTagQties = sportIndicators.expectedTotalQties - sportIndicators.expectedWithTagTotalQties;
    const filtered = _.some(filters);

    if (filtered && !this.sportHasVisibleItems(sport, sportIndicators)) {
      return null;
    }

    let myClassCard = "card card-container";
    if (sportIndicators.receivedWithTagTotalQties >= sportIndicators.expectedWithTagTotalQties) {
      myClassCard += " bg-success";
    } else if (sportIndicators.receivedWithTagTotalQties + sportIndicators.foundAtMovementsQties >= sportIndicators.expectedWithTagTotalQties) {
      myClassCard += " bg-success";
    }

    return (
      <div key={sport} className={myClassCard}>
        <div className="card-body">
          <div className="row">
            <h5 className="card-title col-10">{sport}</h5>
            {this.displayChevronSport(sport)}
          </div>
          {this.displayPlus(sport, sportIndicators)}
          {this.displayNoRFIDQties(noTagQties, sportIndicators.expectedTotalQties)}
          <ProgressBar
            indicator={sportIndicators}
            currency={this.state.currency}
            onBarClick={() => this.onProgressClick({ indicator: sportIndicators, sport })}
          />
          {this.displaySportContent(sport, sportIndicators.uniqueItems, sportIndicators.myItemsDetails, sportIndicators.myParcels)}
        </div>
      </div>
    )
  }

  onTotalProgressClick = () => {
    const { totalIndicator } = this.state;
    this.setState({
      selectedIndicator: {
        indicator: totalIndicator,
        isTotal: true,
      }
    });
  }

  onProgressClick = ({ indicator, sport, pallet }) => {
    this.setState({
      selectedIndicator: {
        indicator,
        sport,
        pallet,
      }
    });
  }

  displayPallet = (palletID, palletIndicators) => {
    const { t } = this.props;
    const { filters } = this.state;
    const uniqueParcels = [...new Set(palletIndicators.myParcels.map(pickingLine => pickingLine.parcel))];
    const noTagQties = palletIndicators.expectedTotalQties - palletIndicators.expectedWithTagTotalQties;

    let myClassCard = "card card-container";
    if (palletIndicators.receivedWithTagTotalQties >= palletIndicators.expectedWithTagTotalQties) {
      myClassCard += " bg-success";
    } else if (palletIndicators.receivedWithTagTotalQties + palletIndicators.foundAtMovementsQties >= palletIndicators.expectedWithTagTotalQties) {
      myClassCard += " bg-success";
    }

    const filtered = _.some(filters);
    if (filtered && !this.palletHasVisibleItems(palletID, palletIndicators)) {
      return null;
    }

    return(
      <div key={palletID} className={myClassCard} >
        <div className="card-body">
          <div className="row">
            <h5 className="card-title col-10">{palletID}</h5>
            {this.displayChevronPallet(palletID)}
          </div>
          <p className="card-text">
            {t('liveStoreReception.card.parcels', 'Parcels')}
            {': '}
            {uniqueParcels.length}</p>
          {this.displayNoRFIDQties(noTagQties, palletIndicators.expectedTotalQties)}
          <ProgressBar
            indicator={palletIndicators}
            currency={this.state.currency}
            onBarClick={() => this.onProgressClick({ indicator: palletIndicators, pallet: palletID })}
          />
          {this.displayPalletContent(palletID, uniqueParcels, palletIndicators.myParcels)}
        </div >
      </div>
    )
  }

  displayNoRFIDQties = (noTagQties, totalQties) => {
    const { t } = this.props;
    if (noTagQties > 0){
      const tNoTagQties = t('liveStoreReception.card.qties', {
        defaultValue: '{{count}} qty',
        count: noTagQties,
      });
      const tTotalQties = t('liveStoreReception.card.qties', {
        defaultValue: '{{count}} qty',
        count: totalQties,
      });
      const translatedQties = t(
        'liveStoreReception.card.totalQties',
        {
          defaultValue: 'Total {{totalQties}} - {{noTagQties}} no RFID',
          totalQties: tTotalQties,
          noTagQties: tNoTagQties,
        },
      );
      return(
        <div className="card-text">{translatedQties}</div>
      )
    }
  }

  displayPlus = (sport, sportIndicators) => {
    if (this.state.sportsToDisplayContent.includes(sport) && (calculateFullyReceived(sport, sportIndicators.myParcels).length > 0 || sportIndicators.expectedTotalQties > sportIndicators.expectedWithTagTotalQties )){
      if (!this.state.sportsToDisplayEvenFullyReceivedItems.includes(sport)){
        return (
          <div className="offset-10 col-2 textRightAligned clickableText" onClick={(event) => this.hideUnhideItems(sport)}><i className="vtmn-icon_edit_plus"></i></div>
        )
      } else {
        return (
          <div className="offset-10 col-2 textRightAligned clickableText" onClick={(event) => this.hideUnhideItems(sport)}><i className="vtmn-icon_edit_minus"></i></div>
        )
      }
    }

    function calculateFullyReceived(sport, parcels){
      const fullyReceivedArray = parcels.filter(parcel => parcel.qty_read_by_store_gate >= parcel.qty_confirmed)
      return fullyReceivedArray;
    }
  }

  displaySportContent = (sport, uniqueItems, sportItemsDetails, sportParcels) => {
    if (this.state.sportsToDisplayContent.includes(sport)){
      const { filters, filterValues } = this.state;
      const displayFullyReceivedItems = this.state.sportsToDisplayEvenFullyReceivedItems.includes(sport);
      return(
        <div key={sport}>
          {uniqueItems.map((item_id, index) => (
            <DisplayItem
              reception={this.state.myReception}
              key={item_id}
              filters={filters}
              filterValues={filterValues}
              itemDetails={sportItemsDetails.filter(item => item.item_id === item_id)[0]}
              allParcels={sportParcels}
              displayFullyReceivedItems={displayFullyReceivedItems}
              warehouseReadingActivated={this.state.warehouseReadingActivated}
              user={this.props.user}
              updateTokens={this.props.updateTokens}
              store={this.props.match.params.store}
              movement={this.state.movements[item_id]}
            />
          ))}
        </div>
      )
    }
  }

  hideUnhideItems = (sport) => {
    let sportsList = this.state.sportsToDisplayEvenFullyReceivedItems;
    if (sportsList.includes(sport)){
      sportsList = sportsList.filter(e => e !== sport);
      this.setState({
        sportsToDisplayEvenFullyReceivedItems: sportsList
      });
    } else {
      sportsList.push(sport);
      this.setState({
        sportsToDisplayEvenFullyReceivedItems: sportsList
      });
    }
  }

  hideUnhidePallets = (palletID) => {
    let newPalletsToDisplayContent = this.state.palletsToDisplayContent;
    if (newPalletsToDisplayContent.includes(palletID)){
      newPalletsToDisplayContent = newPalletsToDisplayContent.filter(e => e !== palletID);
      this.setState({
        palletsToDisplayContent: newPalletsToDisplayContent
      })
    } else {
      newPalletsToDisplayContent.push(palletID);
      this.setState({
        palletsToDisplayContent: newPalletsToDisplayContent
      })
    }
  }

  displayChevronPallet = (palletID) => {
    if (this.state.palletsToDisplayContent.includes(palletID)){
      return(
        <div onClick={() => this.hideUnhidePallets(palletID)} className="clickableText textRightAligned col-2"><i className="vtmn-icon_arrow2_up"></i></div>
      )
    } else {
      return(
        <div onClick={() => this.hideUnhidePallets(palletID)} className="clickableText textRightAligned col-2"><i className="vtmn-icon_arrow2_down"></i></div>
      )
    }
  }

  hideUnhideSport = (sport) => {
    let newSportToDisplayContent = this.state.sportsToDisplayContent;
    if (newSportToDisplayContent.includes(sport)){
      newSportToDisplayContent = newSportToDisplayContent.filter(e => e !== sport);
      this.setState({
        sportsToDisplayContent: newSportToDisplayContent
      })
    } else {
      newSportToDisplayContent.push(sport);
      this.setState({
        sportsToDisplayContent: newSportToDisplayContent
      })
    }
  }

  displayChevronSport = (sport) => {
    if (this.state.sportsToDisplayContent.includes(sport)){
      return(
        <div onClick={() => this.hideUnhideSport(sport)} className="clickableText textRightAligned col-2"><i className="vtmn-icon_arrow2_up"></i></div>
      )
    } else {
      return(
        <div onClick={() => this.hideUnhideSport(sport)} className="clickableText textRightAligned col-2"><i className="vtmn-icon_arrow2_down"></i></div>
      )
    }
  }

  displayPalletContent = (palletID, parcelIDs, pickingLines) => {
    if (this.state.palletsToDisplayContent.includes(palletID)){
      return (
        <PalletDetails
          reception={this.state.myReception}
          user = {this.props.user}
          updateTokens = {this.props.updateTokens}
          filters = {this.state.filters}
          filterValues = {this.state.filterValues}
          parcels = {parcelIDs}
          palletID = {palletID}
          pickingLines = {pickingLines}
          store = {this.props.match.params.store}
          warehouseReadingActivated = {this.state.warehouseReadingActivated}
          itemDetails = {this.state.itemDetails}
          movements = {this.state.movements}
        />
      )
    }
  }

  loader = (myBoolean, message) => {
    if (this.state[myBoolean]) {
      return(
        <Row>
          <Spinner
            size="dm"
            animation="border"
            variant="primary"
            className="deliverySpinner"
          />
          <div className="align-self-center">{message}</div>
        </Row>
      )
    }
  }

  setFilters = (filters) => {
    this.setState({ filters });
    localStorage.liveStoreReceptionFilters = JSON.stringify(filters);
  }

  displayFiltersPanel = () => {
    const { t } = this.props;
    const { filters, filterValues, filtersVisible, currency } = this.state;

    if (!filtersVisible) {
      return null;
    }

    return (
      <Row className="mb-3">
        <SwitchFilter
          controlId="filter-both-gates-missing"
          label={t('liveStoreReception.filters.bothGatesMissing', 'Missing both at warehouse and store')}
          value={filters.bothGatesMissing}
          onChange={() => this.setFilters({ ...filters, bothGatesMissing: !filters.bothGatesMissing })}
        />
        <SwitchFilter
          controlId="filter-store-gate-zero"
          label={t('liveStoreReception.filters.storeGateZero', 'Store has seen 0')}
          value={filters.storeGateZero}
          onChange={() => this.setFilters({ ...filters, storeGateZero: !filters.storeGateZero })}
        />
        <SwitchFilter
          controlId="filter-hide-incomplete-boxes"
          label={t('liveStoreReception.filters.hideIncompleteBoxes', 'Hide if read > 50% PCB')}
          value={filters.hideIncompleteBoxes}
          onChange={() => this.setFilters({ ...filters, hideIncompleteBoxes: !filters.hideIncompleteBoxes })}
        />
        <SwitchFilter
          controlId="filter-hide-controlled"
          label={t('liveStoreReception.filters.hideControlled', 'Hide controlled')}
          value={filters.hideControlled}
          onChange={() => this.setFilters({ ...filters, hideControlled: !filters.hideControlled })}
        />
        <div className="minimumPriceFilter">
          <SwitchFilter
            controlId="filter-minimum-price-switch"
            value={filters.hideCheaper}
            onChange={() => this.onChangeHideCheaper()}
          />
          <span>{t('liveStoreReception.filters.priceMoreThan', 'Price ≥')}</span>
          <NumberFilter
            controlId="filter-minimum-price"
            value={filterValues.hideCheaper}
            onChange={(v) => this.onChangeMinimumPriceFilter(v)}
            maxLength={4}
          />
          <span>{currency}</span>

        </div>
      </Row>
    )
  }

  onChangeHideCheaper = () => {
    const { filters } = this.state;
    this.setFilters({ ...filters, hideCheaper: !filters.hideCheaper })
  }

  onChangeMinimumPriceFilter = (value) => {
    const { defaultMinimumPrice, filters, filterValues } = this.state;

    if (value !== defaultMinimumPrice) {
      this.setState({ defaultMinimumPrice: null });
      this.setFilters({ ...filters, hideCheaper: true })
    }
    this.setState({
      filterValues: {
        ...filterValues,
        hideCheaper: value
      },
    });
  }

  displayFiltersButton = () => {
    const { filters, filtersVisible } = this.state;
    const filtered = _.some(filters);

    return (
      <Button
        size="sm"
        className={`m-1 icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
        as="li"
        onClick={() => this.setState({ filtersVisible: !filtersVisible })}
      >
        <i className="vtmn-icon_filter2"></i>
        {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
        {' '}
        {filtersVisible ? (
          <i className="vtmn-icon_chevron_up_v2 right m-0"></i>
        ) : (
          <i className="vtmn-icon_chevron_down_v2 right m-0"></i>
        )}
      </Button>
    )
  }

  displayListHeader = () => {
    const {
      myReception,
      activeList,
      loadingPallets,
      loadingItemDetails,
      loadingNewTags,
    } = this.state;
    if (myReception.id !== undefined){
      const baseClass="nav-item nav-link clickableText tabHeader";
      let byPalletClass = baseClass;
      let unexpectedClass = baseClass;
      let bySportClass = baseClass;
      switch(activeList){
        case "unexpected":
          unexpectedClass += " active";
          break;
        case "by pallet":
          byPalletClass += " active";
          break;
        default:
          bySportClass += " active";
      }

      const loading = (
        loadingPallets
        || loadingItemDetails
        || loadingNewTags
      );

      return(
        <div className="card-header list-header">
          {this.displayFiltersPanel()}
          <ul className="nav nav-tabs card-header-tabs flex-nowrap">
            {loading ? (
              <Spinner
                size="sm"
                animation="border"
                variant="primary"
                className="deliverySpinner"
              />
            ) : null}
            {this.displayFiltersButton()}
            <li className={bySportClass} onClick={() => this.switchSelectedList("by sport")}>
              <Trans i18nKey="liveStoreReception.card.headers.bySport">By sport</Trans>
            </li>
            <li className={byPalletClass} onClick={() => this.switchSelectedList("by pallet")}>
              <Trans i18nKey="liveStoreReception.card.headers.byPallet">By pallet</Trans>
            </li>
            <li className={unexpectedClass} onClick={() => this.switchSelectedList("unexpected")}>
              <Trans i18nKey="liveStoreReception.card.headers.unexpected">Unexpected</Trans>
            </li>
          </ul>
        </div>
      )
    }
  }

  switchSelectedList = (selection) => {
    this.setState({
      activeList: selection
    })
  }

  displayList = () => {
    const { t } = this.props;
    if (this.state.myReception.id !== undefined) {
      const hasContentLoaded = this.getHasContentLoaded();
      return(
        <div>
          {this.displayTotal()}
          <div className="card">
            {this.displayListHeader()}
            <div className="card-body">
              {hasContentLoaded && this.displayListContent()}
              {!hasContentLoaded && this.loader(
                'loadingPallets',
                t('liveStoreReception.loader.pallets', 'Loading shipping list')
              )}
              {!hasContentLoaded && this.loader(
                'loadingItemDetails',
                t('liveStoreReception.card.loadingItemDetails', 'Loading item details')
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  getHasContentLoaded = () => {
    const { palletIndicators, sportIndicators, unexpectedItems } = this.state;
    const hasContentLoaded = !!(
      (palletIndicators && palletIndicators.length)
      || (sportIndicators && sportIndicators.length)
      || (unexpectedItems && unexpectedItems.length)
    );
    return hasContentLoaded;
  }

  displayListContent = () => {
    const { user, updateTokens } = this.props;
    const {
      filters,
      filterValues,
      warehouseReadingActivated,
      currency,
      myReception,
      unexpectedSportItems,
    } = this.state;
    const { store } = this.props.match.params;
    switch(this.state.activeList){
      case "unexpected":
        return(
          <UnexpectedTab
            store={store}
            user={user}
            updateTokens={updateTokens}
            filters={filters}
            filterValues={filterValues}
            warehouseReadingActivated={warehouseReadingActivated}
            currency={currency}
            delivery={myReception && myReception.id}
            unexpectedSportItems={unexpectedSportItems}
          />
        )
      case "by sport":
        return(
          <div className="card-columns">
            {this.state.sportIndicators.map(({ name, indicator }) => this.displaySport(name, indicator))}
          </div>
        )
      default:
        return(
          <div className="card-columns">
            {this.state.palletIndicators.map(({ name, indicator }) => this.displayPallet(name, indicator))}
          </div>
        )
    }
  }

  displayTotal = () => {
    if (this.state.itemDetails.length > 0){
      return(
        <ProgressBar
          isTotal
          indicator={this.state.totalIndicator}
          currency={this.state.currency}
          onBarClick={this.onTotalProgressClick}
        />
      )
    }
  }

  displayChevronReception = () => {
    switch (this.state.forceDisplayReceptions){
      case false:
        return(
          <th className="chevronHeader">
            <i className="vtmn-icon_arrow2_down clickableText" onClick={this.forceDisplayReceptions}></i>
          </th>
        );
      case true:
        return(
          <th className="chevronHeader">
            <i className="vtmn-icon_arrow2_up clickableText" onClick={this.forceDisplayReceptions}></i>
          </th>
        );
      default:
        return null;
    }
  }

  getWarehouseUnexpected = (delivery, items) => {
    const store = this.props.match.params.store;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const url = `${process.env.REACT_APP_base_URL}/api/${store}/deliveries/v2/${delivery}/items/unexpected`;
    return fetchWithJWT(url, {
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      },
      method: 'POST',
      body: JSON.stringify({ items }),
    })
    .then(handleApiResponse)
    .catch(error => {
      console.warn(`Error in getWarehouseUnexpected for delivery : ${delivery}, items : ${items} , error : ${error}`);
    })
  }

  displayReadingRateModal = () => {
    const { selectedIndicator, myReception, currency } = this.state;

    return (
      <ReadingRateModal
        show={!!selectedIndicator}
        indicator={selectedIndicator && selectedIndicator.indicator}
        sport={selectedIndicator && selectedIndicator.sport}
        pallet={selectedIndicator && selectedIndicator.pallet}
        delivery={myReception && myReception.id}
        currency={currency}
        onHide={() => this.setState({ selectedIndicator: null })}
      />
    );
  }

  render(){
    const { shipmentsPage, shipmentsPageSize, shipmentsTotal } = this.state;
    const { t } = this.props;
    return(
      <div className = "container-fluid liveStoreReception mb-1">
        <table className="table table-hover">
          <thead className="thead-light tableHeader">
            <tr>
              <th scope="col">
                <div className="refreshHeader">
                  <button
                    className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--ghost refreshButton"
                    onClick={this.getDeliveries}
                  >
                    <img className="robot-icon" src={refreshIconSrc} alt="robot-status" />
                  </button>
                  <Trans i18nKey="liveStoreReception.table.reception">Reception</Trans>
                </div>
              </th>
              <th scope="col" className="d-none d-md-table-cell">
                <Trans i18nKey="liveStoreReception.table.tags">Tags</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="liveStoreReception.table.date">Date</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="liveStoreReception.table.status">Status</Trans>
              </th>
              <th scope="col" className="d-none d-md-table-cell">
                <Trans i18nKey="liveStoreReception.table.statusChange">Status change date</Trans>
              </th>
              <th scope="col" width="160" className="d-none d-md-table-cell">
                <Trans i18nKey="liveStoreReception.table.robotStatus">Robot status</Trans>
              </th>
              {this.displayChevronReception()}
            </tr>
          </thead>
          <tbody>
            {this.state.loadingReceptions && (
              <tr>
                <td colSpan="5" align="center">
                  <div className="loaderWrapper">
                    {this.loader(
                      'loadingReceptions',
                      t('liveStoreReception.loader.receptions', 'Loading list of deliveries')
                    )}
                  </div>
                </td>
              </tr>
            )}
            {!this.state.loadingReceptions && this.state.receptions.map(e => this.displayReception(e))}
          </tbody>
        </table>
        {!this.state.loadingReceptions && (this.state.myReception.id === undefined || this.state.forceDisplayReceptions)
        && (
          <div className="shipmentsTableFooter">
            <TableFooter
              pagination={{
                page: shipmentsPage,
                size: shipmentsPageSize,
              }}
              totals={shipmentsTotal}
              setPagination={({ page, size }) => {
                let myReception = this.state.myReception;
                if (shipmentsPage !== page) {
                  myReception = { id: undefined };
                }
                this.setState({
                  forceDisplayReceptions: null,
                  shipmentsPage: page,
                  shipmentsPageSize: size,
                  myReception,
                }, this.getDeliveries);
              }}
            />
          </div>
        )}
        {this.displayAlerts()}
        {this.displayReadingRateModal()}
        {this.displayList()}
      </div>
    )
  }
}

export default withTranslation()(LiveStoreReception);
