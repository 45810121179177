import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDebounce from '../../../functions/useDebounce';

const isNil = (val) => val === null || val === undefined;

const NumberFilter = ({
    controlId,
    label,
    value,
    onChange,
    maxLength,
  }) => {
    const [stateValue, setStateValue] = useState(value);
    const debouncedValue = useDebounce(stateValue, 100);

    useEffect(
        () => {
            onChange(debouncedValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [debouncedValue],
    );

    return (
      <Form.Group controlId={controlId} className="filter-item">
        <Form.Control
          size="sm"
          placeholder={label}
          type="number"
          value={isNil(stateValue) ? '' : String(stateValue)}
          onChange={(e) => setStateValue(Math.max(0, parseInt(e.target.value) ).toString().slice(0,maxLength))}
        />
      </Form.Group>
    );
  };
  
  export default NumberFilter;