import React, { useState } from 'react';
import _ from "lodash";
import { DisplayItem } from '../displayItem';
import ProgressBar from './ProgressBar';
import ProgressDetailsModal from './ProgressDetailsModal';

const SportCard = ({
  user,
  updateTokens,
  sport,
  indicator,
  store,
  filters,
  filterValues,
  warehouseReadingActivated,
  currency,
  delivery,
}) => {
  const [hideItems, setHideItems] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const { total } = indicator;
  const [doNotShowItems, setDoNotShowItems] = useState(indicator.foundAtMovementsQties >= total);
  let myClassCard = "card card-container";
  const items = _.orderBy(Object.values(indicator.items), ['amount'], ['desc']);
  if (indicator.foundAtMovementsQties >= total) {
    myClassCard += " bg-success";
  }

  return (
    <div key={sport} className={myClassCard}>
      <div className="card-body">
        <div className="row">
          <h5 className="card-title col-10">{sport}</h5>
          <div onClick={() => setHideItems(!hideItems)} className="clickableText textRightAligned col-2">
            <i className={hideItems ? "vtmn-icon_arrow2_down" : "vtmn-icon_arrow2_up"}></i>
          </div>
        </div>
        <div className="card-text">Total {total} qty</div>
        
        <ProgressBar
          indicator={indicator}
          currency={currency}
          onBarClick={() => setShowDetails(true)}
        />
        {!hideItems && (
          <div key={sport}>
            {indicator.foundAtMovementsQties >= indicator.total && (
              <div className="offset-10 col-2 textRightAligned clickableText" onClick={() => setDoNotShowItems(!doNotShowItems)}>
                <i className={doNotShowItems ? "vtmn-icon_edit_plus" : "vtmn-icon_edit_minus"}></i>
              </div>
            )}
            {!doNotShowItems && items.map((item) => (
              <DisplayItem
                key={item.item}
                filters={filters}
                filterValues={filterValues}
                itemDetails={item.details}
                allParcels={item.expectedPickingLinesForThisItem}
                displayFullyReceivedItems={false}
                warehouseReadingActivated={warehouseReadingActivated}
                user={user}
                updateTokens={updateTokens}
                store={store}
                unexpectedMatchesSize={item.unexpectedMatchesSize}
                unexpected
                movement={item.movement}
              />
            ))}
          </div>
        )}
      </div>
      {showDetails && (
        <ProgressDetailsModal
          show={showDetails}
          onHide={() => setShowDetails(false)}
          sport={sport}
          delivery={delivery}
          indicator={indicator}
          currency={currency}
        />
      )}
    </div>
  );
};

export default SportCard;
