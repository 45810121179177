import React, { useState, useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useTableRequest from '../../functions/useTableRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';
import { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import { formatDateTime } from '../../functions/formatDate';
import Loader from '../loader';
import { TableFooter } from '../widgets/DataTable';
import StoreSelect from './filters/StoreSelect';
import WarehouseSelect from './filters/WarehouseSelect';
import SelectFilter from '../widgets/filters/SelectFilter';
import DaterangeFilter from '../widgets/filters/DaterangeFilter';
import TableHeadCell from '../widgets/TableHeadCell';
import { formatDateForBigQuery } from '../../functions/formatDate';
import '../../stylesheets/shipmentsList.css';

const getList = (user, updateTokens, { filters, pagination, sort }, storeId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };

  if (formatedFilters.startDateFrom) {
    formatedFilters.startDateFrom = formatDateForBigQuery(formatedFilters.startDateFrom);
  }
  if (formatedFilters.startDateTo) {
    formatedFilters.startDateTo = formatDateForBigQuery(formatedFilters.startDateTo);
  }

  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  });

  const url = `${process.env.REACT_APP_base_URL}/api/returns/shipments/${storeId}?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const ShipmentsTableBody = ({ data, onClick }) => {
  return (
    <tbody>
      {data.map((x, i) => (
        <tr
          key={i}
          onClick={(e) => onClick(x)}
        >
          <td>{x.shipmentId}</td>
          <td>{x.expeditor}</td>
          <td>{x.tagCount}</td>
          <td>{x.expectedQty}</td>
          <td>{x.status}</td>
          <td>{x.startDate ? formatDateTime(new Date(x.startDate)) : null}</td>
          <td>{x.finishDate ? formatDateTime(new Date(x.finishDate)) : null}</td>
          <td>&nbsp;</td>
        </tr>
      ))}
    </tbody>
  );
};

const statuses = [{
  label: 'Started',
  name: 'Started',
  id: 'started',
}, {
  label: 'Finished',
  name: 'Finished',
  id: 'finished',
}];

const ShipmentsList = ({ user, updateTokens, siteId, onClick, isWarehouse }) => {
  const { t } = useTranslation();
  const [filtered, setFiltered] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const initialFilters = {
    startDateFrom: null,
    startDateTo: null,
    outSiteId: [],
    inSiteId: [],
    status: null,
  };

  const [{
    loading,
    data,
    error,
    filters,
    sort,
    pagination,
    totals,
  // eslint-disable-next-line no-unused-vars
  }, fetchReturns, setFilters, setPagination, setSort] = useTableRequest(
    getList, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  useEffect(() => {
    fetchReturns(user, updateTokens, {
      filters,
      pagination,
      sort,
    }, siteId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, sort]);

  useEffect(() => {
    selectShipment(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!showAll) {
      setFiltered(data.filter((r) => r.shipmentId === selectedShipment));
      return;
    }
    setFiltered(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showAll]);

  useEffect(() => {
    setShowAll(true);
    setSelectedShipment(null);
    setFiltered(null);
    setFilters(initialFilters);
    setPagination({ ...pagination, page: 0});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const selectShipment = (shipment) => {
    setSelectedShipment(shipment && shipment.shipmentId);
    setShowAll(!shipment);
    onClick && onClick(shipment);
  }

  const tableColumns = [{
    sortable: true,
    field: 'shipmentId',
    title: t('returns.shipments.shipmentId', 'Shipment Id')
  }, {
    sortable: true,
    field: 'expeditor',
    title: isWarehouse
           ? t('returns.shipments.expeditor', 'Expeditor')
           : t('returns.shipments.destination', 'Destination')
  }, {
    sortable: true,
    field: 'tagsCount',
    title: t('returns.shipments.tagsCount', 'Tags count')
  }, {
    sortable: true,
    field: 'expected',
    title: t('returns.shipments.expected', 'Expected')
  }, {
    sortable: true,
    field: 'status',
    title: t('returns.shipments.status', 'Status')
  }, {
    sortable: true,
    field: 'startDate',
    title: t('returns.shipments.startDate', 'Start date')
  }, {
    sortable: true,
    field: 'finishDate',
    title: t('returns.shipments.finishDate', 'Finish date')
  }];

  return (
    <div className="shipments-list">
      <Row>
        <Card body className="shipments-list-states-filters">
          <Row>
            {isWarehouse && (
              <StoreSelect
                user={user}
                updateTokens={updateTokens}
                selected={filters.outSiteId}
                onChange={(site) => setFilters({ ...filters, outSiteId: site })}
                siteId={siteId}
                label={t('returns.filters.expeditor','Expeditor')}
              />
            )}
            {!isWarehouse && (
              <WarehouseSelect
                user={user}
                updateTokens={updateTokens}
                selected={filters.inSiteId}
                onChange={(site) => setFilters({ ...filters, inSiteId: site })}
                siteId={siteId}
                label={t('returns.filters.destination','Destination')}
              />
            )}
            <DaterangeFilter
              controlId="filter-date"
              label={t('returns.filters.date', 'Date')}
              valueFrom={filters.startDateFrom}
              valueTo={filters.startDateTo}
              onChange={(startDateFrom, startDateTo) => setFilters({
                ...filters,
                startDateFrom,
                startDateTo,
              })}
            />
            <SelectFilter
              filterOpts
              controlId={'select-status-id'}
              label={t('returns.filters.status','Status')}
              options={statuses}
              selected={filters.status}
              onChange={(v) => setFilters({...filters, status: v})}
            />
          </Row>
        </Card>
      </Row>
      {loading && <Loader />}
      {!loading && !error && filtered && (
        <>
          <table className="table table-hover">
            <thead className="thead-light tableHeader">
              <tr>
                {tableColumns.map((column, i) => (
                  <TableHeadCell
                    key={i}
                    field={column}
                    sort={sort}
                    setSort={setSort}
                  />
                ))}
                <th className="chevronHeader">
                  <i
                    className={`${!showAll ? 'vtmn-icon_arrow2_down' : 'vtmn-icon_arrow2_up'} clickableText`}
                    onClick={() => selectedShipment && setShowAll(!showAll)}
                  ></i>
                </th>
              </tr>
            </thead>
            <ShipmentsTableBody
              data={filtered}
              onClick={selectShipment}
            />
          </table>
          {showAll && (
            <TableFooter
              pagination={pagination}
              totals={totals}
              setPagination={setPagination}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ShipmentsList;
