import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DaterangeFilter from '../widgets/filters/DaterangeFilter';
import InputFilter from '../widgets/filters/InputFilter';
import FiltersPannel from '../widgets/filters/FiltersPannel';
import SelectColumns from '../widgets/filters/SelectColumns';
import StoreSelect from '../efficiencyDashboard/StoreSelect';
import CountryFilter from '../widgets/filters/CountryFilter';
import SelectFilter from '../widgets/filters/SelectFilter';
import StoreGroupSelect from '../widgets/StoreGroupSelect';
import { getStatusOptions, getRecordTypes } from './statuses';

const hasFilters = (filters) => {
  // check all filters except: create_date_from, create_date_to
  const keys = Object.keys(filters).filter(x => x !== 'unloading_start_date_from' && x !== 'unloading_start_date_to');
  const values = keys.map(k => filters[k]).filter(v => v);
  const filtered = values.length !== 0;
  return filtered;
}

const MainFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
  user,
  updateTokens,
}) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState(null);

  useEffect(() => {
    // compare with undefined because site_ids can be predefined
    if(group === undefined) {
      setFilters({ ...filters, site_ids: null });
      return;
    }
    if(group && group.stores.length) {
      setFilters({ ...filters, site_ids: group.stores.map(({ id }) => id) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <>
      <DaterangeFilter
        controlId="filter-start-date"
        label={t('efficiencyAdmin.columns.date', 'Date')}
        valueFrom={filters.unloading_start_date_from}
        valueTo={filters.unloading_start_date_to}
        onChange={(unloading_start_date_from, unloading_start_date_to) => setFilters({
          ...filters,
          unloading_start_date_from,
          unloading_start_date_to,
        })}
      />
      <CountryFilter
        user={user}
        updateTokens={updateTokens}
        selected={filters.country_id}
        onChange={(country_id) => setFilters({
          ...filters,
          country_id,
        })}
      />
      <StoreGroupSelect
        user={user}
        updateTokens={updateTokens}
        onChange={setGroup}
        countryId={filters.country_id}
        selected={group && group.id ? group.id : null}
        label={t('navbar.administrating.groupOfStores','Group of stores')}
      />
      <StoreSelect
        user={user}
        updateTokens={updateTokens}
        selected={filters.site_ids}
        onChange={(site_ids) => setFilters({ ...filters, site_ids: site_ids })}
        countryId={filters.country_id}
        label={t('efficiencyDashboard.filters.stores','Stores')}
        inverted={invertedFilters.site_ids}
        onInvert={() => setInvertedFilters({
          ...invertedFilters,
          site_ids: !invertedFilters.site_ids,
        })}
      />
      <InputFilter
        controlId="filter-delivery"
        label={t('efficiencyAdmin.columns.delivery', 'Delivery')}
        value={filters.delivery}
        onChange={(delivery) => setFilters({ ...filters, delivery })}
      />

    </>
  );
};

const AdditionalFilters = ({
  filters,
  setFilters,
  invertedFilters,
  setInvertedFilters,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InputFilter
        controlId="filter-leader"
        label={t('efficiencyAdmin.columns.leader', 'Leader')}
        value={filters.leader}
        onChange={(leader) => setFilters({ ...filters, leader })}
      />
      <SelectFilter
        controlId="filter-status"
        label={t('efficiencyAdmin.columns.status', 'Status')}
        options={getStatusOptions(t)}
        selected={filters.status}
        onChange={(status) => setFilters({ ...filters, status })}
      />
    </>
  );
};

const Filters = ({
  filters,
  setFilters,
  onClear,
  invertedFilters,
  setInvertedFilters,
  fields,
  fieldsSelection,
  setFieldsSelection,
  user,
  updateTokens,
  removeButton,
}) => {
  const { t } = useTranslation();
  const filtered = hasFilters(filters);
  return (
    <FiltersPannel
      filtered={filtered}
      onClear={onClear}
      mainFilters={
        <MainFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
          user={user}
          updateTokens={updateTokens}
        />
      }
      aditionalFilters={
        <AdditionalFilters
          filters={filters}
          setFilters={setFilters}
          invertedFilters={invertedFilters}
          setInvertedFilters={setInvertedFilters}
        />
      }
      selectColumns={
        <SelectColumns
          fields={fields}
          fieldsSelection={fieldsSelection}
          setFieldsSelection={setFieldsSelection}
        />
      }
      exportButton={removeButton}
      generalFilters={
         <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip
              placement="top"
              delay={{ show: 250, hide: 400 }}
              id={`admin-thumb-tooltip-warehouse`}
            >
              <div className="tooltip-for-types">
                <Trans i18nKey="efficiencyAdmin.tooltipForTypes">
                  Potential incorrect records are the one with of productivity
                  not in the 50 - 250 qty/hour/teammate range and where shelving duration is under a minute
                </Trans>
              </div>
            </Tooltip>
          }
        >
          <Form.Group className="record-types">
            <Form.Label>
              <span className="filter-label-text">
                {t('efficiencyAdmin.recordTypes.label', 'Type of records')}:
              </span>
            </Form.Label>
            <SelectFilter
              controlId="filter-potential-incorrect"
              options={getRecordTypes(t)}
              selected={filters.records_type}
              onChange={(records_type) => setFilters({
                ...filters,
                records_type,
              })}
            />
          </Form.Group>
        </OverlayTrigger>
      }
    />
  );
};

export default Filters;
