import React, { useEffect } from 'react';
import { Row, Card, Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import useTableRequest from '../../functions/useTableRequest';
import { formatDateTime } from '../../functions/formatDate';
import { handleApiResponse, handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import fetchWithJWT from '../../functions/fetchWithJWT';
import Loader from '../loader';
import SelectFilter from '../widgets/filters/SelectFilter';
import DaterangeFilter from '../widgets/filters/DaterangeFilter';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import getQueryString from '../../functions/getQueryString';
import { formatDateForBigQuery } from '../../functions/formatDate';
import { DataTable, TableFooter } from '../widgets/DataTable';
import '../../stylesheets/adminPreloadState.css';

const getPreloadStates = (user, updateTokens, { filters, pagination, sort }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters};
  if (formatedFilters.processed_date_from) {
    formatedFilters.processed_date_from = formatDateForBigQuery(formatedFilters.processed_date_from);
  }
  if (formatedFilters.processed_date_to) {
    formatedFilters.processed_date_to = formatDateForBigQuery(formatedFilters.processed_date_to);
  }
  if (formatedFilters.start_date_from) {
    formatedFilters.start_date_from = formatDateForBigQuery(formatedFilters.start_date_from);
  }
  if (formatedFilters.start_date_to) {
    formatedFilters.start_date_to = formatDateForBigQuery(formatedFilters.start_date_to);
  }
  if (formatedFilters.end_date_from) {
    formatedFilters.end_date_from = formatDateForBigQuery(formatedFilters.end_date_from);
  }
  if (formatedFilters.end_date_to) {
    formatedFilters.end_date_to = formatDateForBigQuery(formatedFilters.end_date_to);
  }

  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  });
  
  const url = `${process.env.REACT_APP_base_URL}/api/states/preloads?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const getAvailableStores = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/deployment`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const getPreloadCodes = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/states/preload/codes`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const statuses = [{
  label: 'In progress',
  name: 'In progress',
  id: 'in_progress',
}, {
  label: 'Ended',
  name: 'Ended',
  id: 'ended',
}];

const AdminPreloadState = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const initialFilters = {
    code: null,
    store: [],
    status: null,
  };
  const [{
    loading,
    data,
    error,
    filters,
    sort,
    pagination,
    totals,
  }, fetchPreloadStates, setFilters, setPagination, setSort] = useTableRequest(
    getPreloadStates, {
      initialState: {
        filters: initialFilters,
      }
    }
  );
  const [{
    loading: loadingStores,
    data: stores,
    error: errorStores,
  }, fetchAvailableStores] = useRequest(getAvailableStores);
  const [{
    loading: loadingPreloadCodes,
    data: preloadCodes,
    error: errorPreloadCodes,
  }, fetchPreloadCodes] = useRequest(getPreloadCodes);

  useEffect(() => {
    fetchPreloadStates(user, updateTokens, {
      filters,
      pagination,
      sort,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, sort]);
  useEffect(() => {
    fetchAvailableStores(user, updateTokens);
    fetchPreloadCodes(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = (stores && stores.map(store => ({
    label: `${store.id} - ${store.name}`,
    name: store.name,
    id: store.id,
  }))) || [];

  const columns = [{
    title: t('adminPreloadState.code', 'Code'),
    field: 'preloadCode',
    sortable: true,
  }, {
    title: t('adminPreloadState.siteId', 'Site Id'),
    field: 'siteId',
    sortable: true,
    width: 100,
  }, {
    title: t('adminPreloadState.startDate', 'Start date'),
    field: 'startDate',
    sortable: true,
    valueRenderer: (val) => val ? formatDateTime(new Date(val)) : null,
  }, {
    title: t('adminPreloadState.endDate', 'End date'),
    field: 'endDate',
    sortable: true,
    valueRenderer: (val) => val ? formatDateTime(new Date(val)) : null,
  }, {
    title: t('adminPreloadState.processedRows', 'Processed rows'),
    field: 'processedRows',
    sortable: true,
  },  {
    title: t('adminPreloadState.totalRows', 'Total rows'),
    field: 'totalRows',
    sortable: true,
  }, {
    title: t('adminPreloadState.lastDate', 'Last processed date'),
    field: 'lastProcessedDate',
    sortable: true,
    valueRenderer: (val) => val ? formatDateTime(new Date(val)) : null,
  }, {
    title: t('adminPreloadState.status', 'Status'),
    field: 'inProgress',
    width: 120,
    valueRenderer: (val) => val
      ? (<span className="stamp-in-progress">In progress</span>)
      : (<span className="stamp-ended">Ended</span>)
  },  {
    title: t('adminPreloadState.error', 'Error'),
    field: 'error',
    sortable: true,
    width: 200,
  }];
  
  return (
    <>
      <Row>
        <Card body className="preload-states-filters">
          <div className="states-filter-item">
            <SelectFilter
              multiple
              filterOpts
              controlId={'select-preload-code'}
              label={t('adminPreloadState.filters.code','Preload code')}
              options={preloadCodes}
              selected={filters.code}
              onChange={(v) => setFilters({...filters, code: v})}
              loading={loadingPreloadCodes}
              error={errorPreloadCodes}
            />
          </div>
          <div className="states-filter-item">
            <SelectFilter
              multiple
              filterOpts
              controlId={'select-store-id'}
              label={t('adminPreloadState.filters.store','Store')}
              options={options}
              selected={filters.store}
              onChange={(v) => setFilters({...filters, store: v})}
              loading={loadingStores}
              error={errorStores}
            />
          </div>
          <div className="states-filter-item">
            <SelectFilter
              filterOpts
              controlId={'select-status-id'}
              label={t('adminPreloadState.filters.status','Status')}
              options={statuses}
              selected={filters.status}
              onChange={(v) => setFilters({...filters, status: v})}
            />
          </div>
          <div className="daterange-filter-item">
            <DaterangeFilter
              controlId="filter-start-date"
              label={t('adminPreloadState.filters.startDate', 'Start date')}
              valueFrom={filters.start_date_from}
              valueTo={filters.start_date_to}
              onChange={(start_date_from, start_date_to) => setFilters({
                ...filters,
                start_date_from,
                start_date_to,
              })}
            />
          </div>
          <div className="daterange-filter-item">
            <DaterangeFilter
              controlId="filter-end-date"
              label={t('adminPreloadState.filters.endDate', 'End date')}
              valueFrom={filters.end_date_from}
              valueTo={filters.end_date_to}
              onChange={(end_date_from, end_date_to) => setFilters({
                ...filters,
                end_date_from,
                end_date_to,
              })}
            />
          </div>
          <div className="daterange-filter-item">
            <DaterangeFilter
              controlId="filter-processed-date"
              label={t('adminPreloadState.lastDate', 'Last processed date')}
              valueFrom={filters.processed_date_from}
              valueTo={filters.processed_date_to}
              onChange={(processed_date_from, processed_date_to) => setFilters({
                ...filters,
                processed_date_from,
                processed_date_to,
              })}
            />
          </div>
          <div className="switch-filter-item">
            <Form.Label>
              <Trans i18nKey="adminPreloadState.filters.allRecords">
                All records
              </Trans>
            </Form.Label>
            <SwitchFilter
              controlId="filter-stayed-at-warehouse"
              label={t('adminPreloadState.filters.hasError', 'Has error')}
              value={filters.has_error}
              onChange={() => setFilters({
                ...filters,
                has_error: !filters.has_error,
              })}
            />
          </div>
        </Card>
      </Row>
      {loading && <Loader />}
      {!loading && !error && data && (
        <DataTable
          rowKey={x => `${x.id}-${x.movementId}`}
          data={data}
          fields={columns}
          loading={loading}
          error={error}
          sort={sort}
          setSort={setSort}
        />
      )}
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </>
  );
};

export default AdminPreloadState;
