import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Form, InputGroup } from 'react-bootstrap';


const DaterangeFilter = ({
  controlId,
  label,
  valueFrom,
  valueTo,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group controlId={controlId} className="filter-item">
      <Form.Label>
        <span className="filter-label-text">
          {label}
          {': '}
        </span>
      </Form.Label>
      <InputGroup className="filter-date-pare">
        <Form.Control
          size="sm"
          placeholderText={t('filters.from', 'From')}
          isClearable
          as={DatePicker}
          selected={valueFrom}
          onChange={(nextValueFrom) => onChange(nextValueFrom, valueTo)}
          dateFormat="dd/MM/yyyy"
        />
        <Form.Control
          size="sm"
          placeholderText={t('filters.to', 'To')}
          isClearable
          as={DatePicker}
          selected={valueTo}
          onChange={(nextValueTo) => onChange(valueFrom, nextValueTo)}
          dateFormat="dd/MM/yyyy"
        />
      </InputGroup>
    </Form.Group>
  );
}

export default DaterangeFilter;
