import React, { useEffect, useState } from 'react';
import { Button, Form, Modal , InputGroup} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import '../../../../stylesheets/warehouseContenantsControl.css';

const QtyModal = ({ value, onChange, show, onHide }) => {
  const [stateValue, setStateValue] = useState();

  useEffect(() => setStateValue(value), [value]);
  const onSave = () => {
    onChange(stateValue);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="warehouseContenantsControl.ipQty">IP+ quantity</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0" >
        <InputGroup id="qty-value" className="input-new-qty mb-3">
          <Form.Label column lg="4" className="text-lg-right">
            <Trans i18nKey="warehouseContenantsControl.foundedQty">
              Found quantity
            </Trans>
            {': '}
          </Form.Label>
          <Button className="founded-qty-edit-btn" onClick={() => setStateValue(stateValue - 1)}>-</Button>
          <Form.Control
            step={1}
            type="number"
            pattern="[0-9]*"
            value={stateValue}
            onChange={(event) => { setStateValue(event.target.value); }}
          />
          <Button className="founded-qty-edit-btn" onClick={() => setStateValue(stateValue + 1)}>+</Button>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button className="m-0" variant="primary" onClick={onSave}>
          <Trans i18nKey="shared.ok">OK</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QtyModal;
