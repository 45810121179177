import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import ProgressDetailsContent from './ProgressDetailsContent';

const ProgressDetailsModal = ({
  indicator,
  currency,
  show,
  onHide,
  delivery,
  sport,
}) => {
  const { t } = useTranslation();
  let title = t(
    'liveStoreReception.progressDetails.title',
    {
      defaultValue: 'Delivery {{delivery}} - {{sport}}',
      delivery,
      sport,
    }
  );

  return (
    <Modal
      className="readingRateModal"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-0 pr-0">
        <ProgressDetailsContent
          indicator={indicator}
          currency={currency}
        />
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Button variant="secondary" onClick={onHide}>
          <Trans i18nKey="liveStoreReception.readingRateModal.close">Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProgressDetailsModal;
