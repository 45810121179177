import React, { Component } from 'react';
import _ from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Modal, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import "react-datepicker/dist/react-datepicker.css";
import { formatDateForBigQuery, formatDateTimeForBigQuery } from "../functions/formatDate";
import { TableFooter } from './widgets/DataTable';
import fetchWithJWT from '../functions/fetchWithJWT';
import handleApiResponse from '../functions/handleApiResponse';
import StayedAtWarehouseLabel from './warehouse/StayedAtWarehouseLabel';
import "../stylesheets/reportingStoreReception.css";
import "../stylesheets/searchInAStore.css";
import "../stylesheets/item.css";
import logoRFID from './RFID-Icon.svg';

const getTotals = (item, discrepancies) => {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const totalExpectedQties = discrepancies.map(discrepancy => discrepancy.qty_confirmed).reduce(reducer, 0);
  const totalReadByStoreGate = discrepancies.map(discrepancy => discrepancy.qtyReadByStoreGate).reduce(reducer, 0);
  const totalReadByWarehouseGate = discrepancies.map(discrepancy => Math.min(discrepancy.qtyReadByWarehouseGate, discrepancy.qty_confirmed)).reduce(reducer, 0);
  const totalUnexpected = discrepancies.map(discrepancy => discrepancy.unexpectedQty).reduce(reducer, 0);
  return {
    totalExpectedQties: totalExpectedQties,
    totalReadByStoreGate: totalReadByStoreGate,
    totalReadByWarehouseGate: totalReadByWarehouseGate,
    totalUnexpected: totalUnexpected
  }
}

const getWrongSizes = (discrepancies) => {
  if (_.some(discrepancies, 'wrong_sizes')) {
    const wrongSizes = [];
    discrepancies.forEach(d => {
      d.wrong_sizes.forEach(dw => {
        const hasItem = wrongSizes.findIndex(x => x.item === dw.item) !== -1;
        if (!hasItem) {
          wrongSizes.push(dw);
        }
      });
    });
    return wrongSizes;
  }
}

class Item extends Component {
  constructor(props){
    super(props);
    this.state = {
      showModal: false,
    }
  }

  diplayDiscrepancyDetails = (discrepancy, discrepancyType) => {
    const receptionDate = new Date(discrepancy.reception_date.value);
    const formattedReceptionDate = receptionDate.toLocaleDateString('ru');
    const isWrongSize = discrepancyType === 'wrong_size';
    const hasTransactionDate = discrepancy.transaction_date !== null;

    const wrongSizes = isWrongSize && discrepancy.wrong_sizes
      ? discrepancy.wrong_sizes.map(x => `${x.item} - ${x.item_lib}`)
      : [];

    return (
      <tr key={discrepancy.insertId}>
        <td>{formattedReceptionDate}</td>
        <td>{discrepancy.delivery}</td>
        {(discrepancyType === 'expedition' || discrepancyType === 'transport') ? (
          <>
            <td>{discrepancy.qty_confirmed}</td>
            <td>{discrepancy.qtyReadBeforeWarehouseGate}</td>
            <td>{discrepancy.qtyReadByStoreGate}</td>
          </>
        ) : (
          <td>{discrepancy.unexpectedQty}</td>
        )}
        {hasTransactionDate ? (
          <>
            <td><i className="discrepancyValidated mx-auto vtmn-icon_decision" alt="discrepancyValidated icon"></i></td>
            <td>{discrepancy.realized_movement}</td>
            <td>{discrepancy.user}</td>
          </>
        ) : (
          <>
            <td><i className="discrepancyNotValidated mx-auto vtmn-icon_decision" alt="discrepancyNotValidated icon"></i></td>
            <td></td>
            <td></td>
          </>
        )}
        {isWrongSize ? (
          <td>{wrongSizes.map(x => <div key={x}>{x}</div>)}</td>
        ) : null}
      </tr>
    );
  }

  displayRFID = (discrepancy) => {
    if (discrepancy.article_flag !== 'P' && discrepancy.article_flag !== 'L'){
      return(
        <img className="not-rfid" src={logoRFID} alt="RFID-icon"></img>
      )
    }
  }

  displayValidated = (discrepancies) => {
    const discrepanciesValidated = discrepancies.filter(discrepancy => discrepancy.movementId !== null).length;
    if (discrepanciesValidated === discrepancies.length){
      return(
        <i className="discrepancyValidated vtmn-icon_decision" alt="discrepancyValidated icon"></i>
      )
    } else if (discrepanciesValidated > 0){
      return(
        <div className="row discrepancyValidated">
          <i className="vtmn-icon_decision" alt="discrepancyValidated icon"></i>
          <div className="validationRatio">{discrepanciesValidated}/{discrepancies.length}</div>
        </div>
      )
    }
  }

  onModalClose = () => this.setState({ showModal: false });

  itemModal = (discrepancies, discrepancyType, picture, price, currency) => {
    const { t } = this.props;
    const { showModal } = this.state;
    const first = discrepancies[0];
    const sortedDiscrepancies = discrepancies.sort((a, b) => new Date(b.reception_date.value) - new Date(a.reception_date.value));

    return(
      <Modal
        show={showModal}
        onHide={this.onModalClose}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        className="reportingProductModal"
      >
        <div className="modal-header">
          <img src={picture} alt="item" />
          <h5 className="modal-title">{first.item} - {first.item_lib}</h5>
          <button type="button" className="close" onClick={this.onModalClose} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body row">
          <table className="table table-hover card-text tableReportingModal">
            <thead>
              <tr>
                <th scope="col">
                  {t('storeReception.modal.date', 'Date')}
                </th>
                <th scope="col">
                  {t('storeReception.modal.delivery', 'Delivery')}
                </th>
                {(discrepancyType === 'expedition' || discrepancyType === 'transport') ? (
                  <>
                    <th
                      scope="col"
                      title={t(
                        'storeReception.modal.shippingListTitle',
                        'Tags expected by shipping list'
                      )}
                    >
                      {t('storeReception.modal.shippingList', 'Shipping list')}
                    </th>
                    <th
                      scope="col"
                      title={t(
                        'storeReception.modal.readInWarehouseTitle',
                        'Tags security activated at warehouse'
                      )}
                    >
                      {t('storeReception.modal.readInWarehouse', 'Read in warehouse')}
                    </th>
                    <th
                      scope="col"
                      title={t(
                        'storeReception.modal.storeGateTitle',
                        'Tags read in store'
                      )}
                    >
                      {t('storeReception.modal.storeGate', 'Store inbound')}
                    </th>
                  </>
                ) : (
                  <th
                    scope="col"
                    title={t(
                      'storeReception.modal.unexpectedTitle',
                      'Unexpected tags read in store'
                    )}
                  >
                    {t('storeReception.modal.unexpected', 'Unexpected')}
                  </th>
                )}
                <th scope="col"></th>
                <th
                  scope="col"
                  title={t(
                    'storeReception.modal.validatedTitle',
                    'Realized by store movements'
                  )}
                >
                  {t('storeReception.modal.validated', 'Validated')}
                </th>
                <th scope="col">
                  {t('storeReception.modal.user', 'User')}
                </th>
                {discrepancyType === 'wrong_size' && (
                  <th scope="col">
                    {t('storeReception.modal.wrongSizes', 'Missing items')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {sortedDiscrepancies.map(discrepancy => this.diplayDiscrepancyDetails(discrepancy, discrepancyType))}
            </tbody>
          </table>
        </div>

      </Modal>
    )
  }

  renderWrongSize = (match) => {
    const { t } = this.props;
    const titleStart = t('storeReception.item.unexpectedMatchesSize', 'Same missing quantity for another size of this model');
    const title = `${titleStart} (${match.item} - ${match.item_lib})`;

    return (
      <div className="sizeMatchIconWrapper" key={match.item}>
        <i className="sizeMatchIcon vtmn-icon_notif_alert"
          title={title}
        ></i>
      </div>
    );
  }

  renderWrongSizes = () => {
    const { discrepancyType, discrepancies } = this.props;
    if (discrepancyType === 'wrong_size' && discrepancies && discrepancies.length) {
      const wrongSizes = getWrongSizes(discrepancies);
      return wrongSizes.map(this.renderWrongSize);
    }
    return null;
  }

  render() {
    const { t, discrepancyType, discrepancies } = this.props;
    let picture = null;
    const pictureDiscrepancy = discrepancies.find(discrepancy => discrepancy.pixlId !== null);
    if (pictureDiscrepancy !== undefined){
      picture = "https://contents.mediadecathlon.com/p" + pictureDiscrepancy.pixlId + "/a.jpg?f=60x60";
    }
    let price = null;
    let currency = null;
    const priceDiscrepancy = discrepancies.find(discrepancy => discrepancy.price !== null);
    if (priceDiscrepancy !== undefined){
      price = priceDiscrepancy.price;
      currency = priceDiscrepancy.currency;
    }
    const totals = getTotals(discrepancies[0].item, discrepancies);
    let item_lib = discrepancies[0].item_lib;
    if (item_lib !== null){
      item_lib = item_lib.toLowerCase()
    }

    function totalLine(discrepancyType, totalExpectedQties, totalReadByStoreGate, totalReadByWarehouseGate, totalUnexpected){
      switch (discrepancyType){
        case 'transport':
          return(
            <div className="card-text mr-auto">
              {discrepancies[0].item}
              {' : '}
              {t('storeReception.item.totalInWarehouse', 'total in warehouse')}
              {' '}
              {totalReadByWarehouseGate}
              {' - '}
              {t('storeReception.item.read', 'read')}
              {' '}
              {totalReadByStoreGate}
            </div>
          )
        case 'unexpected':
          return(
            <div className="card-text mr-auto">
              {discrepancies[0].item}
              {' : '}
              {t('storeReception.item.totalUnexpected', 'total unexpected')}
              {' '}
              {totalUnexpected}</div>
          )
        case 'wrong_size':
          return(
            <div className="card-text mr-auto">
              {discrepancies[0].item}
              {' : '}
              {t('storeReception.item.totalWrongSize', 'total wrong size')}
              {' '}
              {totalUnexpected}</div>
          )
        default: //expedition
          return(
            <div className="card-text mr-auto">
              {discrepancies[0].item}
              {' : '}
              {t('storeReception.item.totalExpected', 'total expected')}
              {' '}
              {totalExpectedQties}
              {' - '}
              {t('storeReception.item.read', 'read')}
              {' '}
              {totalReadByStoreGate}</div>
          )
      }
    }

    return(
      <div key={discrepancies[0].item}>
        <div className="card itemDiscrepancy" onClick={() => this.setState({ showModal: true }) }>
          <div className="row">
            {totalLine(discrepancyType, totals.totalExpectedQties, totals.totalReadByStoreGate, totals.totalReadByWarehouseGate, totals.totalUnexpected)}
            {this.displayRFID(discrepancies[0])}
            {this.renderWrongSizes()}
            {this.displayValidated(discrepancies)}
          </div>
          <div className="row">
            <div className="col-3 itemImage">
              <p className="price">{price} <span className="currency">{currency}</span></p>
              <img src={picture} alt="item" />
            </div>
            <div className="col-9 card-text">
              <div className="card-text">{item_lib}</div>
            </div>
          </div>
        </div>
        {this.itemModal(discrepancies, discrepancyType, picture, price, currency)}
      </div>
    );
  }
}


class ReportingStoreReception extends Component {
  constructor(props){
    super(props);
    this.titleMap = {
      expedition: 'expedition',
      transport: 'transport',
      unexpected: 'unexpected',
      wrong_size: 'wrong size',
    };
    this.state = {
      expedition: [],
      transport: [],
      unexpected: [],
      wrong_size: [],
      activeList: 'expedition',
      includeNonRFIDItems: false,
      onlyValidatedDiscrepancies: false,
      onlyStayedAtWarehouse: false,
      selectedDepartments: null,
      isLoading: true,
      googleCredentials: {
        token: null,
        validUntil: null
      },
      exportAttemptNumber: 0,
      modalIDVisible: null,
      expeditionPagination: {
        page: 0,
        size: 20,
      },
      transportPagination: {
        page: 0,
        size: 20,
      },
      unexpectedPagination: {
        page: 0,
        size: 20,
      },
      wrong_sizePagination: {
        page: 0,
        size: 20,
      },
      totals: {
        expedition: {
          items: 0,
          pages: 0,
        },
        transport: {
          items: 0,
          pages: 0,
        },
        unexpected: {
          items: 0,
          pages: 0,
        },
        wrong_size: {
          items: 0,
          pages: 0,
        },
      },
      search: '',
    }
  }

  componentDidMount(){
    window.addEventListener('storage', this.onExportAsked);

    const now = new Date();
    const start = new Date();
    start.setDate(start.getDate() - 1);
    this.getDiscrepancies(start, now);
  }

  componentDidUpdate(prevProps) {
    const prevStoreId = prevProps.match.params.store;
    const storeId = this.props.match.params.store;

    if (storeId !== prevStoreId) {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      this.setState({
        expedition: [],
        transport: [],
        unexpected: [],
        wrong_size: [],
        startDate,
        endDate,
        isLoading: true,
        modalIDVisible: null,
        expeditionPagination: {
          page: 0,
          size: 20,
        },
        transportPagination: {
          page: 0,
          size: 20,
        },
        unexpectedPagination: {
          page: 0,
          size: 20,
        },
        wrong_sizePagination: {
          page: 0,
          size: 20,
        },
        totals: {
          expedition: {
            items: 0,
            pages: 0,
          },
          transport: {
            items: 0,
            pages: 0,
          },
          unexpected: {
            items: 0,
            pages: 0,
          },
          wrong_size: {
            items: 0,
            pages: 0,
          },
        },
        search: '',
      });
      this.getDiscrepancies(startDate, endDate);
    }
  }

  onExportAsked = (event) => {
    if (event.key === 'credentials' && event.newValue !== null){
      const credentials = JSON.parse(event.newValue)
      this.createSheet(credentials.token)
      this.setState({
        googleCredentials: credentials
      })
    }
  }

  componentWillUnmount(){
    window.removeEventListener('storage', this.onExportAsked);
  }

  sortedDiscrepancies = (discrepancies, discrepancyType) => {
    function filterDiscrepancies(item, discrepancies){
      return discrepancies.filter(discrepancy => item === discrepancy.item)
    }
    const uniqueItems = [...new Set(discrepancies.map(discrepancy => discrepancy.item))];
    uniqueItems.sort((a, b) => {
      const totalsB = getTotals(b, filterDiscrepancies(b, discrepancies));
      const totalsA = getTotals(a, filterDiscrepancies(a, discrepancies));
      let diff = 0;
      switch (discrepancyType) {
        case 'transport':
          diff = (totalsB.totalReadByWarehouseGate - totalsB.totalReadByStoreGate)
            - (totalsA.totalReadByWarehouseGate - totalsA.totalReadByStoreGate);
          break;
        case 'unexpected':
        case 'wrong_size':
          diff = totalsB.totalUnexpected - totalsA.totalUnexpected;
          break;
        default://expedition
          diff = (totalsB.totalExpectedQties - totalsB.totalReadByStoreGate)
            - (totalsA.totalExpectedQties - totalsA.totalReadByStoreGate);
      }
      return diff;
    });
    discrepancies.sort((a, b) => uniqueItems.indexOf(a.item) - uniqueItems.indexOf(b.item));
  }

  calcTotals = (allDiscrepancies, pagination) => {
    let discrepancies = allDiscrepancies;
    discrepancies = this.filterIncludeNonRFIDItems(discrepancies);
    discrepancies = this.filterOnlyValidatedDiscrepancies(discrepancies);
    discrepancies = this.filterOnlyStayedAtWarehouse(discrepancies);
    discrepancies = this.filterSelectedDepartments(discrepancies);
    discrepancies = this.filterSearch(discrepancies);

    const uniqueItems = [...new Set(discrepancies.map(discrepancy => discrepancy.item))];
    return {
      items: uniqueItems.length,
      pages: Math.max(1, Math.ceil(uniqueItems.length / pagination.size)),
    };
  }

  recalcTotals = () => {
    const { activeList, totals } = this.state;
    const discrepancies = this.state[activeList];
    const paginationKey = `${activeList}Pagination`;
    const pagination = this.state[paginationKey] || {};
    this.setState({
      totals: {
        ...totals,
        [activeList]: this.calcTotals(discrepancies, pagination),
      }
    });
  }

  getDiscrepancies = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return;
    }
    const storeId = this.props.match.params.store;
    const { user, updateTokens } = this.props;
    const { token, refreshToken, tokenExpireDate } = user;
    const endDateBigQuery = formatDateForBigQuery(endDate);
    const startDateBigQuery = formatDateTimeForBigQuery(startDate);
    const url = `${process.env.REACT_APP_base_URL}/api/stores/${storeId}/discrepancies?startDate=${startDateBigQuery}&endDate=${endDateBigQuery}`;
    return fetchWithJWT(url, {
      method: 'POST',
      body: JSON.stringify({}),
      jwtOpts: {
        token,
        refreshToken,
        tokenExpireDate,
        updateTokens,
      }
    })
    .then(handleApiResponse)
    .then(discrepancies => {
      const {
        expeditionPagination,
        transportPagination,
        unexpectedPagination,
        wrong_sizePagination,
      } =  this.state;
      if (discrepancies) {
        this.sortedDiscrepancies(discrepancies.expedition, 'expedition');
        this.sortedDiscrepancies(discrepancies.transport, 'transport');
        this.sortedDiscrepancies(discrepancies.unexpected, 'unexpected');
        this.sortedDiscrepancies(discrepancies.wrong_size, 'wrong_size');

        this.setState({
          expedition: discrepancies.expedition,
          transport: discrepancies.transport,
          unexpected: discrepancies.unexpected,
          wrong_size: discrepancies.wrong_size,
          isLoading: false,
          startDate: startDate,
          endDate: endDate,
          expeditionPagination: {
            page: 0,
            size: expeditionPagination.size,
          },
          transportPagination: {
            page: 0,
            size: transportPagination.size,
          },
          unexpectedPagination: {
            page: 0,
            size: unexpectedPagination.size,
          },
          wrong_sizePagination: {
            page: 0,
            size: wrong_sizePagination.size,
          },
          totals: {
            expedition: this.calcTotals(discrepancies.expedition, expeditionPagination),
            transport: this.calcTotals(discrepancies.transport, transportPagination),
            unexpected: this.calcTotals(discrepancies.unexpected, unexpectedPagination),
            wrong_size: this.calcTotals(discrepancies.wrong_size, wrong_sizePagination),
          },
          search: '',
        })
      }
    })
    .catch(error => {
      console.warn(`Error in getDiscrepancies for store ${storeId} between dates ${startDateBigQuery} and ${endDateBigQuery}`);
    })
  }

  switchSelectedList = (selection) => {
    this.setState({
      activeList: selection
    })
  }

  filterSearch = (discrepancies) => {
    let filtered = discrepancies;
    if (this.state.search) {
      const searchLower = this.state.search.toLowerCase();
      filtered = discrepancies.filter((discrepancy) => {
        const toSearch = `${discrepancy.item} ${discrepancy.item_lib}`.toLowerCase();
        const matches = toSearch.indexOf(searchLower) !== -1;
        return matches;
      });
    }
    return filtered;
  }

  filterIncludeNonRFIDItems = (discrepancies) => {
    let filtered = discrepancies;
    if (!this.state.includeNonRFIDItems){
      filtered = discrepancies.filter(discrepancy => discrepancy.article_flag === 'P' || discrepancy.article_flag === 'L')
    }
    return filtered;
  }

  filterOnlyValidatedDiscrepancies = (discrepancies) => {
    let filtered = discrepancies;
    if (this.state.onlyValidatedDiscrepancies){
      filtered = discrepancies.filter(discrepancy => discrepancy.movementId !== null)
    }
    return filtered;
  }

  filterOnlyStayedAtWarehouse = (discrepancies) => {
    let filtered = discrepancies;
    const { activeList, onlyStayedAtWarehouse } = this.state
    if (onlyStayedAtWarehouse && activeList === 'expedition') {
      filtered = discrepancies.filter(discrepancy => (
        discrepancy.movementId !== null
        && discrepancy.qtyReadBeforeWarehouseGate
        && discrepancy.qtyReadByStoreGate < discrepancy.qty_confirmed
        && discrepancy.qtyReadByWarehouseGate < discrepancy.qty_confirmed
        && discrepancy.qtyReadBeforeWarehouseGate > discrepancy.qtyReadByStoreGate
        && discrepancy.qtyReadBeforeWarehouseGate > discrepancy.qtyReadByWarehouseGate
      ));
    }
    return filtered;
  }

  filterOnFlagAndValidated = (discrepancyType) => {
    let discrepancies = this.state[discrepancyType];
    discrepancies = this.filterIncludeNonRFIDItems(discrepancies);
    discrepancies = this.filterOnlyValidatedDiscrepancies(discrepancies);
    return discrepancies
  }

  filterSelectedDepartments = (discrepancies) => {
    let filtered = discrepancies;
    if (this.state.selectedDepartments !== null) {
      if (this.state.selectedDepartments.length > 0) {
        filtered = discrepancies.filter(discrepancy => this.state.selectedDepartments.map(department => department.value).includes(discrepancy.department_label))
      }
    }
    return filtered;
  }

  applyAllFilters = (discrepancyType) => {
    let discrepancies = this.filterOnFlagAndValidated(discrepancyType);
    discrepancies = this.filterSelectedDepartments(discrepancies);
    discrepancies = this.filterSearch(discrepancies);
    discrepancies = this.filterOnlyStayedAtWarehouse(discrepancies);
    return discrepancies;
  }

  applyPagination = (items, discrepancyType) => {
    const paginationKey = `${discrepancyType}Pagination`;
    const pagination = this.state[paginationKey];
    if (pagination) {
      const from = pagination.page * pagination.size;
      const to = (pagination.page + 1) * pagination.size;
      return items.slice(from, to);
    }
    return items;
  }

  changeStartDate = (date) => {
    this.setState({
      startDate: date,
      isLoading: true
    })
    this.getDiscrepancies(date, this.state.endDate)
  }

  changeEndDate = (date) => {
    this.setState({
      endDate: date,
      isLoading: true
    })
    this.getDiscrepancies(this.state.startDate, date)
  }

  handleSearchChange = (event) => {
    this.setState({
      search: event.target.value
    }, this.recalcTotals);
  }

  handleChangeCheckboxRFID = (event) => {
    this.setState({
      includeNonRFIDItems: event.target.checked
    }, this.recalcTotals)
  }

  handleChangeCheckboxValidated = (event) => {
    this.setState({
      onlyValidatedDiscrepancies: event.target.checked
    }, this.recalcTotals)
  }

  handleChangeCheckboxStayedAtWarehouse = (event) => {
    this.setState({
      onlyStayedAtWarehouse: event.target.checked
    }, this.recalcTotals)
  }

  handleChangeDepartments = (selectedDepartments) => {
    this.setState({ selectedDepartments }, this.recalcTotals);
  };

  loader = () => {
    if (this.state.isLoading){
      return(
        <div className="row">
          <Loader
             type="TailSpin"
             color="#00BFFF"
             height={50}
             width={50}
          />
          <div>
            <Trans i18nKey="storeReception.loading">Loading</Trans>
          </div>
        </div>
      )
    }
  }

  displayListHeader = () => {
    const baseClass="nav-item nav-link clickableText tabHeader";
    let transportClass = baseClass;
    let unexpectedClass = baseClass;
    let wrongSizeClass = baseClass;
    let expeditionClass = baseClass;
    switch(this.state.activeList){
      case "unexpected":
        unexpectedClass += " active";
        break;
      case "wrong_size":
        wrongSizeClass += " active";
        break;
      case "transport":
        transportClass += " active";
        break;
      default:
        expeditionClass += " active";
    }
    return(
      <div className="card-header list-header">
        <ul className="nav nav-tabs card-header-tabs">
          <li className={expeditionClass} onClick={() => this.switchSelectedList("expedition")}>
            <Trans i18nKey="storeReception.expedition">Expedition</Trans>
          </li>
          <li className={transportClass} onClick={() => this.switchSelectedList("transport")}>
            <Trans i18nKey="storeReception.transport">Transport</Trans>
          </li>
          <li className={unexpectedClass} onClick={() => this.switchSelectedList("unexpected")}>
            <Trans i18nKey="storeReception.unexpected">Unexpected</Trans>
          </li>
          <li className={wrongSizeClass} onClick={() => this.switchSelectedList("wrong_size")}>
            <Trans i18nKey="storeReception.wrongSize">Wrong size</Trans>
          </li>
        </ul>
      </div>
    )
  }

  setPagination = (nextPagination) => {
    const { activeList, totals } = this.state;
    const paginationKey = `${activeList}Pagination`;
    const nextTotals = {
      ...totals,
      [activeList]: {
        items: totals[activeList].items,
        pages: Math.max(1, Math.ceil(totals[activeList].items / nextPagination.size)),
      },
    };
    this.setState({
      [paginationKey]: nextPagination,
      totals: nextTotals,
    });
  }

  displayList = () => {
    const { activeList } = this.state;
    const paginationKey = `${activeList}Pagination`;
    const pagination = this.state[paginationKey] || {};
    const totals = this.state.totals[activeList];
    return(
      <div>
        <div className="card">
          {this.displayListHeader()}
          <div className="card-body">
            <TableFooter
              pagination={pagination}
              totals={totals}
              setPagination={this.setPagination}
            />
            {this.displayListContent(activeList)}
          </div>
        </div>
      </div>
    )
  }

  displayListContent = (discrepancyType) => {
    switch (discrepancyType){
      case 'transport':
        return(
          <div className="card-columns">
            {this.displayTotal('transport')}
            {this.displayContent('transport')}
          </div>
        )
      case 'unexpected':
        return(
          <div className="card-columns">
            {this.displayTotal('unexpected')}
            {this.displayContent('unexpected')}
          </div>
        )
      case 'wrong_size':
        return(
          <div className="card-columns">
            {this.displayTotal('wrong_size')}
            {this.displayContent('wrong_size')}
          </div>
        )
      default:
        return(
          <div className="card-columns">
            {this.displayTotal('expedition')}
            {this.displayContent('expedition')}
          </div>
        )
    }
  }

  displayContent = (discrepancyType) => {
    const { t, user, updateTokens } = this.props;
    const discrepancies = this.applyAllFilters(discrepancyType);
    let uniqueItems = [...new Set(discrepancies.map(discrepancy => discrepancy.item))];
    uniqueItems = this.applyPagination(uniqueItems, discrepancyType);
    return (
      <div>
        {uniqueItems.map(item => (
          <Item
            key={item}
            t={t}
            user={user}
            updateTokens={updateTokens}
            discrepancyType={discrepancyType}
            discrepancies={discrepancies.filter(discrepancy => discrepancy.item === item)}
          />
        ))}
      </div>
    );
  }

  displayParameters = () => {
    const expeditionDepartments = this.filterOnFlagAndValidated('expedition').map(discrepancy => discrepancy.department_label);
    const transportDepartments = this.filterOnFlagAndValidated('transport').map(discrepancy => discrepancy.department_label);
    const unexpectedDepartments = this.filterOnFlagAndValidated('unexpected').map(discrepancy => discrepancy.department_label);
    const wrongSizeDepartments = this.filterOnFlagAndValidated('wrong_size').map(discrepancy => discrepancy.department_label);
    const departments = [...new Set(
      expeditionDepartments
      .concat(transportDepartments)
      .concat(unexpectedDepartments)
      .concat(wrongSizeDepartments)
    )];
    const options = departments.map(department => {
      if(department !== null){
        return {
          value: department,
          label: department.toLowerCase()
        }
      } else {
        return {
          value: department,
          label: department
        }
      }
    });
    const { selectedDepartments, activeList } = this.state;
    const { t } = this.props;
    const animatedComponents = makeAnimated();
    return (
      <div className="card params">
        <table>
          <tbody>
            <tr>
              <td className="paramsFirstColumn">
                <div className="card-text paramsText">
                  <Trans i18nKey="storeReception.filters.from">From</Trans>
                </div>
              </td>
              <td>
                <div className="card-text datepicker">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.changeStartDate}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    minDate={new Date('2020-06-04')}
                  />
                </div>
              </td>
              <td>
                <div className="row card-body">
                  <div className="card-text">
                    <Trans i18nKey="storeReception.filters.includeNoRFID">Include no RFID items</Trans>
                  </div>
                  <input className="checkbox" type="checkbox" onChange={this.handleChangeCheckboxRFID} checked={this.state.includeNonRFIDItems}/>
                </div>
              </td>
            </tr>
            <tr>
              <td className="paramsFirstColumn">
                <div className="card-text paramsText">
                  <Trans i18nKey="storeReception.filters.to">To</Trans>
                </div>
              </td>
              <td>
                <div className="card-text datepicker">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.changeEndDate}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    minDate={new Date('2020-06-04')}
                  />
                </div>
              </td>
              <td>
                <div className="row card-body">
                  <div className="card-text">
                    <Trans i18nKey="storeReception.filters.onlyValidated">Only validated</Trans>
                  </div>
                  <input className="checkbox" type="checkbox" onChange={this.handleChangeCheckboxValidated} checked={this.state.onlyValidatedDiscrepancies}/>
                </div>
              </td>
            </tr>
            {activeList === 'expedition' && (
              <tr>
                <td colSpan={3}>
                  <div className="row card-body">
                    <StayedAtWarehouseLabel validated />
                    <input
                      className="checkbox"
                      type="checkbox"
                      onChange={this.handleChangeCheckboxStayedAtWarehouse}
                      checked={this.state.onlyStayedAtWarehouse}
                    />
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2} className="discrepancySearch card-text">
                <Form.Control
                  placeholder={t('storeReception.filters.search', 'Search...')}
                  type="text"
                  value={this.state.search || ''}
                  onChange={this.handleSearchChange}
                />
              </td>
              <td className="departmentPicker card-text">
                <Select
                  options={options}
                  isMulti={true}
                  value={selectedDepartments}
                  onChange={this.handleChangeDepartments}
                  isSearchable={true}
                  placeholder={t('storeReception.filters.allSports', 'All sports')}
                  components={animatedComponents}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <button type="button" className="btn exportButton" onClick={this.onExport}>
                  <Trans i18nKey="storeReception.filters.export">Export</Trans>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  onExport = () => {
    const VALID_LIMIT_MS = 20000;
    const { googleCredentials } = this.state;
    if (googleCredentials && googleCredentials.token && googleCredentials.validUntil) {
      const validUntil = new Date(googleCredentials.validUntil);
      const now = new Date();
      const msLeft = validUntil.getTime() - now.getTime();
      if (msLeft > VALID_LIMIT_MS) {
        this.createSheet(googleCredentials.token);
        return;
      }
    }

    this.exportOAuth();
  }

  exportOAuth = () => {
    const previousExportAttemptNumber = this.state.exportAttemptNumber;
    this.setState({
      exportAttemptNumber: previousExportAttemptNumber + 1
    })
    const baseUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const client_id = process.env.REACT_APP_spreadsheet_clientId;
    const redirect_uri = `${process.env.REACT_APP_fedID_redirect_URI}/googleOAuth`;
    const scope = 'https://www.googleapis.com/auth/spreadsheets';
    window.open(`${baseUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=${scope}`, "_blank")
  }

  displayAlertPopUpAreBlocked = () => {
    if (this.state.exportAttemptNumber > 1){
      return (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>
            <Trans i18nKey="storeReception.trouble">Experiencing trouble?</Trans>
          </strong>
          {' '}
          <Trans i18nKey="storeReception.troubleHint">
            It seems you are having difficulties to export. Hint: you need pop-up to be allowed in your browser.
          </Trans>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    }
  }

  createSheet = (token) => {
    const discrepancyTypes = ['expedition', 'transport', 'unexpected', 'wrong_size'];
    return fetch("https://sheets.googleapis.com/v4/spreadsheets", {
      method:'POST',
      headers:{"Authorization": "Bearer " + token},
      body: JSON.stringify({
        properties: {
          title: `${this.props.match.params.store}-${this.state.startDate.toLocaleDateString('fr')} to ${this.state.endDate.toLocaleDateString('fr')}`
        },
        sheets: discrepancyTypes.map(discrepancyType => ({
          properties: {
            title: this.titleMap[discrepancyType],
          },
        })),
      })
    })
    .then(result => result.json())
    .then(spreadsheet => {
      window.open(`https://docs.google.com/spreadsheets/d/${spreadsheet.spreadsheetId}`);
      return Promise.all(discrepancyTypes.map(discrepancyType => this.addDataToSheet(spreadsheet.spreadsheetId, token, discrepancyType)))
    })
    .catch(error => {
      console.warn("There was an error when creating the spreadsheet : " + error);
    })
  }

  getSpreadSheetLastColumn = (colCount) => {
    const SPREADSHEET_KEYS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    let colNumber = colCount;
    let reminder = colCount;
    const letterCount = SPREADSHEET_KEYS.length;
    while (colNumber > 0) {
      reminder = colNumber % letterCount;
      if (reminder === 0) {
        result = 'Z' + result;
        colNumber = Math.floor(colNumber / letterCount) - 1;
      } else {
        result = SPREADSHEET_KEYS[reminder - 1] + result;
        colNumber = Math.floor(colNumber / letterCount);
      }
    }
    return result;
  }

  getTabProperty = (discrepancyType, colCount) => {
    return {
      discrepancyType,
      title: this.titleMap[discrepancyType],
      lastColumn: this.getSpreadSheetLastColumn(colCount),
    };
  }

  getDiscrepanciesExportData = (discrepancies) => {
    const keys = Object.keys(discrepancies[0]);
    const data = discrepancies.map(line => {
      const lineData = [];

      keys.forEach(key => {
        let val = line[key];
        if (key === 'wrong_sizes' && val) {
          const wrongSizesItems = line[key].map(x => `${x.item} - ${x.item_lib}`);
          val = wrongSizesItems.join('\n');
        } else if (key === 'content') {
          val = val || []
          const pallets = val.map(x => `${x.pallet}`).join('\n');
          const parcels = val.map(x => `${x.parcel}`).join('\n');
          lineData.push(pallets);
          lineData.push(parcels);
          return;
        } else if (val === null || val === undefined) {
          val = '';
        } else if (val instanceof Array) {
          val = val.join(' ');
        } else if (typeof val === 'object' && val.value) {
          val = val.value.toString();
        } else {
          val = val.toString();
        }
        lineData.push(val);
      });
      return lineData;
    });
    return data;
  }

  getTitleLine = (discrepancies) => {
    let titleLine = Object.keys(discrepancies[0]);
    const contentIndex = titleLine.indexOf('content');
    if (contentIndex !== -1) {
      titleLine = [
        ...titleLine.slice(0, contentIndex),
        'pallets',
        'parcels',
        ...titleLine.slice(contentIndex + 1, titleLine.length),
      ];
    }
    return titleLine;
  }

  addDataToSheet = (spreadsheetId, token, discrepancyType) => {
    const discrepancies = this.applyAllFilters(discrepancyType);

    if (!discrepancies || !discrepancies.length) {
      console.log(`No data for: ${discrepancyType}`);
      return;
    }

    const titleLine = this.getTitleLine(discrepancies);
    const data = this.getDiscrepanciesExportData(discrepancies);
    const numberOfLines = data.length + 1;
    const colCount = titleLine.length;
    const tabProperty = this.getTabProperty(discrepancyType, colCount);
    const { lastColumn, title } = tabProperty;
    const range = `${title}!A1:${lastColumn}${numberOfLines}`;

    return fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=USER_ENTERED`,{
      method: 'PUT',
      headers:{"Authorization": "Bearer " + token},
      body: JSON.stringify({
        range: range,
        values: [titleLine].concat(data),
      })
    })
    .catch(error => {
      console.warn(`There was an error when sending data to SpreadSheet ${spreadsheetId} : ${error}`);
    })
  }

  displayTotal = (discrepancyType) => {
    const { t } = this.props;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const discrepancies = this.applyAllFilters(discrepancyType);
    let numberOfDiscrepancies = 0;
    let numberOfDiscrepanciesValidated = 0;
    let numberOfModels = 0;
    let numberOfItems = 0;
    let totalAmount = 0;
    let totalAmountValidated = 0;
    let discrepanciesValidated = [];

    if (discrepancies.length > 0){
      numberOfDiscrepancies = discrepancies.length;
      discrepanciesValidated = discrepancies.filter(discrepancy => discrepancy.movementId !== null);
      numberOfDiscrepanciesValidated = discrepanciesValidated.length;
      numberOfModels = [...new Set(discrepancies.map(discrepancy => discrepancy.model_id))].length;
      numberOfItems = [...new Set(discrepancies.map(discrepancy => discrepancy.item))].length;
    }
    switch (discrepancyType){
      case 'transport':
        totalAmount = discrepancies.map(discrepancy => (Math.min(discrepancy.qtyReadByWarehouseGate, discrepancy.qty_confirmed) - discrepancy.qtyReadByStoreGate) * discrepancy.price).reduce(reducer, 0);
        totalAmountValidated = discrepanciesValidated.map(discrepancy => (Math.min(discrepancy.qtyReadByWarehouseGate, discrepancy.qty_confirmed) - discrepancy.qtyReadByStoreGate) * discrepancy.price).reduce(reducer, 0);
        break;
      case 'wrong_size':
        totalAmount = discrepancies.map(discrepancy => discrepancy.price * discrepancy.suggested_movement).reduce(reducer, 0);
        totalAmountValidated = discrepanciesValidated.map(discrepancy => discrepancy.price * discrepancy.realized_movement).reduce(reducer, 0);
        break;
      case 'unexpected':
        totalAmount = discrepancies.map(discrepancy => discrepancy.price * discrepancy.suggested_movement).reduce(reducer, 0);
        totalAmountValidated = discrepanciesValidated.map(discrepancy => discrepancy.price * discrepancy.realized_movement).reduce(reducer, 0);
        break;
      default:
        totalAmount = discrepancies.map(discrepancy => discrepancy.price * (discrepancy.qty_confirmed - discrepancy.qtyReadByStoreGate)).reduce(reducer, 0);
        totalAmountValidated = discrepanciesValidated.map(discrepancy => discrepancy.price * (discrepancy.qty_confirmed - discrepancy.qtyReadByStoreGate)).reduce(reducer, 0);
    }
    totalAmount = parseInt(totalAmount, 10);
    totalAmountValidated = parseInt(totalAmountValidated, 10);
    let currency = discrepancies.find(discrepancy => discrepancy.currency !== null);
    if (currency !== undefined && currency.currency !== null){
      currency = currency.currency.toLowerCase()
    }
    return(
      <div className="card totalCard">
        <div className="card-text">
          {numberOfDiscrepancies}
          {' '}
          {t('storeReception.totals.discrepancies', 'discrepancies')}
          {' / '}
          {numberOfDiscrepanciesValidated}
          {' '}
          {t('storeReception.totals.validated', 'validated')}
        </div>
        <div className="card-text">
          {numberOfModels}
          {' '}
          {t('storeReception.totals.modelCodes', 'model codes')}
          {' / '}
          {numberOfItems}
          {' '}
          {t('storeReception.totals.itemCodes', 'item codes')}
        </div>
        <div className="card-text">
          {totalAmount} {currency}
          {' / '}
          {totalAmountValidated} {currency}
          {' '}
          {t('storeReception.totals.validated', 'validated')}</div>
      </div>
    )
  }

  render(){
    return(
      <div className = "container-fluid reportingStoreReception">
        {this.loader()}
        {this.displayAlertPopUpAreBlocked()}
        {this.displayParameters()}
        {this.displayList()}
      </div>
    )
  }
}

export default withTranslation()(ReportingStoreReception);
