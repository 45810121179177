import React from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';

const DateTimeEdit = ({ label, disabled, value, onChange, controlId, minDate, maxDate }) => {
  return (
    <Form.Group className="form-item datetime-edit" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        disabled={disabled}
        as={ReactDatePicker}
        showTimeSelect
        selected={value ? new Date(value) : null}
        onChange={(value) => onChange && onChange(value)}
        dateFormat="dd/MM/yyyy HH:mm:ss"
        minDate={minDate}
        maxDate={maxDate}
      />
    </Form.Group>
  );
}

export default DateTimeEdit;
