import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';
import useRequest from '../../../functions/useRequest';
import SelectFilter from './SelectFilter';
import renderFlagItem from '../renderFlagItem';

import '../../../stylesheets/countrySelect.css';

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return countries.map(({ id, code, name }) => ({
    id,
    countryCode: code,
    label: name,
  }));
}


const CountryFilter = ({ selected, onChange, disabled, user, updateTokens }) => {
  const { t } = useTranslation();

  const [{
    loading,
    error,
    data: countries,
  }, fetchCountries] = useRequest(getCountries);

  useEffect(() => {
    fetchCountries(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectFilter
      filterOpts
      controlId="select-store-id"
      label={t('countrySelect.label', 'Country')}
      options={countries || []}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      error={error}
      renderItem={renderFlagItem}
    />
  );
};

export default CountryFilter;
