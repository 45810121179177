import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCards } from './cards';
import TopPerformer from './TopPerformer';

const EfficiencyCardLine = ({ unit, value, title, isHeader }) => {
  return (
    <div className={`efficiency-card-line ${isHeader ? 'efficiency-card-header-line' : ''} d-flex flex-column`}>
      <div className="d-flex flex-row align-items-center">
        {value && <div className="efficiency-card-line-value">{value}</div>}
        <div className="efficiency-card-line-title">{title}</div>
      </div>
      {unit && <div className="efficiency-card-line-unit d-flex flex-row">{unit}</div>}
    </div>
  );
};

const EfficiencyCard = ({ icon, header, lines }) => {
  return (
    <div className="efficiency-card d-flex flex-row">
      <div className="efficiency-card-icon-wrap">
        <img
          className="efficiency-card-icon"
          src={icon}
          alt="icon"
        />
      </div>
      <div className="efficiency-card-content d-flex flex-column">
        <EfficiencyCardLine
          isHeader
          unit={header.unit}
          value={header.value}
          title={header.title}
        />
        {lines.map((x, index) => (
          <EfficiencyCardLine
            key={index}
            unit={x.unit}
            value={x.value}
            title={x.title}
          />
        ))}
      </div>
    </div>
  );
};

const EfficiencyView = ({ dashboard, mode }) => {
  const { t } = useTranslation();
  const isStoreView = mode === 'store';
  const isCountryView = mode === 'country';
  const cards = getCards(t, dashboard, isCountryView);
  return (
    <div className="efficiency-view">
      {isStoreView && dashboard.top_performer?.leader && (
        <TopPerformer
          leader={dashboard.top_performer?.leader}
          date={dashboard.top_performer?.start_date}
          productivity={dashboard.top_performer?.productivity}
        />
      )}
      <div className="efficiency-view-cards">
        {cards.map(({ icon, header, lines }, index) => (
          <EfficiencyCard
            key={index}
            icon={icon}
            header={header}
            lines={lines}
          />
        ))}
      </div>
    </div>
  );
};

export default EfficiencyView;
