import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, InputGroup } from 'react-bootstrap';
import useDebounce from '../../functions/useDebounce';

const SearchBar = ({
  value,
  onChange,
  onRefresh,
  loading,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);

  useEffect(
    () => {
      if (value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );

  return (
    <InputGroup id="searchbar" className="searchbar mb-3">
      <Form.Control
        placeholder={t('searchBar.search', 'Search...')}
        type="text"
        value={stateValue || ''}
        onChange={(event) => { setStateValue(event.target.value); }}
      />
      <InputGroup.Append>
        <Button
          className="refresh-button"
          onClick={onRefresh}
          disabled={loading}
        >
          {t('searchBar.refresh', 'Refresh')}
        </Button>
      </InputGroup.Append>

    </InputGroup>
  );
};

export default SearchBar;
