import React, { useRef, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import useRequest from '../../../../../functions/useRequest';
import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';
import ImportInstructionModal from './commonModals';
import '../../../../../stylesheets/importLQT.css';

const getCurrentProcessing = (user, updateTokens, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/current`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

const checkRequest = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/state?path=${path}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

const ImportLQT = ({
    user,
    updateTokens,
    size,
    warehouse,
    updateData,
}) => {
  const [showInstruction, setShowInstruction] = useState(false);
  const [path, setPath] = useState();
  const [count, setCount] = useState();
  const [total, setTotal] = useState();
  const [error, setError] = useState();
  const [created, setCreated] = useState();
  const [existing, setExisting] = useState();
  const [finished, setFinished] = useState(false);
  const timerRef = useRef();
  
  const [, doGetCurrent] = useRequest(getCurrentProcessing, {
    onError: setError,
    onSuccess: (resp) => {
      const {
        processed_rows,
        total_rows,
        error: err,
        created_entities,
        existing_entities,
        path: filePath,
      } = resp;
      setCount(processed_rows);
      setTotal(total_rows);
      setError(err);
      setCreated(created_entities);
      setExisting(existing_entities);
      setPath(filePath);
    }
  });

  const [, doCheckState] = useRequest(checkRequest, {
    onError: setError,
    onSuccess: (resp) => {
      const {
        count: processed_rows,
        total: total_rows,
        created: created_entities,
        existing: existing_entities,
      } = resp;
      setCount(processed_rows);
      setTotal(total_rows);
      setCreated(created_entities);
      setExisting(existing_entities);
    }
  });

  useEffect(() => {
    doGetCurrent(user, updateTokens, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse]);

  useEffect(() => {
    setFinished(total > 0 && !!error);
  }, [total, error]);

  useEffect(() => {
    if (finished || !path || error) {
      return;
    }
    doCheckState(user, updateTokens, warehouse, path);
    timerRef.current = setInterval(() => {
      doCheckState(user, updateTokens, warehouse, path);
    }, 1000);
    
    return () => clearInterval(timerRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, path, finished, error]);

  useEffect(() => {
    if (!!(count && total && count >= total) && timerRef.current) {
      setFinished(true);
    }
  }, [count, total]);

  const onModalsConfirm = () => {
    setShowInstruction(false);
    setPath();
    setError();
    setCount();
    setTotal();
    setCreated();
  }

  return (
    <>
      <div className={!!path ? "file-import-wrapper" : ""}>
        <Button
          size={size}
          className="icon-button filter-button"
          onClick={() => setShowInstruction(true)}
          variant={error ? "danger" : "primary"}
        >
          {path && (
            <>
              {finished && error && <i className="vtmn-icon_notif_alert"></i>}
              {!finished && !error && (
                <Spinner size="sm" animation="border" variant="light" className="buttonSpinner" />
              )}
            </>
          )}
          <Trans i18nKey="declareToControl.actions.import">
            Import picking extract (LQT)
          </Trans>
        </Button>
        {path && (
          <>
            {error && <span className="red-alert">{error}</span>}
            {!error && (
              <span className="process-status">
                <Trans
                  i18nKey="declareToControl.uploadingProgress.rowsCounter"
                  values={{
                    count: count > total ? total : count,
                    total,
                  }}
                />
              </span>
            )}
          </>
        )}
      </div>

      {showInstruction && (
        <ImportInstructionModal
          user={user}
          updateTokens={updateTokens}
          warehouse={warehouse}
          onClose={() => setShowInstruction(false)}
          error={error}
          count={count}
          total={total}
          created={created}
          existing={existing}
          path={path}
          onConfirm={onModalsConfirm}
          onFinished={() => updateData({ page: 0 })}
        />
      )}
    </>
  );
}

export default ImportLQT;
