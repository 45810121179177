import handleApiResponse from './handleApiResponse';
import fetchWithJWT from './fetchWithJWT';

const getStoreFeatures = (store, user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${store}/features`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

export default getStoreFeatures;
