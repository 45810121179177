import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';

const cteateContact = (
  siteId,
  contactInfo,
  user,
  updateTokens,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${siteId}/contacts`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ contactInfo }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateContact = (
  contactId,
  siteId,
  contactInfo,
  user,
  updateTokens,
) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/${siteId}/contacts/${contactId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ contactInfo }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const saveContact = (
  contactId,
  siteId,
  contactInfo,
  user,
  updateTokens,
) => (
  contactId
    ? updateContact(contactId, siteId, contactInfo, user, updateTokens)
    : cteateContact(siteId, contactInfo, user, updateTokens)
);

const ContactDialog = ({
  user,
  updateTokens,
  siteId,
  contactId,
  contactInfo,
  open,
  onClose,
  onSaved,
}) => {
  const [stateContact, setStateContact] = useState(contactInfo);
  useEffect(() => { setStateContact(contactInfo) }, [contactInfo]);
  const { t } = useTranslation();
  const title = t('adminDeploymentManagement.editContact', 'Edit contact');

  const [{
    loading: saving,
    error,
  }, save] = useRequest(saveContact, { onSuccess: onSaved });

  return (
    <Modal
      show={open}
      onHide={onClose}
      className="modalAlert"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="mb-0">
          <Form.Control
            disabled={saving}
            as="textarea"
            rows={3}
            value={stateContact || ''}
            onChange={(event) => { setStateContact(event.target.value); }}
            isInvalid={!!error}
          />
          {!saving && error && (
            <Form.Control.Feedback type="invalid">
              {(error && error.message) || <Trans i18nKey="defaultError">Error</Trans>}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className="m-0 mr-2">
          <Trans i18nKey="dialog.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() => save(contactId, siteId, stateContact, user, updateTokens)}
          className="m-0"
          disabled={saving}
        >
          {saving && <Spinner size="sm" animation="border" variant="light" className="buttonSpinner" />}
          <Trans i18nKey="dialog.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ContactDialog;
