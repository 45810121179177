export const STATUSES = [
  'inprogress',  // measurement in progress
  'complete',  // leader confirmed values, or 8h passed after create_date
  'incomplete',  // measurement was not complete, status set by job after 8h
];



export const getStatusOptions = (t) => {
  const statusOptions = [{
    id: 'inprogress',
    label: t('efficiencyAdmin.status.inprogress', 'In progress'),
    variant: 'primary',
  }, {
    id: 'complete',
    label: t('efficiencyAdmin.status.complete', 'Complete'),
    variant: 'success',
  }, {
    id: 'incomplete',
    label: t('efficiencyAdmin.status.incomplete', 'Incomplete'),
    variant: 'danger',
  }]
  return statusOptions;
};

export const getStatusMap = (t) => {
  const statusMap = {
    inprogress: {
      id: 'inprogress',
      label: t('efficiencyAdmin.status.inprogress', 'In progress'),
      variant: 'primary',
    },
    complete: {
      id: 'complete',
      label: t('efficiencyAdmin.status.complete', 'Complete'),
      variant: 'success',
    },
    incomplete: {
      id: 'incomplete',
      label: t('efficiencyAdmin.status.incomplete', 'Incomplete'),
      variant: 'danger',
    },
  }
  return statusMap;
};

export const getRecordTypes = (t) => {
  const options = [{
    id: 'all',
    label: t('efficiencyAdmin.recordTypes.all', 'All records'),
  }, {
    id: 'correct',
    label: t('efficiencyAdmin.recordTypes.correct', 'Correct'),
  }, {
    id: 'incorrect',
    label: t('efficiencyAdmin.recordTypes.incorrect', 'Potentially incorrect'),
  }];
  return options;
};

export const getTypesMap = (t) => {
  const map = {
    correct: {
      id: 'correct',
      label: t('efficiencyAdmin.recordTypes.correct', 'Correct'),
      variant: 'success',
    },
    incorrect: {
      id: 'incorrect',
      label: t('efficiencyAdmin.recordTypes.incorrect', 'Potentially incorrect'),
      variant: 'danger',
    },
    valid: {
      id: 'valid',
      label: t('efficiencyAdmin.recordTypes.valid', 'Valid'),
      variant: 'warning'
    }
  }
  return map;
}
