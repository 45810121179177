import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { generateRandomString56, generateCodeChallengeFromVerifier } from '../functions/pkce';
import logoEnd2end from './logoEnd2end.jpg';
import productFlowMobile from './productFlowMobile.png';
const fedId_clientID = process.env.REACT_APP_fedID_clientID;
const fedID_URL = process.env.REACT_APP_fedID_URL;
const fedID_redirect_URI = process.env.REACT_APP_fedID_redirect_URI;

class Login extends Component {

  connectToFedid = async () => {
    const state = generateRandomString56();
    const codeVerifier = generateRandomString56();
    sessionStorage.setItem(`login-code-verifier-${state}`, codeVerifier);
    const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
    let authUrl = `${fedID_URL}?response_type=code&client_id=${fedId_clientID}&state=${state}&redirect_uri=${fedID_redirect_URI}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=profile openid`;
    if (this.props.reauthenticate) {
      authUrl += '&prompt=login'
    }
    window.location.replace(authUrl)
  }

  render(){
    const { t } = this.props;
    return(
      <div className="landing-page test-deployment">
        <div className="text-center">
          <img className="logo" src={logoEnd2end} alt="logo end2end"/>
        </div>
        <div className="back text-center">
          <img className="productFlow" src={productFlowMobile} alt="product flow"/>
        </div>
        <button onClick={this.connectToFedid} className="btn btn-lg btn-primary btn-block loginButton mx-auto">{t('login.login', 'Login')}</button>
      </div>
    )
  }
}

export default withTranslation()(Login);
