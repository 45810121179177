import React from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from '../../../functions/formatMoney';
import ProgressBar from './ProgressBar';

const ProgressDetailsContent = ({
  indicator,
  currency,
}) => {
  const { t } = useTranslation();
  const notCheckedLabel = t(
    'liveStoreReception.progress.notChecked',
    'Not checked:',
  );
  const readByRobotLabel = t(
    'liveStoreReception.progress.notUnexpected',
    'Not unexpected:',
  );
  const revisedAtMovementsLabel = t(
    'liveStoreReception.progress.revisedAtMovements',
    'Has movement correction:',
  );
  const confirmedMissingLabel = t(
    'liveStoreReception.progress.confirmedUnexpected',
    'Confirmed unexpected:',
  );
  const notCheckedQties = indicator.total
    - indicator.foundAtMovementsQties
    - indicator.revisedAtMovementsQties
    - indicator.missingAtMovementsQties;

  return (
    <>
      <div className="row">
        <div className="col">
          <ProgressBar indicator={indicator} currency={currency} />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col text-right">
          {readByRobotLabel}
        </div>
        <div className="col">
          {indicator.foundAtMovementsQties}
          {indicator.foundAtMovementsValue ? ` = ${formatMoney(indicator.foundAtMovementsValue, currency)}` : null}
        </div>
      </div>
      <div className="row">
        <div className="col text-right">
          {revisedAtMovementsLabel}
        </div>
        <div className="col">
          {indicator.revisedAtMovementsQties}
          {indicator.revisedAtMovementsValue ? ` = ${formatMoney(indicator.revisedAtMovementsValue, currency)}` : null}
        </div>
      </div>
      <div className="row">
        <div className="col text-right">
          {confirmedMissingLabel}
        </div>
        <div className="col">
          {indicator.missingAtMovementsQties}
          {indicator.missingAtMovementsValue ? ` = ${formatMoney(indicator.missingAtMovementsValue, currency)}` : null}
        </div>
      </div>
      <div className="row">
        <div className="col text-right">
          {notCheckedLabel}
        </div>
        <div className="col">
          {notCheckedQties}
          {indicator.additionalValue ? ` = ${formatMoney(indicator.additionalValue, currency)}` : null}
        </div>
      </div>
    </>
  );
};

export default ProgressDetailsContent;