import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter from './SelectFilter';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';

const getUniverses = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/reporting/discrepancies/universes`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const UniverseFilter = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  controlId,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [{
    loading,
    data,
    error,
  }, fetchUniverses] = useRequest(getUniverses);

  useEffect(() => {
    fetchUniverses(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = (data && data.map(universe => ({
    label: universe.name,
    name: universe.name,
    id: universe.name,
  }))) || [];

  return (
    <SelectFilter
      multiple
      filterOpts
      controlId={controlId || 'select-universe-item'}
      label={label || t('declareToControl.filters.universe','Universe')}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      error={error}
      placeholder={placeholder}
    />
  );
};

export default UniverseFilter;
