import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const StayedAtWarehouseLabel = ({ validated }) => (
  <OverlayTrigger
    placement="bottom"
    overlay={
      <Tooltip id="stayed-at-warehouse-tooltip">
        <ul className="text-left p-1 pl-3 m-0">
          {validated && (
            <li>
              <Trans i18nKey="filters.stayedAtWarehouseTitleValidated">
                validated
              </Trans>
            </li>
          )}
          <li>
            <Trans i18nKey="filters.stayedAtWarehouseTitleBeforeGate">
              security activated at warehouse {'>'} both read in store and by warehouse gate
            </Trans>
          </li>
          <li>
            <Trans i18nKey="filters.stayedAtWarehouseTitleShipping">
              by shipping list {'>'} both read in store and by warehouse gate
            </Trans>
          </li>
        </ul>
      </Tooltip>
    }
  >
    <div>
      <Trans i18nKey="filters.stayedAtWarehouse">
        Stayed at warehouse
      </Trans>
    </div>
  </OverlayTrigger>
);

export default StayedAtWarehouseLabel;
