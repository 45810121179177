import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import capitalize from '../../functions/capitalize';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import MonitoringTooltipContent from './MonitoringTooltipContent';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import blankFlag from '../../stylesheets/blankFlag.gif';
import '../../stylesheets/adminStoresMonitoring.css';

const getStoresData = (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/stores/pilot/monitoring`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const MonitoringCellValue = ({
  id,
  val,
  maxVal,
  linkto,
  robot,
  racket,
  hasRobot,
  hasRacket,
}) => {
  if (
    ((val === null || val === undefined) && (maxVal === null || maxVal === undefined))
    || (robot && !hasRobot)
    || (racket && !hasRacket)
  ) {
    return <div className="cell-value">-</div>;
  }

  let valStr;
  if (val === null || val === undefined) {
    valStr = '-';
  } else {
    valStr = `${val}`;
  }

  let maxValStr;
  if (maxVal === null || maxVal === undefined) {
    maxValStr = '-';
  } else {
    maxValStr = `${maxVal}`;
  }

  let className = '';
  let icon = '';
  if (!val || !maxVal) {
    className = 'link cell-danger';
    icon = 'vtmn-icon_thumb_down';
  } else if (val > maxVal * 0.9) {
    className = 'link cell-success';
    icon = 'vtmn-icon_thumb_up';
  } else if (val > maxVal * 0.5) {
    className = 'link cell-warning';
    icon = 'vtmn-icon_thumb_down';
  } else {
    className = 'link cell-danger';
    icon = 'vtmn-icon_thumb_down';
  }

  const tooltipType = robot ? 'robot' : 'gate';


  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`admin-thumb-tooltip-${tooltipType}-${id}`}>
          <MonitoringTooltipContent robot={robot} racket={racket} />
        </Tooltip>
      }
    >
      <div className={`cell-value ${className}`} >
        <Link to={linkto}>
          {valStr}
          {' / '}
          {maxValStr}
        </Link>
        <i className={icon}></i>
      </div>
    </OverlayTrigger>
  );
}

const MonitoringRow = ({ store }) => {
  const flag = `flag flag-${store.countryCode.toLowerCase()}`;
  const gateLinkto = `/${store.countryId}/${store.id}/monitoring/gate`;
  const robotLinkto = `/${store.countryId}/${store.id}/monitoring/robot`;

  return (
    <tr>
      <td className="text-center">
        <img src={blankFlag}  className={flag} alt={store.countryCode} />
      </td>
      <td>
        {store.id}
      </td>
      <td>
        {capitalize(store.name)}
      </td>
      <td>
        <MonitoringCellValue
          id={store.id}
          val={store.monitoring.gateTagsReceived}
          maxVal={store.monitoring.gateTagsExpected}
          linkto={gateLinkto}
          racket
          hasRacket={store.features.hasRacket}
        />
      </td>
      <td>
        <MonitoringCellValue
          id={store.id}
          val={store.monitoring.robotTotalUnsoldTags}
          maxVal={store.monitoring.robotMaxUnsoldTags}
          linkto={robotLinkto}
          robot
          hasRobot={store.features.hasRobot}
        />
      </td>
    </tr>
  );
};

const AdminStoresMonitoring = ({ user, updateTokens }) => {
  const [{
    loading,
    data,
    error,
  }, fetchStoresData] = useRequest(getStoresData);
  useEffect(() => {
    fetchStoresData(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container adminStoresMonitoring">
      {loading && <Loader />}
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && data && (
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesMonitoring.country">Country</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storesMonitoring.store">Store</Trans>
              </th>
              <th scope="col">
                <Trans i18nKey="storesMonitoring.description">Description</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesMonitoring.receptionReads">Reception last readings</Trans>
              </th>
              <th scope="col" className="text-center">
                <Trans i18nKey="storesMonitoring.robotReads">Robot last readings</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(store => <MonitoringRow key={store.id} store={store} />)}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default AdminStoresMonitoring;
