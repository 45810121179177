import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ indicator, currency, isTotal, onBarClick }) => {
  const { t } = useTranslation();
  const missingLabel = t(
    'liveStoreReception.card.missing',
    'Missing',
  );
  const readByGateLabel = t(
    'liveStoreReception.progress.readInStore',
    'Read in store:',
  );
  const readInWarehouseLabel = t(
    'liveStoreReception.progress.readInWarehouse',
    'Read in warehouse:',
  );
  const readByRobotLabel = t(
    'liveStoreReception.progress.readByRobot',
    'Found by stock movement:',
  );
  const confirmedMissingLabel = t(
    'liveStoreReception.progress.confirmedMissing',
    'Confirmed missing:',
  );
  const revisedAtMovementsLabel = t(
    'liveStoreReception.progress.revisedAtMovements',
    'Has movement correction:',
  );
  const notCheckedLabel = t(
    'liveStoreReception.progress.notChecked',
    'Not checked:',
  );
  let myClassProgressBar = "progress-bar";
  if (indicator.receivedWithTagTotalQties >= indicator.expectedWithTagTotalQties) {
    myClassProgressBar += " bg-success";
  } else if (indicator.percentDone < 30) {
    myClassProgressBar += " progress-bar-begin"
  }
  let missingValueToDisplay = "";
  if (indicator.missingValue > 0 && indicator.receivedWithTagTotalQties < indicator.expectedWithTagTotalQties){
    //sometimes one price can be missing so we should not display this missing value
    missingValueToDisplay = ` = ${missingLabel} ${indicator.missingValue} ${currency}`;
  }

  let readByGateTitle = `${readByGateLabel} ${indicator.receivedWithTagTotalQties}`;
  if (indicator.receivedValue) {
    readByGateTitle += ` = ${indicator.receivedValue} ${currency}`;
  }
  let readInWarehouseTitle = `${readInWarehouseLabel} ${indicator.warehouseWithTagTotalQties}`;
  if (indicator.warehouseValue) {
    readInWarehouseTitle += ` = ${indicator.warehouseValue} ${currency}`
  }
  let readByRobotTitle = `${readByRobotLabel} ${indicator.foundAtMovementsQties}`;
  if (indicator.foundAtMovementsValue) {
    readByRobotTitle += ` = ${indicator.foundAtMovementsValue} ${currency}`;
  }
  let confirmedMissingTitle = `${confirmedMissingLabel} ${indicator.missingAtMovementsQties}`;
  if (indicator.missingAtMovementsValue) {
    confirmedMissingTitle += ` = ${indicator.missingAtMovementsValue} ${currency}`;
  }
  let revisedAtMovementsTitle = `${revisedAtMovementsLabel} ${indicator.revisedAtMovementsQties}`;
  if (indicator.revisedAtMovementsValue) {
    revisedAtMovementsTitle += ` = ${indicator.revisedAtMovementsValue} ${currency}`;
  }

  let notCheckedTitle = `${notCheckedLabel} ${indicator.leftQties}`;
  if (indicator.leftValue) {
    notCheckedTitle += ` = ${indicator.leftValue} ${currency}`;
  }
  return (
    <div className={`progress ${isTotal ? 'total' : ''}`} onClick={onBarClick}>
      <div className={myClassProgressBar}
        role="progressbar"
        style={{width: indicator.percentDone + "%"}}
        aria-valuenow={indicator.percentDone}
        aria-valuemin="0"
        aria-valuemax="100"
        title={readByGateTitle}
      >
        <span>
          {indicator.receivedWithTagTotalQties + indicator.foundAtMovementsQties}
          {' / '}
          {indicator.expectedWithTagTotalQties}
          {' '}
          {missingValueToDisplay}
        </span>
      </div>
      {indicator.foundAtMovementsQties ? (
        <div className="progress-bar progress-bar-striped bg-success"
          role="progressbar"
          style={{width: indicator.percentFoundAtMovements + "%"}}
          aria-valuenow={indicator.percentFoundAtMovements}
          aria-valuemin="0"
          aria-valuemax="100"
          title={readByRobotTitle}
        ></div>
      ) : null}
      {indicator.percentWarehouseVisible ? (
        <div className="progress-bar progress-bar-striped"
          role="progressbar"
          style={{width: indicator.percentWarehouseVisible + "%", opacity: '0.3'}}
          aria-valuenow={indicator.percentWarehouseVisible}
          aria-valuemin="0"
          aria-valuemax="100"
          title={readInWarehouseTitle}
        ></div>
      ) : null}
      {indicator.percentLeft ? (
        <div className="progress-bar"
          role="progressbar"
          style={{width: indicator.percentLeft + "%", backgroundColor: 'transparent'}}
          aria-valuenow={indicator.percentLeft}
          aria-valuemin="0"
          aria-valuemax="100"
          title={notCheckedTitle}
        ></div>
      ) : null}
      {indicator.revisedAtMovementsQties ? (
        <div className="progress-bar progress-bar-striped bg-warning"
          role="progressbar"
          style={{width: indicator.percentRevisedAtMovements + "%"}}
          aria-valuenow={indicator.percentRevisedAtMovements}
          aria-valuemin="0"
          aria-valuemax="100"
          title={revisedAtMovementsTitle}
        ></div>
      ) : null}
      {indicator.missingAtMovementsQties ? (
        <div className="progress-bar bg-danger"
          role="progressbar"
          style={{width: indicator.percentMissingAtMovements + "%"}}
          aria-valuenow={indicator.percentMissingAtMovements}
          aria-valuemin="0"
          aria-valuemax="100"
          title={confirmedMissingTitle}
        ></div>
      ) : null}
    </div>
  );
}

export default ProgressBar;
