import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import ErrorMessage from '../errorMessage';
import RacketForm from './RacketForm';

const getRacket = ({
  user,
  updateTokens,
  id,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/rackets/${id}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse)
  .then(racket => ({
    ...racket,
    trainingDate: racket.trainingDate && new Date(racket.trainingDate),
    startDate: racket.startDate && new Date(racket.startDate),
  }));
};

const createRacket = ({
  user,
  updateTokens,
  racket,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/rackets`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(racket),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateRacket = ({
  user,
  updateTokens,
  id,
  racket,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/rackets/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(racket),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const AdminDeploymentRacket = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { racketId } = useParams();
  const history = useHistory();
  const isNew = racketId === 'new';
  const title = t('deploymentRacket.enabling', 'Enabling rackets');
  const [racket, setRacket] = useState({});

  const [{
    loading,
    error,
  }, fetchRacket] = useRequest(getRacket, {
    onSuccess: setRacket
  });
  useEffect(() => {
    if (!isNew) {
      fetchRacket({
        id: racketId,
        user,
        updateTokens,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, racketId, fetchRacket]);

  const [{
    loading: saving,
    error: saveError,
  }, saveRacket] = useRequest(isNew ? createRacket : updateRacket, {
    onSuccess: () => history.goBack(),
  });

  const hasError = !loading && !saving && (error || saveError);
  const isReady = !loading && !saving && !error;
  const onSave = () => saveRacket({
    id: racketId,
    racket,
    user,
    updateTokens,
  });

  return (
    <Container className="adminDeploymentRacket">
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
        {(loading || saving) && <Loader />}
        {isReady && (
          <RacketForm
            user={user}
            updateTokens={updateTokens}
            racket={racket}
            setRacket={setRacket}
          />
        )}
        {hasError && <ErrorMessage error={error || saveError} />}
        </Card.Body>
        <Card.Footer className="p-0">
          <Button
            variant="secondary"
            className="icon-button"
            onClick={() => history.goBack()}
          >
            <i className="vtmn-icon_cross"></i>
            <Trans i18nKey="deploymentRacket.cancel">Cancel</Trans>
          </Button>
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={!isReady || !racket.storeId}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="deploymentRacket.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );

};

export default AdminDeploymentRacket;
