import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter from './SelectFilter';

const RfidFilter = ({
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
}) => {
  const { t } = useTranslation();
  const options = [{
    id: 'P',
    label: t('declareToControl.filters.rfid.p', 'P - Placed in production'),
  }, {
    id: 'L',
    label: t('declareToControl.filters.rfid.l', 'L - Placed in logisticts'),
  }, {
    id: 'N',
    label: t('declareToControl.filters.rfid.n', 'N - No tag placed'),
  }, {
    id: 'empty',
    label: t('declareToControl.filters.rfid.empty', 'Empty'),
  }];
  return (
    <SelectFilter
      controlId="rfid-select-val"
      label="RFID"
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      multiple
    />
  );
}

export default RfidFilter;
