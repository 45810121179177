import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Button, Modal, Badge } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import TextEdit from '../../widgets/form/TextEdit';
import IntEdit from '../../widgets/form/IntEdit';
import NumberEdit from '../../widgets/form/NumberEdit';
import PlannedPanel from './plannedPanel';
import RealizedPanel from './realizedPanel';
import SimpleSelect from '../../widgets/SimpleSelect';
import DurationEdit from '../../widgets/form/DurationEdit';
import { getStatusOptions } from '../statuses';
import { formatCurrency } from '../../../functions/formatMoney';

export const getTime = (val) => val ? new Date(val).getTime() : 0;
export const numberRenderer = (val) => _.isNil(val) ? null : Number(val.toFixed(2));

const EditForm = ({ efficiency, setEfficiency }) => {
  const { t } = useTranslation();
  if (!efficiency) {
    return null;
  }
  const currency = formatCurrency(efficiency.currency);
  const overallDuration = efficiency.shelving_finish_date
    ? (getTime(efficiency.shelving_finish_date) - getTime(efficiency.unloading_start_date)) / 1000
    : 0;
  const duration = {
    unloading: efficiency.unloading_finish_date && efficiency.unloading_start_date
      ? (getTime(efficiency.unloading_finish_date) - getTime(efficiency.unloading_start_date)) / 1000
      : 0,
    shelving: efficiency.shelving_finish_date && efficiency.shelving_start_date
      ? (getTime(efficiency.shelving_finish_date) - getTime(efficiency.shelving_start_date)) / 1000
      : 0,
  };
  duration.total = duration.unloading + duration.shelving;

  const teammateHours = {
    unloading: (efficiency.unloading_involvement
      ? _.sum(efficiency.unloading_involvement) 
      : efficiency.unloading_teammates) * (duration.unloading / 3600),
    shelving: (efficiency.shelving_involvement
      ? _.sum(efficiency.shelving_involvement) 
      : efficiency.shelving_teammates) * (duration.shelving / 3600),
  }
  const quantities = efficiency.deliveries.reduce((acc, d) => acc + d.qty, 0);
  const totalHours = teammateHours.unloading + teammateHours.shelving;
  const overallProductivity = totalHours ? quantities / totalHours : 0;

  return (
    <>
      <div className="deliveries-wrapper">
        <span className="deliveries-label">
          {t('efficiencyAdmin.columns.deliveries', 'Deliveries')}:
        </span>
        <Badge pill variant="primary" className="deliveries-badge">
          {efficiency.deliveries.map((d) => d.id).join(', ')}
        </Badge>
        
      </div>
      <div className="d-flex flex-wrap measurement-main-info">
        <TextEdit
          disabled
          label={t('efficiencyAdmin.columns.leader', 'Leader')}
          value={efficiency.leader}
          controlId="leader"
        />
        <TextEdit
          disabled
          label={t('efficiencyAdmin.columns.store', 'Store')}
          value={ `${efficiency.site_id} - ${efficiency.site_name}`}
          controlId="store"
        />
        <IntEdit
          disabled
          label={t('efficiencyAdmin.columns.parcels', 'Parcels')}
          value={efficiency.parcels}
          controlId="parcels"
        />
        <IntEdit
          disabled
          label={t('efficiencyAdmin.columns.pallets', 'Pallets')}
          value={efficiency.pallets}
          controlId="pallets"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.costPerHour', 'Cost per hour')} (${currency})`}
          value={efficiency.cost_per_hour}
          controlId="cost_per_hour"
        />
      </div>
      <hr className="item-delimiter" />
      <div className="form-body">
        <div className="body-left-side">
          <PlannedPanel efficiency={efficiency} setEfficiency={setEfficiency} />
        </div>
        <div className="body-right-side">
          <RealizedPanel efficiency={efficiency} setEfficiency={setEfficiency} />
        </div>
      </div>
      <hr className="item-delimiter" />
      <div className="d-flex flex-wrap">
        <DurationEdit
          disabled
          label={t('efficiencyAdmin.columns.totalDuration', 'Total duration')}
          value={(overallDuration > duration.total ? duration.total : overallDuration) || efficiency.total_duration}
          controlId="total_duration"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.totalInvestment', 'Total investment')} (${currency})`}
          value={numberRenderer((totalHours * efficiency.cost_per_hour) || efficiency.total_investment)}
          controlId="total_investment"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.totalTeammatesHours', 'Total teammates hours')}`}
          value={numberRenderer(totalHours)}
          controlId="total_hours"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.overallProductivity', 'Overall productivity')}`}
          value={numberRenderer(overallProductivity)}
          controlId="overall_productivity"
        />
      </div>
    </>
  );
};

const EditWindow = ({ efficiency, onHide, onSave }) => {
  const { t } = useTranslation();
  const [stateEfficiency, setStateEfficiency] = useState(efficiency);
  useEffect(() => {
    setStateEfficiency(efficiency);
  }, [efficiency]);

  const incorrectUnloadingDate =
    stateEfficiency && dayjs(stateEfficiency.unloading_start_date).isAfter(dayjs(stateEfficiency.unloading_finish_date));
  const incorrectShelvingDate =
    stateEfficiency && dayjs(stateEfficiency.shelving_start_date).isAfter(dayjs(stateEfficiency.shelving_finish_date));

  const isValid = !!stateEfficiency && !!stateEfficiency.unloading_start_date && !incorrectShelvingDate && !incorrectUnloadingDate;

  return (
    <Modal
      size="xl"
      show={!!efficiency}
      onHide={onHide}
    >
      <Modal.Header closeButton className="measurement-modal-header">
        <i className="modal-header-icon text-primary vtmn-icon_edit"></i>
        <Modal.Title>
          <Trans i18nKey="efficiencyAdmin.editMeasurement">Edit measurement</Trans>
          <SimpleSelect
            controlId="status"
            label={t('efficiencyAdmin.columns.status', 'Status')}
            options={getStatusOptions(t)}
            selected={stateEfficiency && stateEfficiency.status}
            onChange={(status) => setStateEfficiency({ ...efficiency, status })}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <EditForm
          efficiency={stateEfficiency}
          setEfficiency={setStateEfficiency}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="mx-1 my-0" variant="secondary" onClick={onHide}>
          <Trans i18nKey="shared.cancel">Cancel</Trans>
        </Button>
        <Button 
          className="mx-1 my-0"
          variant="primary"
          onClick={() => onSave(stateEfficiency)}
          disabled={!isValid}
        >
          <Trans i18nKey="shared.save">Save</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditWindow;
