import React, { useState, useEffect, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {
  NavDropdown,
  InputGroup,
  Form,
} from 'react-bootstrap';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import { handleApiResponse } from '../../functions/handleApiResponse';
import ErrorMessage from '../errorMessage';
import blankFlag from "../../stylesheets/blankFlag.gif";
import "../../stylesheets/flags.css";
import "../../stylesheets/sitesMenuItem.css";

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

const onSiteClick = (site) => {
  localStorage.selectedSite = JSON.stringify(site);
};

const capitalize = (name) => {
  const splitted = name.split(" ");
  return splitted.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ")
};

const groupByCountry = (arr) => {
  const cObj = {};
  arr.forEach((site) => {
    if (!cObj[site.countryCode]) {
      cObj[site.countryCode] = [site];
    } else {
      cObj[site.countryCode].push(site);
    }
  });
  return cObj;
};

const getFilteredCountries = (obj) => {
  const newMap = {};
  for(let code in obj) {
    newMap[code] = true;
  }
  return newMap;
};

const SiteRow = ({ site }) => {
  const { path } = useRouteMatch();
  const { warehouse } = useParams();

  const wasWarehouse = !!warehouse;

  let linkto;
  if (wasWarehouse && site.isWarehouse) {
    linkto = path.replace('/:country/', `/${site.countryId}/`).replace('/:warehouse/', `/${site.id}/`);
  } else if (!wasWarehouse && !site.isWarehouse) {
    linkto = path.replace('/:country/', `/${site.countryId}/`).replace('/:store/', `/${site.id}/`);
  } else if (wasWarehouse && !site.isWarehouse) {
    linkto = path.replace('/warehouse', '').replace('/:country/', `/${site.countryId}/`).replace('/:warehouse/', `/${site.id}/`);
  } else {
    linkto = path.replace('/:country/', `/${site.countryId}/`).replace('/:store/', `/warehouse/${site.id}/`);
  }
  const flag = `flag flag-${site.countryCode.toLowerCase()}`;
  
  return (
    <Link to={linkto} onClick={() => onSiteClick(site)}>
      <NavDropdown.Item className="site" as="div">
        <div className="country-flag">
          <img src={blankFlag} className={flag} alt={site.countryCode} />
        </div>
        <div className="site-label">
          {site.id} - {capitalize(site.name)}
        </div>
      </NavDropdown.Item>
    </Link>
  );
};

const CountryTree = ({ countriesDict, sites, siteSearch }) => {
  const [filteredSites, setFilteredSites] = useState([]);
  const [countriesMap, setCountriesMap] = useState({});
  const [visibleMap, setVisibleMap] = useState({});

  useEffect(() => {
    const cObj = groupByCountry(filteredSites);
    setCountriesMap(cObj);

    if (!siteSearch) {
      return;
    }
    const newMap = getFilteredCountries(cObj);
    setVisibleMap(newMap);
  }, [filteredSites, siteSearch]);

  useEffect(() => {
    if (!siteSearch) {
      setFilteredSites(sites);
      setVisibleMap({});
      return;
    }
    setFilteredSites(
      sites.filter(
        site => `${site.countryCode} ${site.id} - ${site.name}`.toLowerCase().indexOf(siteSearch.toLowerCase()) !== -1
      )
    );
  }, [siteSearch, sites]);

  const toogleCountry = (countryCode) => {
    setVisibleMap({ ...visibleMap, [countryCode]: !visibleMap[countryCode] });

  };

  return (
    <>
      {Object.keys(countriesMap).filter((code) => !!countriesDict[code]).map((code) => {
        const flag = `flag flag-${code.toLowerCase()}`;
        return (
          <div key={code}>
            <div className="country-row">
              <i
                className={`${!visibleMap[code] ? 'vtmn-icon_arrow2_down' : 'vtmn-icon_arrow2_up'} clickableText`}
                onClick={() => toogleCountry(code)}
              ></i>
              <div className="country-flag">
                <img src={blankFlag} className={flag} alt={code} />
              </div>
              <span
                className="clickableText"
                onClick={() => toogleCountry(code)}
              >
                {countriesDict[code].name}
              </span>
            </div>
            {visibleMap[code] && countriesMap[code].map((site) => <SiteRow key={site.id} site={site} />)}
          </div>
        );
      })}
    </>
  )
};

const SitesMenuItem = ({ sites, user, updateTokens }) => {
  const { t } = useTranslation();
  const { store, warehouse } = useParams();
  const [siteSearch, setSiteSearch] = useState('');
  const [countriesDict, setCountriesDict] = useState({});
  const siteFilterRef = useRef();

  const [{
    error,
    data: countries,
  }, fetchCountries] = useRequest(getCountries);

  useEffect(() => {
    fetchCountries(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCountries]);

  useEffect(() => {
    if (!countries) {
      return;
    }
    const tmp = {};
    countries.forEach((country) => {
      tmp[country.code] = country;
    });
    setCountriesDict(tmp);
  }, [countries]);

  const getSelectedSiteTitle = () => {
    let selectedSiteTitle = 'Not selected';
    if (store) {
      const myStore = sites.filter(s => s.id.toString() === store);
      const storeName = (myStore[0] && myStore[0].name) || 'Unknown';
      selectedSiteTitle = `${store} - ${storeName}`;
    }
    if (warehouse) {
      const myWarehouse = sites.filter(s => s.id.toString() === warehouse);
      const warehouseName = (myWarehouse[0] && myWarehouse[0].name) || 'Unknown';
      selectedSiteTitle = `${warehouse} - ${warehouseName}`;
    }
    return selectedSiteTitle;
  };

  const onSiteSelectToggle = (isShow) => {
    if (!isShow) {
      return;
    }
    setSiteSearch('');
    setTimeout(() => {
      if (siteFilterRef && siteFilterRef.current) {
        siteFilterRef.current.focus();
      }
    }, 1);
  }

  return (
    <NavDropdown
      className="dropdown-site-select"
      title={getSelectedSiteTitle()}
      onToggle={onSiteSelectToggle}
    >
      <InputGroup id="site-filter" className="site-filter mb-1 pl-3 pr-3">
        <Form.Control
          placeholder={t('navbar.siteSelect.filter', 'filter...')}
          type="text"
          value={siteSearch}
          onChange={(event) => setSiteSearch(event.target.value)}
          ref={siteFilterRef}
        />
      </InputGroup>
      {error && <ErrorMessage error={error} />}
      {!error && (
        <CountryTree
          countriesDict={countriesDict}
          sites={sites}
          siteSearch={siteSearch}
        />
      )}
    </NavDropdown>
  );
};

export default SitesMenuItem;
