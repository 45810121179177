import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import useRequest from '../../../../../functions/useRequest';
import fetchWithJWT from '../../../../../functions/fetchWithJWT';
import handleApiResponse from '../../../../../functions/handleApiResponse';
import ErrorMessage from '../../../../../components/errorMessage';
import '../../../../../stylesheets/importLQT.css';

const dismissRequest = (user, updateTokens, warehouse, path) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/file/state`;
  return fetchWithJWT(url, {
    method: 'PUT',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body: JSON.stringify({
      path,
      status: 'finished',
    }),
  })
  .then(handleApiResponse);
}

const DismissErrorModal = ({
  user,
  updateTokens,
  warehouse,
  path,
  onClose,
  onConfirm,
  show,
}) => {
  const [{ error }, doImportRequest] = useRequest(dismissRequest, {
    onSuccess: onConfirm
  });

  const confirm = () => {
    doImportRequest(user, updateTokens, warehouse, path);
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          <Trans i18nKey="declareToControl.dismissError.warning">Warning</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans i18nKey="declareToControl.dismissError.text">
          Mark this error as read?
        </Trans>
        {error && <ErrorMessage error={error} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="m-0 mr-2" onClick={onClose}>
          <Trans i18nKey="declareToControl.dismissError.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" className="m-0 mr-2" onClick={confirm}>
          <Trans i18nKey="declareToControl.dismissError.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DismissErrorModal;
