import React, { useEffect } from 'react';
import SelectFilter from '../widgets/filters/SelectFilter';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import renderFlagItem from '../widgets/renderFlagItem';
import '../../stylesheets/storeSelect.css';

const getAvailableStores = (user, updateTokens, countryId) => {
  const { token, refreshToken, tokenExpireDate } = user;
  let url = `${process.env.REACT_APP_base_URL}/api/efficiency/stores`;
  if (countryId) {
    url += `?country_id=${countryId}`;
  }
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const StoreSelect = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  countryId,
  inverted,
  onInvert,
}) => {
  const [{
    loading,
    data,
    error,
  }, fetchAvailableStores] = useRequest(getAvailableStores);

  useEffect(() => {
    fetchAvailableStores(user, updateTokens, countryId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const options = (data && data.map(store => ({
    countryCode: store.country_code,
    label: `${store.id} - ${store.name}`,
    name: store.name,
    id: store.id,
  }))) || [];
  return (
    <SelectFilter
      invertable
      filterOpts
      inverted={inverted}
      onInvert={onInvert}
      multiple
      controlId="select-store-id"
      label={label}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      error={error}
      renderItem={renderFlagItem}
    />
  );
}

export default StoreSelect;
