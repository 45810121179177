import React, { useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const FiltersHead = ({
  filtered,
  isVisible,
  setVisibility,
  exportButton,
  selectColumns,
  generalFilters,
}) => {
  return (
    <Card.Header>
      {selectColumns}
      <Button
        size="sm"
        className={`icon-button filter-button ${filtered ? 'filter-button-filtered' : ''}`}
        onClick={() => setVisibility(isVisible ? 'hidden' : 'partial')}
      >
        <i className="vtmn-icon_filter2"></i>
        {filtered && (<i className="vtmn-icon_tiny_bold_valid subicon"></i>)}
        <Trans i18nKey="filters.filters">Filters</Trans>
        {isVisible ? (
          <i className="vtmn-icon_chevron_up_v2 right"></i>
        ) : (
          <i className="vtmn-icon_chevron_down_v2 right"></i>
        )}
      </Button>
      {generalFilters}
      <div className="spacer"/>
      {exportButton}
    </Card.Header>
  );
};

const FiltersButtons = ({ isFull, hasAdditinalFilters, setVisibility, onClear }) => {
  return (
    <div className="filter-button-container">
      <div className="spacer" />

      {hasAdditinalFilters && (
        <Button
          size="sm"
          className="icon-button filter-button filter-button-toggle"
          onClick={() => setVisibility(isFull ? 'partial' : 'full')}
        >
          {isFull ? (
            <Trans i18nKey="filters.less">Less</Trans>
          ) : (
            <Trans i18nKey="filters.more">More</Trans>
          )}
          {isFull ? (
            <i className="vtmn-icon_chevron_up_v2 right"></i>
          ) : (
            <i className="vtmn-icon_chevron_down_v2 right"></i>
          )}
        </Button>
      )}

      <Button
        size="sm"
        className="icon-button filter-button filter-button-clear"
        onClick={onClear}
      >
        <i className="vtmn-icon_cross"></i>
        <Trans i18nKey="filters.clear">Clear</Trans>
      </Button>
    </div>
  );
};

const FiltersPannel = ({
  filtered,
  onClear,
  mainFilters,
  aditionalFilters,
  exportButton,
  selectColumns,
  generalFilters,
}) => {
  const [visibility, setVisibility] = useState('partial');
  const isVisible = visibility !== 'hidden';
  const isFull = visibility === 'full';
  return (
    <Card className={`filter-card filter-card-${visibility} mb-3 mt-0`}>
      <FiltersHead
        filtered={filtered}
        isVisible={isVisible}
        setVisibility={setVisibility}
        exportButton={exportButton}
        selectColumns={selectColumns}
        generalFilters={generalFilters}
      />
      {isVisible && (
        <Card.Body>
          <Row>
            {mainFilters}
            {isFull && aditionalFilters}
            <FiltersButtons
              hasAdditinalFilters={!!aditionalFilters}
              isFull={isFull}
              setVisibility={setVisibility}
              onClear={onClear}
            />
          </Row>
        </Card.Body>
      )}
    </Card>
  );
}

export default FiltersPannel;
