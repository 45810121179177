import React from 'react';
import blankFlag from "../../stylesheets/blankFlag.gif";

const renderFlagItem = (option, props, index) => (
  <div className="d-flex">
    <div className="country-flag">
      <img
        src={blankFlag}
        className={`flag flag-${option.countryCode.toLowerCase()}`}
        alt={option.countryCode}
      />
    </div>
    <div className="ml-2 site-label">
      {option.label}
    </div>
  </div>
);

export default renderFlagItem;
