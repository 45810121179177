const getFiltersWithInverted = (filters, invertedFilters, snakeCase) => {
  if (!invertedFilters) {
    return filters;
  }

  let result = { ...filters };
  Object.entries(invertedFilters).forEach(([key, value]) => {
    if (value) {
      const newKey = snakeCase
        ? `not_${key[0]}${key.slice(1)}`
        : `not${key[0].toUpperCase()}${key.slice(1)}`;
      result[newKey] = result[key];
      delete result[key];
    }
  });
  return result;
};

export default getFiltersWithInverted;
