import React from 'react';

import medalIcon from '../../images/dashboard_icons/medal.png';
import winnerIcon from '../../images/dashboard_icons/winner.png';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../functions/formatDate';

const NAME_RX = /^(.*) \((.+)\)$/;

const TopPerformer = ({ leader, date, productivity }) => {
  const { t } = useTranslation();
  const [, name,] = leader && NAME_RX.exec(leader);
  return (
    <div className="efficiency-card efficiency-performer d-flex flex-row">
      <div className="efficiency-card-icon-wrap">
        <img
          className="efficiency-card-icon"
          src={medalIcon}
          alt="icon"
        />
      </div>
      <div className="efficiency-performer-title flex-fill">
        <h4>{t('efficiencyDashboard.topPerformer', 'Top performer')}</h4>
      </div>
      <div className="efficiency-card-icon-wrap">
        <img
          className="efficiency-card-icon"
          src={winnerIcon}
          alt="icon"
        />
      </div>

      <div className="efficiency-card-content flex-fill d-flex flex-column">
        <div className="efficiency-performer-name">{name}</div>
        <div className="efficiency-performer-performance">
          {productivity}
          {' '}
          {t('efficiencyDashboard.topPerformerUnit', 'articles per hour')}
        </div>
        <div className="efficiency-performer-date">{date ? formatDate(new Date(date)) : null}</div>
      </div>

    </div>
  )
}

export default TopPerformer;
