import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button, Card } from 'react-bootstrap';
import ProgressBar from './ProgressBar';
import ProgressDetailsContent from './unexpectedTab/ProgressDetailsContent';
import '../../stylesheets/liveReception.css';

const ReadingRateModal = ({
  show,
  onHide,
  delivery,
  sport,
  pallet,
  indicator,
  currency,
}) => {
  const { t } = useTranslation();
  const readInWarehouseLabel = t(
    'liveStoreReception.progress.readInWarehouse',
    'Read in warehouse:',
  );
  const readByGateLabel = t(
    'liveStoreReception.progress.readInStore',
    'Read in store:',
  );
  const readByRobotLabel = t(
    'liveStoreReception.progress.readByRobot',
    'Found by stock movement:',
  );
  const confirmedMissingLabel = t(
    'liveStoreReception.progress.confirmedMissing',
    'Confirmed missing:',
  );
  const revisedAtMovementsLabel = t(
    'liveStoreReception.progress.revisedAtMovements',
    'Has movement correction:',
  );
  const notCheckedLabel = t(
    'liveStoreReception.progress.notChecked',
    'Not checked:',
  );
  let title = t(
    'liveStoreReception.readingRateModal.totalTitle',
    {
      defaultValue: 'Delivery {{delivery}} reading rate',
      delivery,
    }
  );
  if (sport) {
    title = t(
      'liveStoreReception.readingRateModal.sportTitle',
      {
        defaultValue: 'Delivery {{delivery}} - {{sport}} reading rate',
        delivery,
        sport,
      }
    );
  } else if (pallet) {
    title = t(
      'liveStoreReception.readingRateModal.palletTitle',
      {
        defaultValue: 'Delivery {{delivery}} - pallet {{pallet}} reading rate',
        delivery,
        pallet,
      }
    );
  }
  if (!indicator) {
    return null;
  }
  const {
    receivedWithTagTotalQties,
    warehouseWithTagTotalQties,
    foundAtMovementsQties,
    missingAtMovementsQties,
    revisedAtMovementsQties,
    leftQties,
    receivedValue,
    warehouseValue,
    foundAtMovementsValue,
    missingAtMovementsValue,
    revisedAtMovementsValue,
    leftValue,
  } = indicator;

  return (
    <Modal
      className="readingRateModal"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-0 pr-0">
        <div className="row">
          <div className="col">
            <ProgressBar indicator={indicator} currency={currency} />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col text-right">
            {readInWarehouseLabel}
          </div>
          <div className="col">
            {warehouseWithTagTotalQties}
            {warehouseValue ? ` = ${warehouseValue} ${currency}` : null}
          </div>
        </div>

        <div className="row">
          <div className="col text-right">
            {readByGateLabel}
          </div>
          <div className="col">
            {receivedWithTagTotalQties}
            {receivedValue ? ` = ${receivedValue} ${currency}` : null}
          </div>
        </div>

        <div className="row">
          <div className="col text-right">
            {readByRobotLabel}
          </div>
          <div className="col">
            {foundAtMovementsQties}
            {foundAtMovementsValue ? ` = ${foundAtMovementsValue} ${currency}` : null}
          </div>
        </div>

        <div className="row">
          <div className="col text-right">
            {confirmedMissingLabel}
          </div>
          <div className="col">
            {missingAtMovementsQties}
            {missingAtMovementsValue ? ` = ${missingAtMovementsValue} ${currency}` : null}
          </div>
        </div>

        <div className="row">
          <div className="col text-right">
            {revisedAtMovementsLabel}
          </div>
          <div className="col">
            {revisedAtMovementsQties}
            {revisedAtMovementsValue ? ` = ${revisedAtMovementsValue} ${currency}` : null}
          </div>
        </div>



        <div className="row">
          <div className="col text-right">
            {notCheckedLabel}
          </div>
          <div className="col">
            {leftQties}
            {leftValue ? ` = ${leftValue} ${currency}` : null}
          </div>
        </div>

        {indicator && indicator.unexpected && (
          <div className="unexpected-summary-content">
            <Card.Title>
              <Trans i18nKey="liveStoreReception.card.headers.unexpected">Unexpected</Trans>
            </Card.Title>
            <ProgressDetailsContent
              indicator={indicator.unexpected}
              currency={currency}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Button variant="secondary" onClick={onHide}>
          <Trans i18nKey="liveStoreReception.readingRateModal.close">Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default ReadingRateModal;
