import {
  useReducer,
  useCallback,
  useRef,
  useEffect,
} from 'react';

const REQUEST_START = 'REQUEST_START';
const REQUEST_DONE = 'REQUEST_DONE';
const REQUEST_ERROR = 'REQUEST_ERROR';
const SET_FILTERS = 'SET_FILTERS';
const SET_PAGINATION = 'SET_PAGINATION';
const SET_SORT = 'SET_SORT';

const defaultInitialState = {
  requestId: 0,
  loading: false,
  data: [],
  error: null,
  filters: {},
  sort: undefined,
  pagination: {
    page: 0,
    size: 20,
  },
  totals: {
    items: 0,
    pages: 0,
  },

};

let counter = 0;

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
        pagination: {
          ...state.pagination,
          page: 0,
        },
        totals: {
          items: 0,
          pages: 0,
        },
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case REQUEST_START:
      return {
        ...state,
        loading: true,
        error: null,
        requestId: action.payload.requestId,
      };
    case REQUEST_DONE: {
      const { data, contentRange, requestId } = action.payload;
      if (requestId < state.requestId) {
        return state;
      }
      const { size, page } = state.pagination;
      const nextData = (page > 0 && state.data) ? [...state.data, ...data] : data;
      const items = contentRange.max + 1;
      const pages = Math.max(1, Math.ceil(items / size));
      const totals = {
        items,
        pages,
      };
      return {
        ...state,
        loading: false,
        error: null,
        data: nextData,
        totals,
      };
    }
    case REQUEST_ERROR:
      return { ...state, loading: false, data: [], error: action.error };
    default:
      throw new Error('error');
  }
};

const loadMoreRequest = async (
  fetcher,
  dispatch,
  isMountedRef,
  user,
  updateTokens,
  filters,
  pagination,
  sort,
  args,
) => {
  try {
    counter += 1;
    const requestId = counter;
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_START, payload: { requestId } });
    const { result, contentRange } = await fetcher(user, updateTokens, { filters, pagination, sort }, ...args);
    if (!isMountedRef.current) {
      return;
    }
    dispatch({
      type: REQUEST_DONE,
      payload: {
        data: result,
        contentRange,
        requestId,
      },
    });
    return result;
  } catch (error) {
    if (!isMountedRef.current) {
      return;
    }
    dispatch({ type: REQUEST_ERROR, error });
    return;
  }
};

const setFilters = (dispatch) => (filters) => dispatch({
  type: SET_FILTERS,
  payload: filters,
});

const setPagination = (dispatch) => (pagination) => dispatch({
  type: SET_PAGINATION,
  payload: pagination,
});

const setSort = (dispatch) => (sort) => dispatch({
  type: SET_SORT,
  payload: sort,
});

export const useLoadMoreRequest = (
  fetcher,
  {
    initialState,
  } = {},
) => {
  const initialIState = {
    ...defaultInitialState,
    ...initialState,
  };
  const isMountedRef = useRef(true);
  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);
  const [state, dispatch] = useReducer(reducer, initialIState);

  const requestCallback = (
    user,
    updateTokens,
    { filters, pagination, sort } = {},
    ...args
  ) => loadMoreRequest(
    fetcher,
    dispatch,
    isMountedRef,
    user,
    updateTokens,
    filters,
    pagination,
    sort,
    args,
  );
  const memoizedRequestCallback = useCallback(requestCallback, [fetcher]);
  /* eslint-disable react-hooks/exhaustive-deps */
  const memoizedSetFilters = useCallback(setFilters(dispatch), []);
  const memoizedSetPagination = useCallback(setPagination(dispatch), []);
  const memoizedSetSort = useCallback(setSort(dispatch), []);
  /* eslint-enable react-hooks/exhaustive-deps */
  return [
    state,
    memoizedRequestCallback,
    memoizedSetFilters,
    memoizedSetPagination,
    memoizedSetSort,
  ];
};

export default useLoadMoreRequest;
