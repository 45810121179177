import React, { Component } from 'react';
import _ from 'lodash';
import "../../stylesheets/palletDetails.css";
import { DisplayItem, calculateItemProps } from "./displayItem";

export default class PalletDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      parcelsForceDisplayItems: [],
      loading: false,
      parcels: [],
      parcelIndicators: {},
    }
  }

  componentDidMount() {
    const parcelIndicators = {};
    this.props.parcels.forEach((parcelID) => {
      const parcelIndicator = this.calculateParcelIndicators(parcelID);
      parcelIndicators[parcelID] = parcelIndicator;
    });
    const sortedParcels = this.props.parcels.sort(
      (a, b) => {
        const expectedWithTagTotalQtiesA = parcelIndicators[a].expectedWithTagTotalQties;
        const expectedWithTagTotalQtiesB = parcelIndicators[b].expectedWithTagTotalQties;
        const receivedWithTagTotalQtiesA = parcelIndicators[a].receivedWithTagTotalQties;
        const receivedWithTagTotalQtiesB = parcelIndicators[b].receivedWithTagTotalQties;
        const missingA = expectedWithTagTotalQtiesA - receivedWithTagTotalQtiesA;
        const missingB = expectedWithTagTotalQtiesB - receivedWithTagTotalQtiesB;
        return missingB - missingA;
      }
    );
    this.setState({
      parcelIndicators,
      parcels: sortedParcels,
    });
  }

  parcelHasVisibleItems = (parcelID) => {
    const { filters } = this.props;
    const parcelIndicators = this.state.parcelIndicators[parcelID];
    if (!parcelIndicators) {
      return false;
    }
    const displayFullyReceivedItems = this.state.parcelsForceDisplayItems.includes(parcelID);

    const hasVisibleItems = parcelIndicators.uniqueItems.map((item_id) => {
      const itemDetails = this.props.itemDetails.filter(items => items.item_id === item_id)[0];
      const allParcels = this.props.pickingLines.filter(pickingLine => pickingLine.parcel === parcelID && pickingLine.item === item_id);
      const movement = this.props.movements[item_id];
      const { visible } = calculateItemProps({ allParcels, itemDetails, movement, filters, displayFullyReceivedItems });
      return visible;
    }).filter(x => x).length > 0;
    return hasVisibleItems;
  }

  displayParcel = (parcelID, hasVisibleItems) => {
    const { filters, reception } = this.props;
    const parcelIndicators = this.state.parcelIndicators[parcelID];
    if (!parcelIndicators) {
      return;
    }
    const filtered = _.some(filters);
    if (filtered && !hasVisibleItems) {
      return;
    }

    const displayFullyReceivedItems = this.state.parcelsForceDisplayItems.includes(parcelID);
    const fullyReceived = parcelIndicators.myPickingLinesWithTag.find(pickingLine => pickingLine.qty_read_by_store_gate < pickingLine.qty_confirmed) === undefined;
    let classTitle = "col-11";
    if (fullyReceived){
      classTitle += " text-success"
    }
    return(
      <div key={parcelID} className="card card-container" >
        <div className="card-header row align-items-start">
          <div className={classTitle}>{parcelID}</div>
          {this.displayPlus(parcelID, parcelIndicators, displayFullyReceivedItems, fullyReceived, hasVisibleItems)}
        </div>
        <ul className="itemInParcel">
          {parcelIndicators.uniqueItems.map((item_id, index) => (
            <DisplayItem
              reception={reception}
              key={item_id}
              filters={this.props.filters}
              filterValues={this.props.filterValues}
              itemDetails={this.props.itemDetails.filter(items => items.item_id === item_id)[0]}
              allParcels={
                this.props.pickingLines
                  .filter(pickingLine => pickingLine.parcel === parcelID && pickingLine.item === item_id)
              }
              displayFullyReceivedItems={displayFullyReceivedItems}
              warehouseReadingActivated={this.props.warehouseReadingActivated}
              user={this.props.user}
              updateTokens={this.props.updateTokens}
              store={this.props.store}
              movement={this.props.movements[item_id]}
            />
          ))}
        </ul>
      </div>
    )
  }

  displayPlus = (parcelID, parcelIndicators, displayFullyReceivedItems, fullyReceived, hasVisibleItems) => {
    if (
      displayFullyReceivedItems
      || parcelIndicators.myPickingLines.find(pickingLine => pickingLine.qty_read_by_store_gate >= pickingLine.qty_confirmed)
      || fullyReceived
      || !hasVisibleItems
    ) {
      if (!this.state.parcelsForceDisplayItems.includes(parcelID)){
        return (
          <div className="textRightAligned clickableText" onClick={(event) => this.hideUnhideItems(parcelID)}><i className="vtmn-icon_edit_plus"></i></div>
        )
      } else {
        return (
          <div className="textRightAligned clickableText" onClick={(event) => this.hideUnhideItems(parcelID)}><i className="vtmn-icon_edit_minus"></i></div>
        )
      }
    }
  }

  calculateParcelIndicators = (parcelID) => {
    const myPickingLines = this.props.pickingLines.filter(pickingLine => pickingLine.parcel === parcelID);
    const uniqueItems = [...new Set(myPickingLines.map(pickingLine => pickingLine.item))];
    const myItemsDetails = this.props.itemDetails.filter(item => uniqueItems.includes(item.item_id));
    const myItemsDetailsWithTag = myItemsDetails.filter(item => item.article_flag === 'P' || item.article_flag === 'L');
    const uniqueItemsWithTag = [...new Set(myItemsDetailsWithTag.map(itemDetails => itemDetails.item_id))];
    const myPickingLinesWithTag = myPickingLines.filter(pickingLine => uniqueItemsWithTag.includes(pickingLine.item));
    const sortedUniqueItems = _.orderBy(
      myPickingLines,
      [
        (item) => {
          const details = myItemsDetails.filter((details) => details.item_id === item.item);
          const price = details.length ? details[0].price : 0;
          return (item.qty_confirmed - item.qty_read_by_store_gate) * price;
        },
        (item) => {
          const details = myItemsDetails.filter((details) => details.item_id === item.item);
          const price = details.length ? details[0].price : 0;
          return item.qty_confirmed * price;
        },
      ],
      ['desc', 'desc'],
    ).map(({ item }) => item);

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const expectedTotalQties = myPickingLines.map(pickingLine => pickingLine.qty_confirmed).reduce(reducer, 0);
    const expectedWithTagTotalQties = myPickingLinesWithTag.map(pickingLine => pickingLine.qty_confirmed).reduce(reducer, 0);
    const receivedTotalQties = myPickingLines.map(pickingLine => pickingLine.qty_read_by_store_gate).reduce(reducer, 0);
    const receivedWithTagTotalQties = myPickingLinesWithTag.map(pickingLine => pickingLine.qty_read_by_store_gate).reduce(reducer, 0);

    return {
      myItemsDetails: myItemsDetails,
      uniqueItems: sortedUniqueItems,
      uniqueItemsWithTag: uniqueItemsWithTag,
      expectedTotalQties: expectedTotalQties,
      expectedWithTagTotalQties: expectedWithTagTotalQties,
      receivedTotalQties: receivedTotalQties,
      receivedWithTagTotalQties: receivedWithTagTotalQties,
      myPickingLines: myPickingLines,
      myPickingLinesWithTag: myPickingLinesWithTag
    }
  }

  hideUnhideItems = (parcelID) => {
    let parcelsForceDisplayItems = this.state.parcelsForceDisplayItems;
    if (parcelsForceDisplayItems.includes(parcelID)){
      parcelsForceDisplayItems = parcelsForceDisplayItems.filter(e => e !== parcelID);
      this.setState({
        parcelsForceDisplayItems: parcelsForceDisplayItems
      })
    } else {
      parcelsForceDisplayItems.push(parcelID);
      this.setState({
        parcelsForceDisplayItems: parcelsForceDisplayItems
      })
    }
  }

  render(){
    let parcels = this.props.parcels;
    if(this.state.parcels.length > 0){
      parcels = this.state.parcels;
    }
    const parcelsHasVisibleItems = parcels.map(parcelID => this.parcelHasVisibleItems(parcelID));

    return(
      <div className = "pallet">
        {parcels.map((parcelID, index) => this.displayParcel(parcelID, parcelsHasVisibleItems[index]))}
      </div>
    )
  }
}
