export const arrayMax = (array) => array.reduce((a, b) => a > b ? a : b, 0);

export const inventoryIdToDate = (inventoryId) => {
  if (!inventoryIdToDate || !/^(\d){12}$/.test(inventoryId)) {
    return null;
  }
  const year = inventoryId.substr(0, 4);
  const month = parseInt(inventoryId.substr(4, 2), 10) -1;
  const day = inventoryId.substr(6, 2);
  const hours = inventoryId.substr(8, 2);
  const minutes = inventoryId.substr(10, 2);
  const date = new Date(
    year,
    month,
    day,
    hours,
    minutes,
  );
  return date;
};

export const getPercentAutoImpacted = (history) => {
  const percentAutoImpacted = history.map(x => {
    const modelsTotal = (
      (x.autoImpactModels || 0)
      + (x.determinationInProgressModels || 0)
      + (x.noAutoImpactModels || 0)
    );
    return x.autoImpactModels * 100 / modelsTotal || 0;
  });
  return percentAutoImpacted;
};
