import React from 'react';
import _ from 'lodash';
import { Badge, Button } from 'react-bootstrap';
import { formatDate, formatDurationSec } from '../../functions/formatDate';
import { Trans } from 'react-i18next';
import formatMoney from '../../functions/formatMoney';
import { getStatusMap, getTypesMap } from './statuses';

const moneyRenderer = (val, field, row) => formatMoney(val, row.currency);
export const dateRenderer = (val) => val ? formatDate(new Date(val)) : null;
const numberRenderer = (val) => _.isNil(val) ? null : Number(val.toFixed(2));

export const getFields = ({ t, askValidation }) => {
  const statusMap = getStatusMap(t);
  const typesMap = getTypesMap(t);
  return [
    {
      title: t('efficiencyAdmin.columns.recordType ', 'Record type '),
      field: 'type',
      sortable: true,
      default: true,
      valueRenderer: (val, field, row) => (
        <h6 className={`m-0 ${val === 'incorrect' ? 'measurement-type' : ''}`}>
          <Badge
            className='incorrect-type-wrapper'
            variant={typesMap[val].variant}
            onClick={() => val === 'incorrect' && askValidation(row.id)}
          >
              <span className='type-text'>{typesMap[val].label || val}</span>
              {val === 'incorrect' && <i className="vtmn-icon_edit"></i>}
          </Badge>
          <br/>
        </h6>
      )
    },
    {
      title: t('efficiencyAdmin.columns.date', 'Date'),
      field: 'unloading_start_date',
      sortable: true,
      default: true,
      valueRenderer: dateRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.store', 'Store'),
      field: 'site_id',
      sortable: true,
      default: true,
      valueRenderer: (val, field, row) => `${val} - ${row.site_name}`,
    },
    {
      title: t('efficiencyAdmin.columns.leader', 'Leader'),
      field: 'leader',
      sortable: true,
      default: true,
    },
    {
      title: t('efficiencyAdmin.columns.warehouse', 'Warehouse'),
      field: 'warehouse_id',
      sortable: true,
      default: false,
    },
    {
      title: t('efficiencyAdmin.columns.deliveries', 'Deliveries'),
      field: 'deliveries',
      default: true,
      valueRenderer: (val) => (
        <div className="deliveries-grid">
          {
            val.map((d) => (
              <Badge pill variant="primary" className="deliveries-badge">
                {d.id}
              </Badge>
            ))
          }
        </div>
      )
    },
    {
      title: t('efficiencyAdmin.columns.quantities', 'Quantities'),
      field: 'quantities',
      sortable: true,
      default: true,
    },
    {
      title: t('efficiencyAdmin.columns.parcels', 'Parcels'),
      field: 'parcels',
      sortable: true,
      default: false,
    },
    {
      title: t('efficiencyAdmin.columns.pallets', 'Pallets'),
      field: 'pallets',
      sortable: true,
      default: false,
    },
    {
      title: t('efficiencyAdmin.columns.unloadingTeammates', 'Unloading teammates'),
      field: 'unloading_teammates',
      sortable: true,
      default: true,
      valueRenderer: (val, field, row) => `${val} (${numberRenderer(row.unloading_teammates_involved)})`,
    },
    {
      title: t('efficiencyAdmin.columns.shelvingTeammates', 'Shelving teammates'),
      field: 'shelving_teammates',
      sortable: true,
      default: true,
      valueRenderer: (val, field, row) => `${val} (${numberRenderer(row.shelving_teammates_involved)})`,
    },
    {
      title: t('efficiencyAdmin.columns.plannedOverallProductivity', 'Planned overall productivity'),
      field: 'planned_overall_productivity',
      sortable: true,
      default: false,
      valueRenderer: numberRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.plannedUnloadingProductivity', 'Planned unloading productivity'),
      field: 'planned_unloading_productivity',
      sortable: true,
      default: false,
      valueRenderer: numberRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.plannedShelvingProductivity', 'Planned shelving productivity'),
      field: 'planned_shelving_productivity',
      sortable: true,
      default: false,
      valueRenderer: numberRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.plannedUnloadingInvestment', 'Planned unloading investment'),
      field: 'planned_unloading_investment',
      sortable: true,
      default: false,
      valueRenderer: moneyRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.plannedShelvingInvestment', 'Planned shelving investment'),
      field: 'planned_shelving_investment',
      sortable: true,
      default: false,
      valueRenderer: moneyRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.plannedTotalInvestment', 'Planned total investment'),
      field: 'planned_total_investment',
      sortable: true,
      default: false,
      valueRenderer: moneyRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.plannedUnloadingDuration', 'Planned unloading duration'),
      field: 'planned_unloading_duration',
      sortable: true,
      default: false,
      valueRenderer: formatDurationSec,
    },
    {
      title: t('efficiencyAdmin.columns.plannedShelvingDuration', 'Planned shelving duration'),
      field: 'planned_shelving_duration',
      sortable: true,
      default: false,
      valueRenderer: formatDurationSec,
    },
    {
      title: t('efficiencyAdmin.columns.plannedTotalDuration', 'Planned total duration'),
      field: 'planned_total_duration',
      sortable: true,
      default: false,
      valueRenderer: formatDurationSec,
    },
    {
      title: t('efficiencyAdmin.columns.unloadingDuration', 'Unloading duration'),
      field: 'unloading_duration',
      sortable: true,
      default: false,
      valueRenderer: formatDurationSec,
    },
    {
      title: t('efficiencyAdmin.columns.shelvingDuration', 'Shelving duration'),
      field: 'shelving_duration',
      sortable: true,
      default: false,
      valueRenderer: formatDurationSec,
    },
    {
      title: t('efficiencyAdmin.columns.totalDuration', 'Total duration'),
      field: 'total_duration',
      sortable: true,
      default: true,
      valueRenderer: formatDurationSec,
    },
    {
      title: t('efficiencyAdmin.columns.overallProductivity', 'Overall productivity'),
      field: 'overall_productivity',
      sortable: true,
      default: true,
      valueRenderer: numberRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.productivity', 'Shelving productivity'),
      field: 'shelving_productivity',
      sortable: true,
      default: true,
      valueRenderer: numberRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.unloadingInvestment', 'Unloading investment'),
      field: 'unloading_investment',
      sortable: true,
      default: false,
      valueRenderer: moneyRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.shelvingInvestment', 'Shelving investment'),
      field: 'shelving_investment',
      sortable: true,
      default: false,
      valueRenderer: moneyRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.totalInvestment', 'Total investment'),
      field: 'total_investment',
      sortable: true,
      default: true,
      valueRenderer: moneyRenderer,
    },
    {
      title: t('efficiencyAdmin.columns.status', 'Status'),
      field: 'status',
      sortable: true,
      default: true,
      valueRenderer: (val) => (
        <h6 className="m-0">
          <Badge pill variant={statusMap[val].variant}>
            {statusMap[val].label || val}
          </Badge>
        </h6>
      )
    },
  ];
}

const getActionColumn = ({ onEdit, onDel }) => ({
  title: null,
  field: 'action',
  sortable: false,
  width: 150,
  valueRenderer: (val, field, row) => (
    <div className="d-flex justify-content-center">
      <Button size="sm" className="m-0 mx-1" onClick={() => onEdit(row)}>
        <Trans i18nKey="efficiencyAdmin.edit">Edit</Trans>
      </Button>
      <Button variant="danger" size="sm" className="m-0 mx-1" onClick={() => onDel(row)}>
        <Trans i18nKey="efficiencyAdmin.del">Del</Trans>
      </Button>
    </div>
  )
})

export const getDefaultSelection = (fields) => {
  const defaultSelection = {};
  for (const { field } of fields.filter(x => x.default)) {
    defaultSelection[field] = true;
  }
  return defaultSelection;
};

export const getSelectedFields = ({ fields, selection, onEdit, onDel }) => {
  const selected = fields.filter(x => selection[x.field]);
  selected.push(getActionColumn({ onEdit, onDel }))
  return selected;
};
