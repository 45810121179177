import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import getPilotSites from "../functions/getPilotSites.js";
import handleApiResponse from '../functions/handleApiResponse';
import fetchWithJWT from '../functions/fetchWithJWT';
import SearchBar from './widgets/SearchBar';
import ErrorMessage from './errorMessage';
import Loader from './loader';
import "../stylesheets/flags.css";
import "../stylesheets/chooseStore.css";
import blankFlag from "../stylesheets/blankFlag.gif";

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  }).then(handleApiResponse);
  return countries;
};

export default class ChooseStore extends Component {
  state = {
    search: '',
    sites: [],
    error: null,
    loading: true,
    countriesDict: {},
    visibleMap: {},
  }

  componentDidMount(){
    const { history, user } = this.props;
    const adminAccess = user.rights.indexOf('adminPanel') > -1;
    const savedSelectedSite = localStorage.selectedSite !== 'undefined' ? localStorage.selectedSite : undefined;
    const url = window.location.href;
    const isFromAuth = url.indexOf('?code=') !== -1 && url.indexOf('&state=') !== -1;

    if ((savedSelectedSite && isFromAuth) || !adminAccess) {
      const site = JSON.parse(savedSelectedSite || null);
      this.redirectToSiteHome(site);
    } else {
      if (isFromAuth) {
        // cleanup address sile after
        history.push('/');
      }
      this.loadSites();
      this.loadCountries();
    }
  }

  redirectToSiteHome = async (site) => {
    const { history, logOut } = this.props;
    let selectedSite = site;
    try {
      this.setState({ loading: true });
      const sites = await getPilotSites(this.props.user, this.props.updateTokens);
      this.setState({ loading: false });
      if (!sites.length) {
        logOut();
      }
      selectedSite = sites.find(x => site && x.id === `${site.id}`) || sites[0];
      localStorage.selectedSite = JSON.stringify(selectedSite);
    } catch (e) {
      this.setState({ loading: false });
    }

    const linkto = selectedSite.isWarehouse
      ? `/${selectedSite.countryId}/warehouse/${selectedSite.id}/contenants/control`
      : `/${selectedSite.countryId}/${selectedSite.id}/real_time/store_reception`;
    history.push(linkto);
  }

  loadSites = () => {
    this.setState({ loading: true });
    getPilotSites(this.props.user, this.props.updateTokens)
    .then(sites => {
      this.setState({ sites: sites || [], loading: false });
    })
    .catch(error => {
      this.setState({ error, loading: false });
      console.warn(`Error while searching for sites`);
    });
  }

  updateCountries = (sites) => {
    const countries = {};
    sites.forEach((site) => {
      if (!countries[site.countryCode]) {
        countries[site.countryCode] = [site];
      } else {
        countries[site.countryCode].push(site);
      }
    });
    return Object.keys(countries).length ? countries : null;
  }

  loadCountries = () => {
    this.setState({ loading: true });
    getCountries(this.props.user, this.props.updateTokens)
    .then((c) => {
      const countriesDict = {};
      c.forEach((country) => {
        countriesDict[country.code] = country;
      });
      this.setState({ countriesDict, loading: false });
    })
    .catch(error => {
      this.setState({ error, loading: false });
      console.warn(`Error countries loading`);
    });
  }

  onSiteClick = (site) => {
    localStorage.selectedSite = JSON.stringify(site);
  }

  toogleCountry = (countryCode) => {
    const { visibleMap } = this.state;
    this.setState({ visibleMap: { ...visibleMap, [countryCode]: !visibleMap[countryCode] } })
  }

  displaySite = (site) => {
    const linkto = site.isWarehouse
      ? `/${site.countryId}/warehouse/${site.id}/contenants/control`
      : `/${site.countryId}/${site.id}/real_time/store_reception`;
    const flag = `flag flag-${site.countryCode.toLowerCase()}`;
    return(
      <tr key={site.id}>
        <td>&nbsp;</td>
        <td>
          <Link to={linkto} onClick={() => this.onSiteClick(site)}>
            {site.id}
          </Link>
        </td>
        <td>
          <Link to={linkto} onClick={() => this.onSiteClick(site)}>
            {this.capitalize(site.name)}
          </Link>
        </td>
        <td>
          <div className="country-flag">
            <img src={blankFlag} className={flag} alt={site.countryCode} />
          </div>
        </td>
      </tr>
    )
  }

  renderCountryRow = (countryCode) => {
    const { visibleMap, countriesDict } = this.state;
    const flag = `flag flag-${countryCode.toLowerCase()}`;

    return (
        <tr key={countryCode}>
          <td>
            <i
              className={`${!visibleMap[countryCode] ? 'vtmn-icon_arrow2_down' : 'vtmn-icon_arrow2_up'} clickableText`}
              onClick={() => this.toogleCountry(countryCode)}
            ></i>
          </td>
          <td colSpan={2}>
            <span
              className="clickableText"
              onClick={() => this.toogleCountry(countryCode)}
            >
              {countriesDict[countryCode] && countriesDict[countryCode].name}
            </span>
          </td>
          <td>
            <div className="country-flag">
              <img src={blankFlag} className={flag} alt={countryCode} />
            </div>
          </td>
        </tr>
    );
  }

  renderCountryTree = (countries) => {
    const { visibleMap } = this.state;
    const view = [];
    for(let countryCode in countries) {
      view.push(
        this.renderCountryRow(countryCode),
        visibleMap[countryCode] && countries[countryCode].map(this.displaySite)
      );
    }
    return view;
  }

  capitalize = (name) => {
    const splitted = name.split(" ");
    return splitted.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ")
  }

  filterSites = (sites, search) => {
    if (!sites) {
      return [];
    }
    if (!search) {
      return sites;
    }
    const filtered = sites.filter(site => {
      const toSearch = `${site.id} ${site.name}`.toLowerCase();
      const matches = toSearch.indexOf(search.toLowerCase()) !== -1;
      return matches;
    })
    return filtered;
  }

  render() {
    const { error, search, sites, loading } = this.state;
    const filtered = this.filterSites(sites, search);
    const countries = this.updateCountries(filtered);

    return(
      <div className="container chooseStore pt-2">
        <SearchBar
          loading={loading}
          value={search}
          onChange={(search) => this.setState({ search })}
          onRefresh={this.loadSites}
        />
        {error && <ErrorMessage error={error} />}
        {(!countries || loading) && <Loader />}
        {countries && !loading && !error && (
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="arrow-column">&nbsp;</th>
                <th scope="col" className="site-column">
                  <Trans i18nKey="chooseStore.site">Site</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="chooseStore.description">Description</Trans>
                </th>
                <th scope="col" className="country-column">
                  <Trans i18nKey="chooseStore.country">Country</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderCountryTree(countries)}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}
