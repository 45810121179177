import React from 'react';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import { formatDateTime } from '../../functions/formatDate';
import { arrayMax, inventoryIdToDate, getPercentAutoImpacted } from './functions';

const Info = ({ history, report }) => {
  const inventoryIds = report.inventories
    .filter(x => x.inventoryId !== 'RETURN')
    .map(x => x.inventoryId);
  const { totalUnsoldTags } = report;
  const lastInventoryId = arrayMax(inventoryIds);
  const lastRead = inventoryIdToDate(lastInventoryId);
  const totalUnsoldTagsList = history.map(x => x.totalUnsoldTags);
  const maxTags = arrayMax(totalUnsoldTagsList);
  const percentAutoImpacted = getPercentAutoImpacted(history);
  const avgPercentAutoImpacted = _.mean(percentAutoImpacted.filter(x => x)) || 0;

  return (
    <>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringRobot.info.lastRead">Date of the last read:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {formatDateTime(lastRead)}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringRobot.info.maxLabel">Max of selected period:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {maxTags}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringRobot.info.currentReads">Current amount of tags:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {totalUnsoldTags}
        </div>
      </div>
      <div className="row">
        <div className="col info-label">
          <Trans i18nKey="monitoringRobot.info.avgPercentAutoImpacted">Average models auto-impacted:</Trans>
          {' '}
        </div>
        <div className="col info-value">
          {avgPercentAutoImpacted.toFixed(1)}
          {'%'}
        </div>
      </div>
    </>
  )
};

export default Info;
