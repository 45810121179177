import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter from './SelectFilter';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';

const getSectors = (user, updateTokens, { warehouse, byDiscrepancies }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/sectors?by_discrepancies=${byDiscrepancies}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const WarehouseSectorFilter = ({
  user,
  updateTokens,
  disabled,
  selected,
  onChange,
  label,
  controlId,
  placeholder,
  warehouse,
  byDiscrepancies,
}) => {
  const { t } = useTranslation();
  const [{
    loading,
    data,
    error,
  }, fetchUniverses] = useRequest(getSectors);

  useEffect(() => {
    fetchUniverses(user, updateTokens, { warehouse, byDiscrepancies });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, byDiscrepancies]);

  const options = (data && data.map(({ warehouse_sector }) => ({
    label: warehouse_sector,
    name: warehouse_sector,
    id: warehouse_sector,
  }))) || [];

  return (
    <SelectFilter
      filterOpts
      controlId={controlId || 'select-warehouse-sector'}
      label={label || t('declareToControl.filters.warehouseSector','Warehouse sector')}
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      error={error}
      placeholder={placeholder}
    />
  );
}

export default WarehouseSectorFilter;
