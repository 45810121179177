import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../functions/formatDate';
import { arrayMax, getPercentAutoImpacted } from './functions';
import COLORS from '../../consts/colors';
import checkImageSrc from '../../images/robot-auto-impacted-icon.svg';

const checkImage = new Image(24, 48);
checkImage.src = checkImageSrc;

const tooltipInitial = {
  date: null,
  value: null,
  lines: [],
  style: {},
}

const getBarColor = (tags, maxTags) => COLORS.VTMN_SUCCESS;

const HistoryChart = ({ history }) => {
  const { t } = useTranslation();
  const chartRef = useRef();
  const [tooltip, setTooltip] = useState(tooltipInitial);
  const [tooltipIndex, setTooltipIndex] = useState();
  const [barsData, setBarsData] = useState([]);
  const totalUnsoldTagsList = history.map(x => x.totalUnsoldTags);
  const autoImpacted = history.map(x => x.autoImpacted ? x.totalUnsoldTags : null);
  const percentAutoImpacted = getPercentAutoImpacted(history);

  useEffect(() => {
    const data = history.map((row) => {
      const lines = [];
      const inventoryId = row ? row.lastInventoryId : '';
      lines.push({
        value: t('monitoringRobot.history.infoTitle', 'INVENTORY INFORMATION'),
        className: 'color-wrapper sublabel margin-top'
      });
      if (inventoryId) {
        lines.push({
          value: `${t('monitoringRobot.history.inventoryId', 'Inventory ID')}: ${inventoryId}`,
        });
      };
      lines.push({
        value: (
          <>
            <span className="chart-color" style={{backgroundColor: COLORS.VTMN_SUCCESS}}>&nbsp;</span>
            {t('monitoringRobot.history.stockRead', 'Stock read')}: {row.totalUnsoldTags}
          </>
        ),
        className: 'color-wrapper'
      });

      if (row.it_sales_stock) {
        lines.push({
          value: `${t('monitoringRobot.history.itSalesStock', 'It sales stock')}: ${row.it_sales_stock} tags`,
        });
      }
      if (row.coverage_percentage) {
        lines.push({
          value: `${t('monitoringRobot.history.coverage', 'Coverage %')}: ${row.coverage_percentage}`,
        });
      }
      if (row.reading_state) {
        lines.push({
          value: `${t('monitoringRobot.history.readingState', 'Reading state')}: ${row.reading_state}`,
        });
      }

      const models = (
        (row.autoImpactModels || 0)
        + (row.determinationInProgressModels || 0)
        + (row.noAutoImpactModels || 0)
      );

      const deltaBelow = row.auto_impact_deltas_below_thresholds || 0;
      const deltaValueLow = row.auto_impact_delta_value_low || 0;
      const toZero = row.auto_impact_to_zero || 0;
      const noImpactIndeterminable = row.no_auto_impact_indeterminable || 0;
      const noSufficientQuantity = row.no_auto_impact_no_sufficient_quantity || 0;
      const modelsTotal = deltaBelow + deltaValueLow + toZero + noImpactIndeterminable + noSufficientQuantity;
      const autoImpactModelsPercent = (row.autoImpactModels * 100 / models || 0).toFixed(1);
      
      lines.push({
        value: t('monitoringRobot.history.autoImpact', 'AUTOIMPACT'),
        className: 'color-wrapper sublabel margin-top'
      });
      lines.push({
        value: (
          <>
            <span className="chart-color" style={{backgroundColor: COLORS.VTMN_BRAND_DIGITAL}}>&nbsp;</span>
            <span className="blue-text sublabel">
              {t('monitoringRobot.history.models', 'Models')}: {autoImpactModelsPercent}% ({modelsTotal || models} models)
            </span>
          </>
        ),
        className: 'color-wrapper'
      });
      
      if (deltaBelow) {
        lines.push({
          value: `${t('monitoringRobot.history.deltaBelowThreshold', 'Delta below threshold')}:
                  ${deltaBelow} (${(deltaBelow * 100 / modelsTotal).toFixed(1)}%)`,
          className: 'green-text',
        });
      }
      if (deltaValueLow) {
        lines.push({
          value: `${t('monitoringRobot.history.impactDeltaValueLow', 'Delta value low')}:
                  ${deltaValueLow} (${(deltaValueLow * 100 / modelsTotal).toFixed(1)}%)`,
          className: 'green-text',
        });
      }
      if (toZero) {
        lines.push({
          value: `${t('monitoringRobot.history.impactToZero', 'Impact to zero')}:
                  ${toZero} (${(toZero * 100 / modelsTotal).toFixed(1)}%)`,
          className: 'green-text',
        });
      }
      if (noImpactIndeterminable) {
        lines.push({
          value: `${t('monitoringRobot.history.noImpactIndeterminable', 'No impact indeterminable')}:
                  ${noImpactIndeterminable} (${(noImpactIndeterminable * 100 / modelsTotal).toFixed(1)}%)`,
          className: 'red-text',
        });
      }
      if (noSufficientQuantity) {
        lines.push({
          value: `${t('monitoringRobot.history.noSufficientQuantity', 'No sufficient quantity')}:
                  ${noSufficientQuantity} (${(noSufficientQuantity * 100 / modelsTotal).toFixed(1)}%)`,
          className: 'red-text',
        });
      }
      const familyTotalImpact = row.impactTotalFamilies || 0;
      const familyPartialImpact = row.impactPartialFamilies || 0;
      const familiesTotal = familyTotalImpact + familyPartialImpact;
      const impactPartialFamiliesPercent = (familyPartialImpact * 100 / familiesTotal || 0).toFixed(1);
      const impactTotalFamiliesPercent = (familyTotalImpact * 100 / familiesTotal || 0).toFixed(1);

      if (familiesTotal) {
        lines.push({
          value: <span className="blue-text sublabel">
                  {t('monitoringRobot.history.family', 'Family')}: {familiesTotal}
                </span>,
          className: 'margin-top',
        });
      }
      if (familyTotalImpact) {
        lines.push({
          value: `${t('monitoringRobot.history.familyTotalImpact', 'Total impact')}: ${familyTotalImpact} (${impactPartialFamiliesPercent}%)`,
          className: 'green-text',
        });
      }
      if (familyPartialImpact) {
        lines.push({
          value: `${t('monitoringRobot.history.familyPartialImpact', 'Partial impact')}: ${familyPartialImpact} (${impactTotalFamiliesPercent}%)`,
          className: 'green-text',
        });
      }

      return lines;
    });

    setBarsData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const maxTags = arrayMax(totalUnsoldTagsList);
  const data = {
    labels: history.map(x => formatDate(new Date(x.date))),
    datasets: [
      {
        type: 'line',
        data: percentAutoImpacted,
        borderColor: COLORS.VTMN_BRAND_DIGITAL,
        backgroundColor: COLORS.VTMN_BRAND_DIGITAL,
        fill: false,
        yAxisID: 'yRight',
        label: t('monitoringRobot.history.percentImpacted', 'Auto-impacted models percent'),
      },
      {
        // dataset for checkmark icons
        type: 'line',
        data: autoImpacted,
        pointStyle: checkImage,
        showLine: false,
        yAxisID: 'yLeft',
        label: 'checkIcon',
      },
      {
        label: t('monitoringRobot.history.stockRead', 'Stock read'),
        type: 'bar',
        data: totalUnsoldTagsList,
        backgroundColor: totalUnsoldTagsList.map(x => getBarColor(x, maxTags)),
        yAxisID: 'yLeft',
      },
    ]
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          id: 'yLeft',
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: "Amount of tags",
          },
        },
        {
          id: 'yRight',
          position: 'right',
          gridLines: {
            display: false,
          },
          ticks: {
            max: 100,
            min: 0,
            stepSize: 10,
          },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: "Percent of models auto-impacted",
          },
        }
      ],
    },
    tooltips: {
      intersect: false,
      mode: 'label',
      itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
      position: 'average',
      enabled: false,
      custom: function(tooltipModel) {
        // if chart is not defined, return early
        const chart = chartRef.current;
        if (!chart) {
          return;
        }

        const position = chart.chartInstance.canvas.getBoundingClientRect();
        // hide the tooltip when chartjs determines you've hovered out
        if (tooltipModel.opacity === 0) {
          setTooltipIndex(null);
          setTooltip(tooltipInitial);
          return;
        }

        const dataIndex = tooltipModel.dataPoints[0].index;
        if (dataIndex === tooltipIndex) {
          return;
        }
        setTooltipIndex(dataIndex);

        // set values for display of data in the tooltip
        const date = tooltipModel.dataPoints[0].xLabel;
        const value = tooltipModel.dataPoints[0].yLabel;
        const {offsetLeft: positionX, offsetTop: positionY, scrollWidth} = chart.chartInstance.canvas;
        const style = {
          position: 'absolute',
          opacity: 1,
          top: positionY + tooltipModel.caretY + 'px',
          fontFamily: tooltipModel._bodyFontFamily,
          fontSize: tooltipModel.bodyFontSize + 'px',
          fontStyle: tooltipModel._bodyFontStyle,
          padding: tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px',
          pointerEvents: 'none',
        }
        if (scrollWidth / 2 > tooltipModel.caretX) {
          style.left = position.left + window.scrollX + tooltipModel.caretX + 'px';
          style.right = 'inherit';
        } else {
          style.right = scrollWidth + position.left * 2 - (positionX + tooltipModel.caretX) + 'px';
          style.left = 'inherit';
        }

        const lines = barsData[dataIndex];

        setTooltip({style, date, value, lines});
      },
    }
  }
  return (
    <div className="monitoringRobot-chart">
      <Bar
        data={data}
        options={options} 
        plugins={[ChartAnnotation]}
        ref={chartRef}
      />
      {tooltip.value && (
        <div className="end2end-chartjs-tooltip" style={tooltip.style}>
          <table>
            <thead>
              <tr><th>{tooltip.date}</th></tr>
            </thead>
            <tbody>
              {tooltip.lines.map((line, i) => (
                <tr key={i}>
                  <td className={`${line.className}`}>{line.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HistoryChart;
