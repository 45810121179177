import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import useRequest from '../../functions/useRequest';
import { Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import renderFlagItem from './renderFlagItem';
import SelectToggleButton from './SelectToggleButton';
import '../../stylesheets/countrySelect.css';

import blankFlag from "../../stylesheets/blankFlag.gif";

const getCountries = async (user, updateTokens) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/countries?sort=name`;
  const countries = await fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  }).then(handleApiResponse);
  return countries.map(({ id, code, name }) => ({
    id,
    countryCode: code,
    label: name,
  }));
}


const CountrySelect = ({ selected, onChange, user, updateTokens, filterOpts }) => {
  const { t } = useTranslation();

  const [{
    loading,
    error,
    data: countries,
  }, fetchCountries] = useRequest(getCountries);

  useEffect(() => {
    fetchCountries(user, updateTokens);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCountries = (selected && countries && countries.filter(x => String(x.id) === String(selected))) || [];
  const [selectedCountry] = selectedCountries;
  const hasValue = selectedCountries && !!selectedCountries.length;

  return (
    <Form.Group as={Row} className="d-flex country-select flex-row align-items-center justify-space-between">
      <div className="country-flag">
        <img
          src={blankFlag}
          className={`flag flag-${selectedCountry?.countryCode?.toLowerCase()}`}
          alt={selectedCountry?.countryCode}
        />
      </div>
      <div className="col-lg">
        <Typeahead
          id="select-store-id"
          renderMenuItemChildren={renderFlagItem}
          placeholder={t('countrySelect.placeholder', 'Select country...')}
          disabled={loading}
          isLoading={loading}
          isInvalid={!!error}
          options={countries}
          filterBy={filterOpts ? undefined : () => true}
          selected={selectedCountries}
          paginationText={t('defaultMoreResults', 'Display more results...')}
          onChange={([country]) => onChange(country && country.id)}
        >
          {({ isMenuShown, toggleMenu }) => (
            !hasValue && (
              <SelectToggleButton
                isOpen={isMenuShown}
                onClick={e => {
                  toggleMenu();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              />
            )
          )}
        </Typeahead>
        {!loading && error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('defaultError', 'Error')}
          </Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  );
};

export default CountrySelect;
