import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import SettingsForm from './SettingsForm';

const getSettings = ({
  user,
  updateTokens,
  siteId,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/settings/${siteId}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const createSettings = ({
  user,
  updateTokens,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/settings`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateSettings = ({
  user,
  updateTokens,
  storeId,
  settings,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/efficiency/settings/${storeId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify(settings),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const EfficiencySettings = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { siteId } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const predefinedStore = (search && (new URLSearchParams(search)).get('store')) || null;
  const isNew = siteId === 'new';
  const title = isNew
    ? t('efficiencyDeployment.settings.new', 'New measurement efficiency settings')
    : t('efficiencyDeployment.settings.edit', 'Edit measurement efficiency settings');
  const [settings, setSettings] = useState({});

  const [{
    loading,
    error,
  }, fetchSettings] = useRequest(getSettings, {
    onSuccess: setSettings
  });

  useEffect(() => {
    if (!isNew) {
      fetchSettings({
        siteId,
        user,
        updateTokens,
      });
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, siteId, fetchSettings]);

  useEffect(() => {
    if (predefinedStore) {
      setSettings({ id: predefinedStore });
      return;  
    }
    setSettings({});
  }, [predefinedStore])

  const [{
    loading: saving,
    error: saveError,
  }, saveSettings] = useRequest(isNew ? createSettings : updateSettings, {
    onSuccess: () => history.goBack(),
  });

  const onSave = () => saveSettings({
    storeId: siteId,
    settings,
    user,
    updateTokens,
  });

  const hasError = !loading && !saving && (error || saveError);
  const isReady = !loading && !saving && !error && !!settings;

  return (
    <Container className="adminDeploymentGate">
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
          {(loading || saving) && <Loader />}
          {isReady && (
            <SettingsForm
              user={user}
              updateTokens={updateTokens}
              settings={settings}
              setSettings={setSettings}
              isNew={isNew}
            />
          )}
          {hasError && <ErrorMessage error={error} />}
        </Card.Body>
        <Card.Footer className="p-0">
          <Button
            variant="secondary"
            className="icon-button"
            onClick={() => history.goBack()}
          >
            <i className="vtmn-icon_cross"></i>
            <Trans i18nKey="efficiencyDeployment.settings.cancel">Cancel</Trans>
          </Button>
          <Button
            className="icon-button float-right"
            onClick={onSave}
            disabled={!isReady || !settings || !settings.id}
          >
            <i className="vtmn-icon_valid"></i>
            <Trans i18nKey="efficiencyDeployment.settings.save">Save</Trans>
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default EfficiencySettings;
