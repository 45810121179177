import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import IntEdit from '../../widgets/form/IntEdit';
import InvolvementEdit from './InvolvementEdit';
import NumberEdit from '../../widgets/form/NumberEdit';
import DurationEdit from '../../widgets/form/DurationEdit';
import { formatCurrency } from '../../../functions/formatMoney';
import { numberRenderer } from './index';

const PlannedPanel = ({ efficiency, setEfficiency }) => {
  const { t } = useTranslation();
  const currency = formatCurrency(efficiency.currency);
  const calculated = {
    teamUnloadingHours: (efficiency.planned_unloading_involvement
      ? _.sum(efficiency.planned_unloading_involvement) 
      : efficiency.planned_unloading_teammates) * (efficiency.planned_unloading_duration / 3600),
    teamShelvingHours: (efficiency.planned_shelving_involvement
      ? _.sum(efficiency.planned_shelving_involvement) 
      : efficiency.planned_shelving_teammates) * (efficiency.planned_shelving_duration / 3600),
    shelvingDuration: (efficiency.planned_quantities * 3600)
      / (efficiency.planned_shelving_involvement
        ? _.sum(efficiency.planned_shelving_involvement) 
        : efficiency.planned_shelving_teammates)
      / efficiency.planned_shelving_productivity
  };

  return (
    <>
      <h4>Planned</h4>
      <div className="d-flex flex-wrap">
        <IntEdit
          disabled
          label={t('efficiencyAdmin.columns.quantities', 'Quantities')}
          value={efficiency.planned_quantities}
          onChange={(planned_quantities) => setEfficiency({ ...efficiency, planned_quantities })}
          controlId="quantities"
        />
        <NumberEdit
          label={t('efficiencyAdmin.columns.shelvingProductivity', 'Shelving productivity')}
          value={efficiency.planned_shelving_productivity}
          onChange={(planned_shelving_productivity) => setEfficiency({ ...efficiency, planned_shelving_productivity })}
          controlId="planned_shelving_productivity"
        />
        <InvolvementEdit
          label={t('efficiencyAdmin.columns.unloadingTeammates', 'Unloading teammates')}
          teammates={efficiency.planned_unloading_teammates}
          involvement={efficiency.planned_unloading_involvement}
          onChange={(unloading_teammates, unloading_involvement) => setEfficiency({
            ...efficiency,
            planned_unloading_teammates: unloading_teammates,
            planned_unloading_involvement: unloading_involvement,
          })}
          controlId="unloading_teammates"
          maxValue={efficiency.planned_unloading_duration}
        />
        <InvolvementEdit
          label={t('efficiencyAdmin.columns.shelvingTeammates', 'Shelving teammates')}
          teammates={efficiency.planned_shelving_teammates}
          involvement={efficiency.planned_shelving_involvement}
          onChange={(shelving_teammates, shelving_involvement) => setEfficiency({
            ...efficiency,
            planned_shelving_teammates: shelving_teammates,
            planned_shelving_involvement: shelving_involvement,
          })}
          controlId="shelving_teammates"
          maxValue={efficiency.planned_shelving_duration}
        />
        <DurationEdit
          label={t('efficiencyAdmin.columns.unloadingDuration', 'Unloading duration')}
          value={efficiency.planned_unloading_duration}
          onChange={(planned_unloading_duration) => setEfficiency({ ...efficiency, planned_unloading_duration })}
          controlId="planned_unloading_duration"
        />
        <DurationEdit
          disabled
          label={t('efficiencyAdmin.columns.shelvingDuration', 'Shelving duration')}
          value={calculated.shelvingDuration || efficiency.planned_shelving_duration}
          controlId="planned_shelving_duration"
        />
      </div>
      <div className="d-flex flex-wrap">
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.plannedUnloadingTeamHours', 'Teammate unloading hours')}`}
          value={numberRenderer(calculated.teamUnloadingHours || efficiency.planned_unloading_hours)}
          controlId="planned_unloading_hours"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.plannedShealvingTeamHours', 'Teammate shelving hours')}`}
          value={numberRenderer(calculated.teamShelvingHours || efficiency.planned_shelving_hours)}
          controlId="planned_shelving_hours"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.unloadingInvestment', 'Unloading investment')} (${currency})`}
          //need to think about whether it is worth using the current cost_per_hour value
          value={numberRenderer((calculated.teamUnloadingHours * efficiency.cost_per_hour) || efficiency.planned_unloading_investment)}
          controlId="planned_unloading_investment"
        />
        <NumberEdit
          disabled
          label={`${t('efficiencyAdmin.columns.shelvingInvestment', 'Shelving investment')} (${currency})`}
          value={numberRenderer((calculated.teamShelvingHours * efficiency.cost_per_hour) || efficiency.planned_shelving_investment)}
          controlId="planned_shelving_investment"
        />        
      </div>
    </>
  );
};

export default PlannedPanel;
