import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Tabs, Tab } from 'react-bootstrap';
import dayjs from 'dayjs';

import Filters from './Filters';
import { getFields, getDefaultSelection, getSelectedFields} from './fields';
import { formatDateForBigQuery, formatDateTimeForBigQuery } from '../../../../functions/formatDate';
import { DataTable, TableFooter } from '../../../widgets/DataTable';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import getFiltersWithInverted from '../../../../functions/getFiltersWithInverted';
import '../../../../stylesheets/warehouseContenantsDeclareToControl.css';

const getDiscrepancies = (user, updateTokens, { filters, pagination, sort }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateTimeForBigQuery(formatedFilters.receptionDateTo);
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const postToControl = (user, updateTokens, { warehouse, discrepancyId, address, contenant, picker, warehouseSector }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/${discrepancyId}`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({ address, contenant, picker, warehouseSector }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const updateControl = (user, updateTokens, { warehouse, discrepancyId, controlId, address, contenant, picker, warehouseSector }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/${discrepancyId}/${controlId}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ address, contenant, picker, warehouseSector }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const removeControl = (user, updateTokens, { warehouse, discrepancyId, controlId }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/${discrepancyId}/${controlId}`;
  return fetchWithJWT(url, {
    method: 'DELETE',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const getOnInputBlur = ({ t, updateDataRow, user, updateTokens, warehouse }) => ({
  id,
  controlId,
  address,
  contenant,
  picker,
  warehouseSector,
  rowIndex,
}) => {
  if (_.isNil(address) || _.isNil(contenant) || _.isNil(picker)) {
    return;
  }
  let errorSector;
  if (warehouseSector && !/^\d{2}$/.test(warehouseSector)) {
    errorSector = t('declareToControl.errors.twoDigits', 'Only 2 digits as warehouse sector');
  }
  if (errorSector) {
    updateDataRow(rowIndex, {
      address,
      contenant,
      picker,
      warehouseSector,
      saving: false,
      error: {
        warehouseSector: errorSector,
      },
    });
    return;
  }
  updateDataRow(rowIndex, {
    address,
    contenant,
    picker,
    warehouseSector,
    saving: true,
    error: null,
  });
  (
    controlId
      ? updateControl(user, updateTokens, { warehouse, discrepancyId: id, controlId, address, contenant, picker, warehouseSector })
      : postToControl(user, updateTokens, { warehouse, discrepancyId: id, address, contenant, picker, warehouseSector })
  )
  .then(({ id: controlId }) => {
    updateDataRow(rowIndex, {
      address,
      contenant,
      controlId,
      controlStatus: 'new',
      saving: false,
      saved: true,
      error: null,
      clientSideId: undefined,
    });
  })
  .catch(error => {
    updateDataRow(rowIndex, {
      address,
      contenant,
      saving: false,
      saved: false,
      error,
    });
  });
};

const getOnCancelControl = ({ updateDataRow, user, updateTokens, warehouse, data }) => ({ id, controlId, rowIndex }) => {
  updateDataRow(rowIndex, {
    saving: true,
    saved: false,
    error: null,
  });
  removeControl(user, updateTokens, { warehouse, discrepancyId: id, controlId })
  .then(() => {
    const newIndex = data.findIndex((x) => x.controlId === controlId);
    if (newIndex !== -1) {
      updateDataRow(newIndex, {
        controlId: undefined,
        controlStatus: null,
        address: undefined,
        contenant: undefined,
        saving: false,
        saved: false,
        error: null,
      });
    }
  })
  .catch(error => {
    const newIndex = data.findIndex((x) => x.saving && x.controlId === controlId);
    updateDataRow(newIndex, {
      saving: false,
      saved: false,
      error,
    });
  });
};

const DeclareToControlTab = ({ user, updateTokens, discrepancyType }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const initialFilters = {
    receptionDateFrom: dayjs().startOf('day').subtract(7, 'day').toDate(),
    receptionDateTo: dayjs().endOf('day').toDate(),
  };
  const [invertedFilters, setInvertedFilters] = useState({});
  const [
    {
      loading,
      data,
      error,
      filters,
      pagination,
      sort,
      totals,
    },
    fetchDiscrepancies,
    setFilters,
    setPagination,
    setSort,
    updateDataRow,
    insertDataRow,
    deleteDataRow,
  ] = useTableRequest(
    getDiscrepancies, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  const updateData = ({ page, size = pagination.size}) => {
    if (page === pagination.page) {
      fetchDiscrepancies(user, updateTokens, {
        filters: {
          ...getFiltersWithInverted(filters, invertedFilters),
          ...(discrepancyType !== 'wrong_size' ? { controllable: true } : {}),
          discrepancyType,
        },
        pagination,
        sort,
      }, warehouse);
      return;
    }
    setPagination({ page, size });
  };

  useEffect(() => {
    updateData(pagination);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, discrepancyType, filters, invertedFilters, pagination, sort]);
  const onInputBlur = getOnInputBlur({ t, updateDataRow, user, updateTokens, warehouse });
  const onCancelControl = getOnCancelControl({ updateDataRow, user, updateTokens, warehouse, data });
  const allFields = getFields({
    t,
    discrepancyType,
  });
  const [fieldsSelection, setFieldsSelection] = useState(getDefaultSelection(allFields));
  const fields = getSelectedFields({
    fields: allFields,
    selection: fieldsSelection,
    t,
    updateDataRow,
    insertDataRow,
    deleteDataRow,
    onInputBlur,
    onCancelControl,
  });

  return (
    <>
      <Filters
        user={user}
        updateTokens={updateTokens}
        discrepancyType={discrepancyType}
        filters={filters}
        setFilters={setFilters}
        onClear={() => {
          setFilters(initialFilters);
          setInvertedFilters({});
        }}
        invertedFilters={invertedFilters}
        setInvertedFilters={setInvertedFilters}
        totals={totals}
        sort={sort}
        fields={allFields}
        fieldsSelection={fieldsSelection}
        setFieldsSelection={setFieldsSelection}
        updateData={updateData}
      />
      <DataTable
        data={data}
        fields={fields}
        loading={loading}
        error={error}
        sort={sort}
        setSort={setSort}
        rowKey={(row) => {
          if (row.controlId) {
            return `${row.id}-controlled-${row.controlId}`;
          } else if (row.clientSideId) {
            return `${row.id}-draft-${row.clientSideId}`;
          } else {
            return `${row.id}-prestine`;
          }
        }}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </>
  )
};

const DeclareToControl = ({ user, updateTokens }) => {
  const [tab, setTab] = useState('expedition');
  const { t } = useTranslation();
  return (
    <Container className="warehouseContenantsDeclareToControl" fluid>
      <h2>
        <Trans i18nKey="declareToControl.title">Declare to control</Trans>
      </h2>
      <Tabs
        id="declareToControl-tabs"
        activeKey={tab}
        onSelect={(k) => setTab(k)}
      >
        <Tab eventKey="expedition" title={t('declareToControl.expedition', 'Expedition')}>
          {tab === 'expedition' && (
            <DeclareToControlTab
              user={user}
              updateTokens={updateTokens}
              discrepancyType="expedition"
            />
          )}
        </Tab>
        <Tab eventKey="unexpected" title={t('declareToControl.unexpected', 'Unexpected')}>
          {tab === 'unexpected' && (
            <DeclareToControlTab
              user={user}
              updateTokens={updateTokens}
              discrepancyType="unexpected"
            />
          )}
        </Tab>
        <Tab eventKey="wrong_size" title={t('declareToControl.wrongSize', 'Wrong size')}>
          {tab === 'wrong_size' && (
            <DeclareToControlTab
              user={user}
              updateTokens={updateTokens}
              discrepancyType="wrong_size"
            />
          )}
        </Tab>
      </Tabs>
    </Container>
  )
}

export default DeclareToControl;

