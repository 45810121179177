import React from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next'
import SelectToggleButton from '../SelectToggleButton';

const SelectFilter = ({
  controlId,
  label,
  options,
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
  multiple,
  filterOpts,
  disabled,
  loading,
  error,
  renderItem,
  placeholder,
}) => {
  const { t } = useTranslation();
  const selectedItems = multiple ? (
    (selected && options.filter(item => (selected || []).indexOf(String(item.id)) !== -1)) || []
  ) : (
    (selected && options.filter(item => String(item.id) === String(selected))) || []
  );
  const hasValue = selectedItems && selectedItems.length;
  return (
    <Form.Group className="filter-item">
      <Form.Label>
        <span className="filter-label-text">
          {label}
          {inverted && t('filters.excludes', ' excludes')}
          {':'}
        </span>
        {invertable && (
          <Form.Check
            id={`${controlId}-invert-switch`}
            type="switch"
            label={t('filters.invert', 'exclude')}
            checked={inverted || false}
            onChange={onInvert}
          />
        )}
      </Form.Label>
      <div className="filter-select">
        <Typeahead
          size="sm"
          id={controlId}
          options={options}
          disabled={disabled}
          isLoading={loading}
          isInvalid={!!error}
          className={error ? 'is-invalid' : undefined}
          selected={selectedItems}
          multiple={multiple}
          placeholder={placeholder}
          paginationText={t('defaultMoreResults', 'Display more results...')}
          clearButton
          filterBy={filterOpts ? undefined : () => true}
          renderMenuItemChildren={renderItem}
          onChange={multiple
            ? (opts) => {
              if (opts && opts.length) {
                onChange(opts.map(({ id }) => String(id)))
              } else {
                onChange(undefined);
              }
            }
            : ([opt]) => onChange(opt && opt.id)
          }
        >
          {({ isMenuShown, toggleMenu }) => (
            !hasValue && !error && (
              <SelectToggleButton
                isOpen={isMenuShown}
                onClick={e => {
                  toggleMenu();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              />
            )
          )}
        </Typeahead>
        {!loading && error && (
          <Form.Control.Feedback type="invalid">
            {(error && error.message) || t('defaultError', 'Error')}
          </Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  );
}

export default SelectFilter;
