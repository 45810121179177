
import { formatDurationSec, formatDurationHours } from '../../functions/formatDate'
import formatMoney from '../../functions/formatMoney'

import teammatesIcon from '../../images/dashboard_icons/teammates.png';
import durationsIcon from '../../images/dashboard_icons/duration.png';
import boxIcon from '../../images/dashboard_icons/box.png';
import tabletIcon from '../../images/dashboard_icons/tablet.png';
import ecoIcon from '../../images/dashboard_icons/eco.png';
import moneyIcon from '../../images/dashboard_icons/money.png';
import storeIcon from '../../images/dashboard_icons/store.png';
import shelvingIcon from '../../images/dashboard_icons/shelving.png';

export const getCards = (t, dashboard, isCountryView) => [
  ...(isCountryView ? [
    {
      icon: storeIcon,
      header: {
        value: dashboard.store_count,
        unit: null,
        title: t('efficiencyDashboard.cards.stores.title', 'Active stores'),
      },
      lines: [],
    },
  ] : []),
  {
    icon: tabletIcon,
    header: {
      value: null,
      unit: null,
      title: t('efficiencyDashboard.cards.overallProductivity.title', 'Overall Productivity'),
    },
    lines: [
      {
        value: dashboard.planned_overall_productivity,
        unit: t('efficiencyDashboard.cards.productivity.unit', 'ART / hour'),
        title: t('efficiencyDashboard.planned', 'Planned'),
      },
      {
        value: dashboard.overall_productivity,
        unit: t('efficiencyDashboard.cards.productivity.unit', 'ART / hour'),
        title: t('efficiencyDashboard.achieved', 'Achieved'),
      },
    ],
  },
  {
    icon: teammatesIcon,
    header: {
      value: null,
      unit: null,
      title: t('efficiencyDashboard.cards.teammateHours.title', 'Total teammates hours'),
    },
    lines: [
      {
        value: formatDurationHours(dashboard.planned_teammate_hours_sec),
        unit: t('efficiencyDashboard.cards.teammateHours.unit', 'HRS MINS'),
        title: t('efficiencyDashboard.planned', 'Planned'),
      },
      {
        value: formatDurationHours(dashboard.teammate_hours_sec),
        unit: t('efficiencyDashboard.cards.teammateHours.unit', 'HRS MINS'),
        title: t('efficiencyDashboard.achieved', 'Achieved'),
      },
    ],
  },
  {
    icon: durationsIcon,
    header: {
      value: formatDurationSec(dashboard.total_duration),
      unit: t('efficiencyDashboard.cards.duration.unit', 'HRS MINS SECS'),
      title: t('efficiencyDashboard.cards.duration.title', 'AVG Duration'),
    },
    lines: [
      {
        value: formatDurationSec(dashboard.unloading_duration),
        unit: t('efficiencyDashboard.cards.duration.unit', 'HRS MINS SECS'),
        title: t('efficiencyDashboard.forUnloading', 'For unloading + sorting'),
      },
      {
        value: formatDurationSec(dashboard.shelving_duration),
        unit: t('efficiencyDashboard.cards.duration.unit', 'HRS MINS SECS'),
        title: t('efficiencyDashboard.forShelving', 'For shelving'),
      },
    ],
  },
  {
    icon: shelvingIcon,
    header: {
      value: null,
      unit: null,
      title: t('efficiencyDashboard.cards.shelvingProductivity.title', 'Shelving Productivity'),
    },
    lines: [
      {
        value: dashboard.planned_shelving_productivity,
        unit: t('efficiencyDashboard.cards.productivity.unit', 'ART / hour'),
        title: t('efficiencyDashboard.planned', 'Planned'),
      },
      {
        value: dashboard.shelving_productivity,
        unit: t('efficiencyDashboard.cards.productivity.unit', 'ART / hour'),
        title: t('efficiencyDashboard.achieved', 'Achieved'),
      },
    ],
  },
  {
    icon: teammatesIcon,
    header: {
      value: null,
      unit: null,
      title: t('efficiencyDashboard.cards.teammates.title', 'AVG Teammates'),
    },
    lines: [
      {
        value: dashboard.unloading_teammates,
        unit: null,
        title: t('efficiencyDashboard.forUnloading', 'For unloading + sorting'),
      },
      {
        value: dashboard.shelving_teammates,
        unit: null,
        title: t('efficiencyDashboard.forShelving', 'For shelving'),
      },
    ],
  },
  {
    icon: boxIcon,
    header: {
      value: dashboard.quantities,
      unit: t('efficiencyDashboard.cards.quantity.unit', 'ART'),
      title: t('efficiencyDashboard.cards.quantity.title', 'AVG Reception size'),
    },
    lines: [],
  },
  ...(dashboard.show_investment
  ? [{
      icon: ecoIcon,
      header: {
        value: formatMoney(dashboard.delta_investment, dashboard.currency),
        unit: null,
        title: t('efficiencyDashboard.cards.deltaInvestment.title', 'Delta Investment'),
      },
      lines: [
        {
          value: formatMoney(dashboard.planned_total_investment_sum, dashboard.currency),
          unit: null,
          title: t('efficiencyDashboard.planned', 'Planned'),
        },
        {
          value: formatMoney(dashboard.total_investment_sum, dashboard.currency),
          unit: null,
          title: t('efficiencyDashboard.achieved', 'Achieved'),
        },
      ],
    }]
  : []),
  ...(dashboard.show_investment
  ? [{
      icon: moneyIcon,
      header: {
        value: formatMoney(dashboard.total_investment_avg, dashboard.currency),
        unit: null,
        title: t('efficiencyDashboard.cards.avgInvestment.title', 'AVG Investment'),
      },
      lines: [
        {
          value: formatMoney(dashboard.unloading_investment_avg, dashboard.currency),
          unit: null,
          title: t('efficiencyDashboard.forUnloading', 'For unloading + sorting'),
        },
        {
          value: formatMoney(dashboard.shelving_investment_avg, dashboard.currency),
          unit: null,
          title: t('efficiencyDashboard.forShelving', 'For shelving'),
        },
      ],
    }]
  : []),
  ...(dashboard.show_investment
  ? [{
      icon: moneyIcon,
      header: {
        value: formatMoney(dashboard.total_investment_sum, dashboard.currency),
        unit: null,
        title: t('efficiencyDashboard.cards.sumInvestment.title', 'Total Investment'),
      },
      lines: [
        {
          value: formatMoney(dashboard.unloading_investment_sum, dashboard.currency),
          unit: null,
          title: t('efficiencyDashboard.forUnloading', 'For unloading + sorting'),
        },
        {
          value: formatMoney(dashboard.shelving_investment_sum, dashboard.currency),
          unit: null,
          title: t('efficiencyDashboard.forShelving', 'For shelving'),
        },
      ],
    }]
  : []),
];
