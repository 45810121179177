export const getFiltersParams = ({ filters }) => {
  const params = {};
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (filters[key] || filters[key] === false) {
        params[key] = filters[key];
      }
      if (filters[key] && Array.isArray(filters[key])) {
        params[key] = filters[key].map(x => String(x)).join(',');
      }
    });
  }
  return params;
};

export const getSortParams = ({ sort }) => {
  const params = {};
  if (sort) {
    params.sort = sort.field;
    if (sort.direction === 'DESC') {
      params.desc = sort.field;
    }
  }
  return params;
};

export const getPaginationParams = ({ pagination }) => {
  const params = {};
  if (pagination) {
    const from = pagination.page * pagination.size;
    const to = (pagination.page + 1) * pagination.size - 1;
    const range = `${from}-${to}`;
    params.range = range;
  }
  return params;
};

export const getQueryString = ({ filters, pagination, sort }) => {
  const filtersParams = getFiltersParams({ filters });
  const sortParams = getSortParams({ sort });
  const paginationParams = getPaginationParams({ pagination });
  const queryParams = {
    ...filtersParams,
    ...sortParams,
    ...paginationParams,
  };
  return new URLSearchParams(queryParams).toString();
}

export default getQueryString;
