import React from 'react';
import { Trans } from 'react-i18next';
import '../../stylesheets/monitoringTooltipContent.css';


const MonitoringTooltipContent = ({ robot, racket }) => (
  <div className="monitoringTooltipContent">
    <div className="row">
      <i className="success icon vtmn-icon_thumb_up"></i>
      <span>
        {robot ? (
          <Trans i18nKey="monitoring.tooltips.robotGreater90">last read greater then 90% of max</Trans>
        ) : (
          <Trans i18nKey="monitoring.tooltips.gateGreater90">last read greater then 90% of expected</Trans>
        )}
      </span>
    </div>
    <div className="row">
      <i className="warning vtmn-icon_thumb_down"></i>
      <span>
        {robot ? (
          <Trans i18nKey="monitoring.tooltips.robotLess90">last read less then 90% of max</Trans>
        ) : (
          <Trans i18nKey="monitoring.tooltips.gateLess90">last read less then 90% of expected</Trans>
        )}
      </span>
    </div>
    <div className="row">
      <i className="danger vtmn-icon_thumb_down"></i>
      <span>
        {robot ? (
          <Trans i18nKey="monitoring.tooltips.robotLess50">last read less then 50% of max</Trans>
        ) : (
          <Trans i18nKey="monitoring.tooltips.gateLess50">last read less then 50% of expected</Trans>
        )}
      </span>
    </div>
  </div>
);

export default MonitoringTooltipContent;
