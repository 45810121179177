import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { Button, Form, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import IntEdit from '../../widgets/form/IntEdit';
import RangeEdit from '../../widgets/form/RangeEdit';
import useDebounce from '../../../functions/useDebounce';

const fillInvolvement = (teammates, involvement, max) => {
  let nextTeammates = teammates || 0;
  if (nextTeammates < 0) {
    nextTeammates = 0;
  }
  if (nextTeammates > max) {
    nextTeammates = max;
  }
  const nextInvolvement = involvement.slice(0, nextTeammates);
  while (nextTeammates > nextInvolvement.length) {
    nextInvolvement.push(1)
  }
  return [nextTeammates, nextInvolvement];
};

const InvolvementEditModal = ({ controlId, label, onChange, teammates, involvement, show, onHide, maxValue }) => {
  
  const max = 100;
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(involvement.map((v) => v * maxValue));
  const [prevValue, setPrevValue] = useState(involvement.map((v) => v * maxValue));
  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    const nextInvolvement = localValue.map((v) => v > maxValue ? 1 : v / maxValue);
    onChange(teammates, nextInvolvement);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const teammateLabel = t('efficiencyAdmin.teammate','Teammate')
  const onEditTeammate = (val) => {
    const [nextTeammates, nextInvolvement] = fillInvolvement(val, involvement, max);
    const [ , prevInvolvement] = fillInvolvement(val, prevValue.map((x) => x / maxValue), max);
    onChange(nextTeammates, nextInvolvement);
    setLocalValue(nextInvolvement.map((v) => v * maxValue));
    setPrevValue(prevInvolvement.map((v) => v * maxValue));

  };

  const handleChange = (val, i) => {
    setLocalValue((prev) => {
      const nextValue = [...prev];
      nextValue[i] = _.round((val * 60 > maxValue ? maxValue : val * 60), 2);
      return nextValue;
    })
  }

  const close = () => {
    setPrevValue([...localValue]);
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans i18nKey="efficiencyAdmin.involvement">Involvement</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0" >
        <div className="d-flex flex-fill align-items-end">
          <Button className="involvement-edit-btn" onClick={() => onEditTeammate(teammates - 1)}>-</Button>
          <IntEdit
            className="involvement-edit-input"
            max={max}
            label={label}
            value={teammates}
            onChange={onEditTeammate}
            controlId={`involvement-${controlId}`}
          />
          <Button className="involvement-edit-btn" onClick={() => onEditTeammate(teammates + 1)}>+</Button>
        </div>
        {localValue.map((val, index) => {
          const isNegative = val < prevValue[index]
          const hours = 
            Math.trunc( (val - prevValue[index])/3600 ) !== 0
              ? `${ Math.trunc(Math.abs(val - prevValue[index])/3600)}  hours `
              : ''
          const roundFunc = isNegative ? Math.ceil : Math.floor
          const minutes = roundFunc(
              (Math.abs(prevValue[index] - val) % 3600
            ) / 60
          ).toString();

          return (
            <div key={`involve-${index}`}>
              <div className="involvement-val-wrap d-flex align-items-end" key={index}>
                <RangeEdit
                  label={`${teammateLabel} ${index + 1}`}
                  value={Math.ceil((val / 60) / 5) * 5}
                  min={0}
                  // make it a multiple of 5, otherwise the slider cannot be moved to the far right position
                  max={Math.ceil((maxValue / 60) / 5) * 5}
                  step={5} // 5min
                  onChange={(val) => handleChange(val, index)}
                />
                <div className="involvement-val">
                  {`${Math.floor(val/3600)}:${Math.ceil((val%3600) / 60).toString().padStart(2, '0')}`}
                </div>
              </div>
              <div className="involvement-difference">
                {isNegative && '-'}{hours}{minutes} min
              </div>
            </div>
          )
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button className="m-0" variant="primary" onClick={close}>
          <Trans i18nKey="shared.ok">OK</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const InvolvementEdit = ({ label, teammates, involvement, onChange, controlId, maxValue }) => {
  const involved = Number(_.sum(involvement)?.toFixed(2)) || teammates;
  const [show, setShow] = useState(false);

  return (
    <Form.Group className="form-item involvement-edit" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        type="button"
        value={`${teammates} (${involved})`}
        onClick={() => setShow(true)}
      />
      <InvolvementEditModal
        controlId={controlId}
        label={label}
        show={show}
        onHide={() => setShow(false)}
        onChange={onChange}
        teammates={teammates}
        involvement={involvement}
        maxValue={maxValue}
      />
    </Form.Group>
  );
};

export default InvolvementEdit;
