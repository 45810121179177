import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter from './SelectFilter';

const BoolFilter = ({
  controlId,
  label,
  selected,
  onChange,
}) => {
  const { t } = useTranslation();
  const options = [{
    id: 'true',
    label: t('filters.bool.yes', 'Yes'),
  }, {
    id: 'false',
    label: t('filters.bool.no', 'No'),
  }];
  return (
    <SelectFilter
      controlId={controlId}
      label={label}
      options={options}
      selected={selected}
      onChange={onChange}
    />
  );
}

export default BoolFilter;
