import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import SiteSelect from '../widgets/SiteSelect';
import SwitchFilter from '../widgets/filters/SwitchFilter';
import '../../stylesheets/racketForm.css';

const RacketForm = ({
  user,
  updateTokens,
  racket,
  setRacket,
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const predefinedStore = (search && (new URLSearchParams(search)).get('store')) || null;
  useEffect(() => {
    if (!racket.id && predefinedStore) {
      setRacket(prevRacket => ({ ...prevRacket, storeId: predefinedStore, isActive: !!predefinedStore }));
    }
  }, [racket.id, setRacket, predefinedStore]);

  return (
    <Form>
      <SiteSelect
        user={user}
        updateTokens={updateTokens}
        selected={racket.storeId}
        onChange={(storeId) => setRacket({ ...racket, storeId, isActive: true })}
        disabled={!!racket.id || !!predefinedStore}
        filter={{ withoutRacket: !racket.id || !predefinedStore }}
      />
      <Form.Group as={Row} controlId="racket-training-session">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRacket.trainingDate">
            Training date
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-datepicker-col">
          <Form.Control
            isClearable
            placeholderText={t('deploymentRacket.selectDate', 'Select date...')}
            as={DatePicker}
            selected={racket.trainingDate}
            onChange={(trainingDate) => setRacket({ ...racket, trainingDate })}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="racket-training-session">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRacket.ReceptionControlStartDate">
            Racket reception control start date
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="form-datepicker-col">
          <Form.Control
            isClearable
            placeholderText={t('deploymentRacket.selectDate', 'Select date...')}
            as={DatePicker}
            selected={racket.startDate}
            onChange={(startDate) => setRacket({ ...racket, startDate })}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="racket-comment">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRacket.comment">
            Comment
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8">
          <Form.Control
            as="textarea"
            rows={3}
            value={racket.comment || ''}
            onChange={(event) => setRacket({ ...racket, comment: event.target.value })}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="racket-isactive">
        <Form.Label column lg="4" className="text-lg-right">
          <Trans i18nKey="deploymentRacket.isActive">
            Active
          </Trans>
          {': '}
        </Form.Label>
        <Col lg="8" className="racket-active-switch">
          <SwitchFilter
            controlId="racket-isactive-switch"
            value={racket.isActive || false}
            onChange={() => setRacket({ ...racket, isActive: !racket.isActive })}
          />
        </Col>
      </Form.Group>
    </Form>
  );
};

export default RacketForm;
