import React from 'react';
import { Form } from 'react-bootstrap';

const TextEdit = ({ label, disabled, value, onChange, controlId }) => {
  return (
    <Form.Group className="form-item" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        disabled={disabled}
        type="text"
        value={value || ''}
        onChange={(event) => onChange && onChange(event.target.value)}
      />
    </Form.Group>
  );
};

export default TextEdit;
