const successCodes = [200, 201, 202, 206];

export const handleApiResponse = async (response) => {
  const { status } = response;
  let result = await response.text();
  try {
    result = JSON.parse(result);
  } catch (e) {
  }
  if (successCodes.indexOf(status) === -1) {
    const message =  result.message || result || response.statusText;
    const error = new Error(message);
    error.status = status;
    throw error;
  }

  return result;
};

const getContentRange = (contentRangeHeader, resultLength) => {
  let contentRange = {
    from: 0,
    to: resultLength && resultLength - 1,
    max: resultLength && resultLength - 1,
  };
  if (contentRangeHeader) {
    const [range, max] = contentRangeHeader.split('/');
    const [from, to] = range.split('-');
    contentRange = {
      from: Number(from),
      to: Number(to),
      max: Number(max),
    };
  }
  return contentRange;
};

export const handlePaginatedApiResponse = async (response) => {
  const { status, headers } = response;
  const contentRangeHeader = headers.get('Content-Range');

  let result = await response.text();
  try {
    result = JSON.parse(result);
  } catch (e) {
  }

  if (successCodes.indexOf(status) === -1) {
    const message =  result.message || result || response.statusText;
    const error = new Error(message);
    error.status = status;
    throw error;
  }
  const contentRange = getContentRange(contentRangeHeader, result && result.length);
  return { result, contentRange };
};

export default handleApiResponse;
