import JWTManager from './JWTManager';

const jwtManager = new JWTManager();

const defaultHeaders = {
  "Content-Type": "application/json",
};

const fetchWithJWT = async (
  url, {
    method,
    signal,
    headers,
    body,
    jwtOpts,
  }={},
) => {
  const {
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  } = jwtOpts || {};

  const authHeaders = await jwtManager.getAuthHeaders({
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  });
  return fetch(url, {
    method,
    signal,
    headers: {
      ...authHeaders,
      ...headers || defaultHeaders,
    },
    body,
  })
  .then(result => {
    if (result.status === 401) {
      window.location.reload();
    }
    return result;
  })
  .catch((error) => {
    if (
      error instanceof TypeError &&
      error.message === 'Failed to fetch'
    ) {
      throw new Error('Network error');
    }
    throw error;
  })
};

export const getAuthHeaders = async (jwtOpts) => {
  const {
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  } = jwtOpts || {};
  const authHeaders = await jwtManager.getAuthHeaders({
    token,
    refreshToken,
    tokenExpireDate,
    updateTokens,
  });
  return authHeaders;
}

export default fetchWithJWT;
