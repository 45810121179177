import React from 'react';
import LoaderSpinner from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import '../stylesheets/loader.css';


const Loader = ({ message }) => {
  const { t } = useTranslation();
  const messageText = message || t('loader.message', 'Loading...');
  return (
    <div className="row app-loader">
      <LoaderSpinner
        type="TailSpin"
        color="#00BFFF"
        height={50}
        width={50}
        className="app-loader-spinner"
      />
      {messageText}
    </div>
  );
}

export default Loader;
