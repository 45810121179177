import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import ru from './locales/ru.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import it from './locales/it.json';
import bg from './locales/bg.json';
import pl from './locales/pl.json';
import pt from './locales/pt.json';
import de from './locales/de.json';
import tr from './locales/tr.json';
import ro from './locales/ro.json';
import zh from './locales/zh.json';
import nl from './locales/nl.json';
import cz from './locales/cz.json';
import br from './locales/br.json';

const isDevelop = process.env.REACT_APP_my_env && process.env.REACT_APP_my_env.startsWith('develop');

const langDetectorOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'locale',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
  checkWhitelist: true,
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { // translation is the default namespace
        translation: en
      },
      ru: {
        translation: ru
      },
      fr: {
        translation: fr
      },
      es: {
        translation: es
      },
      it: {
        translation: it
      },
      bg: {
        translation: bg
      },
      pl: {
        translation: pl
      },
      pt: {
        translation: pt
      },
      de: {
        translation: de
      },
      ro: {
        translation: ro
      },
      zh: {
        translation: zh
      },
      nl: {
        translation: nl
      },
      tr: {
        translation: tr
      },
      cz: {
        translation: cz
      },
      br: {
        translation: br
      }
    },
    fallbackLng: 'en',
    debug: isDevelop,
    whitelist: ['en', 'ru', 'fr', 'es', 'it', 'bg', 'pl', 'pt', 'de', 'tr', 'ro', 'zh', 'nl', 'cz', 'br'],
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
