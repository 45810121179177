import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';

const trimSigns = (val) => {
  let res = val;
  // trim dots
  const index = val.indexOf('.');
  if (index > -1) {
    res = res.substr(0, index + 1) + res.slice(index).replace(/\./g, '');
  }

  // trim dashes
  res = res.replace(/-/g, '')
  const negative = val.startsWith('-');
  if (negative) {
    res = `-${res}`
  }

  return res;
};

const transformVal = (value) => _.isNil(value) ? '' : String(value);

const NumberEdit = ({ label, disabled, value, onChange, controlId }) => {
  const [textVal, setTextVal] = useState(transformVal(value));
  useEffect(() => {
    setTextVal(transformVal(value));
  }, [value]);

  const onEdit = (e) => {
    const rawVal = e.target.value;
    const nextTextVal = trimSigns(rawVal.replace(/,/g,'.').replace(/[^\d.-]/g, ''));
    const numVal = parseFloat(nextTextVal);
    const nextVal = (numVal || numVal === 0) ? numVal : null;
    return onChange && onChange(nextVal);
  };

  return (
    <Form.Group className="form-item" controlId={controlId}>
      <Form.Label>
        {label}
        {': '}
      </Form.Label>
      <Form.Control
        disabled={disabled}
        type="text"
        inputMode="decimal"
        value={textVal}
        onChange={onEdit}
      />
    </Form.Group>
  );
};

export default NumberEdit;
