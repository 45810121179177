import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectFilter from '../../../widgets/filters/SelectFilter';

export const getMovementCategories = (t, discrepancyType) => {
  const categories = {
    'control_not_done': t('reportingDiscrepancies.columns.movementCategoriesValues.controlNotDone', 'Stock control not done'),
    'as_suggested': discrepancyType === 'expedition'
      ? t('reportingDiscrepancies.columns.movementCategoriesValues.confirmedMissing', 'Confirmed missing')
      : t('reportingDiscrepancies.columns.movementCategoriesValues.asSuggested', 'Unexpected confirmed'),
    'no_tag': t('reportingDiscrepancies.columns.movementCategoriesValues.noTag', 'RFID reading mistake'),
    'smaller': t('reportingDiscrepancies.columns.movementCategoriesValues.smaller', 'Smaller movement than suggested'),
    'bigger': t('reportingDiscrepancies.columns.movementCategoriesValues.bigger', 'Bigger movement than suggested'),
    'grand_total': t('reportingDiscrepancies.grandTotal', 'Grand total'),
  };
  return categories;
};

export const getMovementCategoriesOptions = (t, isExpedition) => {
  const options = [
    {
      id: 'control_not_done',
      label: t('reportingDiscrepancies.columns.movementCategoriesValues.controlNotDone', 'Stock control not done'),
    },
    {
      id: 'as_suggested',
      label: isExpedition
        ? t('reportingDiscrepancies.columns.movementCategoriesValues.confirmedMissing', 'Confirmed missing')
        : t('reportingDiscrepancies.columns.movementCategoriesValues.asSuggested', 'Unexpected confirmed'),
    },
    {
      id: 'no_tag',
      label: t('reportingDiscrepancies.columns.movementCategoriesValues.noTag', 'RFID reading mistake'),
    },
    {
      id: 'smaller',
      label: t('reportingDiscrepancies.columns.movementCategoriesValues.smaller', 'Smaller movement than suggested'),
    },
    {
      id: 'bigger',
      label: t('reportingDiscrepancies.columns.movementCategoriesValues.bigger', 'Bigger movement than suggested'),
    },
  ];
  return options;
};

const MovementCategoriesFilter = ({
  selected,
  onChange,
  invertable,
  inverted,
  onInvert,
  isExpedition,
}) => {
  const { t } = useTranslation();
  const options = getMovementCategoriesOptions(t, isExpedition);
  return (
    <SelectFilter
      controlId="filter-movement-categories"
      label={t('reportingDiscrepancies.columns.movementCategories', 'Movement categories')}
      options={options}
      selected={selected}
      onChange={onChange}
      invertable={invertable}
      inverted={inverted}
      onInvert={onInvert}
      multiple
    />
  );
};

export default MovementCategoriesFilter;
